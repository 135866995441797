import React, { useRef, useState } from 'react';
import { useTheme,useMediaQuery, Grid, Typography, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';

import iconPlayLeft from '../R3/assets/icon-white-button.svg';

const CarouselWithButton = ({ children, isLoading }) => {
	const classes = useStyles();
	const containerRef = useRef(null);
	const [isDragging, setIsDragging] = useState(false);
	const [startX, setStartX] = useState(0);
	const [scrollLeft, setScrollLeft] = useState(0);
    const theme = useTheme();
    const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const handleScrollToLeft = () => {
		const container = containerRef.current;
		if (container) {
			container.scrollLeft -= matchesMobile ? 400 : 700;
		}
	};

	const handleScrollToRight = () => {
		const container = containerRef.current;
		if (container) {
			container.scrollLeft += matchesMobile ? 400 : 700;
		}
	};

	const handleMouseDown = (e) => {
		const container = containerRef.current;
		if (container) {
			setIsDragging(true);
			setStartX(e.pageX - container.offsetLeft);
			setScrollLeft(container.scrollLeft);
		}
	};

	const handleMouseMove = (e) => {
		if (!isDragging) return;
		const container = containerRef.current;
		if (container) {
			e.preventDefault();
			const x = e.pageX - container.offsetLeft;
			const walk = x - startX;
			container.scrollLeft = scrollLeft - walk;
		}
	};

	const handleMouseUpOrLeave = () => {
		setIsDragging(false);
	};

	const handleTouchStart = (e) => {
		const container = containerRef.current;
		if (container) {
			setIsDragging(true);
			setStartX(e.touches[0].pageX - container.offsetLeft);
			setScrollLeft(container.scrollLeft);
		}
	};

	const handleTouchMove = (e) => {
		if (!isDragging) return;
		const container = containerRef.current;
		if (container) {
			e.preventDefault();
			const x = e.touches[0].pageX - container.offsetLeft;
			const walk = x - startX;
			container.scrollLeft = scrollLeft - walk;
		}
	};

	const handleTouchEnd = () => {
		setIsDragging(false);
	};

	return (
        <Grid
          container
          className={classes.section}
        >
          <Grid
            item
            className={classes.layout}
          >
            <div
              ref={containerRef}
              className={`${classes.carousel} ${isLoading ? 'justify-center' : 'justify-start'}`}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUpOrLeave}
              onMouseLeave={handleMouseUpOrLeave}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
            >
              {children}
            </div>
            <Grid
              display={'flex'}
              item
              justifyContent={'flex-end'}
              alignItems={'flex-end'}
            >
              <IconButton
                className={`${classes.iconButton} left`}
                onClick={handleScrollToLeft}
              >
                <img
                  src={iconPlayLeft}
                  alt="left icon"
                  width={24}
                  height={24}
                  style={{ boxShadow: ' 4px 4px 6px 0px rgba(0, 0, 0, 0.25)', borderRadius: '100%' }}
                />
              </IconButton>
              <IconButton
                className={`${classes.iconButton} right`}
                onClick={handleScrollToRight}
              >
                <img
                  src={iconPlayLeft}
                  alt="right icon"
                  width={24}
                  height={24}
                  
                  className="rotate-180"
                  style={{ transform: 'rotate(180deg)', boxShadow: ' 4px 4px 6px 0px rgba(0, 0, 0, 0.25)', borderRadius: '100%' }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      );
};

export default CarouselWithButton;

const useStyles = makeStyles((theme) => ({
	section: {
		margin: '1rem auto',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: 'transparent',
		transition: 'all 0.3s',
		overflow: 'hidden',
		padding: '1rem',
	},
	layout: {
		display: 'flex',
		flexDirection: 'column !important',
		alignItems: 'flex-end !important',
		width: '100%',
	},
	title: {
		marginTop: '1rem',
	},
	subTitle: {
		paddingTop: '0.5rem',
		fontSize: '15px',
		fontWeight: '500',
		color: '#000',
		fontFamily: "'Poppins', sans-serif",
	},
	carousel: {
		position: 'relative',
		width: '100%',
		overflow: 'hidden',
		display: 'flex',
		alignItems: 'center',
		gap: '1rem',
		'&::-webkit-scrollbar': {
			display: 'none',
		},
		'&.no-scrollbar': {
			scrollbarWidth: 'none',
		},
	},
	iconButton: {
		
		
		marginTop: '20% !important',
		position: 'relative !important',
		top: '50% !important',
		transform: 'translateY(-50%) !important',

		backgroundColor: 'rgba(255, 255, 255, 0.2)',
		'&:hover': {
			backgroundColor: '#fff',
		},
	},
}));
