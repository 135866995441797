// import logoEnareDominado from '../assets/logo-enare-dominado.svg';
// import logoTEP from '../assets/logo-tep.svg';
import logoLDL_Revalida from '../assets/logo-ldl-revalida.svg';
import logoTEGO from '../assets/logo-tego.png';

export const cardInfos = [
	// {
	// 	product: 'ENARE',
	// 	agroup: 'HW SOLIDARIO - ENARE-DOMINADO',
	// 	img: logoEnareDominado,
	// 	description: [
	// 		'A banca do ENARE mudou, mas isso não assusta o Time Hardwork!',
	// 		<br />,
	// 		<br />,
	// 		'Já mapeamos e estudamos tudo da banca da Fundação Getúlio Vargas, e já temos tudo de importante para a prova do ENARE (exame nacional de residência médica) nas mãos!',
	// 		<br />,
	// 		<br />,
	// 		'Vamos REVELAR TUDO numa série de simulados temáticos com o arsenal da banca, acompanhados de comentários e mais de 50 aulas focadas na banca!',
	// 		<br />,
	// 		<br />,
	// 		'#dominoenarepelosul',
	// 		<br />,
	// 		'#hardworkpelosul',
	// 	],
	// 	launchDate: 'De 06 de julho a 03 de agosto.',
	// 	confirmationMsg:
	// 		'Sua inscrição no Enare Dominado está confirmada! Em breve você receberá uma confirmação pelo e-mail cadastrado.',
	// },
	{
		product: 'LDL-REVALIDA',
		agroup: 'HW SOLIDARIO - LIVE DAS LIVES - REVALIDA INEP',
		img: logoLDL_Revalida,
		description: [
			'Uma edição ESPECIAL e totalmente ONLINE da revolucionária revisão de véspera para a prova do REVALIDA INEP. Um dia com muitas discussões no padrão Hardwork dos temas quentes para a prova do INEP, numa live totalmente gratuita.',
			<br />,
			<br />,
			'Vamos mostrar a força da comunidade médica que busca a revalidação de diploma!',
			<br />,
			<br />,
			'#revalidapelosul',
			<br />,
			'#hardworkpelosul',
		],
		launchDate: 'Dia 29 de junho.',
		confirmationMsg:
			'Sua inscrição na Live das Lives Revalida INEP (edição especial) está confirmada! Em breve você receberá uma confirmação pelo e-mail cadastrado.',
	},
	// {
	// 	product: 'TEP',
	// 	agroup: 'HW SOLIDARIO - SEMANA TEP',
	// 	img: logoTEP,
	// 	description: [
	// 		'A prova de Título de Especialista em Pediatria está se aproximando, e vamos aproveitar a oportuindade para fazer aquilo que o Hardwork mais sabe: dominar uma banca! Uma semana inteira de discussões totalmente focadas na prova de Título de Especialista, totalmente online e gratuita!',
	// 		<br />,
	// 		<br />,
	// 		'#pediatriapelosul',
	// 		<br />,
	// 		'#hardworkpelosul',
	// 	],
	// 	launchDate: 'De 20 a 24 de maio.',
	// 	confirmationMsg:
	// 		'Sua inscrição na Semana TEP está confirmada! Em breve você receberá uma confirmação pelo e-mail cadastrado.',
	// },
	{
		product: 'TEGO',
		agroup: 'HW SOLIDARIO - SEMANA TEGO',
		img: logoTEGO,
		description: [
			'A prova do TEGO (Título de Especialista em Ginecologia e Obstetrícia) acontecerá em Julho! O Hardwork se mobilizou para dominar a prova contigo!',
			<br />,
			<br />,
			'Teremos uma semana intensa de revisão com foco nos temas esperados na prova. Tudo online, tudo gratuito!',
			<br />,
			<br />,
			'#TEGOpelosul',
			<br />,
			'#hardworkpelosul',
		],
		launchDate: 'De 22 a 26 de julho.',
		confirmationMsg:
			'Sua inscrição na Semana TEGO está confirmada! Em breve você receberá uma confirmação pelo e-mail cadastrado.',
	},
];
