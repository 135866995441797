import { useTheme, useMediaQuery, Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState, useEffect } from 'react';
import Api from '../../../../Services/Api';

const PaymentCard = ({ idTurma, logo, priceParcel, priceParcel2, price: propPrice, bestPrice, shadow, colorBgButton }) => {
	const classes = useStyles({ colorBgButton });
	const theme = useTheme();

	const [apiPrice, setApiPrice] = useState();
	const [apiPriceParcel, setApiPriceParcel] = useState();
	const [apiPriceParcel2, setApiPriceParcel2] = useState();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	useEffect(() => {
		if (!propPrice || !priceParcel) {
			Api.get(`turmasabertas/${idTurma}`).then((res) => {
				if (res && res?.length > 0) {
					const turma = res?.[0];
					if (!propPrice) setApiPrice(turma.valor);
					if (!priceParcel && turma.parcelas.length > 0) {
						setApiPriceParcel(turma.parcelas[0].valor);
					}
				}
			});
		}
	}, [idTurma, propPrice, priceParcel]);

	const price = propPrice || apiPrice;
	const parcelPrice = priceParcel || apiPriceParcel;
	const parcelPrice2 = priceParcel || apiPriceParcel ? (priceParcel || apiPriceParcel) / 12 : 0;

	const formatCurrency = (value) => {
		return new Intl.NumberFormat('pt-BR', {
			style: 'currency',
			currency: 'BRL',
		}).format(value);
	};

	return (
		<Grid
			display={'flex'}
			container
			width={matchesMobile ? 'full' : '370px'}
			height={'350px'}
			justifyContent={'end'}
			alignItems={'center'}
			marginTop={matchesMobile ? '2rem' : '0'}
			style={{ background: '#EBEBEB', borderRadius: '24px', boxShadow: shadow }}
		>
			{bestPrice && (
				<Button
					disabled
					className={classes.optionButton}
				>
					Melhor opção
				</Button>
			)}
			<Grid
				container
				item
				xl={12}
				lg={12}
				justifyContent={'center'}
				alignItems={'center'}
				direction="column"
				style={{ textAlign: 'center', padding: '0.50rem' }}
			>
				<img
					src={logo}
					alt=""
					style={{ width: '80%', marginBottom: '2rem', marginTop: '1.1rem' }}
				/>
                <Grid 
                
                justifyContent="flex-start"
                alignItems="flex-start">
				<Grid
					container
					direction="column"
					justifyContent="flex-start"
					alignItems="flex-start"
					gap={'2px'}
					
				>
					<Typography
						fontFamily={'Manrope'}
						fontSize={'16px'}
						fontWeight={'500'}
						color={'#959595'}
						style={{ textDecoration: 'line-through' }}
					>
						De: {formatCurrency(price)}
					</Typography>
					<Typography
						fontFamily={'Manrope'}
						fontSize={'16px'}
						fontWeight={'500'}
						color={'#000'}
					>
						Por: {formatCurrency(parcelPrice)} à vista
					</Typography>
				</Grid>
				<Typography
					fontFamily={'Manrope'}
					fontSize={matchesMobile ? '30px' : '37px'}
					fontWeight={'500'}
					justifyContent="flex-start"
					alignItems="flex-start"
					color={'#000'}
				>
					12x de {formatCurrency(parcelPrice2)}
				</Typography>
                </Grid>
				<Button
					href={`https://hardworkmedicina.com.br/inscricao/${idTurma}`}
					startIcon={
						<img
							src={''}
							alt=""
						/>
					}
					className={classes.subscribeButton}
					style={{ padding: '0.5rem 3rem', background: '#33C96F', color: '#fff' }}
				>
					Inscrever-se
				</Button>
			</Grid>
		</Grid>
	);
};

export default PaymentCard;

const useStyles = makeStyles((theme) => ({
	subscribeButton: {
		color: '#fff !important',
		cursor: 'pointer',
		borderRadius: '24px !important',
		width: 'fit-content',
		fontFamily: 'Manrope',
		fontSize: '16px',
		marginTop: '2rem !important',
	},
	optionButton: (props) => ({
		cursor: 'pointer',
		borderRadius: '24px !important',
		marginRight: '1rem !important',
		marginTop: '0.5rem !important',
		width: 'fit-content',
		fontFamily: 'Manrope',
		fontSize: '10px !important',
		color: '#fff !important',
		background: props.colorBgButton,
		alignSelf: 'flex-end',
	}),
}));
