import { useState } from 'react';
// import { useRef } from 'react';
import { Button, Container, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import FlipCard from './FlipCard';
import PurchaseCard2024 from './PurchaseCard-2024';
import DefaultButton from '../../../../../Components/Buttons/DefaultButton';

import BgTechGrey from '../../../../../Assets/SprintFinal/bg-tech-grey.png';
import PurchaseCardPro from './PurchaseCard-pro';

const presencialTrainingExp = [
	{
		id: 1,
		img: require('../../../../../Assets/Arena/icon-intense-training.svg').default,
		title: ['1 dia de treinamento intenso'],
		description: [
			'Um dia de programação',
			<strong
				style={{ margin: '0 auto' }}
				className="gradient-text"
			>
				intensa, muito dinâmica e instimista
			</strong>,
			'para entrar de vez na alma da prova prática do INEP.',
		],
	},
	{
		id: 2,
		img: require('../../../../../Assets/Arena/icon-stations-of-training.svg').default,
		title: ['12 estações individuais no novo formato INEP*'],
		description: [
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				12 estações individuais para treinamento
			</strong>,
			'; todas as estações simulam em detalhes a prova prática do INEP.',
			<br />,
			<br />,
			'*6 estações como aluno avaliado e 6 como examinador.',
		],
	},
	{
		id: 3,
		img: require('../../../../../Assets/Arena/icon-training-skills-black.svg').default,
		title: ['8 estações temáticas', <br />, 'em grupo no formato INEP'],
		description: [
			'Treinamento de ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				habilidades específicas
			</strong>,
			' em pequenos grupos.',
		],
	},
	{
		id: 4,
		img: require('../../../../../Assets/Arena/icon-discussion-checklist-black.svg').default,
		title: ['Discussão de 20 estações e checklists'],
		description: [
			'Discussão de estações e checklists ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				no formato INEP
			</strong>,
			'.',
		],
	},
	{
		id: 5,
		img: require('../../../../../Assets/Arena/icon-personal-guidelines-black.svg').default,
		title: ['Orientações personalizadas'],
		description: [
			'Orientações personalizadas do time Hardwork, com ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				professores e instrutores que já dominaram
			</strong>,
			' a prova prática do INEP.',
		],
	},
	{
		id: 6,
		img: require('../../../../../Assets/Arena/icon-presencial-training-black.svg').default,
		title: ['Treinamento de gatilhos de', <br />, 'prova prática'],
		description: [
			'O jogo da prova prática exige raciocínio rápido. ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				Uma informação pode mudar tudo...
			</strong>,
			'são os "gatilhos de estação". No PRESENCIAL, treinamos brincando!',
		],
	},
	{
		id: 7,
		img: require('../../../../../Assets/Arena/icon-food-black.svg').default,
		title: ['Café da manhã, almoço ', <br />, 'e lanche'],
		description: [
			'Momentos de integração com teus colegas, professores e instrutores. ',
			<br />,
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				A gente cuida de ti integralmente
			</strong>,
			'.',
		],
	},
];

const presencialTrainingPro = [
	{
		id: 1,
		img: require('../../../../../Assets/Arena/icon-intense-training.svg').default,
		title: ['2 dias de treinamento intenso'],
		description: [
			'Dois dias de programação',
			<strong
				style={{ margin: '0 auto' }}
				className="gradient-text"
			>
				intensa e muito dinâmica
			</strong>,
			'para entrar de vez na alma da prova prática do INEP.',
		],
	},
	{
		id: 2,
		img: require('../../../../../Assets/Arena/icon-stations-of-training.svg').default,
		title: ['20 estações individuais no novo formato INEP*'],
		description: [
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				20 estações individuais para treinamento
			</strong>,
			'; todas as estações simulam em detalhes a prova prática do INEP.',
			<br />,
			<br />,
			'*10 estações como aluno avaliado e 10 como examinador.',
		],
	},
	{
		id: 3,
		img: require('../../../../../Assets/Arena/icon-training-skills-black.svg').default,
		title: ['8 estações temáticas', <br />, 'em grupo no formato INEP'],
		description: [
			'Treinamento de ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				habilidades específicas
			</strong>,
			' em pequenos grupos.',
		],
	},
	{
		id: 4,
		img: require('../../../../../Assets/Arena/icon-discussion-checklist-black.svg').default,
		title: ['Discussão de 28 estações e checklists'],
		description: [
			'Discussão de estações e checklists ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				no formato INEP
			</strong>,
			'.',
		],
	},
	{
		id: 5,
		img: require('../../../../../Assets/Arena/icon-personal-guidelines-black.svg').default,
		title: ['Orientações personalizadas'],
		description: [
			'Orientações personalizadas do time Hardwork, com ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				professores e instrutores que já dominaram
			</strong>,
			' a prova prática do INEP.',
		],
	},
	{
		id: 6,
		img: require('../../../../../Assets/Arena/icon-presencial-training-black.svg').default,
		title: ['Treinamento de gatilhos de', <br />, 'prova prática'],
		description: [
			'O jogo da prova prática exige raciocínio rápido. ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				Uma informação pode mudar tudo...
			</strong>,
			'são os "gatilhos de estação". No PRESENCIAL, treinamos brincando!',
		],
	},
	{
		id: 7,
		img: require('../../../../../Assets/Arena/icon-food-black.svg').default,
		title: ['Café da manhã, almoço ', <br />, 'e lanche'],
		description: [
			'Momentos de integração com teus colegas, professores e instrutores. ',
			<br />,
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				A gente cuida de ti integralmente
			</strong>,
			'.',
		],
	},
];

const onlineTraining = [
	{
		id: 1,
		img: require('../../../../../Assets/Arena/icon-60-stations-white.svg').default,
		title: ['+ de 60 estações', <br />, 'de aprendizagem'],
		description: [
			'Várias estações que ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				simulam a prova prática
			</strong>,
			' do INEP. Algumas no formato interativo, outras no modelo de vídeo. Todas com orientação do professor.',
		],
	},
	{
		id: 2,
		img: require('../../../../../Assets/Arena/icon-discussions-by-teachers-white.svg').default,
		title: ['Discussões de estações ', <br />, 'pelos professores'],
		description: [
			'Entre na alma de cada estação, saiba exatamente ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				o que é esperado da banca
			</strong>,
			' para diversas situações, revise checklists e assimile conceitos importantes.',
		],
	},
	{
		id: 3,
		img: require('../../../../../Assets/Arena/icon-learn-white.svg').default,
		title: ['Aprenda avaliando', <br />, 'outros alunos'],
		description: [
			'Entre na cabeça do examinador, ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				veja pelo ponto de vista dele
			</strong>,
			' o que é esperado em cada situação e avalie o desempenho de outro aluno.',
		],
	},
	{
		id: 4,
		img: require('../../../../../Assets/Arena/icon-learning-at-different-stations-white.svg').default,
		title: ['Aprenda em estações', <br />, 'interativas'],
		description: [
			'Interaja com a plataforma para ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				treinar a busca de elementos numa estação
			</strong>,
			'. É a hora de aprender a fazer as perguntas.',
		],
	},
	{
		id: 5,
		img: require('../../../../../Assets/Arena/icon-lung-white.svg').default,
		title: ['+ de 150 casos para', <br />, 'aprendizado teórico'],
		description: [
			'É fundamental ter conhecimento teórico na prova prática! No ARENA nós fazemos isso de forma diferente: ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				mais de 150 casos para revisar os temas
			</strong>,
			' que podem aparecer na prova prática do INEP.',
		],
	},
	{
		id: 6,
		img: require('../../../../../Assets/Arena/icon-teacher-orientation-white.svg').default,
		title: ['Para cada caso, uma orientação ', <br />, 'em vídeo do professor'],
		description: [
			'Os casos temáticos trazem consigo ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				orientações do professor em vídeo
			</strong>,
			' sobre o tema.',
		],
	},
	// {
	// 	id: 7,
	// 	img: require('../../../../../Assets/Arena/icon-cyclic-revisions-white.svg').default,
	// 	title: ['Revisões cíclicas', <br />, 'automáticas '],
	// 	description: [
	// 		'Além de estudar os casos, vamos ',
	// 		<strong
	// 			style={{ margin: '0 auto', display: 'inline' }}
	// 			className="gradient-text"
	// 		>
	// 			revisar SEMPRE
	// 		</strong>,
	// 		', até a proximidade da prova! Não podemos esquecer nada.',
	// 	],
	// },
	{
		id: 7,
		img: require('../../../../../Assets/Arena/icon-dm-teachers-white.svg').default,
		title: ['Contato direto', <br />, 'com o professor'],
		description: [
			'É isso mesmo, tenha acesso aos ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				contatos de todos os professores envolvidos no projeto
			</strong>,
			'. E-mail e Instagram, para tirar suas dúvidas e compartilhar experiências.',
		],
	},
	{
		id: 8,
		img: require('../../../../../Assets/Arena/icon-exam-resources-white.svg').default,
		title: ['Orientações para', <br />, 'recursos de prova'],
		description: [
			'Obtenha apoio dos professores Hardwork para ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				avaliar a possibilidade de recursos
			</strong>,
			' para suas estações da prova prática',
		],
	},
];

const arenaOnlineBenefits = [
	'Acesso EXCLUSIVAMENTE Online',
	'+ de 200 checklists',
	'+ de 200 estações simuladas',
	'+ 100 casos temáticos',
	'Pratique estações com outros alunos',
	'Pratique estações com a Inteligência Artifícial.',
	'Estações em vídeos',
];

const arenaExperienceBenefits = [
	'Todos os benefícios presentes no Arena Hardwork Online',
	'Treinamento intensivo em 1 dia',
	'12 estações individuais no novo formato INEP*',
	'8 estações temáticas em grupo no formato INEP',
	'Orientações personalizadas',
	'Treinamento de procedimentos importantes para a prova',
];

const arenaProBenefits = [
	'Todos os benefícios presentes no Arena Hardwork Online',
	'Treinamento intensivo em 2 dias',
	'20 estações individuais no novo formato INEP*',
	'8 estações temáticas em grupo no formato INEP',
	'Orientações personalizadas',
	'Treinamento de procedimentos importantes para a prova',
];

const checklists = [
	{
		icon: require('../assets/icon-green-doctor-arena.svg').default,
		text: 'O maior número de checklists da história',
	},
	{
		icon: require('../assets/icon-green-lamp-arena.svg').default,
		text: 'O maior número de estações da história',
	},
	{
		icon: require('../assets/icon-green-sheets-arena.svg').default,
		text: 'A maior preparação para prova prática do Revalida INEP',
	},
];

export default function ToggleContainerPro({ arena_card, arena_purchase_card, executeScroll }) {
	const classes = useStyles();
	const theme = useTheme();
	// const arena_card = useRef(null);
	const [isContainerOpen, setIsContainerOpen] = useState(false);
	const [activeButtonId, setActiveButtonId] = useState('1329');

	const [open, setOpen] = useState(false);
	// const [selectedImageId, setSelectedImageId] = useState(null);
	const [selectedImageIndex, setSelectedImageIndex] = useState(0);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const handleOpen = (index) => {
		setSelectedImageIndex(index);
		// setSelectedImageId(imageId);
		setOpen(true);
	};

	const handleClose = () => {
		// setSelectedImageId(null);
		setOpen(false);
	};

	const handleButtonClick = (buttonId) => {
		setActiveButtonId(buttonId);
		setIsContainerOpen(true);
		executeScroll();
	};

	return (
		<>
			<Grid
				container
				className={`${!isContainerOpen ? classes.containerBackground : null}`}
			>
				<Grid
					container
					className="section"
					style={{ margin: '0 auto' }}
				>
					<Grid
						item
						container
						className={`${classes.mainContainer} ${isContainerOpen ? classes.containerBackground : null}`}
						gap={matchesMobile ? 1 : 4}
					>
						{checklists.map((elm, index) => {
							return (
								<Grid
									key={index}
									item
									container
									justifyContent={'space-between'}
									alignItems={'center'}
									wrap="nowrap"
									style={{
										border: '2px solid #09D7D7',
										padding: '0.5rem',
										borderRadius: 20,
										marginBottom:
											index === checklists.length - 1 ? (matchesTablet ? '3rem' : '5rem') : null,
										marginLeft: matchesTablet ? '0.5rem' : null,
									}}
								>
									<Grid
										item
										container
										alignItems={'center'}
										wrap="nowrap"
									>
										<img
											src={elm.icon}
											alt=""
											className={classes.icon}
										/>
										<Typography
											color="secondary"
											className={`${classes.textChecklist} poppins`}
										>
											{elm.text}
										</Typography>
									</Grid>
									<img
										src={require('../assets/check-animation.gif')}
										alt=""
										className={classes.checkGif}
									/>
								</Grid>
							);
						})}
						<Grid
							item
							container
							style={matchesMobile ? { marginBottom: '1rem' } : null}
						>
							<Typography
								align={matchesMobile ? 'center' : 'left'}
								color="#09D785"
								className={`${classes.optionTitle} roboto-condensed`}
							>
								Qual modelo de Arena ideal pra ti?
							</Typography>
						</Grid>
						<Grid
							item
							container
							alignContent={'flex-start'}
							columnGap={3}
							rowGap={matchesTablet ? 3 : 0}
						>
							<Grid
								item
								xs={12}
								lg={3.75}
								container
								justifyContent={'flex-start'}
								alignContent={'center'}
							>
								<Button
									variant="contained"
									color={activeButtonId === 1 ? 'primary' : 'secondary'}
									className={`${classes.btn} 
                                    ${activeButtonId === 1 ? null : null} 
                                    poppins 
                                    
                                    `}
									onClick={() => handleButtonClick('1330')}
									style={{
										flexDirection: 'column',
										backgroundColor: '#FFFFFF10',
										scale: activeButtonId === '1330' ? '1.025' : null,
										border: activeButtonId === '1330' ? '1px solid #ffffff' : null,
									}}
								>
									<Grid
										item
										container
										alignContent={'center'}
										style={{
											borderBottom: '1px solid #FFFFFF50',
											padding: matchesTablet ? '1rem' : '1rem 0',
											height: '100%',
										}}
									>
										<Typography
											color="secondary"
											className={`${classes.titleBenefits} poppins`}
											align="left"
										>
											Arena <br />
											Online
										</Typography>
									</Grid>
									{arenaOnlineBenefits.map((text, index) => {
										return (
											<Grid
												key={index}
												item
												container
												justifyContent="center"
												alignContent={'center'}
												style={{
													borderBottom:
														index !== arenaOnlineBenefits.length - 1 &&
														'1px solid #FFFFFF50',
													padding: index === 0 ? '1.9rem 0' : '1rem 0',
												}}
											>
												<Typography
													align="center"
													color={index === 0 ? '#09D785' : 'secondary'}
													className={`${classes.textBenefits} poppins`}
												>
													{text}
												</Typography>
											</Grid>
										);
									})}
									<Grid
										item
										container
										justifyContent={'center'}
										className={classes.gridSelectButton}
									>
										<DefaultButton
											variant="contained"
											color={activeButtonId === '1330' ? 'secondary' : 'primary'}
											className={`${classes.selectButton} poppins`}
											style={{
												backgroundColor: activeButtonId === '1330' ? '#ffffff' : '#00AD58',
											}}
										>
											{activeButtonId === '1330' ? 'Selecionado' : 'Selecionar'}
										</DefaultButton>
									</Grid>
								</Button>
							</Grid>
							<Grid
								item
								xs={12}
								lg={3.75}
								container
								justifyContent={'flex-start'}
								alignContent={'center'}
							>
								<Button
									variant="contained"
									color={activeButtonId === 1 ? 'primary' : 'secondary'}
									className={`${classes.btn} 
                                    ${activeButtonId === 1 ? null : null} 
                                    poppins 
                                    
                                    `}
									onClick={() => handleButtonClick('1335')}
									style={{
										justifyContent: 'flex-start',
										flexDirection: 'column',
										backgroundColor: '#FFFFFF15',
										backgroundImage:
											'linear-gradient(to bottom right, #ffffff15, #ffffff25, #ffffff05)',
										scale: activeButtonId === '1335' ? '1.025' : null,
										border: activeButtonId === '1335' ? '1px solid #ffffff' : null,
									}}
								>
									<Grid
										item
										container
										style={{
											borderBottom: '1px solid #FFFFFF50',
											padding: matchesTablet ? '1rem' : '1rem 0',
										}}
									>
										<Typography
											color="secondary"
											align="left"
											className={`${classes.titleBenefits} poppins`}
										>
											Arena Presencial Experience
										</Typography>
									</Grid>
									{arenaExperienceBenefits.map((text, index) => {
										return (
											<Grid
												key={index}
												item
												container
												justifyContent="center"
												alignContent={'center'}
												style={{
													borderBottom:
														index !== arenaOnlineBenefits.length - 1 &&
														'1px solid #FFFFFF50',
													padding: '1rem 0',
												}}
											>
												<Typography
													align="center"
													color={index === 0 ? '#09D785' : 'secondary'}
													className={`${classes.textBenefits} poppins`}
												>
													{text}
												</Typography>
											</Grid>
										);
									})}
									<Grid
										item
										container
										justifyContent={'center'}
										className={classes.gridSelectButton}
									>
										<DefaultButton
											variant="contained"
											color={activeButtonId === '1335' ? 'secondary' : 'primary'}
											className={`${classes.selectButton} poppins`}
											style={{
												backgroundColor: activeButtonId === '1335' ? '#ffffff' : '#00AD58',
											}}
										>
											{activeButtonId === '1335' ? 'Selecionado' : 'Selecionar'}
										</DefaultButton>
									</Grid>
								</Button>
							</Grid>
							<Grid
								item
								xs={12}
								lg={3.75}
								container
								justifyContent={'flex-end'}
								alignContent={'center'}
							>
								<Button
									variant="contained"
									color={activeButtonId === 2 ? 'primary' : 'secondary'}
									className={`${classes.btn} 
                                    ${activeButtonId === 1 ? null : null} 
                                    poppins 
                                    
                                    `}
									onClick={() => handleButtonClick('1329')}
									style={{
										justifyContent: 'flex-start',
										flexDirection: 'column',
										backgroundColor: '#FFFFFF15',
										filter: 'drop-shadow(0px 0px 0px rgba(9,215,215,1))',
										backgroundImage:
											'linear-gradient(to bottom right, #ffffff15, #ffffff25, #ffffff05)',
										scale: activeButtonId === '1329' ? '1.025' : null,
										border: activeButtonId === '1329' ? '1px solid #ffffff' : null,
									}}
								>
									<Grid
										item
										container
										style={{
											borderBottom: '1px solid #FFFFFF50',
											padding: matchesTablet ? '1rem' : '1rem 0',
										}}
									>
										<Typography
											color="secondary"
											align="left"
											className={`${classes.titleBenefits} poppins`}
										>
											Arena Presencial Pro
										</Typography>
									</Grid>
									{arenaProBenefits.map((text, index) => {
										return (
											<Grid
												key={index}
												item
												container
												justifyContent="center"
												alignContent={'center'}
												style={{
													borderBottom:
														index !== arenaOnlineBenefits.length - 1 &&
														'1px solid #FFFFFF50',
													padding: '1rem 0',
												}}
											>
												<Typography
													align="center"
													color={index === 0 ? '#09D785' : 'secondary'}
													className={`${classes.textBenefits} poppins`}
												>
													{text}
												</Typography>
											</Grid>
										);
									})}
									<Grid
										item
										container
										justifyContent={'center'}
										// style={{ position: 'absolute', width: '86%', bottom: -30 }}
										className={classes.gridSelectButton}
									>
										<DefaultButton
											variant="contained"
											color={activeButtonId === '1329' ? 'secondary' : 'primary'}
											className={`${classes.selectButton} poppins`}
											style={{
												backgroundColor: activeButtonId === '1329' ? '#ffffff' : '#00AD58',
											}}
										>
											{activeButtonId === '1329' ? 'Selecionado' : 'Selecionar'}
										</DefaultButton>
									</Grid>
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				{!activeButtonId && (
					<Grid
						item
						container
						justifyContent={'center'}
						alignContent={'center'}
						style={{ padding: matchesTablet && '0 1rem' }}
					>
						<Typography
							color="secondary"
							align="center"
							className="poppins"
							style={{ fontStyle: 'italic', fontSize: matchesTablet ? '0.8rem' : '1.2rem' }}
						>
							Selecione um modelo de Arena para ver mais detalhes...
						</Typography>
					</Grid>
				)}
			</Grid>
			{activeButtonId && (
				<>
					<Grid
						className={classes.containerBackgroundColor}
						ref={arena_purchase_card}
					>
						<Grid
							item
							xs={12}
							container
							style={matchesMobile ? { marginBottom: 16, paddingLeft: '1em' } : { left: '2em' }}
							className="revalida"
						>
							<Typography
								className={`${classes.subtitle1} roboto-condensed gradient-text`}
								color="primary"
							>
								{activeButtonId === '1335'
									? 'No Arena Experience presencial tu receberás:'
									: activeButtonId === '1329'
									? 'No Arena PRO presencial tu receberás:'
									: 'No treinamento online tu receberás:'}
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							container
							justifyContent={'center'}
							style={{ marginTop: matchesMobile ? 0 : '2rem', paddingBottom: '2rem' }}
						>
							{activeButtonId !== '1330' ? (
								<>
									{activeButtonId === '1335'
										? presencialTrainingExp.map((item, index) => {
												return (
													<Grid
														key={item.id}
														item
														xs={6}
														md={/*activeButtonId === 1 ? 4 : */ 3}
														container
														justifyContent={'center'}
														className={`${classes.gridFlipCard} revalida`}
													>
														<FlipCard
															image={item.img}
															title={item.title}
															description={item.description}
															color="secondary"
															mobile={matchesMobile}
															size={/*activeButtonId === 1 ? 4 : */ 3}
															classes={classes}
														/>
													</Grid>
												);
										  })
										: presencialTrainingPro.map((item, index) => {
												return (
													<Grid
														key={item.id}
														item
														xs={6}
														md={/*activeButtonId === 1 ? 4 : */ 3}
														container
														justifyContent={'center'}
														className={`${classes.gridFlipCard} revalida`}
													>
														<FlipCard
															image={item.img}
															title={item.title}
															description={item.description}
															color="secondary"
															mobile={matchesMobile}
															size={/*activeButtonId === 1 ? 4 : */ 3}
															classes={classes}
														/>
													</Grid>
												);
										  })}
									<Grid
										item
										xs={12}
										container
										justifyContent={'center'}
									>
										<Divider
											variant="middle"
											className={classes.divider}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										container
										style={
											matchesMobile
												? { marginBottom: 16, paddingLeft: '1em' }
												: { left: '2em', marginBottom: '2rem' }
										}
										className="revalida"
									>
										<Typography
											className={`${classes.subtitle1} roboto-condensed gradient-text`}
											color="primary"
										>
											E ainda, tu também receberás no treinamento online:
										</Typography>
									</Grid>
									{!matchesTablet ? (
										<>
											{' '}
											{onlineTraining.map((item, index) => {
												if (item.id < 5) {
													return (
														<Grid
															key={index}
															item
															xs={6}
															md={3}
															container
															justifyContent={'center'}
															className={`${classes.containerFlipCard} revalida`}
														>
															<FlipCard
																image={item.img}
																title={item.title}
																description={item.description}
																color="secondary"
																mobile={matchesMobile}
																size={3}
																classes={classes}
															/>
														</Grid>
													);
												} else {
													return null;
												}
											})}{' '}
										</>
									) : (
										<>
											{onlineTraining.map((item, index) => {
												return (
													<Grid
														key={index}
														item
														xs={6}
														md={3}
														container
														justifyContent={'center'}
														className={`${classes.containerFlipCard} revalida`}
													>
														<FlipCard
															image={item.img}
															title={item.title}
															description={item.description}
															color="secondary"
															mobile={matchesMobile}
															size={3}
															classes={classes}
														/>
													</Grid>
												);
											})}{' '}
										</>
									)}
									{!matchesTablet && (
										<>
											{' '}
											{onlineTraining.map((item, index) => {
												if (item.id > 4 && item.id < 9) {
													return (
														<Grid
															key={index}
															item
															xs={6}
															md={3}
															container
															justifyContent={'center'}
															className={`${classes.containerFlipCard} revalida`}
														>
															<FlipCard
																image={item.img}
																title={item.title}
																description={item.description}
																color="secondary"
																mobile={matchesMobile}
																size={3}
																classes={classes}
															/>
														</Grid>
													);
												} else {
													return null;
												}
											})}
											{/* <Grid
												item
												container
												justifyContent={'center'}
											>
												{onlineTraining.map((item, index) => {
													if (item.id > 7) {
														return (
															<Grid
																key={index}
																item
																xs={6}
																md={3}
																container
																justifyContent={'center'}
																className={`${classes.containerFlipCard} revalida`}
															>
																<FlipCard
																	image={item.img}
																	title={item.title}
																	description={item.description}
																	color="secondary"
																	mobile={matchesMobile}
																	size={3}
																	classes={classes}
																/>
															</Grid>
														);
													} else {
														return null;
													}
												})}
											</Grid>{' '} */}
										</>
									)}
								</>
							) : (
								<>
									{onlineTraining.map((item, index) => {
										if (item.id < 5) {
											return (
												<Grid
													key={index}
													item
													xs={6}
													md={activeButtonId === 1 ? 4 : 3}
													container
													justifyContent={'center'}
													className={`${classes.containerFlipCard} revalida`}
												>
													<FlipCard
														image={item.img}
														title={item.title}
														description={item.description}
														color="secondary"
														mobile={matchesMobile}
														size={activeButtonId === 1 ? 4 : 3}
														classes={classes}
													/>
												</Grid>
											);
										} else {
											return null;
										}
									})}
									{onlineTraining.map((item, index) => {
										if (4 < item.id && item.id < 9) {
											return (
												<Grid
													key={index}
													item
													xs={6}
													md={activeButtonId === 1 ? 4 : 3}
													container
													justifyContent={'center'}
													className={`${classes.containerFlipCard} revalida`}
												>
													<FlipCard
														image={item.img}
														title={item.title}
														description={item.description}
														color="secondary"
														mobile={matchesMobile}
														size={activeButtonId === 1 ? 4 : 3}
														classes={classes}
													/>
												</Grid>
											);
										} else {
											return null;
										}
									})}
								</>
							)}
						</Grid>
					</Grid>
					{/* <Grid
						item
						xs={12}
						container
						justifyContent={'space-between'}
						rowGap={2}
						className={classes.containerGridArenaInterativo}
					>
						<Grid
							item
							xs={12}
							md={5.75}
							container
							rowGap={2}
							className={classes.gridArenaBlock}
						>
							<Grid
								item
								xs={12}
								container
								className={classes.gridTitleArenaBlock}
							>
								<Typography
									className={`${classes.arenaBlockTitle} poppins`}
									color="secondary"
								>
									ARENA INTERATIVO
								</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								container
							>
								<Typography
									className={`${classes.textArenaInterativo} poppins`}
									color="secondary"
								>
									Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
									tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero
									eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
									takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
									consetetur sadipscing elitr, sed diam nonumy eirmod tempor
								</Typography>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							md={6.1}
							container
							className={classes.gridBackgroundImage}
							style={{
								backgroundImage: `url(${require('../assets/arena-image-1.png')})`,
							}}
						></Grid>
						{!matchesTablet && (
							<Grid
								item
								xs={12}
								md={6.1}
								container
								className={classes.gridBackgroundImage}
								style={{
									backgroundImage: `url(${require('../assets/arena-image-2.png')})`,
								}}
							></Grid>
						)}
						<Grid
							item
							xs={12}
							md={5.75}
							container
							rowGap={2}
							className={classes.gridArenaBlock}
							style={{ marginTop: matchesTablet ? '2rem' : null }}
						>
							<Grid
								item
								xs={12}
								container
								justifyContent={'flex-end'}
								className={classes.gridTitleArenaBlock}
							>
								<Typography
									color="secondary"
									className={`${classes.arenaBlockTitle} poppins`}
								>
									ESTAÇÃO COM AMIGO
								</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								container
							>
								<Typography
									className="poppins"
									align="right"
									color="secondary"
									style={{ fontSize: '1rem' }}
								>
									Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
									tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero
									eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
									takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
									consetetur sadipscing elitr, sed diam nonumy eirmod tempor
								</Typography>
							</Grid>
						</Grid>
						{matchesTablet && (
							<Grid
								item
								xs={12}
								md={6.1}
								container
								className={classes.gridBackgroundImage}
								style={{
									backgroundImage: `url(${require('../assets/arena-image-2.png')})`,
								}}
							></Grid>
						)}
					</Grid> */}
					<Grid container>
						<Grid
							container
							className={classes.backgroundWaves}
						>
							<Grid
								container
								className="section"
								style={{ marginBottom: matchesMobile ? 20 : null }}
							>
								<Container
									fixed
									maxWidth="xl"
									disableGutters={true}
								>
									{!activeButtonId ? (
										<Grid
											item
											container
											alignContent="center"
											justifyContent="center"
											className={classes.glassContainer}
										>
											<Grid
												item
												container
												justifyContent={'center'}
												style={matchesMobile ? { marginBottom: '1em' } : null}
											>
												<img
													src={require('../../../../../Assets/Arena/logo-lock.svg').default}
													alt=""
												/>
											</Grid>
											<Grid
												item
												container
												alignContent="center"
												justifyContent="center"
											>
												<Typography
													align="center"
													color="secondary"
													className={`${classes.optionTitle} roboto-condensed`}
												>
													Selecione acima de qual Arena tu vais participar para visualizar os
													valores e fazer inscrição.
												</Typography>
											</Grid>
										</Grid>
									) : null}
									<div ref={arena_card}>
										<PurchaseCardPro
											activeButtonId={activeButtonId}
											idTurma={activeButtonId ? activeButtonId : '1330'}
											// urlToRedirect="/arena-hardwork"
											confirmationMessage={
												'Sua inscrição na lista de espera está confirmada! Avisaremos caso surjam novas vagas.'
											}
										/>
									</div>
									{activeButtonId === 2 && (
										<Typography
											color="primary"
											className={`${classes.disclaimerText2} poppins`}
											style={{ marginTop: '1.5rem' }}
										>
											{' '}
											*Válido para alunos Extensivo Revalida, Sprint Final Revalida ou Arena
											Hardwork sem pendências financeiras.
										</Typography>
									)}
								</Container>
							</Grid>
						</Grid>
					</Grid>
				</>
			)}
		</>
	);
}

const useStyles = makeStyles((theme) => ({
	backgroundWaves: {
		backgroundImage: `url(${BgTechGrey})`,
		width: '100vw !important',
	},
	mainContainer: {
		borderTopLeftRadius: 15,
		borderTopRightRadius: 15,
		padding: '6em 0 2.5em',
		[theme.breakpoints.down('xl')]: {
			padding: '4em 2.5em 6em',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '1em 0 2em 0',
			height: 'unset',
		},
	},
	optionTitle: {
		fontSize: '4rem !important',
		fontWeight: 'bold !important',
		textShadow: '0 0 0.2em #121213, 0 0 0.2em #0c0c0d',
		alignSelf: 'flex-end',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.75rem !important',
			lineHeight: '1 !important',
		},
	},
	btn: {
		width: '100%',
		height: '100%',
		fontSize: '2rem !important',
		fontWeight: 'bold !important',
		borderRadius: '15px !important',
		padding: '1.75rem 2rem !important',
		textTransform: 'unset !important',
		transition: '0.2s ease-in-out !important',
		cursor: 'auto !important',
		[theme.breakpoints.up('md')]: {
			'&:hover': {
				scale: 1.025,
			},
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			padding: '1rem 0.5rem 2rem !important',
		},
	},
	subtitle1: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
			lineHeight: 'unset !important',
		},
	},
	containerBackgroundColor: {
		backgroundColor: '#33333380',
		margin: '0 auto 30px',
		borderRadius: 15,
		maxWidth: '1410px !important',
		padding: '2vh 2.5% 3vh !important',
		[theme.breakpoints.down('sm')]: {
			padding: '3vh 2.5% 0 !important',
		},
	},
	divider: {
		minHeight: 1,
		backgroundColor: '#6D6D6D',
		width: '90%',
		margin: '2rem 0 !important',
	},
	glassContainer: {
		backdropFilter: 'blur(5px)',
		boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.37)',
		height: '100%',
		width: '100%',
		position: 'absolute',
		zIndex: 1,
		borderRadius: 15,
		[theme.breakpoints.down('sm')]: {
			padding: '0 1.5rem ',
		},
	},
	containerFlipCard: {
		margin: '15px 0 !important',
		height: 270,
		padding: '0 1rem ',
		[theme.breakpoints.down('sm')]: {
			padding: 'unset',
			height: 200,
		},
	},
	gridFlipCard: {
		margin: '15px 0 !important',
		height: 270,
		padding: '0 1rem',
		[theme.breakpoints.down('sm')]: {
			padding: 'unset',
			height: 180,
		},
	},
	selectButton: {
		fontSize: '1.5rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	gridSelectButton: {
		marginTop: '2rem !important',
		width: '60% !important',
	},
	disclaimerText: {
		fontSize: '0.8rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.6rem !important',
		},
	},
	disclaimerText2: {
		fontSize: '0.95rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.85rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.7rem !important',
		},
	},
	textChecklist: {
		fontSize: '2rem !important',
		left: '-2rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
			left: '-1.5rem !important',
		},
	},
	checkGif: {
		width: '5rem',
		[theme.breakpoints.down('sm')]: {
			width: '3rem',
		},
	},
	icon: {
		left: '-3rem !important',
		[theme.breakpoints.down('sm')]: {
			width: '3rem',
			left: '-2rem !important',
		},
	},
	textBenefits: {
		fontSize: '1.25rem !important',
		fontWeight: '600 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	titleBenefits: {
		fontSize: '2.5rem !important',
		fontWeight: 'bold !important',
		lineHeight: '1 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem !important',
		},
	},
	containerGridArenaInterativo: {
		margin: '0 auto 30px !important',
		borderRadius: 15,
		maxWidth: '1410px !important',
		padding: '2vh 2.5% 3vh',
		[theme.breakpoints.down('md')]: {
			padding: '2vh 1.25rem 3vh',
		},
	},
	textArenaInterativo: {
		fontSize: '1rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.9rem !important',
		},
	},
	gridTitleArenaBlock: {
		padding: '0.75rem 2rem',
		border: 'solid 4px #09D7D7',
		height: 'fit-content',
		borderRadius: 20,
		backgroundImage: `url(${require('../assets/bg-arena-cards.png')})`,
		backgroundSize: 'contain',
		boxShadow: '0px 0px 8px rgba(9, 215, 215, 1)',
		[theme.breakpoints.down('md')]: {
			padding: '0.5rem 1rem',
		},
	},
	arenaBlockTitle: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('md')]: {
			lineHeight: '1.5 !important',
			fontSize: '1.75rem !important',
		},
	},
	gridArenaBlock: {
		paddingBottom: '10rem',
		[theme.breakpoints.down('md')]: {
			paddingBottom: '0',
		},
	},
	gridBackgroundImage: {
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		[theme.breakpoints.down('md')]: {
			height: 210,
		},
	},
}));
