import React from 'react';
import { Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Api from '../../Services/Api';
import DefaultButton from '../Buttons/DefaultButton';
import { useState } from 'react';

import R3CMCardBackground from '../../Assets/Extensivo/R3CM/cardBackground.png';
import EXTR120232024CardBackground from '../../Assets/Extensivo/R1/2023-24/cardBackground.png';
import CountDownTimer from '../../Pages/LandingPages/Sale/LDL/components/CountDownTimer';
// import R3CGCardBackground from '../../Assets/Extensivo/R3CG/cardBackground.png';

export default function PriceCardVersion3({
	idTurma,
	logo,
	iconBtn,
	idomedStudents,
	bgImageUrl,
	color,
	discount,
	setPromotionalDate,
}) {
	const classes = useStyles(idTurma);
	const theme = useTheme();
	const [turma, setTurma] = useState([]);
	const [lotValueLimit, setLotValueLimit] = useState();
	const [lot, setLot] = useState();
	const currentDate = new Date();
	const [endDate, setEndDate] = useState();
	const [startDate, setStartDate] = useState();

	const matchesDown361 = useMediaQuery(theme.breakpoints.down(361));
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));
	const matchesXl = useMediaQuery(theme.breakpoints.down('xl'));

	useEffect(() => {
		Api.get(`turmasabertas/${idTurma}`).then((res) => {
			if (res) setTurma(res[0]);
			const activeLot = res[0]?.lotes?.find((lot, index) => {
				return lot?.id === res[0]?.id_lote_vigente;
			});
			if (activeLot) {
				const activeLotIndex = res[0]?.lotes?.indexOf(activeLot);
				const currentEndDateFormatted = new Date(activeLot?.data_limite);
				if (activeLotIndex + 1 !== res[0]?.lotes?.length) {
					setEndDate(currentEndDateFormatted);
				}
			}
			const currentStartDateFormatted = new Date(res[0]?.data_inicio_inscricoes);
			setStartDate(currentStartDateFormatted);

			if (setPromotionalDate) setPromotionalDate(activeLot);
			setLot(activeLot);
			setLotValueLimit(new Date(activeLot?.data_limite).getTime());
		});
	}, [idTurma]);

	return (
		<Grid
			container
			alignContent={matchesTablet ? 'space-around' : 'center'}
			justifyContent="space-between"
			className={classes.extensivoR1Card}
			style={
				color
					? {
							border: window.location.pathname === '/trilha-r1-2024' ? null : '2px solid black',
							height: discount ? 'unset' : null,
					  }
					: null
			}
		>
			<Grid
				item
				xs={12}
				md={7}
				container
				direction={window.location.pathname === '/trilha-r3-clinica-medica' ? 'column' : 'row'}
				justifyContent="center"
				alignItems="center"
				className={classes.gridImg}
				style={
					bgImageUrl
						? {
								backgroundImage: `url(${bgImageUrl})`,
								backgroundSize: 'cover',
								borderRadius: 15,
						  }
						: {
								backgroundImage: `url(${
									idTurma === '1174' || idTurma === '1157' || idTurma === '1187' || idTurma === '1203'
										? EXTR120232024CardBackground
										: idTurma === '1175'
										? R3CMCardBackground
										: null
								})`,
						  }
				}
			>
				<img
					src={logo}
					alt=""
					className={classes.extensivoLogoImg}
				/>
				{window.location.pathname === '/sprint-final-revalida' && (
					<>
						<Divider
							variant="middle"
							className={classes.divider}
							style={{ width: matchesMobile ? '100%' : '85%' }}
						/>
						<Grid
							item
							style={{
								backgroundColor: '#058A49',
								borderRadius: 4,
								width: 'fit-content',
								padding: '0.1rem 1rem',
								marginTop: '0.75rem',
							}}
						>
							<Typography
								color="secondary"
								className="poppins"
								style={matchesMobile ? { fontSize: '0.7rem' } : { alignSelf: 'center', fontSize: 13 }}
							>
								20% OFF para ex-aluno: Hardwork Revalida, Trilha Revalida e Arena presencial
							</Typography>
						</Grid>
						<Grid
							container
							direction="column"
							alignItems="flex-start"
							style={{
								width: 'fit-content',
								padding: '0.1rem 1rem',
								marginTop: '0.4rem',
								marginBottom: '0.4rem',
							}}
						>
							<Grid
								item
								justifyContent="center"
								justifySelf="center"
								alignSelf="center"
								style={{
									backgroundColor: '#058A49',
									borderRadius: 4,
									width: 'fit-content',
									padding: '0.1rem 1rem',
								}}
							>
								<Typography
									color="secondary"
									className="poppins"
									style={
										matchesMobile
											? { fontSize: '0.7rem' }
											: {
													justifyContent: 'center',
													justifySelf: 'center',
													alignSelf: 'center',
													fontSize: 13,
											  }
									}
								>
									10% OFF para ex-aluno: Sprint Final ou Arena online
								</Typography>
							</Grid>
							<Grid
								item
								style={{
									width: 'fit-content',
									padding: '0.1rem 1rem',
								}}
							>
								<Typography
									color="secondary"
									className="poppins"
									style={
										matchesMobile
											? { fontSize: '0.7rem', justifySelf: 'flex-start', alignSelf: 'flex-start' }
											: matchesLg
											? {
													justifySelf: 'flex-start',
													alignSelf: 'flex-start',
													fontSize: 13,
													marginTop: '0.90rem',
													marginRight: '45em',
											  }
											: {
													justifySelf: 'flex-start',
													alignSelf: 'flex-start',
													fontSize: 13,
													marginTop: '0.90rem',
													marginRight: '30em',
											  }
									}
								>
									*Desconto não acumulativo
								</Typography>
							</Grid>
						</Grid>
					</>
				)}
				{window.location.pathname === '/trilha-r3-clinica-medica' && (
					<>
						<Divider
							variant="middle"
							className={classes.divider}
							style={{ width: matchesMobile ? '100%' : '85%' }}
						/>
						<Grid
							item
							style={{
								backgroundColor: '#5829B2',
								borderRadius: 4,
								width: 'fit-content',
								padding: '0.5rem 1rem',
								marginTop: '0.75rem',
							}}
						>
							<Typography
								color="secondary"
								className="poppins"
								style={matchesMobile ? { fontSize: '0.7rem' } : { alignSelf: 'center', fontSize: 14 }}
							>
								20% OFF para alunos ou ex-alunos Extensivo
							</Typography>
						</Grid>
					</>
				)}
			</Grid>
			<Grid
				item
				xs={12}
				md={5}
				container
				justifyContent="center"
				alignContent="center"
				className={color ? classes.gridPriceInfoBlack : classes.gridPriceInfo}
				style={
					turma?.encerrada
						? { padding: '1em 0' }
						: discount
						? { padding: matchesTablet ? 0 : '1.5rem 0' }
						: { paddingTop: endDate !== undefined ? null : '0' }
				}
				gap={window.location.pathname === '/sprint-final-revalida' ? 1 : 0}
			>
				<Grid
					item
					container
					justifyContent={matchesTablet ? 'center' : 'flex-end'}
					alignContent="center"
					rowGap={4}
				>
					{window.location.pathname === '/sprint-final-revalida' ? (
						<Grid
							item
							container
							justifyContent={'space-between'}
							wrap={'nowrap'}
							columnGap={2}
							style={{
								backgroundColor: 'transparent',
								padding: matchesMobile ? '0.5rem 1rem 0.25rem' : '0.5rem 0.75rem 0',
								borderRadius: '0 0 8px 8px',
								marginRight: matchesTablet ? 'unset' : '1rem',
								width: 'fit-content',
								paddingTop: '1rem',
							}}
						>
							{/* <Typography
								color={'primary'}
								className="poppins"
								style={{
									fontSize: matchesMobile ? '0.7rem' : '0.8rem',
									alignSelf: matchesMobile ? 'center' : null,
								}}
							>
								<strong>Promoção</strong>
								<br />
								se encerra em
							</Typography>
							<CountDownTimer
								targetDate={endDate}
								textColor={'primary'}
							/> */}
						</Grid>
					) : (
						endDate >= currentDate &&
						window.location.pathname !== '/sprint-final-revalida' && (
							<Grid
								item
								container
								justifyContent={'space-between'}
								wrap={'nowrap'}
								columnGap={2}
								style={{
									backgroundColor: '#ffffff',
									padding: matchesMobile ? '0.5rem 1rem 0.25rem' : '0.5rem 0.75rem 0',
									borderRadius: '0 0 8px 8px',
									marginRight: matchesTablet ? 'unset' : '1rem',
									width: 'fit-content',
								}}
							>
								<Typography
									color={'primary'}
									className="poppins"
									style={{
										fontSize: matchesMobile ? '0.7rem' : '0.8rem',
										alignSelf: matchesMobile ? 'center' : null,
									}}
								>
									<strong>Promoção</strong>
									<br />
									se encerra em
								</Typography>
								<CountDownTimer
									targetDate={endDate}
									textColor={'primary'}
								/>
							</Grid>
						)
					)}

					<Grid
						item
						xs={12}
						container
						direction={'column'}
						justifyContent={turma?.encerrada ? 'center' : 'flex-start'}
						className={classes.priceContainerInfo}
						style={
							matchesMobile
								? { width: turma?.encerrada ? null : 'unset' }
								: turma?.encerrada
								? { width: '70%', margin: '2rem 0' }
								: {
										width: window.location.pathname === '/trilha-r1-2024' ? '100%' : '70%',
								  }
						}
					>
						{turma?.encerrada ? (
							<>
								<Typography
									color={color ? 'secondary' : 'primary'}
									align="left"
									className={`${classes.subscriptionInactiveText} roboto-condensed`}
								>
									INSCRIÇÕES ENCERRADAS
								</Typography>
							</>
						) : (
							<>
								{turma?.valor !== lot?.valor &&
									window.location.pathname === '/sprint-final-revalida' && (
										<Typography
											color={color ? 'primary' : 'secondary'}
											align="left"
											className={`${classes.priceTextStrikeThrough} poppins`}
											style={{ marginBottom: '10px', marginTop: '10px' }}
										>
											De: R${' '}
											{turma?.valor?.toLocaleString('pt-BR', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											})}
										</Typography>
									)}
								{window.location.pathname === '/sprint-final-revalida' && (
									<Typography className={`${classes.priceTextWhite} roboto-condensed`}>
										Até{' '}
										{turma?.parcelas && turma?.parcelas.length > 0
											? turma?.parcelas[turma?.parcelas.length - 1]?.qtd
											: ''}
										x de{<br />}
										<strong className={`${classes.priceTextWhiteUnder} roboto-condensed`}>
											R${' '}
											{turma?.parcelas && turma?.parcelas.length > 0
												? turma?.parcelas[turma?.parcelas.length - 1]?.valor?.toLocaleString(
														'pt-BR',
														{
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														}
												  )
												: ''}
										</strong>
									</Typography>
								)}
								{window.location.pathname === '/sprint-final-revalida' && (
									<Typography
										className={`${classes.priceTextGrey} poppins`}
										style={{ paddingTop: '10px' }}
									>
										Ou à vista por: R${' '}
										{lot
											? lot?.valor?.toLocaleString('pt-BR', {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
											  })
											: turma?.valor?.toLocaleString('pt-BR', {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
											  })}
									</Typography>
								)}
							</>
						)}
					</Grid>
					{window.location.pathname !== '/trilha-r3-clinica-medica' &&
						window.location.pathname !== '/sprint-final-revalida' && (
							<>
								<Grid
									item
									xs={12}
									className={classes.gridDivider}
								>
									<Divider
										variant="middle"
										className={classes.divider}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									className={classes.gridDivider}
									style={{ marginBottom: '0.5rem' }}
								>
									<Typography className={`${classes.price24x} poppins`}>
										Ou até{' '}
										{turma?.parcelas && turma?.parcelas.length > 0 ? turma?.parcelas[11]?.qtd : ''}x
										de R${' '}
										{turma?.parcelas && turma?.parcelas?.length > 0
											? turma?.parcelas[11]?.valor?.toLocaleString('pt-BR', {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
											  })
											: ''}{' '}
										sem juros
									</Typography>
								</Grid>
							</>
						)}
					{/* {window.location.pathname === '/sprint-final-revalida' && (  
                        <Grid
						item
						xs={12}
						container
						direction={'column'}
						justifyContent={'center'}
						className={classes.priceContainerInfo}
						style={
							matchesMobile
								? { width: turma?.encerrada ? null : 'unset' }
								: turma?.encerrada
								? { width: '70%', margin: '2rem 0' }
								: {
										width: window.location.pathname === '/trilha-r1-2024' ? '100%' : '70%',
								  }
						}>
                        <Typography
                            color={color ? 'secondary' : 'primary'}
                            align="center"
                            className={`${classes.subscriptionEmBreveText} roboto-condensed`}
                        >
                            EM BREVE
                        </Typography>
                    </Grid>)} */}
					{turma?.encerrada ? null : (
						<Grid
							item
							container
							alignContent={'center'}
							justifyContent={'center'}
							style={matchesTablet ? null : { height: '4rem' }}
						>
							<DefaultButton
								variant="outlined"
								color="secondary"
								className={`${classes.btn} btn button-info-card`}
								component={Link}
								to={`//hardworkmedicina.com.br/inscricao/${idTurma} `}
							>
								{matchesMobile ? null : (
									<img
										src={iconBtn}
										alt=""
										className={classes.iconBnt}
									/>
								)}
								{' \u00A0Inscrever-se '}
							</DefaultButton>
						</Grid>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	originalPriceText: {
		fontSize: '1.5rem !important',
		color: '#A6A6A6 !important',
		textDecoration: 'line-through',
	},
	extensivoR1Card: {
		borderRadius: 13,
		// border: '2px solid black !important',
		[theme.breakpoints.down('md')]: {
			height: 'unset',
		},
		[theme.breakpoints.down('sm')]: {
			// width: '90% !important',
		},
	},
	gridImg: {
		height: '100%',
		borderBottomLeftRadius: 15,
		paddingTop: '1rem !important',
		[theme.breakpoints.down('md')]: {
			height: 'unset',
		},
		[theme.breakpoints.down('sm')]: {
			padding: 25,
			borderRadius: 25,
		},
	},
	extensivoLogoImg: {
		width: window.location.pathname === '/trilha-r3-clinica-medica' ? 'unset' : '80%',
		maxHeight: '65%',
		[theme.breakpoints.down('md')]: {
			padding: '0px',
			maxHeight: 'unset',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: '0px 10px',
		},
	},
	gridPriceInfo: {
		backgroundColor: '#ffffff !important',
		borderRadius: 15,
		[theme.breakpoints.down('sm')]: {
			padding: '8px 0 ',
		},
	},
	gridPriceInfoBlack: {
		borderBottomRightRadius: 15,
		borderTopRightRadius: 15,
		[theme.breakpoints.down('sm')]: {
			padding: '0 0 8px',
		},
	},
	priceTextStrikeThrough: {
		fontSize: '1.5rem !important',
		color: '#A6A6A6 !important',
		lineHeight: 'unset !important',
		textDecoration: 'line-through',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	priceTextWhite: {
		color: 'white !important',
		fontSize: '2rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '1.5rem !important',
		},
	},
	priceTextWhiteUnder: {
		color: 'white !important',
		fontSize: '4.25rem !important',
		fontWeight: 'bold !important',
		lineHeight: '0.9 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '3rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '3.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.5rem !important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '2.5rem !important',
		},
	},
	priceTextGrey: {
		fontSize: '1.4rem !important',
		color: 'grey !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.25rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.25rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '1rem !important',
		},
	},
	iconBnt: {
		width: '7%',
	},
	btn: {
		width: '75%',
		height: '65%',
		marginBottom: '1rem !important',
		fontSize: '1.35rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		borderColor: 'transparent',
		backgroundColor: '#00AB55 !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				minWidth: '72% !important',
				fontSize: '1.5rem !important',
				backgroundColor: '#00543b !important',
				transition: '0.1s',
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			width: '90%',
		},
	},
	discount: {
		fontSize: '1.1rem',
		color: 'red',
		alignSelf: 'center',
		marginTop: '0.5em',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			margin: '0.5em 0',
		},
	},
	disclaimerText: {
		fontSize: '1rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	subscriptionInactiveText: {
		fontSize: '3.25rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
			margin: '1rem !important',
		},
	},
	subscriptionEmBreveText: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
			margin: '1rem !important',
		},
	},

	soonStripeImg: {
		position: 'absolute',
		top: -18,
		right: -90,
		[theme.breakpoints.down('xl')]: {
			right: -77,
		},
	},
	divider: {
		margin: '0.5rem auto !important',
		backgroundColor: '#6D6D6D',
		minHeight: 1,
	},
	priceContainerInfo: {
		paddingLeft: '2rem',
	},
	gridDivider: {
		paddingRight: '2rem',
		paddingLeft: '2rem',
	},
	price24x: {
		fontSize: '1rem !important',
		color: 'grey !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
}));
