import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Divider, Grid, Modal, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DefaultButton from '../../../../../Components/Buttons/DefaultButton';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

import AmrigsLogo from '../../../../../Assets/SprintFinal/amrigsLogo.svg';
import EinsteinLogo from '../../../../../Assets/SprintFinal/einsteinLogo.svg';
import EnareLogo from '../../../../../Assets/SprintFinal/enareLogo.svg';
import IamspeLogo from '../../../../../Assets/SprintFinal/iamspeLogo.svg';
import PsugoLogo from '../../../../../Assets/SprintFinal/psugoLogo.svg';
import FacMedicinaJundiaiLogo from '../../../../../Assets/SprintFinal/facMedicinaJundiaiLogo.svg';
import PsuMgLogo from '../../../../../Assets/SprintFinal/psuMgLogo.svg';
import SantaCasaLogo from '../../../../../Assets/SprintFinal/santaCasaSpLogo.svg';
import SesDfLogo from '../../../../../Assets/SprintFinal/sesDfLogo.svg';
import SesPeLogo from '../../../../../Assets/SprintFinal/sesPeLogo.svg';
import UfrjLogo from '../../../../../Assets/SprintFinal/ufrjLogo.svg';
import AbcLogo from '../../../../../Assets/SprintFinal/abcLogo.svg';
import SirioLibanesLogo from '../../../../../Assets/SprintFinal/sirioLibanesLogo.svg';
import SurceLogo from '../../../../../Assets/SprintFinal/surceLogo.svg';
import SusBaLogo from '../../../../../Assets/SprintFinal/susBaLogo.svg';
import UnicampLogo from '../../../../../Assets/SprintFinal/unicampLogo.svg';
import SusSpLogo from '../../../../../Assets/SprintFinal/susSpLogo.svg';
import UnespBotucatuLogo from '../../../../../Assets/SprintFinal/unespBotucatuLogo.svg';
import AmpLogo from '../../../../../Assets/SprintFinal/ampLogo.svg';
import UnifespLogo from '../../../../../Assets/SprintFinal/unifestpLogo.svg';
import UspLogo from '../../../../../Assets/SprintFinal/uspLogo.svg';
import UspRibeiraoLogo from '../../../../../Assets/SprintFinal/uspRibeiraoLogo.svg';
import FamemaLogo from '../../../../../Assets/SprintFinal/famemaLogo.svg';
import UerjLogo from '../../../../../Assets/SprintFinal/uerjLogo.svg';
import UfesLogo from '../../../../../Assets/SprintFinal/ufesLogo.svg';

import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Api from '../../../../../Services/Api';
import IntlTelInput from 'react-intl-tel-input';
import Swal from 'sweetalert2';
import { trackPromise } from 'react-promise-tracker';

const exams = [
	{
		id: '1210',
		imageUrl: AbcLogo,
		isOpen: false,
	},
	{
		id: '1211',
		imageUrl: AmpLogo,
		isOpen: false,
	},
	{
		id: '1212',
		imageUrl: AmrigsLogo,
		isOpen: false,
	},
	{
		id: '1213',
		imageUrl: EinsteinLogo,
		isOpen: false,
	},
	{
		id: '1214',
		imageUrl: EnareLogo,
		isOpen: false,
	},
	{
		id: '1216',
		imageUrl: FamemaLogo,
		isOpen: new Date('2023-11-24T00:00') > new Date(),
	},
	{
		id: '1215',
		imageUrl: FacMedicinaJundiaiLogo,
		isOpen: false,
	},
	{
		id: '1223',
		imageUrl: SirioLibanesLogo,
		isOpen: false,
	},
	{
		id: '1217',
		imageUrl: IamspeLogo,
		isOpen: false,
	},
	{
		id: '1221',
		imageUrl: PsugoLogo,
		isOpen: false,
	},
	{
		id: '1218',
		imageUrl: PsuMgLogo,
		isOpen: false,
	},
	{
		id: '1219',
		imageUrl: SantaCasaLogo,
		isOpen: new Date('2024-01-16T23:59') > new Date(),
	},
	{
		id: '1220',
		imageUrl: SesDfLogo,
		isOpen: new Date('2023-12-01T23:59') > new Date(),
	},
	{
		id: '1222',
		imageUrl: SesPeLogo,
		isOpen: new Date('2023-11-26T00:00') > new Date(),
	},
	{
		id: '1224',
		imageUrl: SurceLogo,
		isOpen: false,
	},
	{
		id: '1226',
		imageUrl: SusBaLogo,
		isOpen: false,
	},
	{
		id: '1225',
		imageUrl: SusSpLogo,
		isOpen: new Date('2023-12-01T23:59') > new Date(),
	},
	{
		id: '1227',
		imageUrl: UerjLogo,
		isOpen: false,
	},
	{
		id: '1236',
		imageUrl: UfesLogo,
		isOpen: false,
	},
	{
		id: '1235',
		imageUrl: UfrjLogo,
		isOpen: false,
	},
	{
		id: '1231',
		imageUrl: UnespBotucatuLogo,
		isOpen: false,
	},
	{
		id: '1228',
		imageUrl: UnicampLogo,
		isOpen: false,
	},
	{
		id: '1230',
		imageUrl: UnifespLogo,
		isOpen: false,
	},
	{
		id: '1232',
		imageUrl: UspLogo,
		isOpen: new Date('2023-11-26T00:00') > new Date(),
	},
	{
		id: '1234',
		imageUrl: UspRibeiraoLogo,
		isOpen: false,
	},
];

const TestCard = ({ imageUrl, classes, selected }) => {
	return (
		<Grid
			container
			justifyContent={'flex-end'}
			className={`${classes.cardRoot} ${classes.testCardStyle} ${selected ? classes.testCardSelected : ''} ${
				selected ? 'background-residencia' : null
			}`}
			style={!selected ? { backgroundColor: '#191818', opacity: 0.9, cursor: 'pointer' } : null}
		>
			<Grid
				item
				container
				justifyContent="center"
				className={classes.gridImgTestCard}
				style={{ backgroundColor: selected ? '#333333' : '#191818' }}
			>
				<img
					src={imageUrl}
					alt=""
					className={classes.img}
					style={{ opacity: selected ? 1 : 0.5 }}
				/>
			</Grid>
		</Grid>
	);
};

const TestCardClosed = ({ imageUrl, classes }) => {
	return (
		<Grid
			container
			justifyContent={'flex-end'}
			className={`${classes.cardRoot} ${classes.testCardStyle} `}
			style={{ backgroundColor: '#454545', opacity: 0.9 }}
		>
			<Grid
				item
				container
				justifyContent="center"
				className={classes.gridImgTestCardClosed}
			>
				<img
					src={imageUrl}
					alt=""
					style={{ width: '60%', filter: 'grayscale(1)' }}
				/>
			</Grid>
			<Grid
				item
				container
				justifyContent={'center'}
				alignContent={'center'}
				className={classes.gridTextCardClosed}
			>
				<Typography
					color="secondary"
					className={`${classes.textTestCardClosed} poppins`}
				>
					INSCRIÇÕES EM BREVE
				</Typography>
			</Grid>
		</Grid>
	);
};

const CustomModal = ({ open, onClose, message, onCheckboxChange, checked }) => {
	const classes = useStyles();

	return (
		<Modal
			open={open}
			onClose={onClose}
		>
			<Grid
				container
				direction={'column'}
				className={classes.modalStyle}
				gap={1}
			>
				<Grid item>
					<Typography
						color="primary"
						className={`${classes.modalMessage} poppins`}
					>
						{message}
					</Typography>
				</Grid>
				<Grid item>
					<input
						type="checkbox"
						checked={checked}
						onChange={onCheckboxChange}
						style={{ marginRight: 5 }}
					/>
					<span
						style={{ color: '#000000' }}
						className={`${classes.modalMessage} poppins`}
					>
						Não mostrar novamente
					</span>
				</Grid>
				<Button
					variant="contained"
					color="primary"
					onClick={onClose}
					style={{ marginLeft: 10, borderRadius: 15 }}
				>
					Fechar
				</Button>
			</Grid>
		</Modal>
	);
};

export const Countdown = ({ startDate, endDate, matchesMobile, classes }) => {
	const [timeRemaining, setTimeRemaining] = useState(null);

	useEffect(() => {
		const startCountdownTime = startDate.getTime();
		const endCountdownTime = endDate.getTime();

		const currentTime = new Date().getTime();

		if (currentTime >= startCountdownTime && currentTime < endCountdownTime) {
			const timeInSeconds = Math.ceil((endCountdownTime - currentTime) / 1000);
			setTimeRemaining(timeInSeconds);
		} else {
			setTimeRemaining(null);
		}

		const timer = setInterval(() => {
			const currentTime = new Date().getTime();

			if (currentTime >= startCountdownTime && currentTime < endCountdownTime) {
				const timeInSeconds = Math.ceil((endCountdownTime - currentTime) / 1000);
				setTimeRemaining(timeInSeconds);
			} else {
				setTimeRemaining(null);
			}
		}, 1000);

		return () => clearInterval(timer);
	}, [startDate, endDate]);

	const formatTime = (time) => {
		const days = Math.floor(time / (3600 * 24));
		const hours = Math.floor((time % (3600 * 24)) / 3600);
		const minutes = Math.floor((time % 3600) / 60);
		const seconds = time % 60;

		const formattedDays = days.toString().padStart(2, '0');
		const formattedHours = hours.toString().padStart(2, '0');
		const formattedMinutes = minutes.toString().padStart(2, '0');
		const formattedSeconds = seconds.toString().padStart(2, '0');

		return (
			<span>
				{formattedDays} &nbsp;&nbsp;&nbsp;{formattedHours}:{formattedMinutes}:{formattedSeconds}
			</span>
		);
	};

	return (
		<>
			{timeRemaining !== null ? (
				<Grid
					item
					xs={9}
					md={11}
					lg={8}
					container
					justifyContent={matchesMobile ? 'center' : 'space-around'}
					alignContent={'center'}
					className={classes.timerGrid}
					gap={matchesMobile ? 1 : 0}
				>
					<Typography
						color="secondary"
						className={`${classes.endOfDiscount} poppins`}
					>
						<strong style={{ color: '#FF4765' }}>Valores promocionais</strong>
						<br />
						se encerram em
					</Typography>
					<Grid
						container
						direction={'column'}
						style={{ width: 'fit-content', alignSelf: 'center' }}
					>
						<Grid
							container
							alignItems="center"
							gap={1}
							style={{ width: 'fit-content' }}
						>
							<Typography
								color="secondary"
								className={`${classes.timeText} poppins`}
							>
								{formatTime(timeRemaining)}
							</Typography>
						</Grid>
						<Grid
							container
							wrap={'nowrap'}
							justifyContent={'space-around'}
							alignItems="center"
						>
							<Grid
								item
								container
								style={{ width: 'fit-content' }}
							>
								<Typography
									color="secondary"
									className={`${classes.timeTextDescription} poppins`}
									style={{ fontWeight: 'bold', fontSize: '0.85rem', left: 8 }}
								>
									DIAS
								</Typography>
							</Grid>
							<Grid
								item
								container
								justifyContent={'flex-end'}
								gap={1}
								className={classes.gridTime}
							>
								<Typography
									color="secondary"
									className={`${classes.timeTextDescription} poppins`}
								>
									HORAS
								</Typography>
								<Typography
									color="secondary"
									className={`${classes.timeTextDescription} poppins`}
								>
									MINUTOS
								</Typography>
								<Typography
									color="secondary"
									className={`${classes.timeTextDescription} poppins`}
								>
									SEGUNDOS
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<img
						src={require('../assets/icon-watch-timer-sf.png')}
						alt=""
						className={classes.timerIcon}
					/>
				</Grid>
			) : null}
		</>
	);
};

export default function PriceCardResidencia({ /* imageBackground */ logo, bgImageUrl, color, discount }) {
	const classes = useStyles();
	const theme = useTheme();
	const [turma, setTurma] = useState([]);
	// const [turmaMock] = useState(true);
	const [allProductsModeOption, setAllProductsModeOption] = useState('apenas um');
	const [selectedTests, setSelectedTests] = useState(['1219']);
	const [showAlert, setShowAlert] = useState(false);
	const [hideAlertCheckbox, setHideAlertCheckbox] = useState(false);
	const startDate = new Date('2023-07-21T20:23:00'); // Data Para Começar
	const endDate = new Date('2023-08-30T00:00:00');
	const [waitListVisibility, setWaitListVisibility] = useState('hidden');

	// Use o hook 'useCookies' para acessar os cookies
	const [cookies, setCookie] = useCookies(['hideAlert']);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

    const [preInscricao, setPreInscricao] = useState({
		agrupamento: 'SPRINT FINAL 2024 LISTA DE ESPERA',
		nome: '',
		email: '',
		telefone: '',
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
	});

	const handleToggleProductChoiceMode = () => {
		setAllProductsModeOption(!allProductsModeOption);
		if (selectedTests.length > 0) {
			setSelectedTests([]);
		}
	};

	useEffect(() => {
		Api.get(`turmasabertas/${selectedTests.length > 0 ? selectedTests[0] : '1219'}`).then((res) => {
			if (res) setTurma(res[0]);
		});
	}, [selectedTests]);

	useEffect(() => {
		if (selectedTests.length > 3 && !cookies.hideAlert) {
			setShowAlert(true);
		}
		if (selectedTests.length > 1 && !cookies.hideAlert) {
			setShowAlert(true);
		}
	}, [selectedTests, cookies.hideAlert]);

	const handleOpenWaitList = () => {
		setWaitListVisibility('unset');
	};

	const handleCloseWaitList = () => {
		setWaitListVisibility('hidden');
	};

    const handlePhone = (valid, phoneNumber) => {
		if (valid) {
			setPreInscricao({
				...preInscricao,
				telefone: phoneNumber,
			});
		} else if (preInscricao.telefone.length) {
			setPreInscricao({
				...preInscricao,
				telefone: '',
			});
		}
	};

	const handleTestSelection = (testId) => {
		if (selectedTests.includes(testId)) {
			setSelectedTests(selectedTests.filter((id) => id !== testId));
		} else {
			if (allProductsModeOption === '3 sprints') {
				if (selectedTests.length === 3) {
					setShowAlert(true);
					setCookie('hideAlert', true, { path: '/' });
				} else {
					setSelectedTests([...selectedTests, testId]);
				}
			} else {
				if (selectedTests.length === 1) {
					// setShowAlert(true);
					// setCookie('hideAlert', true, { path: '/' });
				}
				setSelectedTests([testId]);
			}
		}
	};

	const handleCheckboxChange = (event) => {
		setHideAlertCheckbox(true);
		setCookie('hideAlert', event.target.checked, { path: '/' });
	};

	const handleModalClose = () => {
		setShowAlert(false); // Fecha o modal quando o usuário clicar no botão "Fechar"
	};

	const handleOptions = (option) => {
		if (option === 'apenas um') {
			setSelectedTests([]);
			setAllProductsModeOption('apenas um');
		}
		if (option === '3 sprints') {
			setSelectedTests([]);
			setAllProductsModeOption('3 sprints');
		}
		if (option === 'sprints sp') {
			setSelectedTests([]);
			setAllProductsModeOption('sprints sp');
		}
	};

    let sendPreInscricao = function () {
		if (!preInscricao.nome) {
			alert('Ops... Por favor informe seu nome');
			return;
		}
		if (!preInscricao.email || !preInscricao.email.includes('@') || !preInscricao.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (!preInscricao.telefone || preInscricao.telefone.length < 11) {
			alert('Ops... Por favor preencha seu telefone');
			return;
		}

		trackPromise(
			Api.post('PreInscricao', preInscricao).then((res) => {
				if (res) {
					Swal.fire({
						title: 'Massa! ',
						text: 'Sua inscrição na lista de espera está confirmada! Avisaremos assim que a nova turma estiver liberada.',
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then((result) => {
						if (result.isConfirmed) {
							window.location.reload();
						}
					});
				}
			})
		);
	};

	return (
		<>
			<CustomModal
				open={showAlert && !hideAlertCheckbox}
				onClose={handleModalClose}
				message={
					allProductsModeOption === 'apenas um'
						? 'Caso deseje fazer mais de uma prova, veja a opção “Pacote 3 Sprints” ou “Quero Todos”'
						: 'Caso deseje fazer mais de três provas, veja a opção “Quero Todos”'
				}
				onCheckboxChange={handleCheckboxChange}
				checked={hideAlertCheckbox}
			/>
			<Grid container>
				{/* <Grid
					item
					xs={12}
					container
				>
					<Grid
						item
						container
						justifyContent={'center'}
						alignContent={'flex-end'}
						xs={6}
						className={allProductsModeOption ? 'background-residencia' : null}
						style={{
							borderTopLeftRadius: 15,
							borderTopRightRadius: 15,
							paddingTop: matchesMobile ? '0.75rem' : '1rem',
							cursor: 'pointer',
						}}
					>
						<Grid
							item
							xs={12}
							container
							justifyContent={'center'}
							className={allProductsModeOption ? classes.selectedOption : classes.nonSelectedOption}
							style={allProductsModeOption ? null : { borderTopLeftRadius: 15 }}
						>
							<Grid
								item
								style={{ width: '90%', borderBottom: allProductsModeOption ? '1px solid' : null }}
							>
								<DefaultButton
									onClick={handleToggleProductChoiceMode}
									style={matchesMobile ? { padding: '6px 0' } : null}
								>
									<Typography
										color={'secondary'}
										align="center"
										className={`${
											allProductsModeOption
												? classes.selectedOptionText
												: classes.nonSelectedOptionText
										} poppins`}
									>
										QUERO TODOS
									</Typography>
								</DefaultButton>
							</Grid>
						</Grid>
					</Grid>
					<Grid
						item
						container
						justifyContent={'center'}
						alignContent={'flex-end'}
						xs={6}
						className={allProductsModeOption ? null : 'background-residencia'}
						style={{
							borderTopLeftRadius: 15,
							borderTopRightRadius: 15,
							paddingTop: matchesMobile ? '0.75rem' : '1rem',
							cursor: 'pointer',
						}}
					>
						<Grid
							item
							xs={12}
							container
							justifyContent={'center'}
							className={allProductsModeOption ? classes.nonSelectedOption : classes.selectedOption}
							style={allProductsModeOption ? { borderTopRightRadius: 15 } : null}
						>
							<Grid
								item
								style={{ width: '90%', borderBottom: allProductsModeOption ? null : '1px solid' }}
							>
								<DefaultButton
									onClick={handleToggleProductChoiceMode}
									style={matchesMobile ? { padding: '6px 0' } : null}
								>
									<Typography
										color={'secondary'}
										align="center"
										className={`${
											allProductsModeOption
												? classes.nonSelectedOptionText
												: classes.selectedOptionText
										} poppins`}
									>
										QUERO ESCOLHER
									</Typography>
								</DefaultButton>
							</Grid>
						</Grid>
					</Grid>
				</Grid> */}
				<Grid
					item
					xs={12}
					container
					style={{
						backgroundColor: '#000000',
						backgroundImage: `url(${require('../assets/bg-tech-sf-23.png')})`,
						backgroundSize: 'cover',
						borderRadius: 15,
						padding: '0 1rem',
					}}
				>
					<Grid
						container
						alignContent={matchesTablet ? 'space-around' : 'center'}
						justifyContent="space-between"
						className={classes.extensivoR1Card}
					>
						<Grid
							item
							xs={12}
							md={6}
							container
							justifyContent="center"
							alignContent="center"
							className={`${classes.gridImg} residencia`}
							style={{
								backgroundImage: `url(${bgImageUrl})`,
								backgroundSize: 'cover',
							}}
						>
							<img
								src={require('../assets/logo-sf-residencia-2024.svg').default}
								alt="Logo Sprint Final"
								className={classes.extensivoLogoImg}
							/>
							{allProductsModeOption === 'todos' && (
								<Typography
									color="secondary"
									align="center"
									className={`${classes.sfDescription} roboto-condensed`}
								>
									Tenha acesso a todas as{' '}
									<strong>{exams.filter((exam) => exam.isOpen).length}</strong> preparações
									<strong
										className="gradient-text"
										style={{ display: 'inline' }}
									>
										&nbsp;focadas
										<br />
									</strong>
								</Typography>
							)}
							{allProductsModeOption === 'sprints sp' && (
								<Typography
									color="secondary"
									align="center"
									className={`${classes.sfDescription} roboto-condensed`}
								>
									Tenha acesso a todos os Sprints de <strong>São Paulo</strong>
								</Typography>
							)}
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							container
							justifyContent="center"
							alignContent="center"
							className={color ? classes.gridPriceInfoBlack : classes.gridPriceInfo}
							// style={{ padding: matchesTablet ? 0 : '1.5rem 0' }}
							gap={window.location.pathname === '/sprint-final-revalida' ? 1 : matchesTablet ? 1 : 0}
						>
							<Grid
								item
								container
								justifyContent={turma.encerrada ? 'center' : 'flex-start'}
								alignContent="center"
								direction={'column'}
								style={
									matchesMobile
										? { width: turma.encerrada ? null : '75%' }
										: turma.encerrada
										? { width: '70%', margin: '2rem 0' }
										: { width: '70%', marginBottom: 20 }
								}
							>
								{turma.encerrada ? (
									<>
										<Typography
											// color={color ? 'secondary' : 'primary'}
											color={'secondary'}
											align="left"
											className={`${classes.subscriptionInactiveText} poppins`}
											style={{ marginBottom: '0.5rem' }}
										>
											{/* INSCRIÇÕES{!matchesTablet || !matchesMobile ? <br /> : null} ENCERRADAS */}
											Abertura de inscrições em breve!
										</Typography>
										<Typography className={`${classes.underSubscription} poppins`}>
											Aguarde a abertura da próxima turma.
										</Typography>
										<Divider
											variant="middle"
											className={classes.divider}
										/>
										<Typography
											style={{ marginBottom: '1rem' }}
											className={`${classes.underSubscription} poppins`}
										>
											Quer <strong>ser avisado</strong> sobre novas turmas? <br />
											Inscreva-se na lista de espera.
										</Typography>
										<DefaultButton
											variant="outlined"
											color="secondary"
											className={`${classes.btnTwo} btn`} //here
											onClick={() => handleOpenWaitList()}
										>
											{matchesMobile ? null : (
												<img
                                                    style={{width: '7%', marginRight: '4px'}}
                                                    src={require('../assets/icon-bell-regular.svg').default}
													alt=""
												/>
											)}
											Avise-me
										</DefaultButton>
									</>
								) : (
									<>
										{/* {promotionalValueIsOver ? null : (
											<Typography
												color={'secondary'}
												className={classes.priceTextStrikeThrough}
											>
												R$
												{turma?.valor?.toLocaleString('pt-BR')}
												{allProductsModeOption === 'todos'
													? '4.490'
													: turma.valor?.toLocaleString('pt-BR')}
												<sup style={{ fontSize: '61%', top: '-0.75em' }}>,00</sup>
											</Typography>
										)} */}
										<Typography
											color="secondary"
											className={classes.priceTextWhite}
										>
											R${' '}
											{allProductsModeOption === 'todos'
												? '3.290'
												: allProductsModeOption === '3 sprints'
												? '2.490'
												: allProductsModeOption === 'sprints sp'
												? '2.690'
												: turma?.valor?.toLocaleString('pt-BR')}
											<sup style={{ fontSize: '61%', top: '-0.75em' }}>,00</sup>
										</Typography>
										<Typography className={classes.priceTextGrey}>
											{/* ou até {turma?.parcelas[turma?.parcelas?.length - 1]?.qtd} */}
											{turma?.parcelas && turma?.parcelas?.length > 0
												? turma?.parcelas[turma?.parcelas?.length - 1]?.qtd
												: ''}
											x de R${' '}
											{allProductsModeOption === 'todos'
												? '274,17'
												: allProductsModeOption === '3 sprints'
												? '207,50'
												: allProductsModeOption === 'sprints sp'
												? '224,16'
												: '165,83'}{' '}
											no cartão de crédito*
											{/* {turma?.parcelas && turma.parcelas.length > 0
                                            ? turma.parcelas[turma.parcelas.length - 1].valor
                                            : ''} */}
										</Typography>
										{/* <Typography
											color="secondary"
											className={`${classes.text} poppins`}
										>
											*O acesso será liberado a partir de 22/08/2023
										</Typography> */}
									</>
								)}
							</Grid>
							{turma.encerrada ? null : (
								<Grid
									item
									container
									alignContent={'center'}
									justifyContent={'center'}
									style={matchesTablet ? null : { height: '4rem' }}
									className="residencia"
								>
									<DefaultButton
										variant="outlined"
										color="secondary"
										className={`${classes.btn} btn button-info-card ${
											(allProductsModeOption === 'apenas um' && selectedTests.length < 1) ||
											(allProductsModeOption === '3 sprints' && selectedTests.length < 3)
												? null
												: 'btn-primary'
										}`}
										component={
											allProductsModeOption === 'todos' || allProductsModeOption === 'sprints sp'
												? undefined
												: Link
										}
										to={
											allProductsModeOption === 'todos' || allProductsModeOption === 'sprints sp'
												? null
												: allProductsModeOption === '3 sprints'
												? `//hardworkmedicina.com.br/inscricao/${selectedTests[0]},${selectedTests[1]},${selectedTests[2]}`
												: `//hardworkmedicina.com.br/inscricao/${selectedTests[0]}`
										}
										href={
											allProductsModeOption === 'todos'
												? 'https://hardworkmedicina.com.br/inscricao/sprintR12023'
												: allProductsModeOption === 'sprints sp'
												? 'https://hardworkmedicina.com.br/inscricao/sprintR12023SP'
												: null
										}
										disabled={
											(allProductsModeOption === 'apenas um' && selectedTests.length < 1) ||
											(allProductsModeOption === '3 sprints' && selectedTests.length < 3)
										}
									>
										{matchesMobile ? null : (
											<img
												src={require('../../../../../Assets/Svg/Icons/bagIcon.svg').default}
												alt=""
												style={{ height: '85%' }}
											/>
										)}
										&nbsp;{' '}
										{allProductsModeOption === 'todos'
											? 'Adquirir todos'
											: allProductsModeOption === 'apenas um' && selectedTests.length < 1
											? 'Selecione um sprint abaixo'
											: allProductsModeOption === '3 sprints' && selectedTests.length < 3
											? 'Selecione 3 sprints'
											: 'Inscrever-se'}
									</DefaultButton>
								</Grid>
							)}
						</Grid>
					</Grid>
					{allProductsModeOption && !turma.encerrada === 'apenas um' && (
						<Grid
							item
							xs={12}
							container
							justifyContent={'center'}
							alignItems={'center'}
							style={{ margin: '1rem 0', padding: matchesMobile ? '0 0.5rem' : null }}
						>
							<Typography
								color="secondary"
								align={matchesMobile ? 'center' : 'left'}
								className={'poppins'}
								style={{ fontSize: matchesMobile ? '0.8rem' : '1.2rem', fontWeight: 500 }}
							>
								Selecione o Sprint que deseja
							</Typography>
						</Grid>
					)}
					{allProductsModeOption === '3 sprints' && (
						<Grid
							item
							xs={12}
							container
							justifyContent={'center'}
							alignItems={'center'}
							style={{ margin: '1rem 0', padding: matchesMobile ? '0 0.5rem' : null }}
						>
							<Typography
								color="secondary"
								align={matchesMobile ? 'center' : 'left'}
								className={'poppins'}
								style={{ fontSize: matchesMobile ? '0.8rem' : '1.2rem', fontWeight: 500 }}
							>
								Selecione os 3 Sprints que deseja
							</Typography>
						</Grid>
					)}
					<Grid
						item
						xs={12}
						container
						alignContent="center"
						justifyContent="flex-start"
						style={{ margin: `0px 0px ${matchesMobile ? '1rem' : '2rem'}` }}
					>
						{!turma.encerrada && (
							<Grid
								item
								xs={12}
								container
								justifyContent={'center'}
								columnGap={matchesMobile ? 1 : 3}
								rowGap={matchesMobile ? 1.5 : 0}
								style={{ margin: '1rem 0' }}
							>
								<DefaultButton
									variant="outlined"
									color="secondary"
									className={`${classes.optionsBtn} ${
										allProductsModeOption === 'apenas um' ? 'background-residencia' : null
									} btn`}
									onClick={() => handleOptions('apenas um')}
								>
									<Typography
										color={allProductsModeOption === 'apenas um' ? 'primary' : '#D6D6D6'}
										className={`${classes.textOptionsBtn} poppins`}
									>
										Selecionar um
									</Typography>
								</DefaultButton>
								{/* <DefaultButton
                                    variant="outlined"
                                    color="secondary"
                                    className={`${classes.optionsBtn} ${
                                        allProductsModeOption === '3 sprints' ? 'background-residencia' : null
                                    } btn`}
                                    onClick={() => handleOptions('3 sprints')}
                                >
                                    <Typography
                                        color={allProductsModeOption === '3 sprints' ? 'primary' : '#D6D6D6'}
                                        className={`${classes.textOptionsBtn} poppins`}
                                    >
                                        Pacote 3 Sprints
                                    </Typography>
                                </DefaultButton> */}
								{/* <DefaultButton
                                    variant="outlined"
                                    color="secondary"
                                    className={`${classes.optionsBtn} ${
                                        allProductsModeOption === 'sprints sp' ? 'background-residencia' : null
                                    } btn`}
                                    onClick={() => handleOptions('sprints sp')}
                                >
                                    <Typography
                                        color={allProductsModeOption === 'sprints sp' ? 'primary' : '#D6D6D6'}
                                        className={`${classes.textOptionsBtn} poppins`}
                                    >
                                        Pacote provas SP
                                    </Typography>
                                </DefaultButton>
                                <DefaultButton
                                    variant="outlined"
                                    color="secondary"
                                    className={`${classes.optionsBtn} ${
                                        allProductsModeOption === 'todos' ? 'background-residencia' : null
                                    } btn`}
                                    onClick={() => setAllProductsModeOption('todos')}
                                >
                                    <Typography
                                        color={allProductsModeOption === 'todos' ? 'primary' : '#D6D6D6'}
                                        className={`${classes.textOptionsBtn} poppins`}
                                    >
                                        Todos os sprints
                                    </Typography>
                                </DefaultButton> */}
							</Grid>
						)}
						{exams.map((item, index) => (
							<Grid
								item
								xs={6}
								md={2.4}
								lg={2.4}
								key={index}
								container
								justifyContent="center"
								style={{ margin: '8px 0' }}
								onClick={() =>
									item.isOpen &&
									(allProductsModeOption === 'apenas um' || allProductsModeOption === '3 sprints') &&
									handleTestSelection(item.id)
								}
							>
								{item.isOpen ? (
									<TestCard
										key={index}
										imageUrl={item.imageUrl}
										classes={classes}
										selected={
											allProductsModeOption === 'todos' &&
											item?.id !== '1213' &&
											item?.id !== '1214'
												? true
												: allProductsModeOption === 'sprints sp' &&
												  (item?.id === '1210' ||
														item?.id === '1216' ||
														item?.id === '1215' ||
														item?.id === '1217' ||
														item?.id === '1219' ||
														item?.id === '1223' ||
														item?.id === '1225' ||
														item?.id === '1228' ||
														item?.id === '1230' ||
														item?.id === '1231' ||
														item?.id === '1232' ||
														item?.id === '1234')
												? true
												: selectedTests.includes(item.id)
										}
									/>
								) : (
									<TestCardClosed
										key={index}
										imageUrl={item.imageUrl}
										classes={classes}
									/>
								)}
							</Grid>
						))}
					</Grid>
					<Countdown
						startDate={startDate}
						endDate={endDate}
						matchesMobile={matchesMobile}
						classes={classes}
					/>
				</Grid>
			</Grid>
			<Grid
				item
				container
				justifyContent={'center'}
				className={classes.containerWaitList}
				rowGap={1}
				style={{ visibility: waitListVisibility }}
			>
				<Grid
					item
					container
					justifyContent="space-between"
				>
					<Typography
						color="primary"
						className={`${classes.textWaitList} poppins`} 
					>
						Inscrever-se na Lista de Espera
					</Typography>
					<CancelRoundedIcon
						onClick={() => handleCloseWaitList()}
						className={classes.cancelIcon}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					container
					justifyContent={matchesTablet ? 'center' : 'flex-start'}
				>
					<TextField
						fullWidth
						type="string"
						name="nome"
						placeholder="Seu nome completo"
						required={true}
						InputProps={{
							className: `${classes.form} ${classes.formHeight}`,
						}}
						InputLabelProps={{
							shrink: true,
						}}
						style={matchesTablet ? { width: '100%' } : { width: '97%' }}
						onChange={(e) =>
							setPreInscricao({
								...preInscricao,
								nome: e.target.value,
							})
						}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					container
					justifyContent={matchesTablet ? 'center' : 'flex-end'}
					alignContent={'center'}
				>
					<IntlTelInput // Componente Input de telefones internacionais
						name="telefone"
						type="telefone"
						preferredCountries={['BR', 'PY', 'AR', 'CL', 'BO', 'UY', 'CU', 'PT']}
						style={{ width: '100%' }}
						inputClassName={`${classes.phoneNumberForm} ${classes.form} ${classes.formHeight}`}
						nationalMode={true}
						onPhoneNumberChange={(valid, value, selectedCountryData, fullNumber) =>
							handlePhone(valid, fullNumber)
						}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					container
					justifyContent={matchesMobile ? 'center' : 'flex-start'}
				>
					<TextField
						fullWidth
						type="email"
						name="email"
						placeholder="Seu melhor e-mail"
						required={true}
						InputProps={{
							className: `${classes.form} ${classes.formHeight}`,
						}}
						InputLabelProps={{
							shrink: true,
						}}
						style={{ width: '100%' }}
						onChange={(e) =>
							setPreInscricao({
								...preInscricao,
								email: e.target.value,
							})
						}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					md={10}
					lg={8}
					container
					className="residencia"
					style={{ height: 50, padding: '4px 0px' }}
				>
					<DefaultButton
						// type="submit"
						variant="outlined"
						color="secondary"
						className={`${classes.btnWaitList} btn`}
						onClick={() => sendPreInscricao()}
					>
						<img
							// src={require('../assets/icon-bell-regular.svg').default}
							alt=""
							style={{ width: 20 }}
						/>{' '}
						&nbsp; Confirmar inscrição
					</DefaultButton>
				</Grid>
			</Grid>
		</>
	);
}

const useStyles = makeStyles((theme) => ({
	extensivoR1Card: {
		borderBottom: '1px solid !important',
		margin: '1rem 0',
		paddingBottom: '1rem !important',
		[theme.breakpoints.down('md')]: {
			height: 'unset',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '2rem 0 1.5rem',
		},
	},
	gridImg: {
		height: '100%',
		[theme.breakpoints.down('md')]: {
			height: 'unset',
		},
	},
	extensivoLogoImg: {
		width: '70%',
		// maxHeight: '65%',
		[theme.breakpoints.down('xl')]: {
			width: '70%',
		},
		[theme.breakpoints.down('md')]: {
			padding: '0px',
			maxHeight: 'unset',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0px 10px',
			width: '85%',
		},
	},
	divider: {
		margin: '5px !important',
		backgroundColor: '#6D6D6D',
		minHeight: 1,
	},
	gridPriceInfo: {
		// backgroundColor: '#ffffff !important',
		borderRadius: 15,
		[theme.breakpoints.down('sm')]: {
			padding: '8px 0 ',
		},
	},
	gridPriceInfoBlack: {
		borderBottomRightRadius: 15,
		borderTopRightRadius: 15,
		[theme.breakpoints.down('sm')]: {
			padding: '8px 0 ',
		},
	},
	priceTextStrikeThrough: {
		fontSize: '2.2rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		textDecoration: 'line-through',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.1rem !important',
		},
	},
	priceTextWhite: {
		fontSize: '4.5rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '4.2rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '3.25rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '4.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem !important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '2rem !important',
		},
	},
	priceTextGrey: {
		fontSize: '1.25rem !important',
		color: 'grey !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.25rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.95rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.7rem !important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '0.7rem !important',
		},
	},
	btnTwo: {
		width: '70%',
		fontSize: '1.35rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		borderColor: 'transparent',
		backgroundColor: '#00AB55 !important',
		border: '2px solid #fff !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				// minWidth: '72% !important',
				// fontSize: '1.5rem !important',
				// borderWidth: '3px !important',
				backgroundColor: '#00543b !important',
				transition: '0.1s',
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			width: '90%',
		},
	},
	btn: {
		width: '70%',
		fontSize: '1.35rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		borderColor: 'transparent',
		border: '2px solid #707070 !important',
		transition: '0.2s !important',
		backgroundColor: '#777777 !important',
		color: '#ffffff !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				fontSize: '1.5rem !important',
				borderWidth: '3px !important',
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			width: '75%',
		},
		[theme.breakpoints.down(361)]: {
			paddding: '5px 4px !important',
		},
	},
	discount: {
		fontSize: '1.1rem',
		color: 'red',
		alignSelf: 'center',
		marginTop: '0.5em',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			margin: '0.5em 0',
		},
	},
	subscriptionInactiveText: {
		fontSize: '1.7rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
			margin: '1rem !important',
		},
	},
	cardRoot: {
		width: '90% !important',
		height: 80,
		borderRadius: 15,
		[theme.breakpoints.down('xl')]: {
			height: 70,
		},
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
			borderRadius: 5,
		},
	},
	img: {
		width: '70%',
		right: '4%',
		[theme.breakpoints.down('sm')]: {
			width: '85%',
		},
	},
	selectedOption: {
		backgroundColor: '#000000 !important',
		height: 'fit-content',
		alignSelf: 'flex-end',
	},
	nonSelectedOption: {
		backgroundColor: '#454545',
		height: 'fit-content',
		alignSelf: 'flex-end',
	},
	nonSelectedOptionText: {
		fontSize: '2rem !important',
		fontWeight: '600 !important',
		padding: '0.5rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
			padding: '0.5rem 0 !important',
		},
	},
	selectedOptionText: {
		fontSize: '2.5rem !important',
		fontWeight: '600 !important',
		padding: '0.5rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
			padding: '0.5rem 0 !important',
		},
	},
	sfDescription: {
		fontSize: '1.15rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
			margin: '0.5rem 0 !important',
		},
	},
	testCardStyle: {
		transition: 'opacity 0.2s',
	},
	testCardSelected: {
		opacity: 1,
	},
	gridImgTestCard: {
		width: '90% !important',
		borderTopRightRadius: 15,
		borderBottomRightRadius: 15,
		[theme.breakpoints.down('sm')]: {
			padding: '0.75rem 0 !important',
			borderTopRightRadius: 5,
			borderBottomRightRadius: 5,
		},
	},
	timerGrid: {
		bottom: '2rem',
		border: '3px solid #FF4765',
		margin: '3rem auto 0 !important',
		backgroundColor: '#000000',
		padding: '0.75rem 9rem 0.75rem 4rem',
		borderRadius: '25px 50px 50px 25px !important',
		[theme.breakpoints.down('md')]: {
			padding: '0.75rem 2rem 0.75rem 3rem',
			borderRadius: '15px !important',
			justifyContent: 'flex-start !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0.75rem 1rem',
			justifyContent: 'center !important',
		},
	},
	endOfDiscount: {
		fontSize: '1.45rem !important',
		alignSelf: 'center',
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	timeText: {
		fontSize: '3.25rem !important',
		lineHeight: 'unset !important',
		alignSelf: 'center',
		width: 360,
		fontWeight: '600 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2.85rem !important',
			alignSelf: 'center',
			width: 285,
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '3rem !important',
			width: 340,
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
			width: 160,
		},
	},
	timerIcon: {
		position: 'absolute',
		right: -9,
		top: -20,
		[theme.breakpoints.down('xl')]: {
			top: -18,
			right: -8,
			width: '12%',
		},
		[theme.breakpoints.down(1493)]: {
			width: '12%',
			top: -18,
		},
		[theme.breakpoints.down(1247)]: {
			top: -14,
		},
		[theme.breakpoints.down(1229)]: {
			top: -16,
			right: -8,
			width: '13%',
		},
		[theme.breakpoints.down('lg')]: {
			top: -19,
			right: -7,
			width: '12%',
		},
		[theme.breakpoints.down(973)]: {
			width: '13%',
		},
		[theme.breakpoints.down(905)]: {
			width: '14%',
		},
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	gridTime: {
		right: 19,
		[theme.breakpoints.down('xl')]: {
			right: 30,
		},
		[theme.breakpoints.down('sm')]: {
			right: 0,
			left: 10,
		},
	},
	timeTextDescription: {
		fontWeight: 'bold !important',
		fontSize: '0.85rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.45rem !important',
		},
	},
    underSubscriptionTtile: {
		color: '#00AB55',
		fontSize: '1.2rem !important',
	},
	underSubscription: {
		color: '#E8E8E8',
		fontSize: '1.2rem !important',
	},
	text: {
		marginTop: '1rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.7rem !important',
			margin: '1rem 0 !important',
		},
	},
	textTestCardClosed: {
		fontSize: '0.7rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.6rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.5rem !important',
		},
	},
	gridTextCardClosed: {
		backgroundColor: '#ffffff21 !important',
		borderBottomLeftRadius: 15,
		borderBottomRightRadius: 15,
		[theme.breakpoints.down('sm')]: {
			borderBottomLeftRadius: 5,
			borderBottomRightRadius: 5,
			height: '30%',
		},
	},
	gridImgTestCardClosed: {
		backgroundColor: '#19181880',
		borderTopRightRadius: 15,
		borderTopLeftRadius: 15,
		[theme.breakpoints.down('sm')]: {
			height: '70%',
		},
	},
	optionsBtn: {
		width: '17%',
		border: '2px solid #FF005D !important',
		[theme.breakpoints.down('sm')]: {
			width: '45%',
		},
	},
	textOptionsBtn: {
		// color: '#D6D6D6 !important',
		fontWeight: 'bold !important',
		fontSize: '1.25rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.7rem !important',
		},
		[theme.breakpoints.down(361)]: {
			fontSize: '0.65rem !important',
		},
	},
	modalStyle: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		background: '#fff',
		padding: '1.5rem 2rem',
		borderRadius: 15,
		width: 'fit-content !important',
		[theme.breakpoints.down('sm')]: {
			width: '90% !important',
			padding: '1rem 1.25rem',
		},
	},
	modalMessage: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
    containerWaitList: {
		width: '50% !important',
		backgroundColor: '#d6cece',
		zIndex: 1,
		borderRadius: 15,
		padding: '0.5rem 1.25rem',
		boxShadow: '0px 0px 30px 0px #000000',
		border: '2px solid #000000',
		position: 'absolute',
		top: 0,
        right: 0,
		[theme.breakpoints.down('sm')]: {
            width: '100% !important',
			padding: '1.5rem 1.25rem',
            top: '8rem'
		},
	},
    textWaitList: {
		fontSize: '1.3rem !important',
        color: '#00AB55 !important',
        alignSelf: 'center !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.95rem !important',
		},
	},
    form: {
		borderRadius: '8px !important',
		backgroundColor: '#E1E1E1 !important',
		margin: '5px 0 !important',
	},
	formHeight: {
		maxHeight: '3em',
	},
	phoneNumberForm: {
		height: '3em',
		borderRadius: 8,
		width: '100%',
		border: 'unset',
	},
    cancelIcon: {
		color: '#000000',
		right: '-15px',
		top: '-5px',
		'&:hover': {
			cursor: 'pointer',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'absolute',
			top: '-20px',
			right: '-18px',
		},
	},
    btnWaitList: {
		fontSize: '1rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		backgroundColor: '#00AB55 !important',
		border: '1px solid #707070 !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				minWidth: '72% !important',
				fontSize: '1.025rem !important',
				backgroundColor: '#00543b !important',
				transition: '0.1s',
			},
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
}));
