import React, { useRef, useState, useEffect } from 'react';
import { Checkbox, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import Api from '../../../Services/Api';
import DefaultButton from '../../Buttons/DefaultButton';
import { makeStyles } from '@mui/styles';
import { Link, useSearchParams } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

// import { validateCPF } from '../validateCpf';

const subjects = ['Distúrbio ácido-base', 'Crise de asma', 'Crise hipertensiva'];

export default function FormV4({
	closeGroup,
	group,
	title,
	subTitle,
	titleButton,
	type,
	urlToRedirect,
	confirmationMessage,
	rgField,
	testDateField,
	cpfField,
	selectField,
	foodRequirement,
	titleConfirmationMessage,
	noCellphone,
	selectFieldUnicamp,
	selectSpecialtyMock,
	raioX,
	raioX_id,
	exam,
	atleastOneWasSelected,
	setTriggerAtualization,
	triggerAtualization,
	handleScroll,
}) {
	const classes = useStyles({ isActive: atleastOneWasSelected });
	const [searchParams] = useSearchParams();
	const [testDateSelected, setTestDateSelected] = useState('');
	const [preInscricao, setPreInscricao] = useState({
		agrupamento: group,
		nome: '',
		email: '',
		telefone: '',
		rg: '',
		provas: '',
		provas_outras: '',
		optin: false,
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
	});
	const testDateRef = useRef();
	const [testDates] = useState([
		{
			year: 2023,
		},
		{
			year: 2024,
		},
		{
			year: 2025,
		},
		{
			year: 2026,
		},
		{
			year: 2027,
		},
	]);
	const [subject, setSubject] = useState('');
	const [typeSubmit, setTypeSubmit] = useState(false);
	const [telNumb, setTelNumb] = useState();
	// const phoneInputRef = useRef(null);

	useEffect(() => {
		setPreInscricao({
			...preInscricao,
			utm_source: searchParams.get('utm_source'),
			utm_medium: searchParams.get('utm_medium'),
			utm_campaign: searchParams.get('utm_campaign'),
		});
	}, []);

	useEffect(() => {
		if (raioX) {
			setPreInscricao({
				...preInscricao,
				provas: raioX,
			});
		}
	}, [raioX]);

	useEffect(() => {
		if (exam) {
			setPreInscricao({
				...preInscricao,
				provas: exam,
			});
		}
	}, [exam]);

	useEffect(() => {
		setPreInscricao((prevState) => ({
			...prevState,
			agrupamento: closeGroup ? `${group} LISTA DE ESPERA` : group,
		}));
	}, [closeGroup, group]);

	let sendPreInscricao = function (event) {
		event.preventDefault();
		const nomeCompleto = preInscricao.nome.trim();
		const nomeSobrenome = nomeCompleto.split(' ');

		if (!preInscricao.nome || nomeSobrenome.length < 2) {
			alert('Ops... Por favor informe seu nome e sobrenome');
			return;
		}
		if (!preInscricao.email || !preInscricao.email.includes('@') || !preInscricao.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (!preInscricao.telefone && !noCellphone) {
			alert('Ops... Por favor preencha seu telefone com um número válido');
			return;
		}
		if (selectField && !preInscricao.provas) {
			alert('Ops... Por favor informe o tema preferido');
			return;
		}
		if (rgField && !preInscricao.rg) {
			alert('Ops... Por favor informe seu RG');
			return;
		}
		if (cpfField && !preInscricao.cpf) {
			alert('Ops... Por favor informe seu CPF');
			return;
		}
		if (testDateField && !preInscricao.provas) {
			alert('Ops... Por favor informe a data que vai fazer a prova');
			return;
		}
		if (!preInscricao.optin) {
			alert('Ops... Você precisa ler e aceitar os termos de uso acima para se pré-inscrever');
			return;
		}

		setTypeSubmit(true);

		trackPromise(
			Api.post('PreInscricao', preInscricao).then((res) => {
				if (res) {
					Swal.fire({
						title: `${titleConfirmationMessage ? titleConfirmationMessage : 'Massa!'} `,
						text: `${confirmationMessage}`,
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then(() => {
						handleScroll();
						setTriggerAtualization(!triggerAtualization);
					});
				}
			})
		);
	};

	const handlePhone = (valid, phoneNumber) => {
		if (valid) {
			setPreInscricao({
				...preInscricao,
				telefone: phoneNumber,
			});
		} else if (preInscricao.telefone.length) {
			setPreInscricao({
				...preInscricao,
				telefone: '',
			});
		}
	};

	const handleChangeTestYear = (event) => {
		var year = event.target.value;
		if (year === null) {
			setTestDateSelected(null);
			return;
		}
		setTestDateSelected(year);
		setPreInscricao({
			...preInscricao,
			provas: year,
		});
	};

	const handleChangeSubject = (event) => {
		var subject = event.target.value;
		if (subject === null) {
			setSubject(null);
			return;
		}
		setSubject(subject);
		setPreInscricao({
			...preInscricao,
			provas: subject,
		});
	};

	const handleChangUnicamp = (event) => {
		var subject = event.target.value;
		if (subject === null) {
			setSubject(null);
			return;
		}
		setSubject(subject);
		setPreInscricao({
			...preInscricao,
			provas: subject,
		});
	};

	const handleChangSpecialty = (event) => {
		var subject = event.target.value;
		if (subject === null) {
			setSubject(null);
			return;
		}
		setSubject(subject);
		setPreInscricao({
			...preInscricao,
			provas_outras: subject,
		});
	};

	return (
		<Paper
			elevation={4}
			className={`${classes.paper} rounded-card ${`background-${type}`}`}
			style={{
				border: `${atleastOneWasSelected ? 'none' : '1px solid #ffffff'}`,
				opacity: `${atleastOneWasSelected ? '100%' : '15%'}`,
			}}
		>
			<Grid container>
				<form
					className="form-control"
					onSubmit={sendPreInscricao}
				>
					<Grid
						item
						xs={12}
						container
						rowGap={1}
					>
						<Grid
							item
							container
							flexDirection={'column'}
							justifyContent="center"
						>
							<Typography
								className={`${classes.signInCardTitle} poppins`}
								color="secondary"
								align="center"
							>
								{title}
							</Typography>
							<Typography
								className={`${classes.signInCardSubtitle} poppins`}
								color="secondary"
								align="center"
							>
								{subTitle}
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
						>
							<TextField
								fullWidth
								type="string"
								name="nome"
								placeholder="Seu nome completo"
								required={true}
								InputProps={{
									className: `${classes.form} ${
										rgField ? classes.formHeightShort : classes.formHeight
									}`,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										nome: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
						>
							<TextField
								fullWidth
								type="email"
								name="email"
								placeholder="Seu melhor e-mail"
								required={true}
								InputProps={{
									className: `${classes.form} ${
										rgField ? classes.formHeightShort : classes.formHeight
									}`,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										email: e.target.value,
									})
								}
							/>
						</Grid>
						{noCellphone ? null : (
							<Grid
								item
								xs={12}
							>
								<IntlTelInput // Componente Input de telefones internacionais
									name="telefone"
									type="telefone"
									preferredCountries={['BR', 'PY', 'AR', 'CL', 'BO', 'UY', 'CU', 'PT']}
									style={{ width: '100%', border: 'unset' }}
									inputClassName={`${classes.phoneNumberForm} ${
										rgField ? classes.formHeightShort : classes.formHeight
									}`}
									nationalMode={true}
									onPhoneNumberChange={(valid, value, selectedCountryData, fullNumber) =>
										handlePhone(valid, fullNumber)
									}
								/>
							</Grid>
						)}
						{foodRequirement && (
							<Typography
								color="primary"
								className={classes.foodRequirementText}
							>
								{/* *Levar 2kg de alimento no dia do evento */}
								*Contamos contigo para doar 2kg de alimento no dia do evento
							</Typography>
						)}
						{selectField && (
							<>
								<FormControl style={{ width: '100%' }}>
									<InputLabel id="test-select-label">Escolha seu tema preferido...*</InputLabel>
									<Select
										value={subject}
										labelId="test-select-label"
										onChange={handleChangeSubject}
										className={classes.selectMenu}
									>
										<MenuItem value={null}>
											<em>Escolha seu tema preferido...*</em>
										</MenuItem>
										{subjects.map((item, index) => {
											return (
												<MenuItem
													key={index}
													value={item}
													className="poppins"
												>
													<Typography
														color="primary"
														className={`${classes.textSelectItem} poppins`}
													>
														{item}
													</Typography>
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</>
						)}
						{selectFieldUnicamp && (
							<>
								<FormControl style={{ width: '100%' }}>
									<InputLabel
										id="test-select-label"
										className="poppins"
									>
										Vai fazer a prova da UNICAMP?
									</InputLabel>
									<Select
										value={preInscricao?.provas}
										labelId="test-select-label"
										onChange={handleChangUnicamp}
										className={classes.selectMenu}
									>
										<MenuItem value={null}>
											<em>Vai fazer a prova da UNICAMP?</em>
										</MenuItem>
										{selectFieldUnicamp.map((item, index) => {
											return (
												<MenuItem
													key={index}
													value={item}
													className="poppins"
												>
													<Typography
														color="primary"
														className={`${classes.textSelectItem} poppins`}
													>
														{item}
													</Typography>
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</>
						)}
						{selectSpecialtyMock && (
							<>
								<FormControl style={{ width: '100%' }}>
									<InputLabel
										id="test-select-label"
										className="poppins"
									>
										Vai fazer qual especialidade?
									</InputLabel>
									<Select
										value={preInscricao?.provas_outras}
										labelId="test-select-label"
										onChange={handleChangSpecialty}
										className={classes.selectMenu}
									>
										<MenuItem value={null}>
											<em>Vai fazer qual especialidade?</em>
										</MenuItem>
										{selectSpecialtyMock.map((item, index) => {
											return (
												<MenuItem
													key={index}
													value={item}
													className="poppins"
												>
													<Typography
														color="primary"
														className={`${classes.textSelectItem} poppins`}
													>
														{item}
													</Typography>
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</>
						)}
						{rgField && (
							<Grid
								item
								xs={12}
							>
								<TextField
									fullWidth
									type="string"
									name="rg"
									placeholder="Seu RG"
									required={true}
									InputProps={{
										className: `${classes.form} ${
											rgField ? classes.formHeightShort : classes.formHeight
										}`,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(e) =>
										setPreInscricao({
											...preInscricao,
											rg: e.target.value,
										})
									}
								/>
							</Grid>
						)}
						{cpfField && (
							<Grid
								item
								xs={12}
							>
								<TextField
									fullWidth
									type="string"
									name="cpf"
									placeholder="Seu CPF"
									required={true}
									inputProps={{
										maxLength: 11,
									}}
									InputProps={{
										className: `${classes.form} ${classes.formHeight}`,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(e) =>
										setPreInscricao({
											...preInscricao,
											cpf: e.target.value,
										})
									}
									// onBlur={(e) => validationCpf(e)}
								/>
							</Grid>
						)}
						{testDateField && (
							<Grid
								item
								xs={12}
							>
								<FormControl
									style={{ width: '100%', border: 'transparent' }}
									className={`${classes.form} ${classes.formHeight}`}
								>
									{testDateSelected ? null : (
										<InputLabel id="test-select-label">
											Quando vai fazer a prova de residência?
										</InputLabel>
									)}
									<input
										type="hidden"
										name="data_prova"
										ref={testDateRef}
									/>
									<Select
										value={testDateSelected}
										labelId="test-select-label"
										onChange={handleChangeTestYear}
										className={classes.selectMenu}
									>
										{testDates.map((test) => {
											return (
												<MenuItem
													key={test.id}
													value={test.year}
													className="poppins"
												>
													<Typography
														color="primary"
														className={`${classes.textSelectItem} poppins`}
													>
														{test.year}
													</Typography>
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</Grid>
						)}
						<Grid
							item
							xs={12}
						>
							<Checkbox
								style={{ padding: '0px', color: 'white' }}
								checked={preInscricao.optin}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										optin: !preInscricao.optin,
									})
								}
							/>
							<Typography
								variant="caption"
								color={window.location.pathname === '/materiais/semana-tep' ? 'primary' : 'secondary'}
								className={classes.captionText}
							>
								Estou de acordo com o tratamento dos meus dados pessoais para fins de inscrição na
								plataforma Hardwork Medicina, bem como para envio de comunicações relativas aos serviços
								educacionais que se adequem ao meu perfil, de acordo com a nossa{' '}
								<Link
									// color="secondary"
									href="https://hardworkmedicina.com.br/politica-de-privacidade"
									target="_blank"
									underline="hover"
									variant="caption"
									align="right"
									className={`${classes.copyrightText} poppins`}
								>
									Politica de privacidade
								</Link>
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							container
							justifyContent="center"
							className="residencia"
						>
							<DefaultButton
								type={typeSubmit ? 'submit' : 'button'}
								variant="outlined"
								color="secondary"
								className={`${classes.btn} btn`}
								onClick={(e) => sendPreInscricao(e)}
								disabled={!atleastOneWasSelected}
							>
								{titleButton}
							</DefaultButton>
						</Grid>
						{selectField && (
							<Typography
								color="secondary"
								className={classes.selectFieldText}
							>
								*Qual tema você gostaria de ver em uma aula Hardwork?
							</Typography>
						)}
					</Grid>
				</form>
			</Grid>
		</Paper>
	);
}

const useStyles = makeStyles((theme) => ({
	paper: {
		margin: '0 auto',
		padding: '1.5rem !important',
		[theme.breakpoints.up('lg')]: {},
		[theme.breakpoints.down('sm')]: {
			padding: '1rem',
		},
	},
	paperArena: {
		margin: '0 auto',
		padding: '1.5rem',
		[theme.breakpoints.down('lg')]: {
			width: '294px !important',
			top: '-68px !important',
			left: 38,
		},
		[theme.breakpoints.down('md')]: {
			width: 'unset !important',
			top: 'unset !important',
			left: 'unset !important',
		},
	},
	form: {
		borderRadius: '8px !important',
		backgroundColor: theme.palette.secondary.main,
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'transparent !important',
		},
		'& .MuiOutlinedInput-input': {
			height: '0.75em',
		},
	},
	formHeight: {
		maxHeight: '48px !important',
		[theme.breakpoints.down('xl')]: {
			maxHeight: '40.8px !important',
		},
		[theme.breakpoints.down('sm')]: {
			maxHeight: '45px',
		},
	},
	formHeightShort: {
		maxHeight: '2.5em',
	},
	phoneNumberForm: {
		height: '3em',
		borderRadius: 8,
		width: '100%',
		border: 'unset',
	},
	signInCardTitle: {
		fontSize: '1.75rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	signInCardSubtitle: {
		fontSize: '1rem !important',
	},
	btn: {
		fontSize: '1.15rem !important',
		width: '75%',
		backgroundColor: '#000000 !important',
		fontWeight: 'bold !important',
		textTransform: 'uppercase !important',
		border: '1px solid transparent !important',
		'&:hover': {
			border: '1px solid #ffffff !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	copyrightText: {
		color:
			window.location.pathname === '/materiais/semana-tep'
				? theme.palette.primary.main
				: theme.palette.secondary.main,
	},
	captionText: {
		[theme.breakpoints.down('xl')]: {
			fontSize: '0.65rem !important',
		},
	},
	selectMenu: {
		width: '100%',
		borderRadius: '8px !important',
		backgroundColor: '#FFFFFF',
		maxHeight: '3em',
	},
	selectMenuUf: {
		width: '100%',
		borderRadius: '8px !important',
		backgroundColor: '#FFFFFF',
		maxHeight: '3em',
	},
	selectFieldText: {
		fontSize: '0.9rem !important',
		// marginTop: '1rem !important',
	},
	foodRequirementText: {
		fontSize: '1.1rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '0.9rem !important',
		},
	},
}));
