import ProductsTemplate from '../../../Pages/LandingPages/Subscription/Residencia/Products-Template-2024';
import HandsOn from '../TemplatesContent/Hands-on';
import SprintFinalR3CM from '../TemplatesContent/Sprint-final-r3-cm';

// Arquivo que configura as páginas de pré inscrição

const routesProductsTemplate = [
	{
		path: '/pre-inscricao/hardwork-hands-on', // Cria a url
		component: ProductsTemplate, // Usa o componente template como base
		props: {
			group: 'Hands On', // Define o agrupamento do formulário
			children: <HandsOn />, // Aqui passamos a parte dinâmica de cada página, ou seja, o conteúdo à esquerda
			formLogo: require('../../../Pages/LandingPages/Subscription/Residencia/assets/logo-hands-on.png'), // Logo acima do formulário
			backgroundImg: require('../../../Pages/LandingPages/Subscription/Residencia/assets/pocus-ultrassom-hands-on.png'), // Background
		},
	},
	{
		path: '/pre-inscricao/sprint-r3-clinica-medica',
		component: ProductsTemplate,
		props: {
			group: 'Sprint R3 Clínica Médica 2024',
			children: <SprintFinalR3CM />,
			formLogo: require('../Assets/logo-sf-cm-2024.svg').default,
			backgroundImg: require('../Assets/bg-sprint-cm-2024.png'),
		},
	},
	// Adicione mais rotas conforme necessário
];

export default routesProductsTemplate;
