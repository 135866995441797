import { useEffect, useState } from 'react';
import { Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link, useSearchParams } from 'react-router-dom';
import DefaultButton from '../../../../../Components/Buttons/DefaultButton';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import Api from '../../../../../Services/Api';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
import { useStylesPurchaseCard } from './PurchaseCard';

const groups = [
	{
		id: 1,
		active: false,
		day: ['04'],
		month: 'Maio',
	},
	{
		id: 2,
		active: false,
		day: ['18'],
		month: 'Maio',
	},
	{
		id: 3,
		active: false,
		day: ['19'],
		month: 'Maio',
	},
];

export default function PurchaseCardPro({ activeButtonId, idTurma, urlToRedirect, confirmationMessage, ref }) {
	const classes = useStylesPurchaseCard();
	const specificClasses = useStyles();
	const theme = useTheme();
	const [turma, setTurma] = useState([]);
	const [turmaSelecionada, setTurmaSelecionada] = useState(null);
	const [waitListVisibility, setWaitListVisibility] = useState('hidden');
	const [searchParams] = useSearchParams();
	const [preInscricao, setPreInscricao] = useState({
		agrupamento: `ARENA 2024.1 LISTA DE ESPERA PRESENCIAL ${turma === '1335' ? 'EXPERIENCE' : 'PRO'}`,
		nome: '',
		email: '',
		telefone: '',
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
	});
	const [freeAccessOnline, setFreeAccessOnline] = useState(false);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));

	useEffect(() => {
		setPreInscricao({
			...preInscricao,
			utm_source: searchParams.get('utm_source'),
			utm_medium: searchParams.get('utm_medium'),
			utm_campaign: searchParams.get('utm_campaign'),
			utm_content: searchParams.get('utm_content'),
			utm_term: searchParams.get('utm_term'),
		});
		Api.get(`turmasabertas/${idTurma}`).then((res) => {
			if (res) {
				// const turmaManipulada = res.map((turma) => {
				// 	return {
				// 		...turma,
				// 		encerrada: true,
				// 	};
				// });
				// if (activeButtonId === 2) {
				// 	const currentLot = res[0]?.lotes?.find((item) => item.id === res[0]?.id_lote_vigente);
				// 	if (currentLot === null) {
				// 		setTurma(currentLot);
				// 	} else {
				// 		setTurma(res[0]);
				// 	}
				// } else {
				setTurma(res[0]);
			}
		});
		var momentoAtual = new Date();
		var momentoLiberacao = new Date('2023-04-19T10:00:00');
		setFreeAccessOnline(momentoAtual >= momentoLiberacao);
		// setHasInactiveGroup(groups.some((group) => group.active === false));
	}, [idTurma]);

	useEffect(() => {
		if (turma && turma.id) {
			setPreInscricao({
				agrupamento: `ARENA PRO LISTA DE INTERESSE`,
				nome: '',
				email: '',
				telefone: '',
				utm_source: '',
				utm_medium: '',
				utm_campaign: '',
			});
		}
	}, [turma]);

	let sendPreInscricao = function () {
		if (!preInscricao.nome) {
			alert('Ops... Por favor informe seu nome');
			return;
		}
		if (!preInscricao.email || !preInscricao.email.includes('@') || !preInscricao.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (!preInscricao.telefone || preInscricao.telefone.length < 11) {
			alert('Ops... Por favor preencha seu telefone');
			return;
		}

		trackPromise(
			Api.post('PreInscricao', preInscricao).then((res) => {
				if (res) {
					Swal.fire({
						title: 'Massa! ',
						text: `${
							confirmationMessage
								? confirmationMessage
								: 'Avisaremos assim que novas turmas estiverem liberadas.'
						}`,
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then((result) => {
						if (result.isConfirmed) {
							if (urlToRedirect) {
								document.location.href = `${urlToRedirect}`;
							} else {
								document.location.href = '/';
							}
						}
					});
				}
			})
		);
	};

	const handleCloseWaitList = () => {
		setWaitListVisibility('hidden');
	};

	const handleOpenWaitList = () => {
		setWaitListVisibility('unset');
		setTurmaSelecionada(null);
	};

	const phoneMask = (value) => {
		if (!value) return '';
		value = value.replace(/\D/g, '');
		value = value.replace(/(\d{2})(\d)/, '($1) $2');
		value = value.replace(/(\d)(\d{4})$/, '$1-$2');
		return value;
	};

	const handlePhone = (event) => {
		let input = event.target;
		input.value = phoneMask(input.value);
		setPreInscricao({
			...preInscricao,
			telefone: input.value,
		});
	};

	const groupsPro = [
		{
			id: 1,
			active: false,
			name: [
				<span className={specificClasses.spanText2}>Sábado</span>,
				' e ',
				<span className={specificClasses.spanText2}>domingo</span>,
				' imediatamente à data da prova*',
			],
		},
		{
			id: 2,
			active: false,
			name: [
				<span className={specificClasses.spanText}>08</span>,
				' e ',
				<span className={specificClasses.spanText}>09</span>,
				' de Junho',
			],
		},
	];

	return (
		<Grid
			container
			style={!activeButtonId ? { opacity: 0.65 } : null}
			className={`${specificClasses.root}`}
		>
			<Grid
				item
				xs={12}
				container
			>
				<Grid
					item
					container
					justifyContent={'center'}
					alignContent={'center'}
					className={specificClasses.background}
					style={{ padding: activeButtonId === '1330' && matchesTablet ? '2em 2em 1em' : '3rem 3rem 1rem' }}
				>
					<img
						src={
							require(activeButtonId === '1330'
								? '../assets/logo-arena-online-2024.svg'
								: activeButtonId === '1335'
								? '../assets/logo-arena-experience-2024.svg'
								: '../assets/logo-arena-pro-2024.svg').default
						}
						alt="logo"
						style={{ width: matchesLg ? '60%' : matchesTablet ? '70%' : 'unset' }}
					/>
					{activeButtonId === '1330' && (
						<>
							<Grid
								item
								container
								direction={matchesTablet ? 'column' : 'row'}
								wrap="nowrap"
								rowGap={matchesTablet ? 1 : 0}
								className={specificClasses.gridDiscount}
							>
								<Grid
									item
									style={{
										padding: '0.5rem 0.75rem',
										backgroundColor: '#00B9A6',
										borderRadius: 4,
										margin: '0px auto',
									}}
								>
									<Typography
										color="secondary"
										className={`${specificClasses.discountText} poppins`}
									>
										30% OFF para ex-alunos Extensivo Hardwork
									</Typography>
								</Grid>
								<Grid
									item
									style={{
										padding: '0.5rem 0.75rem',
										backgroundColor: '#00B9A6',
										borderRadius: 4,
										margin: '0px auto',
									}}
								>
									<Typography
										color="secondary"
										className={`${specificClasses.discountText} poppins`}
									>
										15% OFF para ex-alunos Sprint Hardwork
									</Typography>
								</Grid>
							</Grid>
							<Grid
								item
								container
							>
								<Typography
									color="secondary"
									className="poppins"
									style={{ fontSize: matchesTablet ? '0.65rem' : '0.75rem', marginTop: '1rem' }}
								>
									*Desconto não cumulativo
								</Typography>
							</Grid>
						</>
					)}
				</Grid>
				<Grid
					item
					container
					direction={'column'}
					justifyContent={activeButtonId === 1 ? 'space-evenly' : 'space-around'}
					alignContent={'center'}
					className={classes.containerOptions}
				>
					<Grid
						item
						container
						direction={'column'}
						className="revalida"
					>
						{turma?.encerrada ? (
							<>
								<Typography
									color={'secondary'}
									align="left"
									className={`${classes.subscriptionInactiveText} roboto-condensed`}
								>
									INSCRIÇÕES ENCERRADAS!
								</Typography>
							</>
						) : (
							<>
								<Typography
									color="secondary"
									className={`${specificClasses.paymentModeText} poppins`}
								>
									Até&nbsp;
									<span
										className="gradient-text"
										style={{ display: 'inline', fontWeight: 700 }}
									>
										{turma?.max_parcelas}x
									</span>
									&nbsp;de
								</Typography>
								<Typography
									color="secondary"
									className={`${classes.priceText} roboto-condensed`}
								>
									R${' '}
									{turma?.parcelas && turma.parcelas?.length > 0
										? `${turma?.parcelas[turma.parcelas?.length - 1].valor?.toLocaleString(
												'pt-BR',
												{
													minimumFractionDigits: 2,
												}
										  )}`
										: ''}
								</Typography>
								<Typography
									color="secondary"
									className={`${specificClasses.paymentModeText} poppins`}
									style={{ marginTop: '1rem' }}
								>
									À&nbsp;
									<span
										className="gradient-text"
										style={{ display: 'inline', fontWeight: 700 }}
									>
										vista
									</span>
									&nbsp;por
								</Typography>
								<Typography
									color="secondary"
									className={`${classes.priceText} roboto-condensed`}
								>
									R${' '}
									{turma?.valor?.toLocaleString('pt-BR', {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									})}
								</Typography>
								{activeButtonId === 1 ? (
									turma?.parcelas && (
										<Typography className={`${classes.priceTextGrey} poppins`}>
											ou até {turma?.parcelas[turma?.parcelas?.length - 1]?.qtd}x de R$
											{activeButtonId === 1
												? turma?.parcelas[turma?.parcelas?.length - 1]?.valor.toLocaleString(
														'pt-BR',
														{
															minimumFractionDigits: 2,
														}
												  )
												: turma?.parcelas[turma?.parcelas?.length - 1]?.valor.toLocaleString(
														'pt-BR',
														{
															minimumFractionDigits: 2,
														}
												  )}{' '}
											sem juros
										</Typography>
									)
								) : (
									<Typography className={`${classes.priceTextGrey} poppins`}>
										ou até {turma?.max_parcelas}x de R$
										{turma?.parcelas && turma.parcelas?.length > 0
											? `${turma?.parcelas[turma.parcelas?.length - 1].valor?.toLocaleString(
													'pt-BR',
													{
														minimumFractionDigits: 2,
													}
											  )}`
											: ''}
									</Typography>
								)}
							</>
						)}
					</Grid>
					{activeButtonId === '1330' && turma?.encerrada ? null : (
						<Grid
							item
							container
							alignContent={'center'}
							justifyContent={'center'}
						>
							<Grid
								item
								container
								className={specificClasses.gridContainerTurmas}
								columnGap={2}
								rowGap={2}
								style={
									activeButtonId !== '1330'
										? { padding: matchesTablet ? '1rem 0 2rem' : '2rem 0 2rem' }
										: { marginBottom: '1rem' }
								}
							>
								{activeButtonId !== '1330' &&
									(activeButtonId === '1335'
										? groups.map((group, index) => {
												return (
													<Grid
														key={group.id}
														item
														className={specificClasses.turmaBox}
													>
														{!group.active && (
															<img
																src={require('../assets/logo-sold-out.png')}
																alt=""
																style={{ position: 'absolute', left: 0, zIndex: 1 }}
															/>
														)}
														<Typography
															color={'#919191'}
															className={`${specificClasses.turmaText} poppins`}
														>
															<span className={specificClasses.spanText}>
																{group?.day}
															</span>{' '}
															de {group.month}
															<br /> Presencial em São Paulo
														</Typography>
													</Grid>
												);
										  })
										: groupsPro.map((group) => (
												<Grid
													key={group.id}
													item
													container
													alignContent={'center'}
													className={specificClasses.turmaBox}
													style={{ width: '100%', minHeight: !matchesTablet && '50%' }}
												>
													{(turma.encerrada || group.id === 2) &&
														activeButtonId !== '1336' && (
															<img
																src={require('../assets/logo-sold-out-wide.png')}
																alt=""
																style={{
																	position: 'absolute',
																	left: 0,
																	zIndex: 1,
																	top: matchesMobile && '20%',
																}}
															/>
														)}
													<Typography
														color={
															group.id === 1 && !turma?.encerrada
																? 'secondary'
																: '#919191'
														}
														className={`${specificClasses.turmaText} poppins`}
														// style={{ bottom: 'unset' }}
													>
														{/* <span className={specificClasses.spanText}>8 e 9</span> de Junho */}
														{group?.name}
														<br /> Presencial em São Paulo
													</Typography>
												</Grid>
										  )))}
							</Grid>
							{activeButtonId === '1336' && (
								<Typography
									align="center"
									color="secondary"
									className={`${specificClasses.supportText} poppins`}
									style={{
										margin: '0.25rem 0 1rem',
									}}
								>
									*A data será confirmada de acordo com a data de liberação do edital.
								</Typography>
							)}
							{activeButtonId === 2 && turma?.encerrada ? null : (
								<DefaultButton
									variant="outlined"
									color="secondary"
									className={`${specificClasses.btn} btn`}
									component={Link}
									onClick={turma?.encerrada && turma?.id !== 1330 ? () => handleOpenWaitList() : null}
									to={
										!turma.encerrada
											? `//hardworkmedicina.com.br/inscricao/${idTurma}${
													activeButtonId !== '1330' && activeButtonId !== '1336'
														? `?pp=${'18/05/2024'}`
														: ''
											  }`
											: null
									}
								>
									{/* <img
										src={
											require(turma?.encerrada
												? '../../../../../Assets/Svg/icon-white-bell-outlined.svg'
												: '../../../../../Assets/Svg/Icons/bagIcon.svg').default
										}
										alt=""
										style={{ width: matchesTablet && '1.5rem' }}
									/> */}
									&nbsp;{' '}
									{turma?.encerrada
										? turma?.id !== 1330
											? 'Avise-me quando houver novas turmas'
											: 'null'
										: 'Inscreva-se'}
								</DefaultButton>
							)}
						</Grid>
					)}
					{activeButtonId !== '1330' && (
						<form
							className="form-control"
							action="javascript:;"
						>
							<Grid
								item
								container
								justifyContent={'center'}
								className={classes.containerWaitList}
								rowGap={1}
								style={{ visibility: waitListVisibility }}
							>
								<Grid
									item
									container
									justifyContent="space-between"
								>
									<Typography
										color="primary"
										className={`${classes.textWaitList} poppins`}
									>
										Inscrever-se na Lista de Espera
									</Typography>
									<CancelRoundedIcon
										onClick={() => handleCloseWaitList()}
										className={classes.cancelIcon}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									md={6}
									container
									justifyContent={matchesTablet ? 'center' : 'flex-start'}
								>
									<TextField
										fullWidth
										type="string"
										name="nome"
										placeholder="Seu nome completo"
										required={true}
										InputProps={{
											className: `${classes.form} ${classes.formHeight}`,
										}}
										InputLabelProps={{
											shrink: true,
										}}
										style={matchesTablet ? { width: '100%' } : { width: '97%' }}
										onChange={(e) =>
											setPreInscricao({
												...preInscricao,
												nome: e.target.value,
											})
										}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									md={6}
									container
									justifyContent={matchesTablet ? 'center' : 'flex-end'}
								>
									<TextField
										fullWidth
										type="tel"
										name="telefone"
										placeholder="Seu celular com DDD"
										required={true}
										pattern="[0-9]*"
										inputProps={{
											maxLength: 15,
										}}
										InputProps={{
											className: `${classes.form} ${classes.formHeight}`,
										}}
										InputLabelProps={{
											shrink: true,
										}}
										style={matchesTablet ? { width: '100%' } : { width: '97%' }}
										onChange={(e) => handlePhone(e)}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									container
									justifyContent={matchesMobile ? 'center' : 'flex-start'}
								>
									<TextField
										fullWidth
										type="email"
										name="email"
										placeholder="Seu melhor e-mail"
										required={true}
										InputProps={{
											className: `${classes.form} ${classes.formHeight}`,
										}}
										InputLabelProps={{
											shrink: true,
										}}
										style={{ width: '100%' }}
										onChange={(e) =>
											setPreInscricao({
												...preInscricao,
												email: e.target.value,
											})
										}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									md={10}
									lg={8}
									container
									className="residencia"
									style={{ height: 50, padding: '4px 0px' }}
								>
									<DefaultButton
										// type="submit"
										variant="outlined"
										color="secondary"
										className={`${classes.btnWaitList} btn`}
										onClick={() => sendPreInscricao()}
									>
										<img
											src={require('../../../../../Assets/Arena/icon-waitList.svg').default}
											alt=""
											style={{ width: 20 }}
										/>{' '}
										&nbsp; Confirmar inscrição
									</DefaultButton>
								</Grid>
							</Grid>
						</form>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: 15,
		backgroundColor: '#000000',
		position: 'relative',
		[theme.breakpoints.down('sm')]: {
			border: 'unset',
		},
	},
	discountText: {
		fontSize: '0.85rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.75rem !important',
		},
	},
	gridDiscount: {
		borderTop: 'solid 1px #515151',
		paddingTop: '2rem !important',
		marginTop: '2rem !important',
		[theme.breakpoints.down('md')]: {
			paddingTop: '1rem !important',
			marginTop: '1rem !important',
		},
	},
	background: {
		padding: '3rem',
		width: '55% !important',
		backgroundColor: '#000000',
		borderTopLeftRadius: '15px !important',
		borderBottomLeftRadius: '15px !important',
		[theme.breakpoints.down('lg')]: {
			padding: '2em',
		},
		[theme.breakpoints.down('md')]: {
			width: '100% !important',
			borderBottomLeftRadius: '0 !important',
			borderTopRightRadius: '15px !important',
			padding: '2em 2em 1em',
		},
	},
	turmaText: {
		fontWeight: '500 !important',
		bottom: 3,
		[theme.breakpoints.down('md')]: {
			fontSize: '0.7rem !important',
		},
	},
	turmaBox: {
		borderRadius: 9,
		padding: '0.5rem 1rem',
		border: 'solid 1px #09D785',
		[theme.breakpoints.down('md')]: {
			padding: '0.5rem',
		},
	},
	spanText: {
		fontWeight: 'bold !important',
		fontSize: '1.75rem !important',
		lineHeight: 'unset !important',
		top: 3,
		[theme.breakpoints.down('md')]: {
			fontSize: '1.25rem !important',
		},
	},
	spanText2: {
		fontWeight: 'bold !important',
		fontSize: '1.25rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '1rem !important',
		},
	},
	gridContainerTurmas: {
		borderTop: 'solid 1px #515151',
		marginTop: '1rem !important',
		[theme.breakpoints.down('md')]: {
			borderTop: 'solid 1px #515151',
			marginTop: '2rem !important',
		},
	},
	btn: {
		width: '90%',
		fontSize: '1.35rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		borderColor: 'transparent',
		backgroundColor: '#00AB55 !important',
		border: '2px solid #fff !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				backgroundColor: '#00543b !important',
				transition: '0.1s',
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			width: '75%',
		},
	},
	supportText: {
		fontSize: '0.9rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.8rem !important',
		},
	},
	paymentModeText: {
		fontSize: '1.5rem !important',
		lineHeight: 'unset !important',
	},
}));
