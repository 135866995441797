import { Button, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useRef, useState } from 'react';
import FeedbackCard from '../Cards/FeedbackCard';
import ArrowBack from '../../Assets/Svg/arrowBack.svg';
import ArrowForward from '../../Assets/Svg/arrowForward.svg';

import Api from '../../Services/Api';
import { useEffect } from 'react';

export default function StudentsFeedbackSection({ type, title }) {
	const classes = useStyles();
	const carousel = useRef(null);
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	// const [isExpanded, setIsExpanded] = useState(false);

	const [feedbacks, setFeedbacks] = useState([]);

	useEffect(() => {
		var depoimentType =
			type === 'residencia'
				? 'sprint'
				: type === 'revalida'
				? 'revalida'
				: type === 'extensivo'
				? 'extensivo'
				: type === 'clinica medica'
				? 'extensivo'
				: type === 'cirurgia geral'
				? 'extensivo'
				: type === 'arena 2022'
				? 'arena 2022'
				: type === 'Trilha R3'
				? 'Trilha R3'
				: null;
		Api.get(`depoimentos?tipo=${depoimentType}`).then((res) => {
        
			var feedbacks = res.filter((item) => {
				return item.dep_texto !== '';
			});
			if (feedbacks.length) setFeedbacks(feedbacks);
		});
	}, [type]);

	const toggleExpandContainer = () => {
		return true;
	};

	const handleLeftClick = (e) => {
		e.preventDefault();

		carousel.current.scrollLeft -= carousel.current.offsetWidth;
	};

	const handleRightClick = (e) => {
		e.preventDefault();

		carousel.current.scrollLeft += 1380;
	};

	const handleRightClickMobile = (e) => {
		e.preventDefault();

		carousel.current.scrollLeft += carousel.current.offsetWidth;
	};

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
			className={classes.containerFeedback}
		>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					item
					xs={12}
					container
					className={classes.container}
				>
					<Grid
						item
						xs={12}
						className={`${
							type === 'residencia'
								? 'residencia'
								: type === 'extensivo'
								? 'residencia'
								: type === 'clinica medica' || type === 'Trilha R3'
								? 'clinica-medica'
								: type === 'cirurgia geral'
								? 'cirurgia-geral'
								: 'revalida'
						}`}
					>
						<Typography
							className={`${classes.title} ${
								type === 'clinica medica' || type === 'Trilha R3'
									? 'clinica-medica'
									: type === 'cirurgia geral'
									? 'cirurgia-geral'
									: null
							} gradient-text roboto-condensed`}
						>
							{title || 'Depoimentos dos nossos alunos'}
						</Typography>
					</Grid>
				</Grid>
				{/* <Grid
					container
					alignContent="center"
					justifyContent="center"
					className={classes.section}
				>
					{' '}
				</Grid> */}
			</Container>
			<Grid
				item
				xs={12}
				container
				className={classes.gridInfoContainer}
				ref={carousel}
				id={matchesMobile ? null : 'scroll-2'}
				style={
					window.location.pathname === '/sprint-final-revalida' ||
					window.location.pathname === '/sprint-final'
						? { overflowX: 'hidden' }
						: null
				}
			>
				{feedbacks.map((item, index) => {
					return (
						<Grid
							item
							container
							alignContent="flex-start"
							key={index}
							className={classes.gridFeedbackCard}
						>
							<FeedbackCard
								key={index}
								toggleExpandContainer={toggleExpandContainer}
								{...item}
								type={type}
							/>
						</Grid>
					);
				})}
			</Grid>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				style={
					window.location.pathname === '/sprint-final-revalida' ||
					window.location.pathname === '/sprint-final'
						? { marginBottom: '2rem' }
						: null
				}
			>
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					// className={classes.section}
					style={{ maxWidth: 1410, margin: '0 auto' }}
				>
					{' '}
					<Grid
						item
						xs={12}
						container
					>
						<Grid
							item
							xs={12}
						>
							<Button onClick={handleLeftClick}>
								<img
									src={ArrowBack}
									alt=""
									className={classes.arrowIcon}
								/>
							</Button>
							<Button onClick={matchesMobile ? handleRightClickMobile : handleRightClick}>
								<img
									src={ArrowForward}
									alt=""
									className={classes.arrowIcon}
								/>
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
		[theme.breakpoints.down('sm')]: {
			padding: '3vh 0 0 1em !important',
		},
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.75rem !important',
		lineHeight: '1 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
		},
	},
	gridInfoContainer: {
		display: 'flex !important',
		overflowX: 'auto',
		scrollBehavior: 'smooth',
		flexWrap: 'nowrap !important',
		position: 'relative',
		margin: '30px 0 !important',
		minWidth: '100vw',
		paddingLeft: '13%',
		paddingRight: '2.5%',
		[theme.breakpoints.down(1687)]: {
			paddingLeft: '9%',
		},
		[theme.breakpoints.down(1397)]: {
			paddingLeft: '6%',
		},
		[theme.breakpoints.down(1343)]: {
			paddingLeft: '4%',
		},
		[theme.breakpoints.down('lg')]: {
			paddingLeft: '13% !important',
		},
		[theme.breakpoints.down(1171)]: {
			paddingLeft: '8% !important',
		},
		[theme.breakpoints.down(1011)]: {
			paddingLeft: '4% !important',
		},
		[theme.breakpoints.down(881)]: {
			paddingLeft: '16% !important',
		},
		[theme.breakpoints.down('sm')]: {
			left: 'unset !important',
			paddingLeft: '27px !important',
			height: 'unset !important',
			overflowX: 'auto',
			margin: '30px 0 0 !important',
		},
	},
	arrowIcon: {
		width: 15,
	},
	gridFeedbackCard: {
		margin: '0 20px 20px 0 !important',
	},
	containerFeedback: {
		[theme.breakpoints.down('lg')]: {
			// top: '50px',
		},
		[theme.breakpoints.down('md')]: {
			top: '0px',
		},
	},
	container: {
		maxWidth: '1410px !important',
		margin: '60px auto 30px !important',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '1.5rem',
		},
	},
}));
