import React, { useState } from 'react';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const SliderTeacher = ({ title, subtitle, background, videoUrl }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const theme = useTheme();
  const classes = useStyles();
  const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
  const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  return (
    <Grid
      container
      display={'flex'}
      justifyContent={'center'}
      alignItems={'flex-end'}
      className={classes.box}
      style={{
        backgroundImage: `url(${background})`,
        height: '440px',
        width: 'full',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={matchesMobile ? handleClick : undefined}
    >
      {(isHovered || isClicked) && (
        <video
          src={videoUrl}
          autoPlay
          muted
          loop
          className={classes.video}
        />
      )}

      <Grid
        container
        xs={12}
        lg={12}
        direction="column"
        justifyContent={matchesTablet ? 'flex-end' : 'center'}
        rowGap={matchesTablet ? 2 : 4}
        style={{
          height: matchesTablet ? '50%' : null,
          paddingRight: !matchesTablet && '3rem',
        }}
      >
        <Grid item>
          <Grid
            item
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <Typography
              fontFamily={'Manrope'}
              fontWeight={800}
              color={'#FFFFFF'}
              className={classes.title}
            >
              {title}
            </Typography>
          </Grid>
          <Typography
            fontFamily={'Manrope'}
            fontWeight={500}
            color={'#FFFFFF'}
            className={classes.subtitle}
          >
            {subtitle}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SliderTeacher;

const useStyles = makeStyles((theme) => ({
  box: {
    height: '400px !important',
    width: '700px !important',
    borderRadius: '32px',
    padding: '0 2rem',
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.down('xl')]: {
      height: '660px',
    },
    [theme.breakpoints.down('md')]: {
      height: '300px !important',
      padding: '1rem',
      width: '350px !important',
    },
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: 1,
    
    [theme.breakpoints.down('sm')]: {
      top: '50% !important',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      borderRadius: '24px !important'
    },
  },
  title: {
    fontSize: '24px',
    [theme.breakpoints.down('sm')]: {
      lineHeight: '1 !important',
      fontSize: '1.35rem !important',
    },
    zIndex: 2,
  },
  subtitle: {
    fontSize: '20px !important',
    lineHeight: '1.25 !important',
    marginBottom: '1rem !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem !important',
    },
    zIndex: 2,
  },
  text: {
    fontSize: '16px !important',
    marginBottom: '10px !important',
    [theme.breakpoints.down('sm')]: {
      lineHeight: '20px !important',
      fontSize: '1rem !important',
    },
    zIndex: 2,
  },
}));
