import React, { useEffect, useRef, useState } from 'react';
import { Container, Divider, Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { v4 as uuidv4 } from 'uuid';

// COMPONENTS
import Footer from '../../Components/Footer';
import { Header } from '../../Components/Header';
import StudentsFeedbackSection from '../../Components/Sections/StudentsFeedback';
import DiferencialBoxV2 from '../../Components/Cards/DiferencialBoxV2';
import DefaultButton from '../../Components/Buttons/DefaultButton';

// ASSETS
import SprintHwmLogoSection from '../../Assets/SprintFinal/sprintHwmLogoSection.svg';
import SprintFinalMobile from '../../Assets/SprintFinal/sprintFinalMobile.svg';
import BgCardPrice from '../../Assets/Extensivo/R1/2023-24/bg-sf-revalida-price-card.png';
import BgTrilhaDoisAnos from '../../Assets/Extensivo/R1/2023-24/BgTrilhaR1DoisAnos.svg';
import LogoDoisAnos from '../../Assets/Extensivo/R1/2023-24/LogoR1DoisAnos.png';

// SF PROVAS
import AmrigsLogo from '../../Assets/SprintFinal/amrigsLogo.svg';
import EinsteinLogo from '../../Assets/SprintFinal/einsteinLogo.svg';
import EnareLogo from '../../Assets/SprintFinal/enareLogo.svg';
import IamspeLogo from '../../Assets/SprintFinal/iamspeLogo.svg';
import FacMedicinaJundiaiLogo from '../../Assets/SprintFinal/facMedicinaJundiaiLogo.svg';
import PsuMgLogo from '../../Assets/SprintFinal/psuMgLogo.svg';
import SantaCasaLogo from '../../Assets/SprintFinal/santaCasaSpLogo.svg';
import SesDfLogo from '../../Assets/SprintFinal/sesDfLogo.svg';
import SesPeLogo from '../../Assets/SprintFinal/sesPeLogo.svg';
import UfrjLogo from '../../Assets/SprintFinal/ufrjLogo.svg';
import AbcLogo from '../../Assets/SprintFinal/abcLogo.svg';
import SirioLibanesLogo from '../../Assets/SprintFinal/sirioLibanesLogo.svg';
import SurceLogo from '../../Assets/SprintFinal/surceLogo.svg';
import SusBaLogo from '../../Assets/SprintFinal/susBaLogo.svg';
import UnicampLogo from '../../Assets/SprintFinal/unicampLogo.svg';
import SusSpLogo from '../../Assets/SprintFinal/susSpLogo.svg';
import UnespBotucatuLogo from '../../Assets/SprintFinal/unespBotucatuLogo.svg';
import AmpLogo from '../../Assets/SprintFinal/ampLogo.svg';
import UnifespLogo from '../../Assets/SprintFinal/unifestpLogo.svg';
import UspLogo from '../../Assets/SprintFinal/uspLogo.svg';
import UspRibeiraoLogo from '../../Assets/SprintFinal/uspRibeiraoLogo.svg';
import FamemaLogo from '../../Assets/SprintFinal/famemaLogo.svg';
import BagIcon from '../../Assets/Svg/Icons/bagIcon.svg';
import UerjLogo from '../../Assets/SprintFinal/uerjLogo.svg';
import UfesLogo from '../../Assets/SprintFinal/ufesLogo.svg';
import PsugoLogo from '../../Assets/SprintFinal/psugoLogo.svg';

//Diferentials Assets
import lessionsIcon from '../../Assets/Extensivo/R1/2023-24/lessionsIcon.svg';
import iconResume from '../../Assets/Extensivo/R1/2023-24/iconResume.svg';
import iconQuestions from '../../Assets/Extensivo/R1/2023-24/iconQuestions.svg';
import iconTalkWithProfessor from '../../Assets/Extensivo/R1/2023-24/iconTalkWithProfessor.svg';
import iconLivesBank from '../../Assets/Extensivo/R1/2023-24/iconLivesBank.svg';
import iconBooks from '../../Assets/Extensivo/R1/2023-24/iconBooks.svg';
import iconMetrics from '../../Assets/Extensivo/R1/2023-24/iconMetrics.svg';
import saleIcon from '../../Pages/LandingPages/Sale/LDL/Assets/saleIcon.svg';
import flashCards from '../../Assets/Extensivo/R1/2023-24/flashCards.svg';
import vidaReal from '../../Assets/Extensivo/R1/2023-24/ligaDaVidaReal.svg';

import FirstSection from '../../Components/Sections/FirstSection';
import TestCardOpen from '../../Components/Cards/TestCardOpen';
import PriceCardVersion4 from '../../Components/Cards/PriceCardVersion4';
import LearningVersion03 from '../../Components/Sections/LearningVersion03';
import { Helmet } from 'react-helmet';

const exams = [
	{
		id: '1210',
		imageUrl: AbcLogo,
	},
	{
		id: '1211',
		imageUrl: AmpLogo,
	},
	{
		id: '1212',
		imageUrl: AmrigsLogo,
	},
	{
		id: '1213',
		imageUrl: EinsteinLogo,
	},
	{
		id: '1214',
		imageUrl: EnareLogo,
	},
	{
		id: '1216',
		imageUrl: FamemaLogo,
	},
	{
		id: '1215',
		imageUrl: FacMedicinaJundiaiLogo,
	},
	{
		id: '1223',
		imageUrl: SirioLibanesLogo,
	},
	{
		id: '1217',
		imageUrl: IamspeLogo,
	},
	{
		id: '1221',
		imageUrl: PsugoLogo,
	},
	{
		id: '1218',
		imageUrl: PsuMgLogo,
	},
	{
		id: '1219',
		imageUrl: SantaCasaLogo,
	},
	{
		id: '1220',
		imageUrl: SesDfLogo,
	},
	{
		id: '1222',
		imageUrl: SesPeLogo,
	},
	{
		id: '1224',
		imageUrl: SurceLogo,
	},
	{
		id: '1226',
		imageUrl: SusBaLogo,
	},
	{
		id: '1225',
		imageUrl: SusSpLogo,
	},
	{
		id: '1236',
		imageUrl: UerjLogo,
	},
	{
		id: '1227',
		imageUrl: UfesLogo,
	},
	{
		id: '1235',
		imageUrl: UfrjLogo,
	},
	{
		id: '1231',
		imageUrl: UnespBotucatuLogo,
	},
	{
		id: '1228',
		imageUrl: UnicampLogo,
	},
	{
		id: '1230',
		imageUrl: UnifespLogo,
	},
	{
		id: '1232',
		imageUrl: UspLogo,
	},
	{
		id: '1234',
		imageUrl: UspRibeiraoLogo,
	},
];

const learningPathTemplate = [
	{
		id: uuidv4(),
		obj: [
			{
				title: 'Liga da vida real',
				description: [
					'A “Liga da Vida Real” é criada por ti! O princípio é simples: vivenciou no internato? Vamos puxar o tema para a Liga e aprofundar os estudos!',
					<>
						<br />
						<br />
					</>,
					'Qualquer tema que faça parte da rotina de preparação para as provas pode ser priorizado, tudo conforme tua demanda!',
				],
				bgColor: '#ffffff',
				titleColor: '#121212',
				descriptionColor: 'primary',
			},
			// {
			// 	title: 'Aula Hardwork',
			// 	description: [
			// 		'Tudo começa com a apresentação ao tema, que no Hardwork é feita com rigor em didática.',
			// 		<>
			// 			<br />
			// 			<br />
			// 		</>,
			// 		'São as melhores aulas que você já viu sobre cada tema.',
			// 	],
			// 	bgColor: '#0D0D0D',
			// 	titleColor: '#FF005D',
			// 	descriptionColor: 'secondary',
			// },
			// {
			// 	title: 'Estudo por questões',
			// 	description: [
			// 		'Alguns dias após a apresentação ao tema, vamos te conduzir pelo estudo por questões.',
			// 		<>
			// 			<br />
			// 			<br />
			// 		</>,
			// 		'Aqui você entenderá todas as formas em que cada parte do tema é explorado nas provas.',
			// 		<>
			// 			<br />
			// 			<br />
			// 		</>,
			// 		'O professor Hardwork te mostrará tudo em detalhes, aprofundando em cada tema.',
			// 	],
			// 	bgColor: '#0D0D0D',
			// 	titleColor: '#FF005D',
			// 	descriptionColor: 'secondary',
			// },
			// {
			// 	title: 'Revisões cíclicas e personalizadas',
			// 	description: [
			// 		'Questões, questões e questões! É assim que revisamos no Hardwork!',
			// 		<>
			// 			<br />
			// 			<br />
			// 		</>,
			// 		'Toda a dinâmica de revisões é, na verdade, uma continuidade do estudo por questões, explorando com mais ênfase os pontos que você tiver mais dificuldade.',
			// 		<>
			// 			<br />
			// 			<br />
			// 		</>,
			// 		'Todos os temas precisam ser dominados!',
			// 	],
			// 	bgColor: '#0D0D0D',
			// 	titleColor: '#FF005D',
			// 	descriptionColor: 'secondary',

			// },
		],
	},
	// {
	// 	title: ' ',
	// 	description: [' '],
	// 	bgColor: 'transparent',
	// 	titleColor: '#FF005D',
	// 	descriptionColor: 'secondary',
	// 	//url: 'https://player.vimeo.com/video/769929780',
	// },
];

const learningPathTemplateDois = [
	{
		id: uuidv4(),
		obj: [
			{
				title: 'Extensivo Hardwork',
				description: [
					'A trilha do aprendizado funciona por ligas de evolução. Começaremos por temas mais palpáveis, evoluindo para outros mais peculiares.',
					<>
						<br />
						<br />
					</>,
					'São 8 ligas no total, cada uma com temas diferentes. Para passar para a próxima, precisaremos dominar por completo a atual.',
					<>
						<br />
						<br />
					</>,
					'Veja como tudo vai funcionar em cada liga.',
				],
				bgColor: '#ffffff',
				titleColor: '#121212',
				descriptionColor: 'primary',
			},
			{
				title: 'Aula Hardwork',
				description: [
					'Tudo começa com a apresentação ao tema, que no Hardwork é feita com rigor em didática.',
					<>
						<br />
						<br />
					</>,
					'São as melhores aulas que você já viu sobre cada tema.',
				],
				bgColor: '#0D0D0D',
				titleColor: '#FF005D',
				descriptionColor: 'secondary',
			},
			{
				title: 'Estudo por questões',
				description: [
					'Alguns dias após a apresentação ao tema, vamos te conduzir pelo estudo por questões.',
					<>
						<br />
						<br />
					</>,
					'Aqui você entenderá todas as formas em que cada parte do tema é explorado nas provas.',
					<>
						<br />
						<br />
					</>,
					'O professor Hardwork te mostrará tudo em detalhes, aprofundando em cada tema.',
				],
				bgColor: '#0D0D0D',
				titleColor: '#FF005D',
				descriptionColor: 'secondary',
			},
			{
				title: 'Revisões cíclicas e personalizadas',
				description: [
					'Questões, questões e questões! É assim que revisamos no Hardwork!',
					<>
						<br />
						<br />
					</>,
					'Toda a dinâmica de revisões é, na verdade, uma continuidade do estudo por questões, explorando com mais ênfase os pontos que você tiver mais dificuldade.',
					<>
						<br />
						<br />
					</>,
					'Todos os temas precisam ser dominados!',
				],
				bgColor: '#0D0D0D',
				titleColor: '#FF005D',
				descriptionColor: 'secondary',
				//url: 'https://player.vimeo.com/video/769929881',
			},
			{
				title: 'Avaliação de desempenho',
				description: [
					'A regra é dominar temas! A avaliação de desempenho e domínio dos temas será diária, usando métricas do estudo por questões.',
					<>
						<br />
						<br />
					</>,
					'Assim que o grau de suficiência em um tema for atingido, ele estará dominado! Domine todos e migre para a próxima liga de aprendizado.',
					<>
						<br />
						<br />
					</>,
					'"E se eu tiver dificuldade"? Calma! Siga a trilha...',
				],
				bgColor: '#0D0D0D',
				titleColor: '#FF005D',
				descriptionColor: 'secondary',
				//url: 'https://player.vimeo.com/video/769929881',
			},
		],
	},
	// {
	// 	title: ' ',
	// 	description: [' '],
	// 	bgColor: 'transparent',
	// 	titleColor: '#FF005D',
	// 	descriptionColor: 'secondary',
	// 	//url: 'https://player.vimeo.com/video/769929780',
	// },
];

const learningPathTemplateTres = [
	{
		id: uuidv4(),
		obj: [
			{
				title: 'Sprint Final',
				description: [
					'O Sprint te faz entrar na alma do concurso, na mente do examinador! É o primeiro e único modelo de revisão totalmente focado em cada uma das provas! Tudo que tu precisas na reta final de preparação.',
				],
				bgColor: '#ffffff',
				titleColor: '#121212',
				descriptionColor: 'primary',
			},
			{
				title: 'Mais de 23 Sprints diferentes',
				description: [
					'A Revisão que utiliza modelo ativo de aprendizado e tem revisões cíclicas automáticas e personalizadas, que são preparadas pela plataforma de acordo com a necessidade de cada um de nós.',
					<>
						<br />
						<br />
					</>,
					'Cada um tem o seu próprio SPRINT!',
				],
				bgColor: '#0D0D0D',
				titleColor: '#FF005D',
				descriptionColor: 'secondary',
			},
		],
	},
	// {
	// 	title: ' ',
	// 	description: [' '],
	// 	bgColor: 'transparent',
	// 	titleColor: '#FF005D',
	// 	descriptionColor: 'secondary',
	// 	//url: 'https://player.vimeo.com/video/769929780',
	// },
];

const DIFERENTIALS_TRILHA = [
	{
		diferential_title: '+ de 250 aulas',
		diferential_description: `Acesso imediato* a mais de 250 aulas Hardwork, elaboradas com rigor em didática.`,
		diferential_icon: lessionsIcon,
	},
	{
		diferential_title: '+ de 190 resumos Hardwork',
		diferential_description: `Resumos dos principais temas explorados nas provas de residência médica.`,
		diferential_icon: iconResume,
	},
	{
		diferential_title: '+ de 100 mil questões de provas',
		diferential_description: `Mais de 100 mil questões de provas dos principais concursos de residência do Brasil.`,
		diferential_icon: iconQuestions,
	},
	{
		diferential_title: 'Contato direto com os professores',
		diferential_description: `Não existe escola em que o aluno não fale com o professor! Aqui o contato é direto, todos os alunos têm acesso aos professores, tanto nos times, quanto via whatsapp.`,
		diferential_icon: iconTalkWithProfessor,
	},
	{
		diferential_title: '+ de 200 lives de discussão ',
		diferential_description: `Acesso imediato* ao banco de lives do Hardwork Medicina, com discussões de mais de 200 dos principais temas voltados para provas de residência médica.`,
		diferential_icon: iconLivesBank,
	},
	{
		diferential_title: 'Acesso aos principais livros',
		diferential_description: `Sim! No extensivo tu terás acesso a milhares de obras consagradas...Vamos estudar pelas principais referências. Tudo JUNTO com o professor! Vamos evoluir frase por frase dentro dos problemas explorados pelas questões. Nada de se restringir a textos resumidos! Vamos dominar as principais fontes! Vamos te mostrar como.`,
		diferential_icon: iconBooks,
	},
	{
		diferential_title: 'Métricas de desempenho',
		diferential_description: `Vamos te mostrar toda a tua evolução de acordo com as provas que farás.. Afinal, cada prova tem seus temas de predileção, sua forma de abordar cada assunto.`,
		diferential_icon: iconMetrics,
	},
	{
		diferential_title: 'Flashcards',
		diferential_description: `Mais de 10mil flashcards para auxiliar na rotina de estudos e revisões.`,
		diferential_icon: flashCards,
	},
	{
		diferential_title: 'Mentoria Hardwork',
		diferential_description: `Um momento específico de conversa individual para alinhamento do planejamento de estudos, melhor uso da plataforma, discussão sobre especialidades, estilos de provas etc.`,
		diferential_icon: vidaReal,
	},
];

const useInterval = function (callback, delay) {
	const savedCallback = useRef();
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);
	useEffect(() => {
		let id = setInterval(() => {
			savedCallback.current();
		}, delay);
		return () => clearInterval(id);
	}, [delay]);
};

export default function TrilhaR102anos() {
	const classes = useStyles();
	const theme = useTheme();
	const subscribe_card_extensivo_r1 = useRef(null);
	const [setPromotionalDate] = useState();
	const [scroll, setScroll] = useState();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesDownLg = useMediaQuery(theme.breakpoints.down('lg'));

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY;
			if (scrollPosition > 500) {
				setScroll(true);
			} else {
				setScroll(false);
			}
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const [glitchIndex, setGlitchIndex] = useState(0);
	useInterval(() => {
		setGlitchIndex(glitchIndex >= 3 ? 0 : glitchIndex + 1);
	}, 3000);

	const executeScroll = () =>
		subscribe_card_extensivo_r1.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			<Helmet>
				<title>Trilha R1 Dois Anos - Hardwork Medicina</title>
				<meta
					name="description"
					content="O Trilha de 2 anos do Hardwork traz a
                    revolução do estudo por questões acoplada à rotina do internato!"
				/>
			</Helmet>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="residencia"
			>
				{scroll ? (
					<DefaultButton
						id="myBtn"
						variant="outlined"
						color={'secondary'}
						className={`${classes.btnFirstSection} ${'residencia white-btn-secondary'}  poppins`}
						onClick={() => executeScroll()}
					>
						<img
							src={saleIcon}
							alt=""
						/>
						&nbsp;Garantir vaga!
					</DefaultButton>
				) : null}

				{matchesMobile || matchesTablet ? null : <Header />}
				<FirstSection
					backgroundImageUrl={BgTrilhaDoisAnos}
					logoUrl={LogoDoisAnos}
					imageProps={{ left: '4rem' }}
					type="red"
					buttonText="Garantir vaga!"
					executeScroll={executeScroll}
					firstTextPaper={[
						<Typography
							align="left"
							color="secondary"
							className={`${classes.firstText} residencia gradient-text roboto-condensed`}
						>
							Hora de se preparar para as provas em sintonia <br /> com o internato
						</Typography>,
						<Divider style={{ width: '100%', borderColor: '#a2a2a2', marginTop: '1rem' }} />,
						<Typography
							align="left"
							color="secondary"
							className={`${classes.firstSectionText} poppins`}
							style={{ marginTop: '1rem' }}
						>
							O Trilha de 2 anos do Hardwork traz a
							<br />
							revolução do estudo por questões acoplada à rotina do internato!
						</Typography>,
					]}
				/>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
					className="residencia"
				>
					<Grid
						container
						alignContent="center"
						justifyContent="center"
						className="section"
						style={matchesMobile ? { marginTop: 0 } : { paddingBottom: '1rem 0 3rem' }}
					>
						<Grid
							item
							xs={12}
							container
							className={classes.container}
							gap={matchesTablet ? 1 : 2}
						>
							<Grid
								item
								xs={12}
								container
								style={matchesMobile ? null : { padding: '2em 0' }}
							>
								<Grid
									item
									container
									style={matchesDownLg ? { order: 1 } : { paddingRight: '2rem' }}
								>
									{!matchesTablet && (
										<img
											src={require('./Assets/baseSolida.svg').default}
											alt=""
											className={classes.focusImg}
										/>
									)}
									<Grid
										item
										xs={12}
										md={3}
										container
										justifyContent={'flex-end'}
										alignContent={'center'}
										style={{ marginBottom: '2rem' }}
									>
										<img
											src={
												matchesTablet
													? require('../../Pages/LandingPages/Sale/SprintFinal/assets/mobile-fabricio-focus.png')
													: require('./Assets/fabricio-desktop.png')
											}
											alt=""
										/>
									</Grid>
									<Grid
										item
										xs={12}
										md={9}
										container
										alignContent={'center'}
										gap={1}
										style={{
											backgroundImage: `url(${require('../../Pages/LandingPages/Sale/SprintFinal/assets/bg-glitch-1.png')})`,
											paddingLeft: matchesTablet ? null : '1rem',
										}}
									>
										<Grid
											item
											container
											alignContent="center"
											className="residencia"
											style={{
												height: 'fit-content',
											}}
										>
											<Typography
												align={'left'}
												color="secondary"
												className={`${classes.subtitle} gradient-text roboto-condensed`}
											>
												Viu no internato? Estude no Trilha
											</Typography>
										</Grid>
										<Grid
											item
											container
											alignContent="flex-start"
											style={{
												height: 'fit-content',
											}}
										>
											{matchesMobile ? (
												<Typography
													align="left"
													color="secondary"
													className={`${classes.textTitle} poppins`}
												>
													O Estudo não pode ser virtual! Não há forma melhor de aprender algo,
													para vida ou para a prova, que vivenciando na prática diária. A
													proposta aqui é clara: vivenciou um tema no internato? estudaremos
													agora…
													<br />
													<br />
													Obviamente, desde o primeiro acesso já começaremos a ter contato
													progressivamente com a maneira como o tema é explorado nas provas!
												</Typography>
											) : (
												<Typography
													align={'left'}
													color="secondary"
													className={`${classes.text} poppins`}
												>
													{matchesDownLg ? (
														<>
															O Estudo não pode ser virtual! Não há forma melhor de
															aprender algo, para vida ou para a prova, que vivenciando na
															prática diária. A proposta aqui é clara: vivenciou um tema
															no internato? estudaremos agora…
															<br />
															<br />
															Obviamente, desde o primeiro acesso já começaremos a ter
															contato progressivamente com a maneira como o tema é
															explorado nas provas!
														</>
													) : (
														<>
															O Estudo não pode ser virtual! Não há forma melhor de
															aprender algo, para vida ou para a prova, que vivenciando na
															prática diária. A proposta aqui é clara: vivenciou um tema
															no internato? estudaremos agora…
															<br />
															<br />
															Obviamente, desde o primeiro acesso já começaremos a ter
															contato progressivamente com a maneira como o tema é
															explorado nas provas!
														</>
													)}
												</Typography>
											)}
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid
								item
								xs={12}
								container
								alignContent={'center'}
								style={{ padding: '2em 0', marginBottom: matchesTablet ? 0 : '2rem' }}
							>
								<Grid
									item
									container
								>
									{!matchesTablet && (
										<img
											src={require('./Assets/dominamosTudo.svg').default}
											alt=""
											className={classes.strategyImg}
										/>
									)}
									<Grid
										item
										container
										justifyContent={matchesMobile ? 'center' : 'flex-start'}
										alignContent={'center'}
									>
										<Grid
											item
											xs={12}
											md={9}
											container
											justifyContent={'flex-end'}
											alignContent={'center'}
											style={{
												paddingRight: matchesTablet ? null : '1rem',
												order: matchesTablet ? 2 : 1,
												backgroundImage: `url(${require('../../Pages/LandingPages/Sale/SprintFinal/assets/bg-glitch-2.png')})`,
											}}
										>
											<Grid
												item
												className="residencia"
												style={{ height: 'fit-content' }}
											>
												<Typography
													align={matchesTablet ? 'left' : 'right'}
													color="secondary"
													className={`${classes.subtitle} gradient-text roboto-condensed`}
												>
													Do mais comum ao mais peculiar
												</Typography>
											</Grid>
											<Grid
												item
												style={{ height: 'fit-content' }}
											>
												<Typography
													align={matchesTablet ? 'left' : 'right'}
													color="secondary"
													className={`${classes.text} poppins`}
												>
													{matchesMobile ? (
														<>
															Além de poder acoplar a preparação para as provas à rotina
															do internato, também temos a trilha de estudos proposta pelo
															Hardwork.
															<br />
															<br />
															Na trilha do Hardwork, os temas são agrupados em grupos, e
															começamos dominando os que são mais comuns na prova (que não
															por acaso são frequentes na vida!), e progressivamente
															migramos para os mais peculiares.
														</>
													) : (
														<>
															Além de poder acoplar a preparação para as provas à rotina
															do internato, também temos a trilha de estudos proposta pelo
															Hardwork.
															<br />
															<br />
															Na trilha do Hardwork, os temas são agrupados em grupos, e
															começamos dominando os que são mais comuns na prova (que não
															por acaso são frequentes na vida!), e progressivamente
															migramos para os mais peculiares.
														</>
													)}
												</Typography>
											</Grid>
										</Grid>
										<Grid
											item
											xs={12}
											md={3}
											container
											justifyContent={matchesTablet ? 'flex-end' : 'flex-start'}
											alignContent={'center'}
											style={{ order: matchesTablet ? 1 : 2, marginBottom: '1.5rem' }}
											// style={{marginBottom: '2rem',}}
										>
											<img
												src={
													matchesTablet
														? require('../../Pages/LandingPages/Sale/SprintFinal/assets/mobile-strategy.png')
														: require('./Assets/fabio-from-the-right.png')
												}
												alt=""
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid
								item
								xs={12}
								container
								justifyContent={matchesTablet ? 'flex-start' : 'flex-end'}
								alignContent="center"
								style={{ padding: '2em 0' }}
							>
								<Grid
									item
									container
								>
									{!matchesTablet && (
										<img
											src={require('./Assets/almaDaProva.svg').default}
											alt=""
											className={classes.sweatImg}
										/>
									)}
									<Grid
										item
										container
										style={matchesDownLg ? { order: 1 } : null}
									>
										<Grid
											item
											xs={12}
											md={3}
											container
											justifyContent={'flex-end'}
											alignContent={'center'}
											style={{ marginBottom: '2rem' }}
										>
											<img
												src={
													matchesTablet
														? require('../../Pages/LandingPages/Sale/SprintFinal/assets/mobile-alma-prova.png')
														: require('./Assets/fabio-claudio-from-right.png')
												}
												alt=""
											/>
										</Grid>
										<Grid
											item
											xs={12}
											md={9}
											container
											alignContent={'center'}
											style={{
												paddingLeft: matchesTablet ? null : '1rem',
												backgroundImage: `url(${require('../../Pages/LandingPages/Sale/SprintFinal/assets/bg-glitch-3.png')})`,
											}}
										>
											<Grid
												item
												container
												alignContent="flex-start"
												className="residencia"
												style={{ height: 'fit-content' }}
											>
												{matchesMobile ? (
													<Typography
														align={'left'}
														color="secondary"
														className={`${classes.subtitle} gradient-text roboto-condensed`}
													>
														Trilha é Extensivo + Sprint Final
													</Typography>
												) : (
													<Typography
														align="left"
														color="secondary"
														className={`${classes.subtitle} gradient-text roboto-condensed`}
													>
														Trilha é Extensivo + Sprint Final
													</Typography>
												)}
											</Grid>
											<Grid
												item
												container
												alignContent="flex-end"
												style={{ height: 'fit-content' }}
											>
												{matchesMobile ? (
													<Typography
														align="left"
														color="secondary"
														className={`${classes.text} poppins`}
													>
														E no segundo semestre de 2025, tu terás acesso ao Sprint Final,
														a revisão específica para cada banca.
														<br />
														<br />
														São mais de 25 provas totalmente dominadas pelo Sprint Final,
														numa dinâmica de revisões totalmente diferente de tudo que já
														viste! A gente entra na alma de cada uma das bancas!
													</Typography>
												) : (
													<Typography
														align="left"
														color="secondary"
														className={`${classes.text} poppins`}
													>
														E no segundo semestre de 2025, tu terás acesso ao Sprint Final,
														a revisão específica para cada banca.
														<br />
														<br />
														São mais de 25 provas totalmente dominadas pelo Sprint Final,
														numa dinâmica de revisões totalmente diferente de tudo que já
														viste! A gente entra na alma de cada uma das bancas!
													</Typography>
												)}
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</Container>

			<Grid
				container
				className="white-background"
			>
				<Grid
					container
					style={{ padding: '2vh 5%' }}
					className={classes.backgroundWaves}
				>
					<Container
						fixed
						maxWidth="xl"
						disableGutters={true}
					>
						<Grid
							container
							alignContent="center"
							justifyContent="center"
							className={`${matchesTablet ? null : 'section'}  -live-card`}
							style={{
								backgroundColor: 'black',
								borderRadius: 15,
								margin: matchesTablet ? '40px auto' : '80px auto',
							}}
							ref={subscribe_card_extensivo_r1}
						>
							<PriceCardVersion4
								idTurma={1319}
								bgImageUrl={BgCardPrice}
								logo={LogoDoisAnos}
								iconBtn={BagIcon}
								color
								setPromotionalDate={setPromotionalDate}
							/>
						</Grid>
					</Container>
				</Grid>
			</Grid>

			<LearningVersion03
				title={'A Revolução: A Trilha do aprendizado definitivo'}
				cardsInfo={learningPathTemplate[0]}
				cardsInfoTwo={learningPathTemplateDois[0]}
				cardsInfoThree={learningPathTemplateTres[0]}
				background={'background-gradient-residencia'}
			/>

			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className="section"
				>
					<Grid
						item
						xs={12}
						container
					>
						<Grid
							item
							xs={12}
							container
						>
							<Typography
								className={`${classes.subtitle1} roboto-condensed`}
								color="secondary"
							>
								O que mais tem no Trilha
							</Typography>
						</Grid>
						<Grid
							container
							item
							xs={12}
							rowGap={2}
							style={{ margin: '1rem 0rem 3rem 0rem' }}
						>
							{DIFERENTIALS_TRILHA.map((elm, index) => (
								<DiferencialBoxV2
									key={index}
									title={elm.diferential_title}
									description={elm.diferential_description}
									icon={elm.diferential_icon}
								/>
							))}
						</Grid>
					</Grid>
				</Grid>
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className="section"
				>
					<Grid
						item
						xs={12}
						container
						className="residencia"
						style={matchesMobile ? { paddingBottom: '1em' } : { paddingBottom: '2em' }}
					>
						<Typography
							className={`${classes.subtitle1} roboto-condensed`}
							color="secondary"
						>
							E no segundo semestre de 2025
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						container
					>
						<Grid
							item
							xs={4}
							container
							alignContent={matchesMobile ? 'center' : null}
						>
							{matchesMobile ? (
								<img
									src={SprintFinalMobile}
									alt=""
								/>
							) : (
								<img
									src={SprintHwmLogoSection}
									alt=""
								/>
							)}
						</Grid>
						<Grid
							item
							xs={8}
							container
							alignContent="center"
							style={{ paddingLeft: '2em' }}
						>
							<Typography
								color="secondary"
								className={`${classes.thirdSectionText} poppins`}
							>
								Acesso garantido a todos os <strong>Sprints Finais 2025*</strong> para provas de
								residência: <br /> A única revisão realmente focada em provas.
							</Typography>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						container
						alignContent="center"
						justifyContent="flex-start"
						style={{ margin: '40px 0px 20px' }}
					>
						{exams.map((item, index) => (
							<Grid
								item
								xs={6}
								md={2.4}
								lg={2.4}
								key={index}
								container
								justifyContent="center"
								style={{ margin: '8px 0' }}
							>
								<TestCardOpen
									imageUrl={item.imageUrl}
									key={index}
									selected={true}
								/>
							</Grid>
						))}
					</Grid>
					<Grid
						item
						xs={12}
						container
						alignContent="center"
						justifyContent="flex-start"
					>
						<Typography
							color="secondary"
							className={`${classes.thirdSectionText} poppins`}
						>
							*Sprints previstos para 2025. Listagem sujeita a alterações para mais ou para menos.
						</Typography>
					</Grid>
				</Grid>
			</Container>
			<StudentsFeedbackSection type="extensivo" />
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

export const ProductCard = ({
	backgroundImage,
	logo,
	description,
	secondDescription,
	buttonText,
	urlRedirect,
	openDetails,
}) => {
	const classes = useStyles();

	return (
		<Paper
			className={`${classes.scriptPaper} rounded-card`}
			color="secondary"
			elevation={4}
		>
			<Grid
				container
				justifyContent={'center'}
				alignContent={'center'}
				className={classes.gridImage}
				style={{ background: `url(${backgroundImage}) center/cover no-repeat`, height: '200px' }}
			>
				<img
					src={logo}
					alt=""
					style={{ visibility: 'visible' }}
				/>
			</Grid>

			<Grid
				container
				justifyContent={'center'}
				className={classes.gridDescriptionScriptCard}
			>
				{!openDetails ? (
					<Typography
						align="left"
						color="secondary"
						className={`${classes.scriptText} poppins`}
					>
						{description}
					</Typography>
				) : (
					<>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.scriptText} poppins`}
						>
							{description}
						</Typography>
						<Divider
							style={{
								borderColor: '#a2a2a280',
								borderWidth: '1.5px',
								width: '90%',
								transition: 'all 0.5s ease',
							}}
						/>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.scriptText} poppins`}
						>
							{secondDescription}
						</Typography>
					</>
				)}
			</Grid>
		</Paper>
	);
};

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.5em !important',
		lineHeight: '1 !important',
	},
	backgroundWaves: {
		backgroundImage: `url(${process.env.PUBLIC_URL + '/Assets/Img/bgWave2.png'})`,
		width: '100vw !important',
	},
	gradientText: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		lineHeight: '1.5 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.8rem !important',
		},
	},
	firstText: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',

		color: 'red',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
	},
	text: {
		fontSize: '1.15rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	textTitle: {
		fontSize: '1.15rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '0.8rem !important',
		},
	},
	subtitle1: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
			lineHeight: 'unset !important',
		},
	},
	flashCardsTitle: {
		fontSize: '1.8em !important',
		fontWeight: 'bold !important',
	},
	thirdSectionText: {
		fontSize: '1.12rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	disclaimerText2: {
		fontSize: '0.95rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.85rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.7rem !important',
		},
	},
	scriptPaper: {
		backgroundColor: 'transparent !important',
		height: '100%',
		[theme.breakpoints.down('xl')]: {
			minHeight: 'unset',
		},
		[theme.breakpoints.down('lg')]: {
			height: 'unset',
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
			width: 'unset',
			minHeight: 'unset',
			margin: '16px 0px',
		},
	},
	gridImage: {
		borderTopRightRadius: 15,
		borderTopLeftRadius: 15,
		padding: '2rem',
		height: '50%',
	},
	gridDescriptionScriptCard: {
		backgroundColor: '#63636366 !important',
		padding: '1.25rem',
		borderBottomLeftRadius: 16,
		borderBottomRightRadius: 16,
		height: '50%',
		[theme.breakpoints.down('lg')]: {
			padding: '1em 1.5em',
		},
		[theme.breakpoints.down('md')]: {
			height: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '1em',
			borderTopRightRadius: 0,
			borderTopLeftRadius: 0,
		},
	},
	scriptText: {
		fontSize: '0.95rem !important',
		overflowY: 'auto',
		paddingRight: 20,
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
			maxHeight: 175,
			minHeight: 'unset !important',
		},
	},
	gridButton: {
		bottom: 25,
		left: '-5%',
		[theme.breakpoints.down('lg')]: {
			bottom: -17,
		},
		[theme.breakpoints.down('sm')]: {
			left: '-7%',
			bottom: -15,
		},
	},
	btn: {
		fontSize: '1rem !important',
		width: '50%',
		fontWeight: 'bold !important',
		'&:hover': {
			scale: 1.05,
			color: '#ffffff !important',
			'& $logoBtn': {
				filter: 'invert(0)',
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.75rem !important',
			minHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem !important',
			width: '50%',
		},
	},
	gridTextSecondSection: {
		paddingBottom: 70,
		[theme.breakpoints.down('lg')]: {
			paddingBottom: 35,
		},
	},
	textSecondSection: {
		fontSize: '1.85rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.4rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	btnDetail: {
		fontWeight: 'bold',
		width: '100%',
		maxHeight: 50,
		[theme.breakpoints.down('lg')]: {
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			width: '25%',
			fontSize: '.8rem !important',
		},
	},
	gridButtonDetails: {
		bottom: 5,
		left: '70%',
		[theme.breakpoints.down('lg')]: {
			bottom: 20,
		},
		[theme.breakpoints.down('sm')]: {
			left: '-3%',
			bottom: 40,
		},
	},
	gridBtnHeight: {
		transition: 'all .2s',
		display: 'flex',
		alignContent: 'flex-start',
	},
	description: {
		display: 'inline-block',
		fontSize: '1.25rem !important',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			fontSize: '1rem !important',
		},
	},
	descriptionGrid: {
		width: '100% !important',
		margin: '1.5rem 0rem 1.5rem 0rem',
		transition: 'all 0.5s ease',
	},
	subtitle: {
		fontSize: '2.85rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		// marginBottom: '1rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
			marginBottom: '0.5rem !important',
		},
	},
	focusImg: {
		position: 'absolute !important',
		top: 260,
		zIndex: 1,
		left: 215,
		width: '35%',
		[theme.breakpoints.up('xl')]: {
			top: 300,
		},
		[theme.breakpoints.down('lg')]: {
			left: 180,
			top: 230,
			// width: '45%',
		},
		[theme.breakpoints.down('md')]: {
			left: 60,
			// bottom: -45,
		},
	},
	strategyImg: {
		position: 'absolute',
		top: 300,
		zIndex: 1,
		right: 200,
		width: '50%',
		[theme.breakpoints.up('xl')]: {
			top: 360,
		},
		[theme.breakpoints.down('lg')]: {
			right: 130,
			top: 250,
			width: '40%',
		},
		[theme.breakpoints.down('md')]: {
			right: 130,
			width: '15%',
		},
	},
	sweatImg: {
		position: 'absolute',
		top: 320,
		zIndex: 1,
		left: 200,
		width: '50%',
		[theme.breakpoints.up('xl')]: {
			top: 360,
		},
		[theme.breakpoints.down('lg')]: {
			left: 130,
			top: 290,
			width: '40%',
		},
		[theme.breakpoints.down('md')]: {
			width: '15%',
		},
	},
	btnFirstSection: {
		// display: 'none',
		position: 'fixed !important',
		zIndex: '1500',
		border: '5px solid black !important',
		width: 'fit-content',
		padding: '0 3rem !important',
		bottom: '3rem',
		right: '3rem',
		fontSize: '1.425rem !important',
		fontWeight: 'bold !important',
		'&:hover': {
			scale: 1.05,
			color: '#ffffff',
			'& $logoBtn': {
				filter: 'invert(0%) !important',
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
			minHeight: 'unset !important',
			width: 'fit-content',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
			width: 'fit-content',
		},
	},
	firstSectionText: {
		fontSize: '1.9rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.05rem !important',
		},
	},
}));
