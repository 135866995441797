import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const StudentFeedbackItens = ({ description, studentName}) => {

  const classes = useStyles();

  return (
    <Grid
      item
      container
      flexDirection={'column'}
      justifyContent={'space-between'}
      alignItems={'flex-start'}
      width={450}
      height={250}
      borderRadius={4}
      padding={3}
      style={{ backgroundColor: '#EBEBEB' }}
      className={classes.gridCarouselContainer}
    >
      <Grid className={classes.gridCarouselContainer}>
        <div className={classes.descriptionScrollContainer}>
          <Typography
            fontSize={13}
            fontFamily={'Manrope'}
            color={'#040404'}
          >
            {description}
          </Typography>
        </div>
      </Grid>

      <Grid width={'fit-content'} item>
        <Typography
          fontSize={13}
          fontFamily={'Manrope'}
          color={'#040404'}
          fontWeight={'bold'}
          margin={'auto'}
        >
          {studentName}
        </Typography>
       
      </Grid>
    </Grid>
  );
	
};

const useStyles = makeStyles((theme) => ({
  gridCarouselContainer: {
    [theme.breakpoints.down('xl')]: {
        width: '380px !important',
        paddingRight: '50px'
    },
  },
  descriptionScrollContainer: {
    scrollBehavior: 'scroll',
    overflowY: 'auto',
    maxHeight: 150,
    padding: 2, 
    lineHeight: '1.35 !important',
    paddingRight: 20,
    [theme.breakpoints.down('xl')]: {
        fontSize: '1.15rem !important',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.9rem !important',
    },
  },


content: {
    lineHeight: '1.35 !important',
    maxHeight: 178,
    minHeight: 178,
    overflowY: 'auto',
    paddingRight: 20,
    [theme.breakpoints.down('xl')]: {
        fontSize: '1.15rem !important',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.9rem !important',
    },
},
}));

export default StudentFeedbackItens;