import React, { useEffect, useRef, useState } from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Header } from '../../../../Components/Header';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import StudentsFeedbackSection from '../../../../Components/Sections/StudentsFeedback';
import '@splidejs/react-splide/css';
import ToggleContainer2024 from './components/ToggleContainer-2024';
import FirstSectionFullScreen2024 from '../../../../Components/Sections/FirstSectionFullScreen-2024';
import { Helmet } from 'react-helmet';

export const CountDownTimer = ({ targetDate, textColor }) => {
	const classes = useStyles();
	const calculateTimeLeft = () => {
		const now = new Date().getTime();
		const difference = targetDate - now;

		if (difference <= 0) {
			// Contagem regressiva terminou
			return { days: 0, hours: 0, minutes: 0, seconds: 0 };
		}

		const days = Math.floor(difference / (1000 * 60 * 60 * 24));
		const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((difference % (1000 * 60)) / 1000);

		return { days, hours, minutes, seconds };
	};

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

	useEffect(() => {
		const timer = setInterval(() => {
			setTimeLeft(calculateTimeLeft());
		}, 1000);

		return () => {
			clearInterval(timer);
		};
	}, [targetDate]);

	return (
		<>
			<Grid>
				<Grid
					display={'flex'}
					item
					rowGap={'3'}
					alignContent={'space-between'}
				>
					<Grid>
						<Grid
							item
							container
							justifyContent={'center'}
							alignContent={'center'}
							className={classes.containerTime}
							// sx={containerTime}
						>
							<Typography
								variant="h4"
								className={`${classes.textNumber} poppins`}
								// sx={textNumber}
							>
								{timeLeft.days}
							</Typography>
						</Grid>
						<Grid
							item
							container
							// sx={containerTextTimer}
							justifyContent={'center'}
							alignContent={'center'}
						>
							<Typography
								color={textColor}
								display={'flex'}
								justifyContent={'center'}
								className={`${classes.textTimer} poppins`}
								// sx={textTimer}
							>
								dias
							</Typography>
						</Grid>
					</Grid>

					<Grid
						item
						container
						justifyContent={'center'}
						alignContent={'center'}
						className={classes.containerSeparator}
						// sx={ContainerSeparator}
					>
						<Typography
							className={`${classes.separatorText} poppins`}
							// sx={separatorText}
						>
							:
						</Typography>
					</Grid>

					<Grid>
						<Grid
							item
							container
							justifyContent={'center'}
							alignContent={'center'}
							// sx={containerTime}
							className={classes.containerTime}
						>
							<Typography
								variant="h4"
								className={`${classes.textNumber} poppins`}
								// sx={textNumber}
							>
								{timeLeft.hours}
							</Typography>
						</Grid>
						<Grid
							item
							container
							// sx={containerTextTimer}
							justifyContent={'center'}
							alignContent={'center'}
						>
							<Typography
								color={textColor}
								display={'flex'}
								justifyContent={'center'}
								className={`${classes.textTimer} poppins`}
								// sx={textTimer}
							>
								horas
							</Typography>
						</Grid>
					</Grid>

					<Grid
						item
						container
						justifyContent={'center'}
						alignContent={'center'}
						className={classes.containerSeparator}
					>
						<Typography
							className={`${classes.separatorText} poppins`}
							// sx={separatorText}
						>
							:
						</Typography>
					</Grid>

					<Grid>
						<Grid
							item
							container
							justifyContent={'center'}
							alignContent={'center'}
							// sx={containerTime}
							className={classes.containerTime}
						>
							<Typography
								variant="h4"
								className={`${classes.textNumber} poppins`}
								// sx={textNumber}
							>
								{timeLeft.minutes}
							</Typography>
						</Grid>
						<Grid
							item
							container
							// sx={containerTextTimer}
						>
							<Typography
								color={textColor}
								display={'flex'}
								justifyContent={'center'}
								className={`${classes.textTimer} poppins`}
								// sx={textTimer}
							>
								minutos
							</Typography>
						</Grid>
					</Grid>

					<Grid
						item
						container
						className={classes.containerSeparator}
						justifyContent={'center'}
						alignContent={'center'}
					>
						<Typography
							className={`${classes.separatorText} poppins`}
							// sx={separatorText}
						>
							:
						</Typography>
					</Grid>

					<Grid>
						<Grid
							item
							container
							justifyContent={'center'}
							alignContent={'center'}
							// sx={containerTime}
							className={classes.containerTime}
						>
							<Typography
								variant="h4"
								className={`${classes.textNumber} poppins`}
								// sx={textNumber}
							>
								{timeLeft.seconds}
							</Typography>
						</Grid>

						<Grid
							item
							container
							// sx={containerTextTimer}
						>
							<Typography
								color={textColor}
								display={'flex'}
								justifyContent={'center'}
								className={`${classes.textTimer} poppins`}
								// sx={textTimer}
							>
								segundos
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default function ArenaSale2024() {
	const classes = useStyles();
	const theme = useTheme();
	const arena_card = useRef(null);
	const arena_purchase_card = useRef(null);
	const endDate = new Date('2023-10-05T23:59:00');
	const currentDate = new Date();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const endOfPromotionalValue = currentDate >= endDate;

	const executeScroll = () =>
		arena_card.current.scrollIntoView({ behavior: 'smooth', block: matchesTablet ? 'center' : 'start' });
	const executeScrollToggleContainer = () =>
		arena_purchase_card.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			<Helmet>
				<title>Arena Hardwork - Hardwork Medicina</title>
				<meta
					name="description"
					content="A prova prática é um jogo e o time Hardwork só entra pra ganhar!"
				/>
			</Helmet>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="revalida"
			>
				{matchesMobile || matchesTablet ? null : <Header />}
			</Container>
			<Grid
				container
				className="revalida"
			>
				<FirstSectionFullScreen2024
					backgroundImageUrl={require('./assets/bg-first-section-arena-hwm.png')}
					logoUrl={require('./assets/logo-arena-hwm-2024.svg').default}
					type="green"
					buttonText="&nbsp;&nbsp;Encerrado"
					executeScroll={executeScroll}
					firstTextPaper={[
						<Typography
							variant="h1"
							className={`${classes.gradientText} gradient-text roboto-condensed`}
						>
							A prova prática é um jogo e o time Hardwork só entra pra ganhar!
						</Typography>,
						<Typography
							align="left"
							color="secondary"
							className={`${classes.text} poppins`}
						>
							Chegou a hora de dominarmos a nova prova prática do Revalida Inep.
							<br />
							<br />
							Em 2024 um NOVO ARENA, totalmente adaptado ao novo modelo da prova.
						</Typography>,
					]}
					// buttonProps={{
					// 	bottom: '7.5em',
					// 	left: '5em',
					// 	type: 'white-btn',
					// 	color: 'secondary',
					// 	buttonLogoUrl: require('../../../../Assets/Svg/Icons/bagIcon.svg').default,
					// }}
					// imageProps={{ width: '37%', left: '10em', top: '5.75em' }}
				/>
			</Grid>
			<ToggleContainer2024
				arena_card={arena_card}
				arena_purchase_card={arena_purchase_card}
				executeScroll={executeScrollToggleContainer}
			/>
			<StudentsFeedbackSection type="arena 2022" />
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Footer />
			</Container>
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	text: {
		fontSize: '1.75rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.35rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	backgroundWaves: {
		backgroundImage: `url(${process.env.PUBLIC_URL + '/Assets/Img/bgWave2.png)'}`,
		width: '100vw !important',
	},
	gradientText: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		marginBottom: '16px !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.8rem !important',
		},
	},
	timerGrid: {
		position: 'fixed',
		bottom: '2rem',
		zIndex: 3,
		border: '3px solid #ffffff',
		margin: '0 auto !important',
		backgroundColor: '#000000d9',
		padding: '0.75rem 8rem 0.75rem 4rem',
		borderRadius: '25px 50px 50px 25px !important',
		[theme.breakpoints.down(1770)]: {
			padding: '0.75rem 8rem 0.75rem 4rem',
		},
		[theme.breakpoints.down(1240)]: {
			padding: '0.75rem 9rem 0.75rem 4rem',
		},
		[theme.breakpoints.down('md')]: {
			padding: '0.75rem 2rem 0.75rem 3rem',
			borderRadius: '15px !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0.75rem 1rem',
		},
	},
	endOfDiscount: {
		fontSize: '1.25rem !important',
		alignSelf: 'center',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.7rem !important',
		},
	},
	timeText: {
		fontSize: '3.85rem !important',
		lineHeight: 'unset !important',
		color: 'yellow !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2.5rem !important',
			alignSelf: 'center',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '3rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
		},
	},
	timerIcon: {
		position: 'absolute',
		right: -14,
		top: -22,
		[theme.breakpoints.down('xl')]: {
			top: -20,
			right: -11,
			width: '10%',
		},
		[theme.breakpoints.down(1770)]: {
			top: -30,
			right: -15,
			width: 'unset',
		},
		[theme.breakpoints.down(1535)]: {
			top: -27,
			right: -13,
			width: '12%',
		},
		[theme.breakpoints.down(1416)]: {
			top: -22,
			right: -12,
			width: '12%',
		},
		[theme.breakpoints.down(1240)]: {
			top: -22,
			right: -13,
			width: '14%',
		},
		[theme.breakpoints.down(1190)]: {
			width: '12%',
		},
		[theme.breakpoints.down(1060)]: {
			width: '13%',
			top: -19,
			right: -11,
		},
		[theme.breakpoints.down(941)]: {
			width: '15%',
		},
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	timeTextDescription: {
		fontWeight: 'bold !important',
		fontSize: '0.85rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.45rem !important',
		},
	},
	containerTime: {
		backgroundColor: '#ffffff2b',
		borderRadius: '6px',
		color: '#ffffff',
		width: '5rem !important',
		height: '4rem',
		[theme.breakpoints.down(1770)]: {
			width: '3.5rem !important',
			height: '3.5rem',
		},
		[theme.breakpoints.down('sm')]: {
			width: '2rem !important',
			height: '2.5rem',
		},
	},
	textNumber: {
		fontWeight: 'bold !important',
		fontSize: '1.4rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	containerSeparator: {
		color: '#ffffff',
		width: '2.2rem !important',
		height: '3.8rem',
		[theme.breakpoints.down('sm')]: {
			width: '1.2rem !important',
			height: '2.5rem',
		},
	},
	separatorText: {
		fontWeight: 'bold !important',
		fontSize: '1.3rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	textTimer: {
		textTransform: 'uppercase',
		fontWeight: 'bold !important',
		width: '5rem !important',
		height: '1rem',
		fontSize: '0.75rem !important',
		alignSelf: 'center',
		[theme.breakpoints.down(1770)]: {
			width: '4rem !important',
		},
		[theme.breakpoints.down(1240)]: {
			width: '3.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			width: '2rem !important',
			fontSize: '0.5rem !important',
		},
	},
}));
