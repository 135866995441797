const data = {
	// PRODUCTS
	sprint: [
		{
			name: 'Extensivo',
			description:
				'Treinamento inovador que aperfeiçoa progressivamente a base de conhecimentos necessários para as provas de residência médica combinado ao método ativo de estudo por questões',
			url: '/extensivo-r1-2023',
		},
		{
			name: 'Extensivo R3',
			description:
				'O Extensivo R3 oferece aos alunos discussões exclusivas para as provas de especialidades em Clínica Médica, focando nas peculiaridades de cada concurso.',
			url: '/extensivo-r3-clinica-medica',
		},
		{
			name: 'Extensivo Cirurgia',
			description:
				'O Extensivo R3 descomplica e aprofunda temas das especialidades de Cirurgia Geral, através de um método dinâmico, tendo como base o Estudo por Questões e revisões periódicas e personalizadas.',
			url: '//hardworkmedicina.com.br/pre-inscricao/extensivo-r3cg-2023',
		},
		{
			name: 'Sprint Final Hardwork',
			description:
				'O treinamento que retrata a mente da banca nos detalhes! em cada concurso, um modelo ativo de aprendizado e revisões cíclicas personalizadas.',
			url: '//hardworkmedicina.com.br/sprint-final-2022',
			disabled: true,
		},
		{
			name: 'Sprint Final Hardwork R3',
			description:
				'Todos os detalhes exigidos especificamente pela prova da USP. Não é uma mera extensão do curso de acesso direto! Tudo com uma estratégia de revisões organizadas, dinâmicas e personalizadas. ',
			url: '',
			disabled: true,
		},
		{
			name: 'Lives',
			description:
				'A Live das Lives é uma experiência! Tudo na Live das Lives é desenhado para que a revisão seja leve, prazerosa e intensa! São cerca de 7 horas de imersão total na prova! Nada passa em branco!',
			url: '//hardworkmedicina.com.br/live-das-lives-sus-sp-2022',
		},
	],
	revalida: [
		{
			name: 'Live Revalida',
			description:
				'Um SHOW de véspera de Prova. Sem desperdício de energia, sem blá blá blá. Uma revisão leve, prazerosa e intensa em um evento presencial com experiência Hardwork MULTISSENSORIAL! Conexão direta entre professores e alunos.',
			url: '/live-das-lives-revalida-2023',
		},
		{
			name: 'Revalida',
			description:
				'A preparação completa e focada na prova do revalida inep. Consolidamos os assuntos abordados na prova e os revisamos com intensidade através de aulas dinâmicas e estudo por questões.',
			url: '//hardworkmedicina.com.br/extensivo-revalida',
		},
		{
			name: 'Sprint Final Revalida',
			description:
				'Chega de revisar do mesmo jeito. Não se limite pelo roteiro imposto por “todo mundo”. Conheça o método ativo de aprendizado com revisões personalizadas de acordo com as suas necessidades.',
			url: '//hardworkmedicina.com.br/sprint-final-revalida-2023',
		},
		{
			name: 'Arena Hardwork',
			description:
				'Experiência exclusiva e intensa! Estações interativas, realizadas em pequenos grupos, que resolvem lacunas no aprendizado e te preparam para a prova prática do revalida inep. ',
			url: '',
		},
	],
	// LANDING PAGE PRODUCTS
	sprintLP: [
		{
			name: 'Sprint Final',
			value: 'R$1.690,00',
		},
		{
			name: 'Sprint Final + Pacotes de Lives',
			value: 'R$2.290,00',
		},
		{
			name: '3 Sprint Final + Pacotes de Lives',
			value: 'R$4.090,00',
		},
		{
			name: 'Sprint Final Provas de Sp + Pacotes de Lives',
			value: 'R$4.490,00',
		},
	],
	// DOCTORS
	doctors: [
		{
			name: 'Fabrício Valois',
			specialty: 'Pneumologia',
			insta: '@fabricio.valois',
		},
		{
			name: 'Fábio Colagrossi',
			specialty: 'Cirurgia Geral',
			insta: '@drfabiocolagrossi',
		},
		{
			name: 'Decarthon Vitor',
			specialty: 'Cardiologista',
			insta: '@drdecarthonvitor',
		},
		{
			name: 'Fábio Cabar',
			specialty: 'Ginecologia e Obstetrícia',
			insta: '@fabio.cabar',
		},
	],
	// FAQ
	faq: [
		{
			id: 1,
			question: 'O que é o Sprint Final?',
			answer: [
				'O Sprint Final é um modelo de revisão baseado em metodologia ativa, que tem como objetivo aumentar a intensidade de estudos na reta final de preparação para provas, otimizando o tempo para sedimentar os assuntos e com os formatos de abordagem mais comuns em cada concurso.',
				<br />,
				<br />,
				'São vídeos curtos de revisão e áreas de treinamento com perguntas e respostas rápidas. Contando com um algoritmo inteligente, a plataforma personaliza as revisões, privilegiando os assuntos que tu tenhas mais dificuldade.',
			],
		},
		{
			id: 2,
			question:
				'Entendi que o Sprint Final tem metodologia ativa! Mas qual a diferença disso para um método passivo?',
			answer: [
				'Das poucas certezas que se tem sobre métodos de estudo, uma é que as metodologias ativas de aprendizagem são extremamente superiores às passivas. Aprendemos mais, sedimentamos melhor...',
				<br />,
				<br />,
				'Um método passivo é aquele no qual o aluno apenas recebe informações e as armazena; exemplos clássicos são aulas com conteúdo, aulas de resolução de questões, leituras simples de textos, leituras simples de comentários de questões. O método passivo, para ser efetivo, exige muita energia, muita memorização... e haja revisão!',
				<br />,
				<br />,
				'Os métodos ativos são aqueles em que participamos ativamente da construção da informação; somos convidados a pensar a todo instante, a gerar conexões com dados que tivemos contato previamente. O resultado disso é a criação de uma ampla rede de conhecimento, com informações interconectadas, difíceis de serem perdidas... até o processo de revisão é mais simples, pois ao recobrar um conceito, todos aqueles relacionados a ele são reativados na memória.',
			],
		},
		{
			id: 3,
			question: 'O Sprint Final é um Intensivo que tem apenas aulas resumidas?',
			answer: [
				'Não. O Sprint Final tem como grande pilar a área de treinamento para os concursos.',
				<br />,
				<br />,
				'Cada treinamento traz consigo mais de uma centena de áreas de treinamento, cada uma de um tema diferente, e específica para a instituição.',
				<br />,
				<br />,
				'Ao final de cada tema treinado o professor surge com vídeos curtos trazendo os conceitos mais importantes que precisam ser fixados para cada instituição.',
			],
		},
		// {
		// 	id: 4,
		// 	question: 'As áreas de treinamento só podem ser feitas uma vez? Ou poderei fazer várias vezes?',
		// 	answer: [
		// 		'Quando a área de treinamento é liberada, precisa ser concluída para termos sequência no treinamento. Assim que for finalizada, se tornará inativa, mas será reapresentada, no futuro, de acordo com o algoritmo de revisões cíclicas do Sprint, até a data da prova; a área de treinamento, nas revisões, tem formatação diferente da inicial, pois nas revisões será ajustada para as necessidades individuais dos alunos.',
		// 	],
		// },
		{
			id: 4,
			question: ' Como é o algoritmo de revisões personalizadas do Sprint?',
			answer: [
				'Ah, tá bom... quer saber o segredo da coca-cola, é? Bem, essa resposta pertence ao grupo dos ingredientes especiais do Sprint Final. Kkkkkk',
				<br />,
				<br />,
				'Mas não se preocupe. Uma vez tendo finalizado uma área de treinamento, sempre seremos notificados dos momentos de revisar cada assunto. O foco tem de ser em revisar, sempre!',
			],
		},
		// {
		// 	id: 6,
		// 	question:
		// 		'Comecei o Sprint e estou com o dia apertado hoje. Tenho temas para revisar e áreas de treinamento novas... o que devo priorizar?',
		// 	answer: 'Priorize as revisões, SEMPRE. Deixar de revisar quebra todo o processo do Sprint Final.',
		// },
		{
			id: 5,
			question: 'O Sprint Final tem questões comentadas?',
			answer: [
				'O Sprint Final de cada banca traz o acesso aos últimos anos de questões comentadas da prova.',
				<br />,
				<br />,
				'No Sprint Final Revalida, temos todas as questões do INEP comentadas desde 2011.',
			],
		},
		// {
		// 	id: 8,
		// 	question: 'Até quando terei acesso ao Sprint Final Revalida INEP? ',
		// 	answer: 'Até o dia seguinte à realização da prova.',
		// },
		// {
		// 	id: 9,
		// 	question: 'Terei acesso direto aos professores para sanar minhas dúvidas?',
		// 	answer: 'Sim! Os alunos do Hardwork têm acesso direto aos professores e podem sanar eventuais dúvidas de acordo com a respectiva área. Esses contatos são disponibilizados na plataforma, na ferramenta ´Time Hardwork´.',
		// },
		// {
		// 	id: 10,
		// 	question: 'Como posso acessar o Sprint Final?',
		// 	answer: 'O treinamento pode ser acessado pelo desktop, navegadores de tablet e smartphones e através do aplicativo próprio. ',
		// },
		// {
		// 	id: 11,
		// 	question: 'Preciso estar conectado à internet para acessar os treinamentos do Sprint Final?',
		// 	answer: 'Sim. É fundamental. O treinamento não pode ser feito offline por uma razão muito simples: todos os nossos passos nas áreas de treinamento são monitorizados pela plataforma para personalização das revisões futuras.',
		// },
	],
};

export default data;
