import React, { useEffect, useRef, useState } from 'react';
import { Container, Divider, Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

// COMPONENTS
import Footer from '../../../Components/Footer';
import { Header } from '../../../Components/Header';
import DefaultButton from '../../../Components/Buttons/DefaultButton';
import Form from '../../../Components/Utils/Forms/PreRegistration';
import { Link } from 'react-router-dom';
import Api from '../../../Services/Api';

const LiveReactCard = ({ classes, exam, correctionGroupInfo }) => {
	function dateFormatWithTime(date) {
		const data = new Date(date);
		const diasDaSemana = [
			'DOMINGO',
			'SEGUNDA-FEIRA',
			'TERÇA-FEIRA',
			'QUARTA-FEIRA',
			'QUINTA-FEIRA',
			'SEXTA-FEIRA',
			'SÁBADO',
		];
		const diaDaSemana = diasDaSemana[data.getDay()];
		const dia = data.getDate();
		const mesesEmPortugues = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];
		const mes = mesesEmPortugues[data.getMonth()];
		const hora = data.getHours();

		// Formate o dia para adicionar um "0" à esquerda, se for menor que 10
		const diaFormatado = dia < 10 ? `0${dia}` : dia;

		// Formate a hora para adicionar um "0" à esquerda, se for menor que 10
		const horaFormatada = hora < 10 ? `0${hora}` : hora;

		// Crie a string final no formato "dia da semana • dia mes • hora"
		const dataHoraFormatada = `${diaDaSemana} • ${diaFormatado}${mes} • ${horaFormatada}h`;

		return dataHoraFormatada;
	}

	return (
		<Paper
			className={`${classes.paper} rounded-card box box-primary`}
			elevation={4}
		>
			<Grid
				container
				rowGap={1}
			>
				<Grid
					item
					container
					justifyContent="center"
					style={{ padding: '0.5em 1em' }}
				>
					<img
						src={require('../../../Assets/Extensivo/R1/2023/logo-correction-live.svg').default}
						alt="Logo Live Correção"
					/>
				</Grid>
				<Grid
					item
					container
					alignContent="center"
					rowGap={2}
				>
					<Typography
						align="left"
						color="secondary"
						className={`${classes.text} poppins`}
					>
						Correção ao vivo da prova, com comentários das questões mais polêmicas{' '}
						{window.location.pathname === '/live/correcao-santa-casa-sp-123' ? 'da' : 'do'}{' '}
						{exam.toUpperCase()} e download de recursos em PDF!
					</Typography>
					<Typography
						align="left"
						color="secondary"
						className={`${classes.impactText} poppins`}
						style={{ padding: '0 10px' }}
					>
						{dateFormatWithTime(correctionGroupInfo?.data_correcao)}
					</Typography>
				</Grid>
			</Grid>
		</Paper>
	);
};

const ProductCard = ({ backgroundImg, logo, description, buttonText, urlRedirect }) => {
	const classes = useStyles();

	return (
		<Paper
			className={`${classes.scriptPaper} rounded-card`}
			color="secondary"
			elevation={4}
		>
			<Grid
				container
				justifyContent={'center'}
				alignContent={'center'}
				className={classes.gridImage}
				style={{ backgroundImage: `url(${backgroundImg})`, backgroundSize: 'cover' }}
			>
				<img
					src={logo}
					alt=""
					style={{ height: '100%' }}
				/>
			</Grid>
			<Grid
				container
				justifyContent={'center'}
				className={classes.gridDescriptionScriptCard}
			>
				<Typography
					align="left"
					color="primary"
					className={`${classes.scriptText} poppins`}
				>
					{description}
				</Typography>
			</Grid>
			<Grid
				item
				xs={12}
				container
				justifyContent={'flex-end'}
				className={`${classes.gridButton} residencia`}
			>
				<DefaultButton
					variant="outlined"
					color="primary"
					className={`${classes.btn} white-btn-secondary poppins`}
					component={Link}
					to={urlRedirect}
				>
					{buttonText}
				</DefaultButton>
			</Grid>
		</Paper>
	);
};

export default function CorrectionLiveResidencia() {
	const classes = useStyles();
	const theme = useTheme();
	const embeddedContent = useRef(null);
	const [exam, setExam] = useState('');
	const [id, setId] = useState(0);
	const [correctionGroupInfo, setCorrectionGroupInfo] = useState([]);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		const path = window.location.pathname;
		const parts = path.split('-');
		const currentId = parts[parts.length - 1];
		setId(currentId);

		if (id > 0) {
			Api.get(`provasCorrecao`).then((res) => {
				const groups = res;
				const currentGroup = groups.find((group) => {
					return group?.id.toString() === id;
				});
				setCorrectionGroupInfo(currentGroup);
			});
		}
	}, [id]);

	useEffect(() => {
		const path = window.location.pathname;

		let lastPart;

		if (
			path.includes('psu-go') ||
			path.includes('psu-mg') ||
			path.includes('usp-sp') ||
			path.includes('sus-ba') ||
			path.includes('usp-rp')
		) {
			const parts = path.split('-');
			lastPart = parts[parts.length - 3] + '-' + parts[parts.length - 2];
		} else if (path.includes('santa-casa-sp')) {
			lastPart = 'Santa Casa SP';
		} else {
			const parts = path.split('-');
			lastPart = parts[parts.length - 2];
		}
		setExam(lastPart);
	}, []);

	return (
		<Container
			fixed
			maxWidth="xl"
			disableGutters={true}
		>
			{matchesMobile || matchesTablet ? null : <Header />}
			<Grid
				container
				className="section"
				style={matchesMobile ? { marginTop: 80 } : { marginBottom: 0 }}
			>
				<Grid
					item
					xs={12}
					md={9}
					container
					justifyContent="center"
				>
					<img
						src={require('../../../Assets/Extensivo/R1/2023/live-react-banner-residencia.png')}
						alt=""
					/>
				</Grid>
				<Grid
					item
					xs={12}
					md={4}
					lg={6}
					className={classes.firstSectionGridCard}
					container
					justifyContent="flex-end"
					alignItems="center"
				>
					{exam && (
						<LiveReactCard
							classes={classes}
							exam={exam}
							correctionGroupInfo={correctionGroupInfo}
						/>
					)}
				</Grid>
				<Grid
					item
					xs={12}
					container
					direction={'column'}
					style={matchesMobile ? { marginBottom: 16 } : { marginTop: '2em' }}
				>
					<Typography
						className={`${classes.subtitle1} roboto-condensed`}
						color="secondary"
					>
						Correção da prova{' '}
						{window.location.pathname === '/live/correcao-santa-casa-sp-123' ? 'da' : 'do'}{' '}
						{exam.toUpperCase()} de 2023
					</Typography>
					<Typography
						className={`${classes.subtitle2} poppins`}
						color="secondary"
					>
						Inscreva-se ou faça login para acompanhar a correção de prova no player abaixo!
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
				>
					<Divider
						variant="middle"
						className={classes.divider}
					/>
				</Grid>
				{id > 0 && (
					<Grid
						item
						xs={12}
						ref={embeddedContent}
					>
						<iframe
							id={`iframe-correcao`}
							title="correcao"
							src={`https://hardworkmedicina.com.br/correcao-prova/${id}`}
							style={{
								border: 0,
								width: '100%',
								height: matchesMobile ? '600px' : '830px',
								backgroundColor: 'transparent',
							}}
						></iframe>
					</Grid>
				)}
			</Grid>
			<Grid
				container
				justifyContent={'center'}
				className="section"
				style={{ marginTop: 0 }}
			>
				<Grid
					item
					xs={12}
				>
					<Divider
						variant="middle"
						className={classes.divider}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					md={5}
					container
					justifyContent="center"
					style={{ margin: '2rem 0 3rem' }}
				>
					<Form
						group={'LIVE DE CORREÇÃO'}
						title={['Inscreva-se para ter acesso ao', <br />, 'gabarito e live de correção!']}
						titleButton="Confirmar inscrição"
						titleConfirmationMessage={'Massa!'}
						confirmationMessage={
							'Sua inscrição está confirmada! Acesse o player da live acima com o e-mail cadastrado.'
						}
						type="residencia"
						urlToRedirect={`/live/correcao-${
							window.location.pathname === '/live/correcao-santa-casa-sp-123' ? 'santa-casa-sp' : exam
						}-${id}`}
						exam={exam}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					container
					justifyContent={'space-evenly'}
				>
					<Grid
						item
						xs={12}
						lg={4}
					>
						<ProductCard
							backgroundImg={require('../../../Assets/Extensivo/R1/2023/bg-ldl-card.png')}
							logo={
								require('../../../Assets/Extensivo/R1/2023/liveDasLivesResidencia-product-card.svg')
									.default
							}
							description="A revisão de véspera que só o Hardwork consegue fazer! Uma multidão de hardworkers conectados num evento presencial que é uma grande experiência!"
							buttonText={'Inscrições'}
							urlRedirect={'/live-das-lives'}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						lg={4}
					>
						<ProductCard
							backgroundImg={require('../../../Assets/Extensivo/R1/2023/bg-contest-central-product-card.png')}
							logo={
								require('../../../Assets/Extensivo/R1/2023/logo-contest-central-product-card.svg')
									.default
							}
							description="As informações mais importantes sobre os principais concursos de Residência Médica do Brasil."
							buttonText={'Saiba mais'}
							urlRedirect={'/materiais/central-de-concursos'}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Footer />
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	paper: {
		width: 462,
		[theme.breakpoints.down('md')]: {
			width: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '1.2em',
		},
	},
	firstSectionGridCard: {
		position: 'absolute',
		right: 0,
		top: 20,
		[theme.breakpoints.down('md')]: {
			position: 'unset',
			margin: '16px 0px !important',
		},
	},
	text: {
		fontSize: '1.25rem !important',
		lineHeight: '1.25 !important',
		marginTop: '8px !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.35rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	divider: {
		margin: '24px 0px !important',
		backgroundColor: 'grey',
		minHeight: 1,
	},
	subtitle1: {
		fontSize: '2.5rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
			lineHeight: 'unset !important',
			marginBottom: '8px !important',
		},
	},
	subtitle2: {
		fontSize: '1rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	impactText: {
		margin: '0 0 2rem !important',
		fontSize: '1.5rem !important',
		lineHeight: 'unset !important',
		background: 'linear-gradient(90deg, rgb(255 72 0) 10%, rgb(255 0 66) 100%)',
		borderRadius: 5,
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down(361)]: {
			fontSize: '1.25rem !important',
		},
	},
	scriptPaper: {
		height: '100%',
		[theme.breakpoints.down('xl')]: {
			minHeight: 'unset',
		},
		[theme.breakpoints.down('lg')]: {
			height: 'unset',
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
			width: 'unset',
			minHeight: 'unset',
			margin: '16px 0px',
		},
	},
	scriptTitle: {
		fontSize: '3.125rem !important',
		fontWeight: 'bold !important',
		position: 'absolute',
		bottom: 10,
		left: 25,
		textShadow: '0 0 0.2em #121213, 0 0 0.2em #0c0c0d',
		[theme.breakpoints.down('lg')]: {
			left: 25,
			fontSize: '2.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem !important',
			left: 17,
		},
	},
	gridDescriptionScriptCard: {
		backgroundColor: '#FFFFFF !important',
		padding: '1.25rem',
		borderRadius: 16,
		height: '50%',
		[theme.breakpoints.down('lg')]: {
			padding: '1em 1.5em',
		},
		[theme.breakpoints.down('md')]: {
			height: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '1em',
			borderTopRightRadius: 0,
			borderTopLeftRadius: 0,
		},
	},
	scriptText: {
		fontSize: '1rem !important',
		overflowY: 'auto',
		paddingRight: 20,
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
			maxHeight: 175,
			minHeight: 'unset !important',
		},
	},
	gridImage: {
		backgroundColor: '#000000 !important',
		borderTopRightRadius: 15,
		borderTopLeftRadius: 15,
		padding: '2rem',
		height: '50%',
	},
	gridButton: {
		// position: 'fixed',
		bottom: 25,
		left: '-5%',
		[theme.breakpoints.down('lg')]: {
			bottom: -17,
		},
		[theme.breakpoints.down('sm')]: {
			left: '-7%',
			bottom: -15,
		},
	},
	btn: {
		fontSize: '1rem !important',
		width: '50%',
		fontWeight: 'bold !important',
		'&:hover': {
			scale: 1.05,
			color: '#ffffff !important',
			'& $logoBtn': {
				filter: 'invert(0)',
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.75rem !important',
			minHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem !important',
			width: '50%',
		},
	},
}));
