import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import transmissionLive from '../../../Pages/LandingPages/Subscription/Residencia/assets/live-transmission.png';

const InfoBox = ({ title, subtitle, img }) => {
	const classes = useStyles();

	return (
		<Grid
			item
			xs={12}
			container
			justifyContent={'space-between'}
			alignItems={'center'}
		>
			<Grid
				item
				xs={3}
				md={2}
			>
				<img
					src={img}
					alt=""
				/>
			</Grid>
			<Grid
				item
				xs={8.5}
				md={9.5}
				container
				style={{ height: 'fit-content' }}
			>
				<Typography
					align="left"
					color="primary"
					className={`${classes.titleBox}`}
					fontFamily={'Manrope'}
				>
					{title}
				</Typography>
				<Typography
					align="left"
					color="primary"
					className={`${classes.boxText}`}
					fontFamily={'Manrope'}
				>
					{subtitle}
				</Typography>
			</Grid>
		</Grid>
	);
};

export default function HandsOn() {
	const classes = useStyles();
	const theme = useTheme();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Grid
			container
			rowGap={2}
		>
			{matchesTablet && (
				<img
					src={require('../../../Pages/LandingPages/Subscription/Residencia/assets/logo-hands-on-mobile.png')}
					alt=""
					style={{ width: matchesMobile && '80%', margin: matchesTablet && '0 auto' }}
				/>
			)}
			<Typography
				align="left"
				color="primary"
				className={`${classes.title}`}
				fontFamily={'Manrope'}
			>
				HARDWORK HANDS-ON
				<br />
				POCUS E VENTILAÇÃO
			</Typography>
			<Typography
				align="left"
				color="primary"
				className={`${classes.subtitle}`}
				fontFamily={'Manrope'}
				style={{ marginBottom: !matchesTablet && '1.5rem' }}
			>
				O maior treinamento de habilidades online da história
			</Typography>
			<Typography
				align="left"
				color="primary"
				className={`${classes.text}`}
				fontFamily={'Manrope'}
			>
				Treinamento intenso com foco em POCUS (ultrassonografia Point of Care) e suporte ventilatório,
				explorando todos os conceitos essenciais para a vida prática e para as provas.
			</Typography>
			<Typography
				align="left"
				color="primary"
				className={`${classes.impactText}`}
				fontFamily={'Manrope'}
				style={{ margin: matchesTablet ? '1rem 0' : '1.5rem 0 2rem' }}
			>
				28 SET • A PARTIR DAS 8H00 EVENTO ONLINE E GRATUITO
			</Typography>
			<Grid
				item
				xs={12}
				container
				rowGap={4}
			>
				<InfoBox
					title="Transmissão ao vivo"
					subtitle="Acompanhe simultaneamente a transmissão da aula de Habilidades Clínicas, ministrada
                                        por nossos professores, com a participação de nossos alunos nas estações práticas."
					img={transmissionLive}
				/>
				{/* <InfoBox
							title="Download de materiais"
							subtitle="Baixe aqui o material personalizado que preparamos especialmente para você."
							img={materialsDownloadImg}
						/> */}
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: '800 !important',
		fontSize: '2.5rem !important',
		lineHeight: '1 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
		},
	},
	subtitle: {
		fontSize: '1.25rem !important',
		lineHeight: '1.25 !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	text: {
		fontSize: '1.25rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	impactText: {
		fontWeight: '800 !important',
		fontSize: '1.5rem !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '0.8rem !important',
		},
	},
	titleBox: {
		fontWeight: 'bold !important',
		fontSize: '1.5rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.25rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1rem !important',
		},
	},
	boxText: {
		fontSize: '1rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.75rem !important',
		},
	},
}));
