import React, { useState } from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Helmet } from 'react-helmet';

// COMPONENTS
import Footer from '../Components/Footer';
import { Header } from '../Components/Header';
import StudentsFeedbackSection from '../Components/Sections/StudentsFeedback';
import SaibaMaisCard from '../Components/Cards/SaibaMaisCard';
// import Newsletter from '../Components/Utils/Newsletter';
// import DoctorCard from '../Components/Cards/DoctorCard';
// import BlogSection from '../Components/Sections/Blog';
// import TeachingMetodologySection from '../Components/Sections/TeachingMetodology';

export default function ResidenciaMedica2023() {
	const classes = useStyles();
	// const doctorsCarousel = useRef(null);
	const theme = useTheme();
	const [now] = useState(new Date());
	const [releaseTrilhaR12425] = useState(new Date('2024-02-27T20:24:00'));
	const finishTrilhaMaio = new Date('2024-05-31T23:24:59');

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	// const handleLeftClickDoctors = (e) => {
	// 	e.preventDefault();

	// 	doctorsCarousel.current.scrollLeft -= doctorsCarousel.current.offsetWidth;
	// };

	// const handleRightClickDoctors = (e) => {
	// 	e.preventDefault();

	// 	doctorsCarousel.current.scrollLeft += doctorsCarousel.current.offsetWidth - 60;
	// };

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			<Helmet>
				<title>Residência Médica - Hardwork Medicina</title>
				<meta
					name="description"
					content="Tudo que tu precisas para conquistar a vaga de Residência dos teus sonhos"
				/>
			</Helmet>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				{/* <Grid
					container
					alignContent="center"
					justifyContent="center"
					className={`${matchesMobile || matchesTablet ? classes.sectionMobile : classes.section}`}
					style={matchesMobile ? { marginTop: 60 } : null}
				>
					<Grid
						item
						xs={12}
						md={8}
						container
						justifyContent={matchesMobile || matchesTablet ? 'center' : 'flex-end'}
						alignContent="center"
					>
						<img
							src={LiveDasLivesLogo}
							alt=""
							className={classes.liveDasLivesLogo}
						/>
						<img
							src={LiveDasLivesUnicamp}
							alt=""
							className={classes.img}
						/>
						<Grid
							item
							xs={12}
							container
							className={`${classes.gridButton} residencia`}
						>
							<DefaultButton
								variant="outlined"
								color="secondary"
								className={`${classes.btn} grid-btn-subscribe btn btn-secondary`}
							>
								Inscreva-se agora!
							</DefaultButton>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						container
						justifyContent={matchesMobile || matchesTablet ? 'center' : 'flex-start'}
						alignContent="center"
					>
						<Paper
							color="primary"
							className={`${classes.firstSectionPaper} box box-primary`}
							elevation={4}
						>
							<Grid
								container
								justifyContent="center"
							>
								{matchesMobile || matchesTablet ? (
									<Typography
										align="left"
										color="secondary"
										className={`${classes.firstSectionCardText} residencia-texts poppins`}
									>
										A prova do SUS exige uma Live padrão SUS!
										<br />
										<br />
										E é assim com todas as Lives das Lives. Um show de
										<br />
										véspera.
										<br />
										<br />
										Uma experiência presencial e MULTISSENSORIAL com
										<br />
										quem domina a alma dos principais concursos do país.
										<br />
										<br />
										5 blocos diferentes que vão trazer desde a cara da
										<br />
										prova com abordagem específica dos temas até as
										<br />
										nossas apostas para o concurso atual.
									</Typography>
								) : (
									// <Typography
									// 	align="left"
									// 	color="secondary"
									// 	className={`${classes.firstSectionCardText} residencia-texts poppins`}
									// >
									// 	A prova do SUS exige uma Live
									// 	<br />
									// 	padrão SUS! <br />
									// 	E é assim com todas as Lives
									// 	<br />
									// 	das Lives. Um show de
									// 	<br />
									// 	véspera.
									// 	<br />
									// 	<br />
									// 	Uma experiência presencial e<br />
									// 	MULTISSENSORIAL com quem
									// 	<br />
									// 	domina a alma dos principais
									// 	<br />
									// 	concursos do país.
									// 	<br />
									// 	<br />
									// 	5 blocos diferentes que vão
									// 	<br />
									// 	trazer desde a cara da prova
									// 	<br />
									// 	com abordagem específica
									// 	<br />
									// 	dos temas até as nossas
									// 	<br />
									// 	apostas para o concurso
									// 	<br />
									// 	atual.
									// </Typography>
									<Typography
										align="left"
										color="secondary"
										className={`${classes.firstSectionCardText} residencia-texts poppins`}
									>
										A prova do SUS exige uma Live
										<br />
										padrão SUS!
										<br />
										<br />
										E é assim com todas as Lives das
										<br />
										Lives.
										<br />
										Um show de véspera.
										<br />
										<br />
										Uma experiência presencial e<br />
										MULTISSENSORIAL com quem
										<br />
										domina a alma dos principais
										<br />
										concursos do país.
										<br />
										<br />
										5 blocos diferentes que vão
										<br />
										trazer desde a cara da prova
										<br />
										com abordagem específica
										<br />
										dos temas até as nossas
										<br />
										apostas para o concurso
										<br />
										atual.
									</Typography>
								)}
							</Grid>
						</Paper>
					</Grid>
				</Grid> */}
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className={matchesMobile ? classes.sectionMobile : classes.section}
					style={matchesMobile ? { padding: '2vh 1em 4vh', marginTop: 70 } : null}
				>
					<Grid
						item
						xs={12}
						container
					>
						<Grid
							item
							xs={12}
							container
							className="residencia"
						>
							<Typography
								variant="h1"
								className={`${classes.title} gradient-text roboto-condensed`}
							>
								Tudo que tu precisas para conquistar a vaga de
								<br />
								Residência dos teus sonhos
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							style={matchesMobile ? { padding: '10px 0 1em' } : { padding: '1em 0 2.5em' }}
						>
							{matchesMobile ? (
								<Typography
									align="left"
									color="secondary"
									className={`${classes.text} residencia-texts poppins`}
								>
									Nós temos os melhores professores, as melhores aulas, mas isso não basta! Para
									atingir resultados excepcionais tu precisas da melhor metodologia, criada com o
									ingrediente mais importante de todos: questões, MUITAS questões!
								</Typography>
							) : (
								<Typography
									align="left"
									color="secondary"
									className={`${classes.text} residencia-texts poppins`}
								>
									Nós temos os melhores professores, as melhores aulas, mas isso não basta!
									<br />
									Para atingir resultados excepcionais tu precisas da melhor metodologia,
									<br />
									criada com o ingrediente mais importante de todos: questões, MUITAS questões!
								</Typography>
							)}
						</Grid>
						<Grid
							item
							xs={12}
							md={9}
							spacing={matchesMobile ? 1 : 0}
							container
							rowGap={matchesMobile ? 1 : 6}
							className={classes.gridContainerSaibaMais}
						>
							{/* <Grid
								item
								container
								alignContent="center"
								xs={6}
								md={4}
								className={`${matchesMobile ? classes.gridInfoCardMobile : null} `}
							>
								<SaibaMaisCard
									type="residencia"
									name="RPlus"
									description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy"
									url="/trilha-rplus-2024"
								/>
							</Grid> */}
							{/* <Grid
								item
								container
								alignContent="center"
								xs={6}
								md={4}
								className={`${matchesMobile ? classes.gridInfoCardMobile : null} `}
							>
								<SaibaMaisCard
									type="residencia"
									name="Trilha R1 Residência"
									description="O TRILHA R1 é o novo Extensivo Hardwork. É a maior revolução da história de preparação para as provas de residência. Aqui te conduzimos na trilha do aprendizado definitivo. Dominamos tema por tema, te preparando para qualquer prova do Brasil."
									url="/trilha-r1-2024"
								/>
							</Grid> */}
							{/* TRILHA R1 MAIO */}
							{/* <Grid
								item
								container
								alignContent="center"
								xs={6}
								md={4}
								className={`${matchesMobile ? classes.gridInfoCardMobile : null} `}
							>
								<SaibaMaisCard
									type="residencia"
									name="Trilha R1 Residência"
									description="O TRILHA R1 é o novo Extensivo Hardwork. É a maior revolução da história de preparação para as provas de residência. Aqui te conduzimos na trilha do aprendizado definitivo. Dominamos tema por tema, te preparando para qualquer prova do Brasil."
									url="/trilha-r1-2024"
								/>
							</Grid> */}
							<Grid
								item
								xs={6}
								md={4}
								className={`${matchesMobile ? classes.gridInfoCardMobile : null} `}
								container
								alignContent="center"
							>
								<SaibaMaisCard
									type="residencia"
									name="Sprint Final Hardwork"
									description="O Sprint te faz entrar na alma do concurso, na mente do examinador! É o primeiro e único modelo de revisão totalmente focado em cada uma das provas! Tudo que tu precisas na reta final de preparação."
									// url="/sprint-final"
									url="/sprint-final"
								/>
							</Grid>
							<Grid
								item
								xs={6}
								md={4}
								className={`${matchesMobile ? classes.gridInfoCardMobile : null} `}
								container
								alignContent="center"
							>
								<SaibaMaisCard
									type="residencia"
									name="Trilha R3 CG"
									description="O primeiro e único intensivo específico para as provas de especialidades cirúrgicas da USP ou do ENARE."
									url="/hardworq/cirurgia"
								/>
							</Grid>

							{/* <Grid
								item
								container
								alignContent="center"
								xs={6}
								md={4}
								className={`${matchesMobile ? classes.gridInfoCardMobile : null} `}
							>
								<SaibaMaisCard
									type="residencia"
									name="R1Maio"
									description="O TRILHA R1 é o novo Extensivo Hardwork. É a maior revolução da história de preparação para as provas de residência. Aqui te conduzimos na trilha do aprendizado definitivo. Dominamos tema por tema, te preparando para qualquer prova do Brasil."
									url="/trilha-r1-2024-turma-maio"
								/>
							</Grid> */}
							<Grid
								item
								xs={6}
								md={4}
								className={`${matchesMobile ? classes.gridInfoCardMobile : null} `}
								container
								alignContent="center"
							>
								<SaibaMaisCard
									type="residencia"
									name="Trilha R3 CM"
									description="A preparação Hardwork para as provas de especialidades clínicas agora é o Trilha R3 Clínica Médica. Dominamos tema por tema e te conduzimos pela trilha do aprendizado definitivo."
									url="/trilha-r3-clinica-medica"
								/>
							</Grid>
							<Grid
								item
								xs={6}
								md={4}
								className={`${matchesMobile ? classes.gridInfoCardMobile : null} `}
								container
								alignContent="center"
							>
								<SaibaMaisCard
									type="residencia"
									name="Lives"
									description="A revisão de véspera que só o Hardwork consegue fazer! Uma multidão de hardworkers conectados num evento presencial que é uma grande experiência!"
									url="/live-das-lives"
								/>
							</Grid>
							{/* <Grid
								item
								xs={6}
								md={4}
								className={`${matchesMobile ? classes.gridInfoCardMobile : null} `}
								container
								alignContent="center"
							>
								<SaibaMaisCard
									type="residencia"
									name="Sprint Final USP R3"
									description={[
										'O Sprint R3 é tudo que tu precisas na reta final de preparação para as provas de especialidades clínicas da USP.',
										<br />,
										<br />,
										'O Sprint é a revisão que retrata a mente da banca.',
									]}
									url="/sprint-final-r3"
								/>
							</Grid> */}
							<Grid
								item
								xs={6}
								md={4}
								className={`${matchesMobile ? classes.gridInfoCardMobile : null} `}
								container
								alignContent="center"
							>
								<SaibaMaisCard
									type="residencia"
									name="Trilha R1 2 ANOS"
									description="Vai fazer a prova no final de 2025? Comece a se preparar com a trilha do aprendizado definitivo e estude por dois anos. O Extensivo Hardwork agora é Trilha R1, a maior revolução na preparação para as provas de residência."
									url={
										// now >= releaseTrilhaR12425
										'/trilha-r1-2024-2025'
										// : '/pre-inscricao/trilha-residencia-2-anos'
									}
								/>
							</Grid>
							<Grid
								item
								container
								alignContent="center"
								xs={6}
								md={4}
								className={`${matchesMobile ? classes.gridInfoCardMobile : null} `}
							>
								<SaibaMaisCard
									type="residencia"
									name="Trilha R1 Residência Express"
									description="O Trilha Express alinha as maiores revoluções Hardwork: o estudo por questões do
									Extensivo R1 e o Sprint Final, a revisão focada em cada banca.​"
									url="/trilha-r1-express-2024"
								/>
							</Grid>
							{matchesMobile ? (
								<Grid
									item
									xs={6}
									md={4}
									className={`${matchesMobile ? classes.gridInfoCardMobile : null} `}
									container
									alignContent="center"
								>
									<img
										src={process.env.PUBLIC_URL + '/Assets/Img/fabricioImageMobile.png'}
										alt="Fabrício Valois"
										style={matchesMobile ? { bottom: '-35px' } : { bottom: '-19%' }}
									/>
								</Grid>
							) : null}
							{/* <Grid
								item
								xs={6}
								md={4}
								className={`${matchesMobile ? classes.gridInfoCardMobile : null} `}
								container
								alignContent="center"
							>
								<SaibaMaisCard
									type="residencia"
									name="Extensivo R3"
									description="Vamos contigo na preparação para as provas de especialidades clínicas, explorando todas as peculiaridades das provas de R3."
									url="/extensivo-r3-clinica-medica"
								/>
							</Grid> */}
							{/* <Grid
								item
								xs={6}
								md={4}
								className={`${matchesMobile ? classes.gridInfoCardMobile : null} `}
								container
								alignContent="center"
							>
								<SaibaMaisCard
									type="residencia"
									name="Extensivo Cirurgia"
									description="A prova de R3 de cirurgia exige uma preparação específica! Toda a profundidade exigida é dominada no Extensivo R3."
									url="//hardworkmedicina.com.br/pre-inscricao/extensivo-r3cg-2023"
								/>
							</Grid> */}
						</Grid>
						{matchesMobile || matchesTablet ? null : (
							<Grid
								item
								alignContent="center"
								xs={3}
								className={classes.gridFabricioImg}
							>
								<img
									src={process.env.PUBLIC_URL + '/Assets/Img/fabricioImage1.png'}
									alt="Fabrício Valois"
								/>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Container>
			{/* TO DO DEPOIS
			<TeachingMetodologySection type="residencia" /> */}
			{/* <Grid
                container
                alignContent="center"
                justifyContent="center"
                className={classes.section}
            >
                <Grid item xs={12} container ref={doctorsCarousel}>
                <Grid item xs={12} container className="residencia">
                    <Typography
                    className={`${classes.title} gradient-text roboto-condensed`}
                    >
                    Time Hardwork Medicina
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ paddingBottom: "2.5em" }}>
                    <Typography
                    align="left"
                    color="secondary"
                    className={`${classes.text} residencia-texts poppins`}
                    >
                    Aliquam sit amet augue non augue ornare pellentesque. Aenean
                    <br />
                    euismod suscipit tortor dignissim aliquam.
                    </Typography>
                </Grid>
                </Grid>
                <Grid item xs={12} container>
                <Grid item xs={7} container justifyContent="flex-start">
                    <Grid item xs={5} style={{ margin: "0 2em 0 2.5em" }}>
                    <DoctorCard
                        name="Fabrício Valois"
                        specialty="Pneumologia"
                        type="residencia"
                        insta="@fabricio.valois"
                    />
                    </Grid>
                    <Grid item xs={5}>
                    <DoctorCard
                        name="Fábio Colagrossi"
                        specialty="Cirurgia Geral"
                        type="residencia"
                        insta="@drfabiocolagrossi"
                    />
                    </Grid>
                </Grid>
                <Grid item xs={5}>
                    <Paper
                    className={`${classes.ourHistoryCard} rounded-card`}
                    elevation={4}
                    >
                    <Grid
                        container
                        justifyContent="center"
                        style={{
                        height: "100%",
                        padding: "1.5em 2em",
                        }}
                    >
                        <Grid item xs={12} className="residencia">
                        <Typography
                            align="left"
                            className={`${classes.ourHistoryCardTitle} residencia gradient-text roboto-condensed`}
                        >
                            Nossa história
                        </Typography>
                        </Grid>
                        <Grid
                        item
                        xs={12}
                        style={{ backgroundColor: "#fff", height: "38%" }}
                        ></Grid>
                        <Grid item xs={12}>
                        <Typography
                            className={`${classes.textFontSize18} poppins`}
                            color="secondary"
                            gutterBottom
                        >
                            Fusce accumsan nunc eget enim placerat, non molestie ipsum
                            <br />
                            ullamcorper. Phasellus at dictum nisl. Cras maximus
                            pellentesque
                            <br />
                            quam, eget lobortis nunc iaculis non.
                        </Typography>
                        </Grid>
                        <Grid
                        item
                        xs={4}
                        container
                        alignContent="flex-end"
                        className="residencia"
                        >
                        <DefaultButton
                            variant="outlined"
                            color="secondary"
                            className="btn btn-secondary"
                            style={{ fontWeight: "bold" }}
                        >
                            Conheça
                        </DefaultButton>
                        </Grid>
                    </Grid>
                    </Paper>
                </Grid>
                </Grid>
            </Grid> */}
			<StudentsFeedbackSection type="residencia" />
			<Container
				fixed
				maxWidth="xl"
				// maxWidth={false}
				disableGutters={true}
			>
				{/* <BlogSection type="residencia" />    */}
				{/* <Newsletter type="residencia" /> */}
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.75rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.75rem !important',
		},
	},
	text: {
		fontSize: '1.324rem !important',
		lineHeight: '1.3 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.95rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
			lineHeight: '1.2 !important',
		},
	},
	// firstSectionCardText: {
	// 	fontSize: '1.2rem !important',
	// 	lineHeight: '1.3 !important',
	// 	[theme.breakpoints.down('lg')]: {
	// 		fontSize: '0.95rem !important',
	// 	},
	// 	[theme.breakpoints.down('sm')]: {
	// 		fontSize: '0.75rem !important',
	// 		lineHeight: '1.2 !important',
	// 	},
	// },
	// img: {
	// 	height: '96%',
	// 	width: '96%',
	// },
	// liveDasLivesLogo: {
	// 	position: 'absolute',
	// 	zIndex: 1,
	// 	top: '5em',
	// 	left: '1em',
	// 	[theme.breakpoints.down('lg')]: {
	// 		width: 235,
	// 		left: '2em',
	// 	},
	// 	[theme.breakpoints.down('sm')]: {
	// 		top: '15%',
	// 		width: '55%',
	// 	},
	// },
	// gridButton: {
	// 	position: 'absolute',
	// 	bottom: '2.5em',
	// 	[theme.breakpoints.down('sm')]: {
	// 		position: 'relative',
	// 		bottom: '2.5em',
	// 	},
	// },
	// btn: {
	// 	fontSize: '1.15rem !important',
	// 	width: '38%',
	// 	left: '4em',
	// 	textTransform: 'unset !important',
	// 	fontWeight: 'bold !important',
	// 	[theme.breakpoints.down('lg')]: {
	// 		fontSize: '0.75rem !important',
	// 		minHeight: 'unset !important',
	// 	},
	// 	[theme.breakpoints.down('sm')]: {
	// 		fontSize: '0.9rem !important',
	// 		width: '197px',
	// 		left: '2em',
	// 	},
	// },
	// firstSectionPaper: {
	// 	height: '498px',
	// 	borderRadius: '30px !important',
	// 	padding: '1.5rem 1rem',
	// 	display: 'flex',
	// 	alignItems: 'center',
	// 	justifyContent: 'center',
	// 	width: '85%',
	// 	[theme.breakpoints.down('xl')]: {
	// 		height: 'unset !important',
	// 	},
	// 	[theme.breakpoints.down('md')]: {
	// 		minHeight: 'unset !important',
	// 	},
	// 	[theme.breakpoints.down('sm')]: {
	// 		padding: '1rem !important',
	// 		height: 'unset !important',
	// 		width: '100%',
	// 	},
	// },
	// ourHistoryCard: {
	// 	backgroundColor: '#0D0D0D !important',
	// 	height: '100%',
	// },
	// ourHistoryCardTitle: {
	// 	fontWeight: 'bold !important',
	// 	fontSize: '1.75em !important',
	// 	lineHeight: '1 !important',
	// },
	// textFontSize18: {
	// 	fontSize: '.85rem !important',
	// },
	sectionMobile: {
		padding: '2vh 3%',
	},
	gridContainerSaibaMais: {
		[theme.breakpoints.down('xl')]: {
			height: '80% !important',
		},
		[theme.breakpoints.down('lg')]: {
			height: '80% !important',
		},
		[theme.breakpoints.down('md')]: {
			maxHeight: 'unset !important',
			height: '1000px !important',
		},
		[theme.breakpoints.down('sm')]: {
			maxHeight: 'unset !important',
			height: 'unset !important',
		},
	},
	gridFabricioImg: {
		paddingTop: '5%',
		[theme.breakpoints.down('xl')]: {
			paddingTop: 'unset !important',
		},
	},
	gridInfoCardMobile: {
		[theme.breakpoints.down('sm')]: {
			height: '310px !important',
		},
	},
}));
