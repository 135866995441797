import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Header } from '../../../../Components/Header';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import TableHwm from './components/Table';
import FilterContainer from './components/FilterContainer';
import Api from '../../../../Services/Api';
import { Helmet } from 'react-helmet';
import AccordionNews from './components/AccordionNews';

const allLocations = [
	'Acre',
	'Alagoas',
	'Amapá',
	'Amazonas',
	'Bahia',
	'Ceará',
	'Distrito Federal',
	'Espírito Santo',
	'Goiás',
	'Maranhão',
	'Mato Grosso',
	'Mato Grosso do Sul',
	'Minas Gerais',
	'Pará',
	'Paraíba',
	'Paraná',
	'Pernambuco',
	'Piauí',
	'Rio de Janeiro',
	'Rio Grande do Norte',
	'Rio Grande do Sul',
	'Rondônia',
	'Roraima',
	'Santa Catarina',
	'São Paulo',
	'Sergipe',
	'Tocantins',
];

const allSpecialties = [
	'acupuntura',
	'alergia e imunologia',
	'anestesiologia',
	'cirurgia cardiovascular',
	'cirurgia geral',
	'clínica médica',
	'dermatologia',
	'genética médica',
	'ginecologia e obstetrícia',
	'homeopatia',
	'infectologia',
	'medicina de emergência',
	'medicina de família e comunidade',
	'medicina do trabalho',
	'medicina do tráfego',
	'medicina esportiva',
	'medicina física e reabilitação',
	'medicina intensiva',
	'medicina nuclear',
	'medicina preventiva e social',
	'neurocirurgia',
	'neurologia',
	'oftalmologia',
	'ortopedia e traumatologia',
	'otorrinolaringologia',
	'patologia',
	'patologia clínica - medicina laboratorial',
	'pediatria',
	'psiquiatria',
	'radiologia e diagnóstico por imagem',
	'radioterapia',
];

const headerRows = ['CONCURSO', 'EDITAL', 'INSCRIÇÕES', 'PROVAS', 'GABARITO', 'RECURSOS', 'DETALHES'];

export default function ContestCentral2023() {
	const classes = useStyles();
	const theme = useTheme();
	const [contestName, setContestName] = useState('');
	const [filteredData, setFilteredData] = useState([]);

	const [dataContests, setDataContests] = useState([]);
	const [selectedLocations, setSelectedLocations] = useState(allLocations);
	const [specialties, setSpecialties] = useState([]);
	const [selectedSpecialties, setSelectedSpecialties] = useState(allSpecialties);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	async function fetchDataContests() {
		try {
			const res = await Api.get('/concursos');
			setDataContests(res);
		} catch (error) {
			// Tratar o erro, se necessário
		}
	}

	useEffect(() => {
		fetchDataContests();
	}, []);

	useEffect(() => {
		if (dataContests?.length > 0) {
			const uniqueSpecialties = [
				...new Set(
					dataContests.flatMap((contest) =>
						contest.especialidades.map((esp) => esp.especialidade.toLowerCase().trim())
					)
				),
			];
			uniqueSpecialties.sort();
			setSpecialties(uniqueSpecialties);
		}
	}, [dataContests]);

	useEffect(() => {
		const handleSearch = () => {
			let filtered = dataContests;

			if (contestName) {
				filtered = filtered.filter((concurso) =>
					concurso.nome.toLowerCase().trim().includes(contestName.toLowerCase().trim())
				);
				if (filtered.length === 0) {
					const data = dataContests.filter((concurso) =>
						concurso.instituicoes.some((instituicao) =>
							instituicao.instituicao.toLowerCase().trim().includes(contestName.toLowerCase().trim())
						)
					);
					return setFilteredData(data);
				}
			}

			if (selectedLocations.length > 0) {
				filtered = filtered.filter((concurso) => {
					return selectedLocations.some((loc) => {
						return concurso.estados && concurso.estados.includes(loc);
					});
				});
			}

			if (selectedSpecialties.length > 0) {
				filtered = filtered.filter((concurso) =>
					concurso.especialidades.some((esp) =>
						selectedSpecialties.includes(esp.especialidade.toLowerCase().trim())
					)
				);
			}

			setFilteredData(filtered);
		};
		handleSearch();
	}, [contestName, selectedLocations, selectedSpecialties, dataContests]);

	// Função de atualização do estado contestName
	const handleContestNameChange = (value) => {
		setContestName(value);
	};

	const handleLocationChange = (value) => {
		setSelectedLocations(value);
	};

	const toggleLocation = (locationName) => {
		if (selectedLocations.includes(locationName)) {
			// Desmarcar o estado específico
			return selectedLocations.filter((loc) => loc !== locationName);
		} else {
			// Marcar o estado específico
			return [...selectedLocations, locationName].sort();
		}
		// }
	};

	const toggleSpecialty = (specialtyName) => {
		if (selectedSpecialties.includes(specialtyName)) {
			// Desmarcar a especialidade específico
			return selectedSpecialties.filter((esp) => esp !== specialtyName);
		} else {
			// Marcar a especialidade específico
			return [...selectedSpecialties, specialtyName].sort();
		}
		// }
	};

	// Função de atualização do estado specialtyName
	const handleSpecialtyNameChange = (value) => {
		setSelectedSpecialties(value);
	};

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			<Helmet>
				<title>Central de Concursos - Residência Médica</title>
				<meta
					name="description"
					content={
						'Todas as informações mais importantes sobre os principais concursos de Residência Médica do Brasil.'
					}
				/>
			</Helmet>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="revalida"
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					className="section"
					justifyContent={matchesTablet ? 'center' : 'flex-end'}
					style={matchesMobile ? { marginTop: 80 } : { marginTop: 30 }}
				>
					<Grid
						item
						xs={12}
						lg={4}
						container
						className={classes.gridLeftContainer}
					>
						<Grid
							item
							xs={12}
							container
							gap={1}
							style={{ marginBottom: '1rem' }}
						>
							<img
								src={require('./Assets/logo-contest-central.svg').default}
								alt=""
								className={classes.img}
							/>
							<Typography
								color="secondary"
								className={`${classes.text} poppins`}
							>
								As informações mais importantes sobre os principais concursos de Residência Médica do
								Brasil.
							</Typography>
						</Grid>
						<FilterContainer // Componente de Filtro
							location={selectedLocations}
							locations={allLocations}
							specialty={selectedSpecialties}
							specialties={specialties}
							setSelectedLocations={setSelectedLocations}
							setSelectedSpecialties={setSelectedSpecialties}
							onContestNameChange={handleContestNameChange}
							onLocationChange={handleLocationChange}
							onSpecialtyNameChange={handleSpecialtyNameChange}
							toggleLocation={toggleLocation}
							toggleSpecialty={toggleSpecialty}
						/>
						{/* <Grid
							item
							xs={12}
							container
							style={{ marginTop: '1.75rem' }}
							rowGap={1}
						>
							<Grid
								item
								container
							>
								<img
									src={require('./Assets/icon-white-bell.svg').default}
									alt=""
									style={{ marginRight: '0.5rem' }}
								/>
								<Typography
									color="secondary"
									className={`${classes.textNews} poppins`}
								>
									Novidades dos últimos 7 dias
								</Typography>
							</Grid>
							<Grid
								item
								container
								rowGap={1.5}
								// style={{ backgroundColor: 'orange' }}
							>
								<AccordionNews />
								<AccordionNews />
								<AccordionNews />
							</Grid>
						</Grid> */}
					</Grid>
					<Grid
						item
						xs={12}
						lg={8}
						container
						className={classes.gridBackgroundImage}
					>
						{selectedLocations.length > 0 ? (
							<>
								{selectedLocations.map((selectedLocation, index) => {
									const filteredContests = filteredData?.filter(
										(contest) =>
											contest.especialidades_instituicoes.some(
												(esp_inst) =>
													esp_inst.estado.toLowerCase() === selectedLocation.toLowerCase() &&
													selectedSpecialties.includes(
														esp_inst.especialidade?.toLowerCase().trim()
													)
											)
										// (contestName
										// 	? contest.nome
										// 			.toLowerCase()
										// 			.trim()
										// 			.includes(contestName.toLowerCase().trim())
										// 	: true)
									);

									return (
										filteredContests.length > 0 && (
											<TableHwm
												key={index}
												headerRows={headerRows}
												location={selectedLocation}
												contest={filteredContests}
											/>
										)
									);
								})}
							</>
						) : null}
					</Grid>
				</Grid>
			</Container>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Footer />
			</Container>
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	text: {
		fontSize: '1.1rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	img: {
		[theme.breakpoints.down('sm')]: {
			width: '80%',
		},
	},
	fixedContainer: {
		position: 'fixed',
		zIndex: 2,
		maxWidth: '30%',
		marginLeft: 63,
		marginTop: 60,
		paddingRight: 15,
		[theme.breakpoints.down('md')]: {
			maxWidth: 'unset !important',
			position: 'unset !important',
			marginLeft: 0,
			marginTop: 80,
			padding: '0 5%',
		},
	},
	containerSearchBar: {
		margin: '2em 0 !important',
		[theme.breakpoints.down('xl')]: {
			margin: '1em 0 !important',
		},
	},
	filterDescriptionText: {
		fontSize: '1.1rem !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '.9rem !important',
		},
	},
	gridBackgroundImage: {
		backgroundImage: `url(${require('./Assets/background-image.png')})`,
		paddingLeft: '1em',
		backgroundSize: 'cover',
		[theme.breakpoints.down('xl')]: {
			// paddingRight: '5rem',
			paddingLeft: 0,
		},
		[theme.breakpoints.down('lg')]: {
			marginTop: '2rem !important',
			backgroundImage: 'unset',
		},
		[theme.breakpoints.down('sm')]: {
			backgroundImage: 'unset',
			paddingRight: 0,
		},
	},
	textNews: {
		fontWeight: '600 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	gridLeftContainer: {
		paddingRight: '3em',
		height: 'fit-content',
		position: 'sticky',
		top: '15%',
		[theme.breakpoints.down('xl')]: {
			top: '5%',
			position: 'unset',
		},
		[theme.breakpoints.down('lg')]: {
			height: 'fit-content',
			paddingRight: '0',
		},
	},
}));
