import iconArrow from '../assets/icon-arrow-blue.svg';
import iconGlass from '../assets/icon-magnifying-glass-blue.png';
import iconCam from '../assets/icon-cam-blue.svg';
import iconMetrics from '../assets/icon-metrics-blue.svg';
import iconBallon from '../assets/icon-ballon-blue.svg';
import iconApp from '../assets/icon-app-r3-blue.svg';

import imgTest01 from '../assets/img-print-r3-01.png';
import imgTest02 from '../assets/img-print-r3-02.png';
import imgTest03 from '../assets/img-print-r3-03.png';

import card01 from '../assets/img-card-cg-01.png';
import card02 from '../assets/img-card-cg-02.png';
import card01Mobile from '../assets/img-fabricio-mobile-01.png';
import card02Mobile from '../assets/img-fabricio-mobile-02.png';

import bgColagrossi from '../assets/bg-colagrossi-r3-blue.png';
import bgBianca from '../assets/bg-bianca-r3-blue.png';
import bgGrazielly from '../assets/bg-grazielly-r3-blue.png';
import bgThaila from '../assets/bg-thaila-r3-blue.png';
import bgLuana from '../assets/bg-luana-r3-blue.png';
import bgJoao from '../assets/bg-joao-r3-blue.png';

import gifColagrossi from '../assets/video-colagrossi-r3.mp4';
import gifBianca from '../assets/video-bianca-r3.mp4';
import gifGrazielly from '../assets/video-grazielly-r3.mp4';
import gifThaila from '../assets/video-thaila-r3.mp4';
import gifLuana from '../assets/video-luana-r3.mp4';
import gifJoao from '../assets/video-joao-r3.mp4';

import bgColagrossiMobile from '../assets/bg-mobile-colagrossi-r3-blue.png';
import bgBiancaMobile from '../assets/bg-mobile-bianca-r3-blue.png';
import bgGraziellyMobile from '../assets/bg-mobile-grazsielly-r3-blue.png';
import bgThailaMobile from '../assets/bg-mobile-thaila-r3-blue.png';
import bgLuanaMobile from '../assets/bg-mobile-luana-r3-blue.png';
import bgJoaoMobile from '../assets/bg-mobile-joao-r3-blue.png';

export const resume = [
	{
		icon: iconArrow,
		title: 'Desafios Temáticos',
		text: 'Mapeamos todos os temas de cada banca e preparamos desafios temáticos com blocos de questões específicos; em cada desafio, aprendizados essenciais com comentários em texto, além de vídeos objetivos com detalhamento dos temas.',
	},
	{
		icon: iconCam,
		title: 'Vídeos objetivos',
		text: 'Vídeos objetivos direcionados para cada banca. Foco total nas mensagens relevantes para o R+ de cirurgia, com detalhamento de temas de cada subespecialidade.​',
	},
	{
		icon: iconBallon,
		title: 'Banco de questões',
		text: 'Centenas de questões comentadas de provas de R+ de cirurgia. Tenha acesso também a questões de de temas cirúrgicos de provas de acesso direto.​',
	},
	{
		icon: iconGlass,
		title: 'Revisões cíclicas diárias',
		text: 'Depois de desbravar cada tema nos desafios temáticos, hora de revisar até a data da prova! Todos os dias, um pequeno grupo de questões para ampliar, revisar e sedimentar todo o aprendizado.',
	},
	{
		icon: iconMetrics,
		title: 'Métricas personalizadas',
		text: 'Aqui o estudo é totalmente monitorado! Métricas detalhadas para acompanhar o aprendizado de cada tema relevante para a banca.',
	},

	{
		icon: iconApp,
		title: 'Aplicativo',
		text: 'Toda a flexibilidade de acesso através de aplicativo específico. Mas, se quiser, acesse também pelo navegador do computador.',
	},
];

export const notebookMock = [{ bg: imgTest01 }, { bg: imgTest02 }, { bg: imgTest03 }];

export const cardsData = [
	{
		bg: card01,
		bgMobile: card01Mobile,
		title: 'Foco total no R+ de Cirurgia!',
		text: [
			'O grande segredo das provas de R+ Cirurgia é conhecer a forma como ',
			<br />,
			'cada banca se aprofunda nos temas cirúrgicos! ',
			<br />,
			'Não é novidade para ninguém que as provas de especialidades ',
			<br />,
			'cirúrgicas são MUITO diferentes das de acesso direto.',
			<br />,
			<br />,
			'O que talvez você não saiba é que a maioria das pessoas se perde na',
			<br />,
			'preparação.',
			<br />,
			<br />,
			'No HardworQ R+ Cirurgia nós te mostramos todos os detalhes da USP',
			<br />,
			'e do ENARE, aprofundando com foco nas peculiaridades de cada tema',
		],
	},
	{
		bg: card02,
		bgMobile: card02Mobile,
		title: 'Revisão direcionada e intensa',
		text: [
			'Dissecamos em detalhes cada uma das bancas de R+ de cirurgia,',
			<br />,
			'explorando todas as peculiaridades dos temas, propondo desafios',
			<br />,
			'temáticos direcionados.',
			<br />,
			<br />,
			'Realize o desafio temático e explore os temas com vídeos curtos e ',
			<br />,
			'comentários dos professores Hardwork.',
			<br />,
			<br />,
			'A cada desafio temático concluído, revisamos com questões diárias',
			<br />,
			'sobre cada um dos temas, ampliando o conhecimento, e revisando ',
			<br />,
			'até a data da prova.',
		],
	},
];

export const cardsTeachers = [
	{
		bg: bgColagrossi,
		bgMobile: bgColagrossiMobile,
		videoUrl: gifColagrossi,
		title: 'Fábio Colagrossi',
		subtitle: '@drfabiocolagrossi',
	},
	{
		bg: bgBianca,
		bgMobile: bgBiancaMobile,
		videoUrl: gifBianca,
		title: 'Bianca Calsolari',
		subtitle: '@bianca_calsolari',
	},
	{
		bg: bgGrazielly,
		bgMobile: bgGraziellyMobile,
		videoUrl: gifGrazielly,
		title: 'Grazielly Coser',
		subtitle: '@grazicoser',
	},
	{
		bg: bgThaila,
		bgMobile: bgThailaMobile,
		videoUrl: gifThaila,
		title: 'Thaila Gondek',
		subtitle: '@thailagondek',
	},
	{
		bg: bgLuana,
		bgMobile: bgLuanaMobile,
		videoUrl: gifLuana,
		title: 'Luana Gouveia',
		subtitle: '@dra.luanagouveia',
	},
	{
		bg: bgJoao,
		bgMobile: bgJoaoMobile,
		videoUrl: gifJoao,
		title: 'João Marcelo',
		subtitle: '@drjoaomarcelobrito',
	},
];
