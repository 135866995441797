import { useEffect, useState } from 'react';
import { Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link, useSearchParams } from 'react-router-dom';
import DefaultButton from '../../../../../Components/Buttons/DefaultButton';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import Api from '../../../../../Services/Api';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';

const groups = [
	{
		id: 1,
		active: true,
		turma: 'Turma 01',
		date: ['21 - 22'],
	},
	// {
	// 	id: 2,
	// 	active: true,
	// 	turma: 'Turma 02',
	// 	date: ['23 - 24'],
	// },
	// {
	// 	id: 3,
	// 	active: false,
	// 	date: ['Turma 03:', <br />, '05 e 06', <br />, 'de junho'],
	// },
	// {
	// 	id: 4,
	// 	active: false,
	// 	date: ['Turma 04:', <br />, '17 e 18 de junho'],
	// },
	// {
	// 	id: 5,
	// 	active: true,
	// 	date: ['Turma 05:', <br />, '15 e 16 de junho'],
	// },
	// {
	// 	id: 6,
	// 	active: true,
	// 	date: ['Turma 06:', <br />, '19 e 20 de junho'],
	// },
	// {
	// 	id: 7,
	// 	active: true,
	// 	date: ['Turma 07:', <br />, '19 e 20 de junho'],
	// },
	// {
	// 	id: 8,
	// 	active: true,
	// 	date: ['Turma 07:', <br />, '19 e 20 de junho'],
	// },
];

export default function PurchaseCard({ activeButtonId, idTurma, urlToRedirect, confirmationMessage }) {
	const classes = useStylesPurchaseCard();
	const theme = useTheme();
	const [turma, setTurma] = useState([]);
	const [turmaSelecionada, setTurmaSelecionada] = useState(null);
	const [waitListVisibility, setWaitListVisibility] = useState('hidden');
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [promotionalValueIsOver, setPromotionalValueIsOver] = useState(false);
	const [searchParams] = useSearchParams();
	const [preInscricao, setPreInscricao] = useState({
		agrupamento: 'ARENA 2023.2 LISTA DE ESPERA PRESENCIAL',
		nome: '',
		email: '',
		telefone: '',
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
	});
	const [setFreeAccessOnline] = useState(false);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));

	useEffect(() => {
		setPreInscricao({
			...preInscricao,
			utm_source: searchParams.get('utm_source'),
			utm_medium: searchParams.get('utm_medium'),
			utm_campaign: searchParams.get('utm_campaign'),
			utm_content: searchParams.get('utm_content'),
			utm_term: searchParams.get('utm_term'),
		});
		Api.get(`turmasabertas/${idTurma}`).then((res) => {
			if (res) {
				if (activeButtonId === 2) {
					const currentLot = res[0]?.lotes?.find((item) => item.id === res[0]?.id_lote_vigente);
					if (currentLot === null) {
						setTurma(currentLot);
					} else {
						setTurma(res[0]);
					}
				} else {
					setTurma(res[0]);
				}
			}
		});
		var momentoAtual = new Date();
		var momentoLiberacao = new Date('2023-04-19T10:00:00');
		setFreeAccessOnline(momentoAtual >= momentoLiberacao);
		// setHasInactiveGroup(groups.some((group) => group.active === false));
	}, [idTurma, activeButtonId]);

	let sendPreInscricao = function () {
		if (!preInscricao.nome) {
			alert('Ops... Por favor informe seu nome');
			return;
		}
		if (!preInscricao.email || !preInscricao.email.includes('@') || !preInscricao.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (!preInscricao.telefone || preInscricao.telefone.length < 11) {
			alert('Ops... Por favor preencha seu telefone');
			return;
		}

		trackPromise(
			Api.post('PreInscricao', preInscricao).then((res) => {
				if (res) {
					Swal.fire({
						title: 'Massa! ',
						text: `${
							confirmationMessage
								? confirmationMessage
								: 'Sua pré-inscrição está confirmada! Avisaremos assim que a nova turma estiver liberada.'
						}`,
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then((result) => {
						if (result.isConfirmed) {
							if (urlToRedirect) {
								document.location.href = `${urlToRedirect}`;
							} else {
								document.location.href = '/';
							}
						}
					});
				}
			})
		);
	};

	const handleSelectedGroup = (id) => {
		setTurmaSelecionada(id);
		if (showErrorMessage) {
			setShowErrorMessage(false);
		}
		// setPreInscricao({
		// 	...preInscricao,
		// 	agrupamento: `ARENA 2023.1 TURMA ${turmaSelecionada}`,
		// });
	};

	const handleCloseWaitList = () => {
		setWaitListVisibility('hidden');
	};

	const handleOpenWaitList = () => {
		setWaitListVisibility('unset');
		setTurmaSelecionada(null);
	};

	const phoneMask = (value) => {
		if (!value) return '';
		value = value.replace(/\D/g, '');
		value = value.replace(/(\d{2})(\d)/, '($1) $2');
		value = value.replace(/(\d)(\d{4})$/, '$1-$2');
		return value;
	};

	const handlePhone = (event) => {
		let input = event.target;
		input.value = phoneMask(input.value);
		setPreInscricao({
			...preInscricao,
			telefone: input.value,
		});
	};

	const handleSubscribeButton = () => {
		if (turma?.encerrada) {
		}
		if (!turmaSelecionada && activeButtonId !== 2) {
			setShowErrorMessage(true);
		} else {
			setShowErrorMessage(false);
		}
	};

	return (
		<Grid
			container
			style={!activeButtonId ? { opacity: 0.65 } : null}
			className={`${classes.root} revalida-live-card`}
		>
			<Grid
				item
				xs={12}
				container
			>
				<Grid
					item
					container
					justifyContent={'center'}
					alignContent={'center'}
					className={classes.background}
				>
					<img
						src={require(`../../../../../Assets/Arena/logo-arena-hwm-${
							activeButtonId === 1
								? 'presencial-online'
								: activeButtonId === 2
								? 'online'
								: 'presencial-online'
						}.svg`)}
						alt="logo"
						style={{ width: matchesLg ? '50%' : matchesTablet ? '70%' : 'unset' }}
					/>
				</Grid>
				<Grid
					item
					container
					direction={'column'}
					justifyContent={activeButtonId === 1 ? 'space-evenly' : 'space-around'}
					alignContent={'center'}
					className={classes.containerOptions}
				>
					<Grid
						item
						container
						direction={'column'}
						alignContent={turma?.encerrada ? 'center' : 'flex-start'}
					>
						{turma?.encerrada ? (
							<>
								<Typography
									color={'primary'}
									align="left"
									className={`${classes.subscriptionInactiveText} roboto-condensed`}
								>
									{/* {!matchesTablet || !matchesMobile ? <br /> : null}  */}
									INSCRIÇÕES ENCERRADAS
								</Typography>
							</>
						) : (
							<>
								{activeButtonId === 2 && !promotionalValueIsOver && (
									<Typography
										color="primary"
										className={classes.priceTextStrikeThrough}
									>
										R$ {turma?.valor?.toLocaleString('pt-BR')}{' '}
										<sup style={{ fontSize: '67%' }}>,00</sup>
									</Typography>
								)}
								<Typography
									color="primary"
									className={`${classes.priceText} roboto-condensed`}
								>
									R${' '}
									{turma?.valor?.toLocaleString('pt-BR', {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									})}
									<sup style={{ fontSize: '67%' }}>,00</sup>
								</Typography>
								{activeButtonId === 1 ? (
									turma?.parcelas && (
										<Typography className={`${classes.priceTextGrey} poppins`}>
											{/* ou até {parcel}x de R${valuePerParcel} */}
											ou até {turma?.parcelas[turma?.parcelas?.length - 1]?.qtd}x de R$
											{/* {turma?.parcelas} */}
											{activeButtonId === 1
												? turma?.parcelas[turma?.parcelas?.length - 1]?.valor.toLocaleString(
														'pt-BR',
														{
															minimumFractionDigits: 2,
														}
												  )
												: turma?.parcelas[turma?.parcelas?.length - 1]?.valor.toLocaleString(
														'pt-BR',
														{
															minimumFractionDigits: 2,
														}
												  )}
										</Typography>
									)
								) : (
									<Typography className={`${classes.priceTextGrey} poppins`}>
										ou até 12x de R$
										{(turma?.valor / 12).toLocaleString('pt-BR', {
											minimumFractionDigits: 2,
										})}
									</Typography>
								)}
							</>
						)}
					</Grid>
					{activeButtonId === 2 && turma.length > 0 /*&& !freeAccessOnline*/ ? (
						<Typography
							color="primary"
							className={`${classes.text} poppins`}
						>
							*O acesso será liberado a partir de 05/10/2023
						</Typography>
					) : null}
					{activeButtonId !== 2 ? (
						<>
							{turma?.encerrada ? null : (
								<>
									<Grid
										item
										container
										style={{ marginTop: '1.25em' }}
									>
										<Typography
											color="primary"
											className={`${classes.classText} poppins`}
										>
											Selecione a sua turma e data em São Paulo:
										</Typography>
									</Grid>
									<Grid
										item
										container
										gap={2}
										className={classes.containerGroupsOptions}
									>
										{groups.map((group) => {
											const isGroupSelected = group.id === turmaSelecionada;
											return (
												<Grid
													item
													// xs={3}
													container
													justifyContent={'center'}
													alignContent="center"
													key={group.id}
													style={{
														backgroundImage: `url(${require(`../../../../../Assets/Arena/background-group-${
															group.active ? 'green' : 'grey'
														}.png`)})`,
														cursor: `${group.active ? 'pointer' : 'auto'}`,
														boxShadow:
															isGroupSelected && group.active
																? '5px 5px 3px #00000033, inset 0px 0px 5px 10px rgba(0,0,0,0.3)'
																: 'none',
														border:
															isGroupSelected && group.active
																? '3px solid #00DF86'
																: '3px solid transparent',
														scale: isGroupSelected && '1.15',
													}}
													className={`${classes.groupsBox} ${
														group.active && !isGroupSelected && !matchesMobile
															? classes.groupHover
															: null
													}`}
													onClick={() => {
														if (group.active) {
															handleSelectedGroup(group.id);
														}
													}}
												>
													{group.active ? null : (
														<img
															src={require('../../../../../Assets/Arena/logo-sold-out.png')}
															alt=""
															style={{ position: 'absolute', zIndex: 1, top: 20 }}
														/>
													)}
													<Typography
														align="center"
														color="secondary"
														className={`${classes.textGroupTurma} poppins`}
														style={{
															textShadow:
																isGroupSelected && group.active
																	? '0 0 0.2em #121213, 0 0 0.2em #0c0c0d'
																	: 'none',
														}}
													>
														{group.turma}
													</Typography>
													<Typography
														align="center"
														color="secondary"
														className={`${classes.textGroupDate} poppins`}
														style={{
															textShadow:
																isGroupSelected && group.active
																	? '0 0 0.2em #121213, 0 0 0.2em #0c0c0d'
																	: 'none',
														}}
													>
														{group.date}
													</Typography>
													<Typography
														align="center"
														color="secondary"
														className={`${classes.textGroupTurma} poppins`}
														style={{
															textShadow:
																isGroupSelected && group.active
																	? '0 0 0.2em #121213, 0 0 0.2em #0c0c0d'
																	: 'none',
														}}
													>
														de novembro
													</Typography>
												</Grid>
											);
										})}
										{/* {hasInactiveGroup && (
											<Grid
												item
												container
												justifyContent={'center'}
												style={{
													cursor: 'pointer',
												}}
												className={`${classes.groupsBox} ${
													!matchesMobile && classes.groupHover
												}`}
											>
												<Typography
													align="center"
													color="secondary"
													className={`${classes.textGroupDate} poppins`}
													style={{
														textShadow: '0 0 0.2em #121213, 0 0 0.2em #0c0c0d',
														display: 'flex',
														alignItems: 'center',
													}}
													onClick={() => handleOpenWaitList()}
												>
													<img
														src={
															require('../../../../../Assets/Arena/icon-waitList.svg')
																.default
														}
														alt=""
														className={classes.waitListImage}
													/>
													&nbsp; Lista de Espera
												</Typography>
											</Grid>
										)} */}
									</Grid>
								</>
							)}
						</>
					) : null}
					{activeButtonId === 2 && turma?.encerrada ? null : (
						<Grid
							item
							container
							alignContent={'center'}
							justifyContent={'center'}
							style={matchesTablet ? null : { height: '4rem' }}
						>
							{activeButtonId === 2 && turma?.encerrada ? null : (
								<DefaultButton
									variant="outlined"
									color="secondary"
									className={`${classes.btn} btn button-info-card`}
									component={Link}
									onClick={
										turma?.encerrada ? () => handleOpenWaitList() : () => handleSubscribeButton()
									}
									to={
										(activeButtonId === 2 && turma?.length > 0) ||
										(activeButtonId === 1 && turmaSelecionada)
											? `//hardworkmedicina.com.br/inscricao/${idTurma}${
													activeButtonId !== 2
														? `?pp=${
																turmaSelecionada === 1
																	? '21/11/2023'
																	: turmaSelecionada === 2
																	? '23/11/2023'
																	: turmaSelecionada === 3
																	? '05/06/2023'
																	: turmaSelecionada === 4
																	? '17/06/2023'
																	: null
														  }`
														: ''
											  }`
											: null
									}
								>
									<img
										src={require('../../../../../Assets/Svg/Icons/bagIcon.svg').default}
										alt=""
									/>{' '}
									&nbsp; {turma?.encerrada ? 'Avise-me' : 'Inscreva-se'}
								</DefaultButton>
							)}
							{activeButtonId === 2 && (
								<Typography
									color="primary"
									className={`${classes.text} poppins`}
									style={{ fontWeight: 'bold' }}
								>
									10% de desconto para alunos Hardwork*
								</Typography>
							)}
						</Grid>
					)}
					{showErrorMessage && (
						<Typography
							align="center"
							// color="primary"
							className={`poppins`}
							style={{
								color: '#AC0000',
							}}
						>
							Selecione uma turma acima para se inscrever
						</Typography>
					)}
					{activeButtonId !== 2 && (
						<form
							className="form-control"
							action="javascript:;"
						>
							<Grid
								item
								container
								justifyContent={'center'}
								className={classes.containerWaitList}
								rowGap={1}
								style={{ visibility: waitListVisibility }}
							>
								<Grid
									item
									container
									justifyContent="space-between"
								>
									<Typography
										color="primary"
										className={`${classes.textWaitList} poppins`}
									>
										Inscrever-se na Lista de Espera
									</Typography>
									<CancelRoundedIcon
										onClick={() => handleCloseWaitList()}
										className={classes.cancelIcon}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									md={6}
									container
									justifyContent={matchesTablet ? 'center' : 'flex-start'}
								>
									<TextField
										fullWidth
										type="string"
										name="nome"
										placeholder="Seu nome completo"
										required={true}
										InputProps={{
											className: `${classes.form} ${classes.formHeight}`,
										}}
										InputLabelProps={{
											shrink: true,
										}}
										style={matchesTablet ? { width: '100%' } : { width: '97%' }}
										onChange={(e) =>
											setPreInscricao({
												...preInscricao,
												nome: e.target.value,
											})
										}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									md={6}
									container
									justifyContent={matchesTablet ? 'center' : 'flex-end'}
								>
									<TextField
										fullWidth
										type="tel"
										name="telefone"
										placeholder="Seu celular com DDD"
										required={true}
										pattern="[0-9]*"
										inputProps={{
											maxLength: 15,
										}}
										InputProps={{
											className: `${classes.form} ${classes.formHeight}`,
										}}
										InputLabelProps={{
											shrink: true,
										}}
										style={matchesTablet ? { width: '100%' } : { width: '97%' }}
										onChange={(e) => handlePhone(e)}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									container
									justifyContent={matchesMobile ? 'center' : 'flex-start'}
								>
									<TextField
										fullWidth
										type="email"
										name="email"
										placeholder="Seu melhor e-mail"
										required={true}
										InputProps={{
											className: `${classes.form} ${classes.formHeight}`,
										}}
										InputLabelProps={{
											shrink: true,
										}}
										style={{ width: '100%' }}
										onChange={(e) =>
											setPreInscricao({
												...preInscricao,
												email: e.target.value,
											})
										}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									md={10}
									lg={8}
									container
									className="residencia"
									style={{ height: 50, padding: '4px 0px' }}
								>
									<DefaultButton
										// type="submit"
										variant="outlined"
										color="secondary"
										className={`${classes.btnWaitList} btn`}
										onClick={() => sendPreInscricao()}
									>
										<img
											src={require('../../../../../Assets/Arena/icon-waitList.svg').default}
											alt=""
											style={{ width: 20 }}
										/>{' '}
										&nbsp; Confirmar inscrição
									</DefaultButton>
								</Grid>
							</Grid>
						</form>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
}

export const useStylesPurchaseCard = makeStyles((theme) => ({
	root: {
		border: '3px solid #ffffff',
		borderRadius: 15,
		backgroundColor: '#ffffff',
		position: 'relative',
		[theme.breakpoints.down('sm')]: {
			border: 'unset',
		},
	},
	background: {
		backgroundImage: `url(${require('../../../../../Assets/Arena/bg-arena-card.png')})`,
		backgroundSize: 'cover !important',
		padding: '3rem',
		width: '55% !important',
		backgroundColor: '#000000',
		borderTopLeftRadius: '15px !important',
		borderBottomLeftRadius: '15px !important',
		[theme.breakpoints.down('lg')]: {
			padding: '2em',
		},
		[theme.breakpoints.down('md')]: {
			width: '100% !important',
			borderBottomLeftRadius: '0 !important',
			borderTopRightRadius: '15px !important',
		},
	},
	cardTitle: {
		fontSize: '2.5rem !important',
		fontWeight: 'bold !important',
		textShadow: '0 0 0.2em #121213, 0 0 0.2em #0c0c0d',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.2rem !important',
		},
	},
	priceText: {
		fontSize: '5.2rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '3.25rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '4.5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.5rem !important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '2.25rem !important',
		},
	},
	priceTextGrey: {
		fontSize: '1.5rem !important',
		color: '#a9a5a5 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.25rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.95rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '1rem !important',
		},
	},
	btn: {
		fontSize: '1.35rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		borderColor: 'transparent',
		backgroundColor: '#00AB55 !important',
		border: '2px solid #fff !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				minWidth: '72% !important',
				fontSize: '1.5rem !important',
				borderWidth: '3px !important',
				backgroundColor: '#00543b !important',
				transition: '0.1s',
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			width: '90%',
		},
	},
	classText: {
		fontSize: '1.35rem !important',
		// color: 'grey !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.25rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.95rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '1rem !important',
		},
	},
	groupsBox: {
		backgroundColor: '#0D1A17',
		borderRadius: 15,
		padding: '0.25rem 0.5rem',
		width: '22% !important',
		backgroundSize: 'cover',
		[theme.breakpoints.down(1230)]: {
			width: '25% !important',
		},
		[theme.breakpoints.down('lg')]: {
			width: '30% !important',
			height: '65px',
		},
		[theme.breakpoints.down('md')]: {
			height: '80px',
		},
		[theme.breakpoints.down('sm')]: {
			width: '47% !important',
			padding: '0.5rem 0.75rem',
		},
	},
	groupHover: {
		'&:hover': {
			opacity: 0.8,
			scale: 1.05,
		},
	},
	textGroupTurma: {
		fontSize: '0.85rem !important',
		fontWeight: '600 !important',
	},
	textGroupDate: {
		fontSize: '1.5rem !important',
		fontWeight: '600 !important',
		lineHeight: 'unset !important',
	},
	form: {
		borderRadius: '8px !important',
		backgroundColor: '#E1E1E1 !important',
		margin: '5px 0 !important',
	},
	formHeight: {
		maxHeight: '3em',
	},
	btnWaitList: {
		fontSize: '1rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		backgroundColor: '#00AB55 !important',
		border: '1px solid #707070 !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				minWidth: '72% !important',
				fontSize: '1.025rem !important',
				backgroundColor: '#00543b !important',
				transition: '0.1s',
			},
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	cancelIcon: {
		color: '#000000',
		right: '-15px',
		top: '-5px',
		'&:hover': {
			cursor: 'pointer',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'absolute',
			top: '-20px',
			right: '-18px',
		},
	},
	containerWaitList: {
		width: '100% !important',
		backgroundColor: '#d6cece',
		zIndex: 1,
		borderRadius: 15,
		padding: '0.5rem 1.25rem',
		boxShadow: '0px 0px 30px 0px #000000',
		border: '2px solid #000000',
		position: 'absolute',
		bottom: 0,
		[theme.breakpoints.down('sm')]: {
			padding: '1.5rem 1.25rem',
		},
	},
	containerGroupsOptions: {
		margin: '8px 0 16px !important',
		// height: 160,
		[theme.breakpoints.down('xl')]: {
			// height: 138,
		},
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
		},
	},
	textWaitList: {
		fontSize: '1.3rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.95rem !important',
		},
	},
	priceTextStrikeThrough: {
		fontSize: '1.5rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		textDecoration: 'line-through',
	},
	waitListImage: {
		width: 25,
		[theme.breakpoints.down('xl')]: {
			width: 20,
		},
		[theme.breakpoints.down('sm')]: {
			width: 'unset',
		},
	},
	containerOptions: {
		width: '45% !important',
		padding: '2em 3em',
		[theme.breakpoints.down('lg')]: {
			padding: '1em 2em',
		},
		[theme.breakpoints.down('md')]: {
			padding: '1em 1.5em',
			width: '100% !important',
		},
	},
	text: {
		fontSize: '1.15rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			margin: '0.5rem 0 !important',
		},
	},
	subscriptionInactiveText: {
		fontSize: '3.25rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
			margin: '1rem !important',
		},
	},
}));
