import React, { useState } from 'react';
import { Container, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Button, TextareaAutosize } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';

//ASSETS
import Logo from '../../../Assets/LDL/logo-live-das-lives-nps.png';
import LogoRevalida from '../../../Assets/LDL/logo-ldl-nps-revalida.svg';
import CommentDots from '../../../Assets/LDL/comment-dots-light.svg';

// COMPONENTS
import Footer from '../../../Components/Footer';
import { Header } from '../../../Components/Header';
import Api from '../../../Services/Api';

const BoxRating = ({ number, color, colorHover, setShowTextarea, isActive, onClick }) => {
	const [isHovered, setIsHovered] = useState(false);
	const theme = useTheme();

	const handleClick = (index) => {
		setIsHovered(false);
		setShowTextarea(true);
		onClick(index);
	};

	return (
		<Grid
			container
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			onClick={handleClick}
		>
			<Grid
				item
				container
				xs={12}
				align={'center'}
				style={{ margin: '0.1em' }}
			>
				<Grid
					container
					style={{
						width: '3.9rem',
						height: '3.5rem',
						borderRadius: '6px',
						backgroundColor: isHovered || isActive ? colorHover : color,
						color: '#ffffff',
						justifyContent: 'center',
						alignItems: 'center',
						transform: isHovered || isActive ? 'scale(1.30)' : 'scale(1)',
						transition: 'transform 0.2s ease',
						[theme.breakpoints.down('sm')]: {
							width: '2rem !important',
							height: '1rem 1important',
						},
					}}
				>
					<Typography variant="h5">{number}</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

const TextareaComponent = ({ text, handleConcluirClick, handleTextareaChange }) => {
	const theme = useTheme();

	return (
		<Grid
			container
			justifyContent="center"
			align={'center'}
			direction={'column'}
		>
			<Grid
				container
				align={'center'}
				justifyContent={'center'}
				style={{
					marginTop: '20px',
				}}
			>
				<Typography style={{ color: '#ffffff', fontSize: '1.3rem' }}>{text}</Typography>
			</Grid>
			<Grid
				container
				align={'center'}
				justifyContent={'center'}
				style={{
					marginTop: '10px',
				}}
			>
				<TextareaAutosize
					onChange={(target) => handleTextareaChange(target)}
					rows="4"
					cols="50"
					placeholder="Escreva aqui..."
					style={{
						width: '40rem',
						height: '6rem',
						[theme.breakpoints.down('sm')]: {
							width: '10rem !important',
						},
					}}
				/>
			</Grid>
			<Grid
				container
				align={'center'}
				justifyContent={'center'}
				style={{
					marginTop: '10px',
					marginBottom: '10px',
				}}
			>
				<Button
					onClick={() => handleConcluirClick()}
					className=""
					style={{
						width: '40rem',
						height: '3rem',
						borderRadius: '6px',
						background: '#65B44E',
						color: '#ffffff',
						fontSize: '1.3rem',
						fontWeight: 'bolder ',
					}}
				>
					Concluir
				</Button>
			</Grid>
		</Grid>
	);
};

export default function LiveDasLivesNPS({ code, type }) {
	const classes = useStyles();
	const theme = useTheme();
	const [showTextarea, setShowTextarea] = useState(false);
	const [activeRating, setActiveRating] = useState(null);
	const [motivo, setMotivo] = useState('');

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const handleTextareaChange = (event) => {
		setMotivo(event.target.value);
	};

	const handleConcluirClick = () => {
		const postData = {
			avaliado: `live das lives - 2024 ${getUrlCode(code)}`,
			score: activeRating,
			motivo: motivo,
			ip: '192.168.0.1',
		};

		trackPromise(
			Api.post('npsAnonimo', postData).then((res) => {
				if (res) {
					Swal.fire({
						title: 'Massa! ',
						text: 'Sua avaliação foi enviada, obrigado!.',
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then((result) => {
						if (result.isConfirmed) {
							if ('/') {
								document.location.href = `${'/'}`;
							} else {
								document.location.href = '/';
							}
						}
					});
				}
			})
		);

		setShowTextarea(false);
		setActiveRating(null);
		setMotivo('');
	};

	const handleBoxRatingClick = (index) => {
		setActiveRating(index);
		setShowTextarea(true);
	};

	function getUrlCode(code) {
		switch (code) {
			case 'rcf':
				return 'Recife';
			case 'poa':
				return 'Porto Alegre';
			case 'ctb':
				return 'Curitiba';
			case 'svd':
				return 'Salvador';
			case 'rb':
				return 'Rio Branco';
			case 'pv':
				return 'Porto Velho';
			case 'bsb':
				return 'Brasília';
			case 'bh':
				return 'Belo Horizonte';
			case 'cg':
				return 'Campo Grande';
			case 'sp':
				return 'São Paulo';
			default:
				return null;
		}
	}

	const ratingOptions = [
		{
			number: '0',
			text: 'Certo e o que precisamos fazer para você dar uma nota 9 ou 10?',
			color: '#B6222685',
			colorHover: '#B62226',
		},
		{
			number: '1',
			text: 'Certo e o que precisamos fazer para você dar uma nota 9 ou 10?',
			color: '#D5212885',
			colorHover: '#D52128',
		},
		{
			number: '2',
			text: 'Certo e o que precisamos fazer para você dar uma nota 9 ou 10?',
			color: '#E8532685',
			colorHover: '#E85326',
		},
		{
			number: '3',
			text: 'Certo e o que precisamos fazer para você dar uma nota 9 ou 10?',
			color: '#EC6F2585',
			colorHover: '#EC6F25',
		},
		{
			number: '4',
			text: 'Certo e o que precisamos fazer para você dar uma nota 9 ou 10?',
			color: '#F6A72585',
			colorHover: '#F6A725',
		},
		{
			number: '5',
			text: 'Certo e o que precisamos fazer para você dar uma nota 9 ou 10?',
			color: '#FDC82985',
			colorHover: '#FDC829',
		},
		{
			number: '6',
			text: 'Certo e o que precisamos fazer para você dar uma nota 9 ou 10?',
			color: '#EBDA0F85',
			colorHover: '#EBDA0F',
		},
		{
			number: '7',
			text: 'Certo e o que precisamos fazer para você dar uma nota 9 ou 10?',
			color: '#E5E14685',
			colorHover: '#E5E146',
		},
		{
			number: '8',
			text: 'Certo e o que precisamos fazer para você dar uma nota 9 ou 10?',
			color: '#C2D23585',
			colorHover: '#C2D235',
		},
		{
			number: '9',
			text: 'Obrigado! E quais são os principais motivos para atribuir essa nota?',
			color: '#AECA3B85',
			colorHover: '#AECA3B',
		},
		{
			number: '10',
			text: 'Obrigado! E quais são os principais motivos para atribuir essa nota?',
			color: '#65B44E85',
			colorHover: '#65B44E',
		},
	];

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}

				<Grid
					container
					alignContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={
						matchesMobile
							? { marginTop: 80 }
							: matchesTablet
							? { marginTop: 60 }
							: { marginTop: 10, marginBottom: 5 }
					}
				>
					<img
						src={type === 'revalida' ? LogoRevalida : Logo}
						alt="Logo live das Lives"
						className={`${classes.logo} logo`}
					/>
				</Grid>

				<Divider
					variant="middle"
					className={classes.divider}
				/>

				<Grid
					container
					justifyContent={'center'}
				>
					<Grid
						container
						xs={11}
						style={
							matchesMobile
								? { padding: '10px 0 1em', width: '20rem !important' }
								: { padding: '2em 2em 2em 4em' }
						}
						className={classes.gridBox}
						justifyContent={'center'}
					>
						<Grid
							container
							justifyContent={'center'}
							alignItems={'center'}
							direction={'row'}
							className={classes.gridTitle}
						>
							<img
								src={CommentDots}
								alt="Icone de commentário"
								className={classes.icon}
							/>
							<Typography
								variant="h4"
								className={`${classes.title} roboto-condensed`}
								style={{ marginLeft: '10px', marginTop: 0 }}
							>
								PESQUISA RÁPIDA
							</Typography>
						</Grid>
						<Grid
							container
							justifyContent={'center'}
							align={'center'}
							direction={'row'}
							rowSpacing={0}
							rowGap={1}
							className={classes.gridTitle}
						>
							<Typography
								variant="h5"
								className={`${classes.subtitle} roboto-condensed`}
								style={{ marginBottom: '1px' }}
							>
								Em uma escala de 0 a 10, o quanto você indicaria a Live das Lives -{' '}
								{type === 'revalida' ? getUrlCode(code) : code} para um amigo?
							</Typography>
						</Grid>
						<Grid
							item
							container
							xs={12}
							height={'3em'}
							justifyContent={'center'}
							align={'space-between'}
							spacing={0}
							direction={'column'}
						>
							{ratingOptions.map((item, index) => (
								<Grid
									container
									xs={1}
									justifyContent={'center'}
									align={'center'}
									className={classes.gridRating}
									key={index}
								>
									<BoxRating
										number={item.number}
										text={item.text}
										color={item.color}
										colorHover={item.colorHover}
										className={classes.boxRating}
										setShowTextarea={setShowTextarea}
										isActive={activeRating === index}
										onClick={() => handleBoxRatingClick(index)}
									></BoxRating>
								</Grid>
							))}
						</Grid>
						<Grid
							container
							justifyContent={'center'}
							alignItems={'center'}
						>
							{showTextarea && (
								<TextareaComponent
									className={classes.textarea}
									setShowTextarea={setShowTextarea}
									text={ratingOptions[activeRating]?.text}
									handleTextareaChange={handleTextareaChange}
									handleConcluirClick={handleConcluirClick}
								/>
							)}
						</Grid>
					</Grid>
				</Grid>

				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					{matchesTablet ? null : <Footer />}
				</Container>
				{matchesTablet ? <Footer /> : null}
			</Container>
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	logo: {
		width: '10em',
		paddingLeft: '0',
		marginBottom: '10px',
		[theme.breakpoints.down('sm')]: {
			maxWidth: '80% !important',
		},
	},
	divider: {
		margin: 'unset !important',
		backgroundColor: 'grey',
		minHeight: 1,
	},
	gridBox: {
		width: '45rem',
		height: '30rem',
		marginTop: '8px',
		border: 'solid 2px #ffffff',
		borderRadius: '12px',
		background: '#0D0D0D',
		[theme.breakpoints.down('sm')]: {
			width: '40rem !important',
			height: '30rem !important',
		},
		[theme.breakpoints.down('md')]: {
			width: '40rem',
			height: '28rem',
		},
	},
	gridTitle: {
		lineHeight: '1px',

		[theme.breakpoints.down('sm')]: {
			margin: '5px 0',
		},
	},

	title: {
		fontWeight: 'bold !important',
		color: '#ffffff',
		marginTop: '1em',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5em !important',
		},
	},
	subtitle: {
		color: '#ffffff',
		marginBottom: '10px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.4em !important',
		},
	},
}));
