import React, { useState } from 'react';
import { useTheme, useMediaQuery, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import iconPlus from '../assets/icon-circle-plus-duotone.svg';
import iconMinus from '../assets/icon-circle-minus-duotone.svg';

const BoxResumeSprint = ({ icon, title, text }) => {
	const theme = useTheme();
	const classes = useStyles();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesDown1300 = useMediaQuery(theme.breakpoints.down('1300'));
	const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));
	const matchesXl = useMediaQuery(theme.breakpoints.down('xl'));
	const [expanded, setExpanded] = useState(false);

	const handleMouseEnter = () => {
		setExpanded(true);
	};

	const handleMouseLeave = () => {
		setExpanded(false);
	};

	return (
		<Grid
			item
			container
			justifyContent={'center'}
			className="section"
		>
			<Grid
				item
				display={'flex'}
				flexDirection="column"
				width={matchesDown1300 ? 350 : 440}
				height={expanded ? 450 : 290}
				alignItems={'center'}
				className={classes.boxtitle}
				// onMouseEnter={handleMouseEnter}
				// onMouseLeave={handleMouseLeave}
			>
				<Grid
					item
					className={classes.titleContainer}
				>
					<img
						src={icon}
						className={classes.moreButton}
						alt="icone"
					/>
					<Typography
						color={'#000'}
						fontFamily={'Manrope'}
						fontWeight={800}
						fontSize={24}
						marginTop={1}
						marginBottom={1}
						className={classes.title}
					>
						<span style={{ display: 'block' }}>{title}</span>
					</Typography>
				</Grid>
				<Typography
					color={'#000'}
					fontFamily={'Manrope'}
					fontWeight={400}
					fontSize={16}
					className={expanded ? classes.titleExpanded : classes.title}
				>
					{text}
				</Typography>
				{/* <Grid
					item
					className={classes.imageContainer}
				>
					{!expanded && text.length > 50 && (
						<img
							src={iconPlus}
							className={classes.moreButton}
							alt="Expand"
						/>
					)}
					{expanded && (
						<img
							src={iconMinus}
							className={classes.moreButton}
							alt="Collapse"
						/>
					)}
				</Grid> */}
			</Grid>
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	boxtitle: {
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		borderRadius: '24px',
		backgroundColor: '#fff',
		padding: '10px 25px 8px 25px',
		overflow: 'hidden',
		transition: 'height 0.3s ease-in-out',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	title: {
		lineHeight: '28px',
		letterSpacing: '-0.4px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'normal',
	},
	titleExpanded: {
		lineHeight: '28px',
		letterSpacing: '-0.4px',
		whiteSpace: 'normal',
	},
	imageContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%',
	},
	moreButton: {
		color: '#000 !important',
		marginTop: '8px',
		textTransform: 'none',
		'&:hover': {
			transform: 'scale(1.1)',
		},
	},
	titleContainer: {
		marginTop: 'auto',
		alignSelf: 'flex-start',
	},
	name: {
		alignSelf: 'flex-start',
	},
}));

export default BoxResumeSprint;
