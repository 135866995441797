import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

// -- Assets
import iconSubscribe from '../../Assets/icon-subscribe.svg';

// -- Constants

const saoPauloSprints = [
	'1294',
	'1297',
	'1302',
	'1351',
	'1305',
	'1315',
	'1307',
	'1317',
	'1312',
	'1304',
	'1303',
	'1301',
	'1300',
	'1298',
];

const saoPauloSprintsEnare = [
	'1294',
	'1297',
	'1302',
	'1351',
	'1305',
	'1315',
	'1307',
	'1317',
	'1312',
	'1304',
	'1303',
	'1301',
	'1300',
	'1298',
	'1348',
];

const exams = [
	{ name: 'ABC', id: '1294' },
	{ name: 'AMP', id: '1295' },
	{ name: 'AMRIGS', id: '1296' },
	{ name: 'EINSTEIN', id: '1297' },
	{ name: 'ENARE', id: '1348' },
	{ name: 'FAMEMA', id: '1302' },
	{ name: 'FAMERP', id: '1351' },
	{ name: 'F.MED. JUNDIAÍ', id: '1305' },
	{ name: 'SÍRIO LIBANÊS', id: '1315' },
	{ name: 'IAMSPE', id: '1307' },
	{ name: 'PSU GO', id: '1309' },
	{ name: 'PSU MG', id: '1311' },
	{ name: 'SANTA CASA SP', id: '1317' },
	{ name: 'SES DF', id: '1318' },
	{ name: 'SES PE', id: '1316' },
	{ name: 'SURCE', id: '1314' },
	{ name: 'SUS BA', id: '1313' },
	{ name: 'SUS SP', id: '1312' },
	{ name: 'UERJ', id: '1310' },
	{ name: 'UFES', id: '1308' },
	{ name: 'UFRJ', id: '1306' },
	{ name: 'UNESP BOTUCATU', id: '1304' },
	{ name: 'UNICAMP', id: '1303' },
	{ name: 'UNIFESP', id: '1301' },
	{ name: 'USP', id: '1300' },
	{ name: 'USP RIBEIRÃO', id: '1298' },
];

export function CardPaymentContainer({
	title, // -- Title of card in array, with two properties blackText and gradientText
	valorDe, // -- Receive a value of original value of the product
	valorPor, // -- Value with discount
	plusInfo, // -- More information appears on the pink card above the prices
	qParcelas, // -- Quantity of installments (Parcelas)
	vParcelas, // -- Price of each installments (Parcelas)
	vTotalParcelas, // -- Total price of all installments (Parcelas)
	bestChoice, // -- Props that indentifies card as a best choice, putting a pink border and a tag "Mais Escolhido"
	subtitleArr, // -- Informations that appears below title
	arrOfSprints, // -- Receives array with all products
	informationAboutSelection, // -- Receives information about sprint selection
	discountInformation, // -- Receives information about dicount
	handleSubscribeFn, // -- Receives a function that handles subscription
	handleSelectSprint, // -- Receives a function that handles sprints selection when exists, if not necessary pass a non-action function like () => {}
	arrOfSelectedSprints, // -- Receives a array that contains all products selected
	limitOfSelectedSprints, // -- If has a limit of selected products, passing as number
}) {
	const styles = useStyles();
	const theme = useTheme();

	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const [isVisibleSprints, setIsVisibleSprints] = useState(false);

	return (
		<>
			<div
				className={styles.container}
				style={
					isVisibleSprints
						? {
								border: bestChoice ? '1px solid #FD2C78' : '1px solid #CCC',
								borderRadius: 16,
								flexDirection: 'column',
								boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
						  }
						: {
								border: bestChoice ? '1px solid #FD2C78' : '1px solid #CCC',
								borderRadius: 16,
								flexDirection: 'column',
						  }
				}
			>
				<div
					className={styles.container}
					style={{ padding: '32px 32px 32px 32px', borderRadius: 16 }}
				>
					<div
						onClick={() => setIsVisibleSprints(!isVisibleSprints)}
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							gap: 15,
						}}
					>
						{bestChoice && (
							<div className={styles.bestChoice}>
								<h3
									className={styles.gradientText}
									style={{ fontSize: 14 }}
								>
									{bestChoice}
								</h3>
							</div>
						)}
						<div style={{ width: '100%' }}>
							<h2 style={{ fontSize: matchesTablet ? 24 : 32, whiteSpace: 'pre-wrap' }}>
								<span style={{ color: '#303030', fontWeight: 600 }}>{title?.blackText || ''}</span>
								<span className={styles.gradientText}>{title?.gradientText || ''}</span>
							</h2>
						</div>
						<div style={{ width: '100%' }}>
							<h3
								className={styles.subInfoText}
								style={{ marginBottom: 10, whiteSpace: 'pre-wrap' }}
							>
								{subtitleArr?.first || ''}{' '}
								<span style={{ fontWeight: 'bold' }}>{subtitleArr?.bold || ''}</span>{' '}
								{subtitleArr?.second || ''}
							</h3>
						</div>
					</div>
					<div
						style={{
							width: matchesTablet ? '100%' : 'fit-content',
							display: 'flex',
							flexDirection: matchesTablet ? 'column' : 'row',
							alignItems: 'center',
							justifyContent: 'flex-end',
						}}
					>
						<div
							style={{
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: matchesTablet ? 'start' : 'end',
								textAlign: matchesTablet ? 'start' : 'end',
							}}
						>
							{plusInfo && (
								<div className={styles.plusInfo}>
									<h3
										style={{
											fontSize: matchesTablet ? 12 : 14,
											fontWeight: 300,
											color: 'white',
											textWrap: 'nowrap',
										}}
									>
										{plusInfo}
									</h3>
								</div>
							)}
							<div style={{ marginBottom: 16 }}>
								<h4 className={styles.priceTextDe}>De: R$ {valorDe}</h4>
								<h3
									className={styles.priceTextPor}
									style={{ marginTop: 5, fontSize: 20, textWrap: 'nowrap', fontFamily: 'Manrope' }}
								>
									Por:{' '}
									<span
										className={styles.gradientText}
										style={{ fontWeight: 800, fontSize: 28 }}
									>
										R$ {valorPor}
									</span>{' '}
									à vista
								</h3>
								<h2
									className={styles.priceTextPor}
									style={{ fontSize: 16, marginTop: 5, fontFamily: 'Manrope' }}
								>
									(<strong>5%</strong> de desconto)
								</h2>
							</div>
							<h2
								className={styles.priceTextPor}
								style={{ fontSize: 24, textWrap: 'nowrap' }}
							>
								ou <strong>{qParcelas}x</strong> de{' '}
								<span
									className={styles.gradientText}
									style={{ fontSize: matchesTablet ? 32 : 40, fontWeight: 800 }}
								>
									R$ {vParcelas}
								</span>
							</h2>
							<h3
								className={styles.priceTextPor}
								style={{ color: '#959595', fontSize: 14, marginTop: 5, fontFamily: 'Manrope' }}
							>
								{`Valor total: R$ ` + vTotalParcelas}
							</h3>
						</div>
						{isVisibleSprints && (
							<div
								onClick={
									!limitOfSelectedSprints
										? () => handleSubscribeFn()
										: arrOfSelectedSprints?.length === limitOfSelectedSprints
										? () => handleSubscribeFn()
										: () => {}
								}
								className={styles.btnSubscribe}
								style={{
									backgroundColor: !limitOfSelectedSprints
										? '#33C96F'
										: arrOfSelectedSprints?.length === limitOfSelectedSprints
										? '#33C96F'
										: '#9E9E9E',
								}}
							>
								<img src={iconSubscribe} />
								<p style={{ color: 'white', marginTop: matchesTablet ? 0 : 10 }}>Inscreva-se</p>
							</div>
						)}
					</div>
				</div>
				{isVisibleSprints && (
					<div
						style={{
							width: '100%',
							height: matchesTablet ? 300 : 'auto',
							backgroundColor: '#FCFCFC',
							borderBottomLeftRadius: 16,
							borderBottomRightRadius: 16,
							padding: matchesTablet ? 24 : 32,
							overflow: matchesTablet ? 'scroll' : 'hidden',
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: matchesTablet ? 'column' : 'row',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							{informationAboutSelection && (
								<p
									style={{
										color: 'black',
										fontFamily: 'Manrope',
										fontWeight: 'bold',
										fontSize: matchesTablet ? 16 : 20,
									}}
								>
									{informationAboutSelection}
								</p>
							)}

							<div></div>

							{discountInformation && (
								<div className={styles.bestChoice}>
									<h3
										className={styles.gradientText}
										style={{ fontSize: 14 }}
									>
										{`Economia de R$${discountInformation}`}
									</h3>
								</div>
							)}
						</div>

						<div
							style={{
								display: 'grid',
								gap: 10,
								gridTemplateColumns: 'repeat(auto-fill, minmax(125px, 1fr))',
								marginTop: 15,
								transition: 'all 150ms ease-in-out',
							}}
						>
							{arrOfSprints?.map((elm) => (
								<div
									onClick={() => handleSelectSprint(elm?.id)}
									style={{
										width: 115,
										minWidth: 115,
										height: 60,
										borderRadius: 8,
										backgroundColor: arrOfSelectedSprints?.includes(elm?.id)
											? '#1E1E1E'
											: '#F5F5F5',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',

										textAlign: 'center',
										padding: 10,
										color: arrOfSelectedSprints?.includes(elm?.id) ? 'white' : '#303030',
										fontFamily: 'Manrope',
										fontSize: 16,
									}}
								>
									{elm?.name}
								</div>
							))}
						</div>
					</div>
				)}
			</div>
		</>
	);
}

export default function PaymentContainer() {
	const styles = useStyles();

	const [arrOneSprint, setArrOneSprint] = useState([]);
	const [arrtwoSprint, setArrTwoSprints] = useState([]);
	const [arrThreeSprint, setArrThreeSprints] = useState([]);

	const handleSelectThreeSprint = (sprintId) => {
		if (arrThreeSprint?.includes(sprintId)) {
			const newArr = arrThreeSprint?.filter((sprint) => sprint !== sprintId);
			setArrThreeSprints(newArr);
		} else {
			if (arrThreeSprint?.length === 3) return;
			setArrThreeSprints([...arrThreeSprint, sprintId]);
		}
	};

	const handleSelectTwoSprint = (sprintId) => {
		if (arrtwoSprint?.includes(sprintId)) {
			const newArr = arrtwoSprint?.filter((sprint) => sprint !== sprintId);
			setArrTwoSprints(newArr);
		} else {
			if (arrtwoSprint?.length === 2) return;
			setArrTwoSprints([...arrtwoSprint, sprintId]);
		}
	};

	const handleSelectOneSprint = (sprintId) => {
		setArrOneSprint([sprintId]);
	};

	return (
		<section
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: 20,
				width: '100%',
			}}
		>
			<CardPaymentContainer
				title={{ blackText: 'Quero dominar', gradientText: ' 01 prova' }}
				subtitleArr={{
					first: 'Vou focar toda energia em',
					bold: ' 01 prova.',
					second: '',
				}}
				valorDe={'1.990,00'}
				valorPor={'1.661,55'}
				qParcelas={'12'}
				vParcelas={'145,75'}
				vTotalParcelas={'1.749,00'}
				plusInfo={'Sprint unitário'}
				informationAboutSelection={'Selecione o Sprint que deseja'}
				arrOfSprints={exams}
				arrOfSelectedSprints={arrOneSprint}
				handleSubscribeFn={() =>
					window.open(`https://hardworkmedicina.com.br/inscricao/${arrOneSprint.join(',')}`)
				}
				handleSelectSprint={handleSelectOneSprint}
				limitOfSelectedSprints={1}
			/>

			<CardPaymentContainer
				title={{ blackText: 'Quero dominar', gradientText: ' 02 provas' }}
				subtitleArr={{
					first: 'Tenho',
					bold: ' 02 instituições',
					second: ' dos sonhos.',
				}}
				valorDe={'3.184,00'}
				valorPor={'2.658,48'}
				qParcelas={'12'}
				vParcelas={'233,20'}
				vTotalParcelas={'2.798,40'}
				plusInfo={'Pacote 02 sprints'}
				informationAboutSelection={'Selecione os 02 Sprints que deseja'}
				discountInformation={'699,40'}
				arrOfSprints={exams}
				arrOfSelectedSprints={arrtwoSprint}
				handleSelectSprint={handleSelectTwoSprint}
				handleSubscribeFn={() =>
					window.open(`https://hardworkmedicina.com.br/inscricao/${arrtwoSprint.join(',')}`)
				}
				limitOfSelectedSprints={2}
			/>

			<CardPaymentContainer
				bestChoice={'Opção mais escolhida'}
				title={{ blackText: 'Quero dominar', gradientText: ' 03 provas' }}
				subtitleArr={{
					first: 'Preciso de foco e intensidade em pelo menos',
					bold: ' 03 provas.',
					second: '',
				}}
				valorDe={'4.378,00'}
				valorPor={'2.990,79'}
				qParcelas={'12'}
				vParcelas={'262,35'}
				vTotalParcelas={'3.148,20'}
				plusInfo={'Pacote 03 sprints'}
				limitOfSelectedSprints={3}
				informationAboutSelection={'Selecione os 03 Sprints que deseja'}
				discountInformation={'1.398,80'}
				arrOfSprints={exams}
				arrOfSelectedSprints={arrThreeSprint}
				handleSelectSprint={handleSelectThreeSprint}
				handleSubscribeFn={() =>
					window.open(`https://hardworkmedicina.com.br/inscricao/${arrThreeSprint.join(',')}`)
				}
			/>

			<CardPaymentContainer
				title={{ blackText: 'Quero dominar várias ', gradientText: 'provas de São Paulo' }}
				subtitleArr={{
					first: 'Preciso de direcionamento total para as',
					bold: ' bancas de São Paulo.',
					second: '',
				}}
				valorDe={'13.521,00'}
				valorPor={'3.790.50'}
				qParcelas={'12'}
				vParcelas={'332,50'}
				vTotalParcelas={'3.990,00'}
				plusInfo={'Pacote provas de São Paulo'}
				discountInformation={'1.398,80'}
				arrOfSprints={exams}
				arrOfSelectedSprints={saoPauloSprints}
				handleSelectSprint={() => {}}
				handleSubscribeFn={() => window.open(`https://hardworkmedicina.com.br/inscricao/sprintsSP`)}
			/>
			<CardPaymentContainer
				title={{ blackText: 'Quero dominar', gradientText: ' ENARE e várias provas\n de São Paulo' }}
				subtitleArr={{
					first: 'Tenho foco no',
					bold: ' ENARE, ',
					second: 'mas também preciso de direcionamento para provas\n de São Paulo.',
				}}
				valorDe={'15.551,60'}
				valorPor={'3.980,50'}
				qParcelas={'12'}
				vParcelas={'349,16'}
				vTotalParcelas={'4.190,00'}
				plusInfo={'Pacote ENARE + provas de São Paulo'}
				discountInformation={'1.398,80'}
				arrOfSprints={exams}
				arrOfSelectedSprints={saoPauloSprintsEnare}
				handleSelectSprint={() => {}}
				handleSubscribeFn={() => window.open(`https://hardworkmedicina.com.br/inscricao/SPRINTR12024SP_ENARE`)}
			/>

			<CardPaymentContainer
				title={{ gradientText: 'Quero acesso completo!' }}
				subtitleArr={{
					first: 'Tenho foco em',
					bold: ' várias provas ',
					second: 'e quero acesso integral a todas\n as ferramentas do Sprint.',
				}}
				valorDe={'25.394,60'}
				valorPor={'5.310,50'}
				qParcelas={'12'}
				vParcelas={'465,83'}
				vTotalParcelas={'5.590,00'}
				plusInfo={'Pacote todos os Sprints'}
				discountInformation={'19.804,60'}
				arrOfSprints={exams}
				arrOfSelectedSprints={exams?.map((e) => e?.id)}
				handleSelectSprint={() => {}}
				handleSubscribeFn={() => window.open(`https://hardworkmedicina.com.br/inscricao/todosSprints`)}
			/>

			<div className={styles.warningText}>
				*Os Sprints estão sujeitos a alteração ou incorporação em caso de mudança de banca.
			</div>
		</section>
	);
}

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		minHeight: 200,
		justifyContent: 'space-between',
		backgroundColor: '#EBEBEB',
		transition: 'all 150ms ease-in-out',

		cursor: 'pointer',

		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
		},
	},
	gradientText: {
		background: 'linear-gradient(304deg, #FF8251 20.08%, #FD2C78 79.92%)',
		WebkitBackgroundClip: 'text',
		WebkitTextFillColor: 'transparent',
		fontFamily: 'Manrope',
		textAlign: 'start',
	},
	subInfoText: {
		fontFamily: 'Manrope',
		fontSize: '16px',
		fontWeight: 'initial',
		color: '#303030',

		[theme.breakpoints.down('md')]: {
			fontSize: '14px',
		},
	},
	bestChoice: {
		width: 'fit-content',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '10px',
		height: '27px',
		border: '1px solid #FD2C78',
		borderRadius: '4px',
		marginTop: 10,

		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	plusInfo: {
		background: 'linear-gradient(304deg, #FF8251 20.08%, #FD2C78 79.92%)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '10px',
		width: 'fit-content',
		height: '27px',
		borderRadius: '4px',
		marginBottom: 10,

		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	priceTextDe: {
		color: '#959595',
		fontSize: '16px',
		fontWeight: 'initial',
		textDecorationLine: 'line-through',
	},
	priceTextPor: {
		color: '#303030',
		fontSize: '16px',
		fontWeight: 'initial',
	},
	btnSubscribe: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		width: 130,
		minWidth: 130,
		height: 130,
		borderRadius: 8,
		marginLeft: 40,
		transition: 'all 150ms ease-in-out',

		'&:hover': {
			transform: 'scale(1.015)',
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: 60,
			marginLeft: 0,
			marginTop: 10,
			flexDirection: 'row-reverse',
			gap: 20,
		},
	},
	warningText: {
		width: '100%',
		height: 34,
		borderRadius: 4,
		backgroundColor: '#EBEBEB',
		color: '#656565',
		fontSize: 16,
		fontFamily: 'Manrope',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',

		textAlign: 'center',

		[theme.breakpoints.down('md')]: {
			fontSize: 12,
		},
	},
}));
