import { Button, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Header } from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import Form from '../../../Components/Utils/Forms/PreRegistrationArenaInfos';

//Assets
import firstSectionImg from '../Assets/img-area-hardwork.png';
import imgRedirect from '../Assets/icon-redirect.png';
import { useEffect, useState } from 'react';

export function LeftContainer({ imgSource, paragraph, matchesLaptop, scroll }) {
	return (
		<Grid
			item
			container
			height={'100%'}
			xs={matchesLaptop ? 12 : 5.8}
			className="section"
		>
			<Grid
				item
				container
				width={600}
				top={matchesLaptop ? 0 : 20}
				position={!matchesLaptop && scroll ? 'fixed' : 'relative'}
				zIndex={999}
			>
				<img src={imgSource} />
				<Typography
					color={'secondary'}
					fontFamily={'Poppins'}
					fontSize={matchesLaptop ? 28 : 32}
					lineHeight={1}
					fontWeight={500}
					paddingTop={2}
				>
					{paragraph}
				</Typography>

				<Grid
					item
					container
					marginTop={4}
				>
					<Typography
						fontFamily={'Poppins'}
						color="secondary"
						fontSize={matchesLaptop ? 16 : 20}
						lineHeight={1}
					>
						Usaremos tuas respostas para preparar o melhor conteúdo possível que será abordado em nossa Live
						de Correção!
					</Typography>
				</Grid>

				{/* <Grid
					item
					container
					marginTop={8}
				>
					<Typography
						fontFamily={'Poppins'}
						color="secondary"
						fontSize={matchesLaptop ? 16 : 20}
						lineHeight={1}
					>
						Ainda não respondeu nossa pesquisa da prova prática?
					</Typography>
					<Grid
						item
						container
						alignItems={'center'}
						justifyContent={'center'}
						gap={1}
						style={{
							height: 57,
							marginTop: 20,
							width: matchesLaptop ? '100%' : '80%',
							borderRadius: 30,
							border: '2px solid #FFFFFF',
							cursor: 'pointer',
						}}
						className="section-revalida-background"
						onClick={() =>
							window.open(
								'https://home.hardworkmedicina.com.br/live/correcao-prova-pratica-revalida-24-2'
							)
						}
					>
						<img
							src={imgRedirect}
							alt="btn"
							style={{ width: 20 }}
						/>
						<Grid item>
							<Typography
								fontSize={24}
								fontWeight={700}
								color={'secondary'}
							>
								Cadastre-se
							</Typography>
						</Grid>
					</Grid>
				</Grid> */}
			</Grid>
		</Grid>
	);
}

export default function Page() {
	const [scroll, setScroll] = useState();

	const theme = useTheme();

	const matchesLaptop = useMediaQuery(theme.breakpoints.down('lg'));

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY;

			if (scrollPosition > 105) {
				setScroll(true);
			} else {
				setScroll(false);
			}
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<Container disableGutters>
			<Header />
			<Grid
				container
				gap={2}
				justifyContent={'space-between'}
				marginTop={3}
			>
				<LeftContainer
					matchesLaptop={matchesLaptop}
					imgSource={firstSectionImg}
					scroll={scroll}
					paragraph={'Conta pra gente, como foi sua prova prática do Revalida 24.1?'}
				/>
				<Grid
					container
					item
					xs={matchesLaptop ? 12 : 5.8}
					minHeight={'60vh'}
					className="section"
				>
					<Grid maxWidth={557}>
						<Form
							group={'LIVE DE CORREÇÃO REVALIDA 24.1 PRÁTICA - PESQUISA'}
							title={'CONTA PRA GENTE, COMO FOI SUA PROVA PRÁTICA?'}
							titleButton={'Confirmar inscrição'}
							type={'revalida-ldl'}
							urlToRedirect={
								'https://home.hardworkmedicina.com.br/materiais/correcao-prova-pratica-revalida-24-2'
							}
							confirmationMessage={'Sua resposta foi registrada!'}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Footer />
		</Container>
	);
}
