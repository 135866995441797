import React, { useEffect, useState } from "react";

const AnimatedNumber = ({ number }) => {
    const [currentNumber, setCurrentNumber] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        if (currentNumber < number) {
          setCurrentNumber(currentNumber + 10); 
        } else {
          setCurrentNumber(number); 
        }
      }, 1); 
  
      return () => clearInterval(interval); 
    }, [currentNumber, number]);
  
    return <span>{currentNumber.toLocaleString()}</span>; 
  };

  export default AnimatedNumber