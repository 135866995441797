import React from 'react';
import { useTheme, useMediaQuery, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const BoxText = ({}) => {
	const classes = useStyles();
	const theme = useTheme();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Grid
			xl={12}
			sx={10}
			item
			container
			flexDirection={'column'}
			justifyContent={'center'}
			alignItems={'center'}
			borderRadius={4}
			padding={matchesMobile ? 2 : 5}
			style={{ backgroundColor: '#EBEBEB' }}
			className={classes.gridContainer}
			textAlign={matchesMobile ? 'center' : 'start'}
		>
			<Grid className={classes.gridContainer}>
				<div className={classes.descriptionScrollContainer}>
					<Grid
						item
						display={'flex'}
						justifyContent={matchesMobile ? 'flex-start' : 'center'}
					>
						<Typography
							fontSize={matchesMobile ? 24 : 32}
							fontFamily={'Manrope'}
							color={'#040404'}
							fontWeight={'bold'}
							marginBottom={2}
						>
							O que é o Sprint Final Hardwork?
						</Typography>
					</Grid>
					<Typography
						fontSize={matchesMobile ? 16 : 20}
						fontFamily={'Manrope'}
						color={'#040404'}
					>
						O Sprint Final surgiu há alguns anos para revolucionar a preparação final para as provas de
						Residência. <br/><br/> Mostramos o impacto do estudo direcionado por banca e personalizado para cada aluno. <br/><br/>
						Desconstruímos os modelos limitados a aulas resumidas, e atualizamos o conceito de intensivo
						para provas de residência. O resultado? Milhares de histórias Hardwork criadas…
					</Typography>
				</div>
			</Grid>
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	gridContainer: {
		height: '585px !important',

		[theme.breakpoints.down('xl')]: {
			paddingRight: '50px',
		},
		[theme.breakpoints.down('sm')]: {
			width: 'fit-content  !important',
			height: '450px !important ',
			paddingRight: '0px !important',
		},
	},
	descriptionScrollContainer: {
		maxHeight: 150,
		padding: 2,
		lineHeight: '1.35 !important',
		paddingRight: 20,
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.15rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
}));

export default BoxText;
