import React, { useEffect, useState } from 'react';
import {
	Container,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Modal,
	Select,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { makeStyles } from '@mui/styles';
import { trackPromise } from 'react-promise-tracker';
import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import IntlTelInput from 'react-intl-tel-input';
import Swal from 'sweetalert2';

// COMPONENTS
import Footer from '../../Components/Footer';
import { Header } from '../../Components/Header';
import LearningPath from '../../Components/Sections/LearningPath';
import ExtensivoRevalidaCard from '../../Components/Cards/ExtensivoRevalida';
import FirstSection from '../../Components/Sections/FirstSection';
import StudentsFeedbackSection from '../../Components/Sections/StudentsFeedback';
import FlipCard from './Sale/Arena/components/FlipCard';
import MultimidiaCard from '../../Components/Cards/MultimidiaCard';
import DefaultButton from '../../Components/Buttons/DefaultButton';

// ASSETS
// import BgWave2 from '../../Assets/Img/bgWave2.png';

// SERVICES
import Api from '../../Services/Api';
import { Helmet } from 'react-helmet';

const trilhaBonus = [
	{
		id: 1,
		img: require('./Sale/SprintFinal/assets/icon-teacher-orientation-white.svg').default,
		title: ['Aulas dos', <br />, 'principais temas'],
		description: [
			'Dois dias de programação',
			<strong
				style={{ margin: '0 auto' }}
				className="gradient-text"
			>
				intensa e muito dinâmica
			</strong>,
			'para entrar de vez na alma da prova prática do INEP.',
		],
	},
	{
		id: 2,
		img: require('../../Assets/Extensivo/Revalida/icon-live-bank.svg').default,
		title: ['Banco de lives', <br />, 'Hardwork'],
		description: [
			'Mais de ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				20 estações para treinamento intenso
			</strong>,
			'; todas as estações simulam em detalhes a prova prática do INEP.',
		],
	},
	{
		id: 3,
		img: require('../../Assets/Extensivo/Revalida/icon-library-access.svg').default,
		title: ['Acesso aos principais livros'],
		description: [
			'Treinamento de ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				habilidades específicas
			</strong>,
			' em pequenos grupos.',
		],
	},
	{
		id: 4,
		img: require('./Sale/SprintFinal/assets/icon-dot-white.svg').default,
		title: ['Questões', <br />, 'Temáticas'],
		description: [
			'No presencial temos uma oportunidade adicional de ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				aprender estações e aprender checklists
			</strong>,
			'.',
		],
	},
	{
		id: 5,
		img: require('../../Assets/Extensivo/Revalida/icon-resums-white.svg').default,
		title: ['Resumos dos', <br />, 'principais temas'],
		description: [
			'Orientações personalizadas do time Hardwork, com ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				professores e instrutores que já dominaram
			</strong>,
			' a prova prática do INEP.',
		],
	},
	{
		id: 6,
		img: require('../../Assets/Extensivo/Revalida/icon-postit-sheet.svg').default,
		title: ['Acesso ao', <br />, 'Banco de questões'],
		description: [
			'Momentos de integração com teus colegas, professores e instrutores. ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				Trocas riquíssimas
			</strong>,
			' durante os dois dias.',
		],
	},
	{
		id: 7,
		img: require('../../Assets/Extensivo/Revalida/icon-study-by-questions.svg').default,
		title: ['Método de', <br />, 'estudo por questões'],
		description: [
			'O jogo da prova prática exige raciocínio rápido. ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				Uma informação pode mudar tudo...
			</strong>,
			'são os "gatilhos de estação". No PRESENCIAL, treinamos brincando!',
		],
	},
	// {
	// 	id: 8,
	// 	img: require('../../Assets/Extensivo/Revalida/icon-tutor-lives.svg').default,
	// 	title: ['Lives de tutoria'],
	// 	description: [
	// 		'Momentos de integração com teus colegas, professores e instrutores. ',
	// 		<strong
	// 			style={{ margin: '0 auto', display: 'inline' }}
	// 			className="gradient-text"
	// 		>
	// 			Trocas riquíssimas
	// 		</strong>,
	// 		' durante os dois dias.',
	// 	],
	// },
	{
		id: 8,
		img: require('../../Assets/Extensivo/Revalida/icon-mock-sheet.svg').default,
		title: ['Simulados com', <br />, 'comentários em vídeo'],
		description: [
			'Momentos de integração com teus colegas, professores e instrutores. ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				Trocas riquíssimas
			</strong>,
			' durante os dois dias.',
		],
	},
	{
		id: 9,
		img: require('../../Assets/Extensivo/Revalida/icon-cellphone.svg').default,
		title: ['Contato direto', <br />, 'com o professor'],
		description: [
			'Momentos de integração com teus colegas, professores e instrutores. ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				Trocas riquíssimas
			</strong>,
			' durante os dois dias.',
		],
	},
	{
		id: 10,
		img: require('../../Assets/Extensivo/Revalida/icon-bonfire.svg').default,
		title: ['Métricas', <br />, 'do aprendizado'],
		description: [
			'Momentos de integração com teus colegas, professores e instrutores. ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				Trocas riquíssimas
			</strong>,
			' durante os dois dias.',
		],
	},
];

const sprintFinal = [
	{
		id: 1,
		img: require('../../Assets/Extensivo/Revalida/icon-sprint-watch.svg').default,
		title: ['Acesso antecipado', <br />, 'ao Sprint'],
		description: [
			'Orientações personalizadas do time Hardwork, com ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				professores e instrutores que já dominaram
			</strong>,
			' a prova prática do INEP.',
		],
	},
	{
		id: 2,
		img: require('./Sale/SprintFinal/assets/icon-global.svg').default,
		title: ['100% Online'],
		description: [
			'Mais de ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				20 estações para treinamento intenso
			</strong>,
			'; todas as estações simulam em detalhes a prova prática do INEP.',
		],
	},
	{
		id: 3,
		img: require('../../Assets/Extensivo/Revalida/icon-cyclic-revisions.svg').default,
		title: ['Revisões cíclicas', <br />, 'automáticas'],
		description: [
			'Orientações personalizadas do time Hardwork, com ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				professores e instrutores que já dominaram
			</strong>,
			' a prova prática do INEP.',
		],
	},
	{
		id: 4,
		img: require('./Sale/SprintFinal/assets/icon-dot-white.svg').default,
		title: ['Temas mais relevantes para', <br />, 'prova do Revalida-INEP'],
		description: [
			'No presencial temos uma oportunidade adicional de ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				aprender estações e aprender checklists
			</strong>,
			'.',
		],
	},
	{
		id: 5,
		img: require('./Sale/SprintFinal/assets/icon-teacher-orientation-white.svg').default,
		title: ['Para cada tema, uma', <br />, 'orientação em vídeo do professor'],
		description: [
			'Mais de ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				20 estações para treinamento intenso
			</strong>,
			'; todas as estações simulam em detalhes a prova prática do INEP.',
		],
	},
	{
		id: 6,
		img: require('../../Assets/Extensivo/Revalida/icon-mock-sheet.svg').default,
		title: ['Questões comentadas de todas as provas do Revalida INEP'],
		description: [
			'Momentos de integração com teus colegas, professores e instrutores. ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				Trocas riquíssimas
			</strong>,
			' durante os dois dias.',
		],
	},
	{
		id: 7,
		img: require('./Sale/SprintFinal/assets/icon-mock-test.svg').default,
		title: ['Grupos de discussão'],
		description: [
			'Orientações personalizadas do time Hardwork, com ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				professores e instrutores que já dominaram
			</strong>,
			' a prova prática do INEP.',
		],
	},
	{
		id: 8,
		img: require('../../Assets/Extensivo/Revalida/icon-bell.svg').default,
		title: ['Alerta de necessidade de', <br />, 'revisão'],
		description: [
			'Treinamento de ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				habilidades específicas
			</strong>,
			' em pequenos grupos.',
		],
	},
	{
		id: 9,
		img: require('../../Assets/Extensivo/Revalida/icon-dm-teacher.svg').default,
		title: ['Grupo de discussão com', <br />, 'professores e hardworkers'],
		description: [
			'Treinamento de ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				habilidades específicas
			</strong>,
			' em pequenos grupos.',
		],
	},
	{
		id: 10,
		img: require('../../Assets/Extensivo/Revalida/icon-brain-engine.svg').default,
		title: ['Treinamento', <br />, 'personalizado'],
		description: [
			'Orientações personalizadas do time Hardwork, com ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				professores e instrutores que já dominaram
			</strong>,
			' a prova prática do INEP.',
		],
	},
	{
		id: 11,
		img: require('./Sale/SprintFinal/assets/icon-head.svg').default,
		title: ['Orientaçaõ para recursos', <br />, 'da prova'],
		description: [
			'Treinamento de ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				habilidades específicas
			</strong>,
			' em pequenos grupos.',
		],
	},
	{
		id: 12,
		img: require('./Sale/SprintFinal/assets/icon-direct-contact.svg').default,
		title: ['Contato direto com os', <br />, 'professores'],
		description: [
			'Orientações personalizadas do time Hardwork, com ',
			<strong
				style={{ margin: '0 auto', display: 'inline' }}
				className="gradient-text"
			>
				professores e instrutores que já dominaram
			</strong>,
			' a prova prática do INEP.',
		],
	},
];

const learningPathTemplate = [
	{
		title: 'Primeira liga',
		description: [
			'A trilha do aprendizado funciona por ligas de evolução. Começaremos por temas mais palpáveis, evoluindo para outros mais peculiares.',
			<>
				<br />
				<br />
			</>,
			'São 8 ligas no total, cada uma com temas diferentes. Para passar para a próxima, precisaremos dominar por completo a atual.',
			<>
				<br />
				<br />
			</>,
			'Veja como tudo vai funcionar em cada liga:',
		],
		bgColor: '#ffffff',
		titleColor: '#121212',
		descriptionColor: 'primary',
		// url: 'https://player.vimeo.com/video/769930065',
	},
	{
		title: 'Aula Hardwork',
		description: [
			'Tudo começa com a apresentação ao tema, que no Hardwork é feito com rigor em didática.',
			<>
				<br />
				<br />
			</>,
			'São as melhores aulas que você já viu sobre cada tema.',
		],
		bgColor: '#0D0D0D',
		titleColor: '#09D785',
		descriptionColor: 'secondary',
		// url: 'https://player.vimeo.com/video/769929590',
	},
	{
		title: 'Estudo por questões',
		description: [
			'Alguns dias após a apresentação ao tema, vamos te conduzir pelo estudo por questões.',
			<>
				<br />
				<br />
			</>,
			'Aqui você entenderá todas as formas em que cada parte do tema é explorado em questões.',
			<>
				<br />
				<br />
			</>,
			'O professor Hardwork te mostrará tudo em detalhes, aprofundando em cada tema.',
		],
		bgColor: '#0D0D0D',
		titleColor: '#09D785',
		descriptionColor: 'secondary',
		// url: 'https://player.vimeo.com/video/769929662',
	},
	{
		title: 'Revisões cíclicas e personalizadas',
		description: [
			'Questões, questões e questões! É assim que revisamos no Hardwork!',
			<>
				<br />
				<br />
			</>,
			'Toda a dinâmica de revisões é, na verdade, uma continuidade do estudo por questões, explorando com mais ênfase os pontos que você tiver mais dificuldade.',
			<>
				<br />
				<br />
			</>,
			'Todos os temas precisam ser dominados!',
		],
		bgColor: '#0D0D0D',
		titleColor: '#09D785',
		descriptionColor: 'secondary',
		// url: 'https://player.vimeo.com/video/769929881',
	},
	{
		title: 'Avaliação de desempenho',
		description: [
			'A regra é dominar temas! A avaliação de desempenho e domínio dos temas será diária, usando métricas do estudo por questões.',
			<>
				<br />
				<br />
			</>,
			'Assim que o grau de suficiência em um tema for atingido, ele estará dominado! Domine todos e migre para a próxima liga de aprendizado.',
			<>
				<br />
				<br />
			</>,
			'"E se eu tiver dificuldade"? Calma! Siga a trilha...',
		],
		bgColor: '#0D0D0D',
		titleColor: '#09D785',
		descriptionColor: 'secondary',
		// url: 'https://player.vimeo.com/video/769929824',
	},
	{
		title: 'Estude com professor',
		description: [
			'Teve dificuldade? O professor estudará contigo!',
			<>
				<br />
				<br />
			</>,
			'Isso mesmo... vamos abrir o livro juntos, entender frase por frase, parágrafo por parágrafo, o tema ou seu fragmento!',
			<br />,
			'Vamos fazer isso usando a principal referência disponível, que estará à sua disposição na biblioteca virtual do Trilha.',
			<>
				<br />
				<br />
			</>,
			'Após o estudo em conjunto, nova avaliação de desempenho!',
		],
		bgColor: '#0D0D0D',
		titleColor: '#09D785',
		descriptionColor: 'secondary',
		// url: 'https://player.vimeo.com/video/769929949',
	},
	{
		title: 'Próxima liga',
		description: [
			'A evolução é contínua, são 8 ligas no total.',
			<>
				<br />
				<br />
			</>,
			'Na liga do aprendizado seguinte teremos novos temas, ou fragmentos complementares de temas anteriores.',
			<>
				<br />
				<br />
			</>,
			'Mas não pense que os já vistos serão esquecidos... jamais!',
			<>
				<br />
				<br />
			</>,
			'As revisões serão contínuas, até o fim!',
		],
		bgColor: '#ffffff',
		titleColor: '#121212',
		descriptionColor: 'primary',
		// url: 'https://player.vimeo.com/video/769929780',
	},
];

const description1 = [
	[
		'Ideal para a primeira etapa da 1ª edição do Revalida Inep 2024 –',
		<strong>Prova prevista para fevereiro de 2024.</strong>,
	],
];

const description2 = [
	[
		'Ideal para a primeira etapa da 2ª edição do Revalida Inep 2024 –',
		<strong>Prova prevista para agosto de 2024.</strong>,
	],
];

const description3 = [
	[
		'Ideal para a primeira etapa da 1ª edição do Revalida Inep 2025 –',
		<strong>Prova prevista para fevereiro de 2025.</strong>,
	],
];

const description4 = [
	[
		'Ideal para a primeira etapa da 2ª edição do Revalida Inep 2025 –',
		<strong>Prova prevista para agosto de 2025.</strong>,
	],
];

const infoTitles241 = [
	// { title: 'Inscrições', info: 'Até 30/04/2023' },
	{
		title: 'Duração de acesso à plataforma',
		info: 'Até 28/02/24',
		date: calculateMonthsFromToday('2024-02-28'),
		// date: calculateMonthsFromToday('2023-06-14'),
	},
	{ title: 'Início do acesso', info: '03/07/23' },
	{ title: 'Início da trilha', info: '03/07/23' },
	{ title: 'Liberação do Sprint Final', info: ['', <br />, '90 dias antes da primeira etapa do Revalida 2024.1*'] },
	// { title: 'Acesso ao Sprint', info: 'Até a data da prova' },
];

const infoTitles242 = [
	// { title: 'Inscrições', info: 'Até 30/04/2023' },
	{ title: 'Duração de acesso à plataforma', info: 'Até 11/08/24', date: calculateMonthsFromToday('2024-08-11') },
	{ title: 'Início do acesso', info: 'Imediato' },
	{ title: 'Início da trilha', info: 'Imediato' },
	{ title: 'Liberação do Sprint Final', info: ['', <br />, '90 dias antes da primeira etapa do Revalida 2024.2*'] },
	// { title: 'Acesso ao Sprint', info: 'Até a data da prova' },
];

const infoTitles251 = [
	// { title: 'Inscrições', info: 'Até 30/04/2023' },
	{ title: 'Duração de acesso à plataforma', info: 'Até 10/03/25', date: calculateMonthsFromToday('2025-03-10') },
	{ title: 'Início do acesso', info: 'Imediato' },
	{ title: 'Início da trilha', info: 'Imediato' },
	{ title: 'Liberação do Sprint Final', info: ['', <br />, '90 dias antes da primeira etapa do Revalida 2025.1*'] },
	// { title: 'Acesso ao Sprint', info: 'Até a data da prova' },
];

const infoTitles252 = [
	// { title: 'Inscrições', info: 'Até 30/04/2023' },
	{ title: 'Duração de acesso à plataforma', info: 'Até 08/25', date: calculateMonthsFromToday('2025-08') },
	{ title: 'Início do acesso', info: 'Imediato' },
	{ title: 'Início da trilha', info: 'Imediato' },
	{ title: 'Liberação do Sprint Final', info: ['', <br />, '90 dias antes da primeira etapa do Revalida 2025.2*'] },
	// { title: 'Acesso ao Sprint', info: 'Até a data da prova' },
];

const benefits2024 = [
	{ title: 'Estudo por questões - Acesso à Trilha do aprendizado' },
	{ title: 'Banco de aulas e resumos Hardwork' },
	{ title: 'Banco de lives Hardwork' },
	{ title: 'Banco de questões' },
	{ title: 'Questões do Inep comentadas' },
	{ title: [<strong>BÔNUS:</strong>, ' Acesso à Minha Biblioteca™', <br />, '(acesso online a diversos livros)'] },
];

const benefits2025 = [
	{ title: 'Banco de lives Hardwork' },
	{ title: 'Banco de questões' },
	{ title: 'Liberação progressiva de aulas, resumos e questões temáticas' },
	{ title: 'Questões do Inep comentadas' },
	{ title: [<strong>BÔNUS:</strong>, ' Acesso à Minha Biblioteca™', <br />, '(acesso online a diversos livros)'] },
];

const ahead1 = [{ title: 'Acesso antecipado ao Sprint' }, { title: 'Simulados online' }];
const ahead2 = [
	{ title: 'Estudo por questões - Acesso à Trilha do aprendizado' },
	{ title: 'Acesso antecipado ao Sprint' },
	{ title: 'Simulados online' },
];

const testDates = [
	'Segundo semestre 2024',
	'Primeiro semestre 2025',
	'Segundo semestre 2025',
	'Primeiro semestre 2026',
];

const CustomModal = ({ open, handleClose, classes, matchesTablet, matchesMobile, agrupamento }) => {
	const [preInscricao, setPreInscricao] = useState({
		agrupamento: agrupamento,
		nome: '',
		email: '',
		telefone: '',
		provas: '',
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
	});
	const [testDate, setTestDate] = useState();
	const [searchParams] = useSearchParams();

	useEffect(() => {
		setPreInscricao({
			...preInscricao,
			utm_source: searchParams.get('utm_source'),
			utm_medium: searchParams.get('utm_medium'),
			utm_campaign: searchParams.get('utm_campaign'),
		});
	}, []);

	const handlePhone = (valid, phoneNumber) => {
		if (valid) {
			//console.log('ok', phoneNumber);
			setPreInscricao({
				...preInscricao,
				telefone: phoneNumber,
			});
		} else if (preInscricao.telefone.length) {
			setPreInscricao({
				...preInscricao,
				telefone: '',
			});
		}
	};

	let sendPreInscricao = function () {
		if (!preInscricao.nome || preInscricao.nome.trim().split(' ').length < 2) {
			alert('Ops... Por favor, informe seu nome completo');
			return;
		}
		if (!preInscricao.email || !preInscricao.email.includes('@') || !preInscricao.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (!preInscricao.telefone) {
			alert('Ops... Por favor preencha seu telefone com um número válido');
			return;
		}
		if (!preInscricao.provas) {
			alert('Ops... Por favor informe a data desejada');
			return;
		}

		trackPromise(
			Api.post('PreInscricao', preInscricao).then((res) => {
				if (res) {
					Swal.fire({
						title: `${'Massa!'} `,
						text: `${'Sua pré-inscrição está confirmada! Avisaremos assim que a nova turma estiver liberada.'}`,
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then((result) => {
						if (result.isConfirmed) {
							document.location.href = '/trilha-revalida';
						}
					});
				}
			})
		);
	};

	const handleChangeTestDate = (event) => {
		var date = event.target.value;
		if (date === null) {
			setTestDate(null);
			return;
		}
		setTestDate(date);
		setPreInscricao({
			...preInscricao,
			provas: date,
		});
	};

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			className={classes.modalContent}
		>
			<Grid
				item
				container
				alignContent={'space-around'}
				className={classes.gridForm}
			>
				<Grid
					item
					container
					justifyContent="space-between"
					style={{ marginBottom: 16 }}
				>
					<Typography
						color="primary"
						className={`${classes.titleForm} poppins`}
					>
						Diga-nos em qual data vai
						<br />
						fazer o INEP:
					</Typography>
					<CancelRoundedIcon
						onClick={handleClose}
						className={classes.cancelIcon}
					/>
				</Grid>
				<form
					className="form-control"
					action="javascript:;"
				>
					<Grid
						item
						container
						alignContent={'center'}
						justifyContent={'center'}
						rowGap={1}
					>
						<Grid
							item
							xs={12}
							md={6}
							container
							justifyContent={matchesTablet ? 'center' : 'flex-start'}
						>
							<TextField
								fullWidth
								type="string"
								name="nome"
								placeholder="Seu nome completo"
								required={true}
								InputProps={{
									className: `${classes.form} ${classes.formHeight}`,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								style={matchesTablet ? { width: '100%' } : { width: '97%' }}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										nome: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							container
							justifyContent={matchesTablet ? 'center' : 'flex-end'}
						>
							<IntlTelInput // Componente Input de telefones internacionais
								preferredCountries={['BR', 'PY', 'AR', 'CL', 'BO', 'UY', 'CU', 'PT']}
								style={{
									width: '100%',
									margin: '5px 0',
									border: '1px solid #a2a2a2',
									borderRadius: 8,
								}}
								inputClassName={`${classes.phoneNumberForm} ${classes.form} ${
									/*rgField ? classes.formHeightShort :*/ classes.formHeight
								}`}
								nationalMode={true}
								onPhoneNumberChange={(valid, value, selectedCountryData, fullNumber) =>
									handlePhone(valid, fullNumber)
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							container
							justifyContent={matchesMobile ? 'center' : 'flex-start'}
						>
							<TextField
								fullWidth
								type="email"
								name="email"
								placeholder="Seu melhor e-mail"
								required={true}
								InputProps={{
									className: `${classes.form} ${classes.formHeight}`,
								}}
								InputLabelProps={{
									shrink: true,
								}}
								style={{ width: '100%' }}
								onChange={(e) =>
									setPreInscricao({
										...preInscricao,
										email: e.target.value,
									})
								}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							container
							justifyContent={matchesMobile ? 'center' : 'flex-start'}
						>
							<FormControl style={{ width: '100%' }}>
								<InputLabel id="test-select-label">Data da prova INEP desejada</InputLabel>
								<Select
									value={testDate}
									labelId="test-select-label"
									onChange={handleChangeTestDate}
									className={classes.selectMenu}
								>
									<MenuItem
										value={null}
										className={classes.tableRowEven}
									>
										<em>Data da prova INEP desejada</em>
									</MenuItem>
									{testDates.map((item, index) => {
										const rowClass = index % 2 === 0 ? classes.tableRowOdd : classes.tableRowEven;
										return (
											<MenuItem
												key={index}
												value={item}
												className={`${rowClass} poppins`}
											>
												<Typography
													color="primary"
													className={`${classes.textSelectItem} poppins`}
												>
													{item}
												</Typography>
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid
							item
							xs={12}
							md={10}
							lg={8}
							container
							justifyContent={'center'}
							className="revalida"
							style={{ padding: '4px 0px' }}
						>
							<DefaultButton
								type="submit"
								variant="contained"
								color="primary"
								className={`${classes.optionBtn} btn btn-primary`}
								onClick={() => sendPreInscricao()}
							>
								ENVIAR
							</DefaultButton>
						</Grid>
					</Grid>
				</form>
			</Grid>
		</Modal>
	);
};

export const Countdown = ({ startDate, endDate, matchesMobile, classes }) => {
	const [timeRemaining, setTimeRemaining] = useState(0);

	useEffect(() => {
		const startCountdownTime = startDate.getTime();
		const endCountdownTime = endDate.getTime();

		const currentTime = new Date().getTime();

		if (currentTime < startCountdownTime) {
			setTimeRemaining(0); // Define o tempo restante como zero antes do início do countdown
		} else if (currentTime >= startCountdownTime && currentTime < endCountdownTime) {
			setTimeRemaining(Math.ceil((endCountdownTime - currentTime) / 1000));
		} else {
			setTimeRemaining(0);
		}

		const timer = setInterval(() => {
			const currentTime = new Date().getTime();

			if (currentTime < startCountdownTime) {
				setTimeRemaining(0); // Define o tempo restante como zero antes do início do countdown
			} else if (currentTime >= startCountdownTime && currentTime < endCountdownTime) {
				setTimeRemaining(Math.ceil((endCountdownTime - currentTime) / 1000));
			} else {
				setTimeRemaining(0);
			}
		}, 1000);

		return () => clearInterval(timer);
	}, [startDate, endDate]);

	const formatTime = (time) => {
		const hours = Math.floor(time / 3600);
		const minutes = Math.floor((time % 3600) / 60);
		const seconds = time % 60;

		// Formatar os valores para ter sempre 2 dígitos
		const formattedHours = hours.toString().padStart(2, '0');
		const formattedMinutes = minutes.toString().padStart(2, '0');
		const formattedSeconds = seconds.toString().padStart(2, '0');

		return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
	};

	return (
		<>
			{timeRemaining ? (
				<Grid
					item
					xs={11}
					md={9}
					lg={6}
					container
					justifyContent={'space-around'}
					alignContent={'center'}
					className={classes.timerGrid}
					gap={matchesMobile ? 1 : 4}
				>
					<Typography
						color="secondary"
						className={`${classes.endOfDiscount} poppins`}
					>
						<strong style={{ color: '#FFF460' }}>Valores promocionais</strong>
						<br />
						se encerram em
					</Typography>
					<Typography
						color="secondary"
						className={`${classes.timeText} wallpoet`}
					>
						{formatTime(timeRemaining)}
					</Typography>
					<img
						// src={require('../../Assets/Extensivo/Revalida/icon-watch-timer.svg').default}
						src={require('../../Assets/Extensivo/Revalida/icon-watch-timer.png')}
						alt=""
						className={classes.timerIcon}
					/>
				</Grid>
			) : null}
		</>
	);
};

function calculateMonthsFromToday(date) {
	// Get the current date
	const currentDate = new Date();

	// Get the provided date as an argument
	const providedDate = new Date(date);

	// Calculate the difference in months
	const monthDifference =
		(currentDate.getFullYear() - providedDate.getFullYear()) * 12 +
		(currentDate.getMonth() - providedDate.getMonth());

	// Take the absolute value of the month difference
	const absoluteMonthDifference = Math.abs(monthDifference);

	return absoluteMonthDifference;
}

export default function HardworkRevalida232425() {
	const classes = useStyles();
	const theme = useTheme();
	// const [isSelected, setIsSelected] = useState(false);
	const subscribe_card = useRef(null);
	const startDate = new Date('2023-06-12T11:16:00'); // Data Para Começar
	// const startDate = new Date('2023-06-13T20:00:00'); // Data Para Começar
	const endDate = new Date('2023-06-14T21:00:00');

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesDown1400 = useMediaQuery(theme.breakpoints.down(1400));

	const executeScroll = () =>
		subscribe_card.current.scrollIntoView({ behavior: 'smooth', block: matchesTablet ? 'start' : 'center' });

	const [open, setOpen] = useState(false);

	const showDiscount = startDate <= new Date() && endDate >= new Date();

	const handleOpen = (agrupamento) => {
		setSelectedAgrupamento(agrupamento);
		setOpen(true);
	};
	const handleClose = () => setOpen(false);

	const [selectedAgrupamento, setSelectedAgrupamento] = useState('REVALIDA INEP FUTURO (> 2024.1)');

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
            <Helmet>
				<title>Trilha Revalida - Hardwork Medicina</title>
				<meta
					name="description"
					content="Hora de trilhar o aprendizado.
                    A nova preparação revolucionária
                    para a prova do Revalida INEP."
				/>
			</Helmet>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="revalida"
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<FirstSection
					backgroundImageUrl={process.env.PUBLIC_URL + '/Assets/Img/bg-trilha-revalida.png'}
					logoUrl={process.env.PUBLIC_URL + '/Assets/TrilhaRevalida/newRevalidaHwmLogo.svg'}
					type="green"
					buttonText="Inscrever-se"
					executeScroll={executeScroll}
					firstTextPaper={[
						<Typography
							align="left"
							color="secondary"
							className={`${classes.firstText} roboto-condensed`}
						>
							Hora de trilhar o aprendizado.
						</Typography>,
						<Typography
							align="left"
							color="secondary"
							className={`${classes.text} poppins`}
						>
							A nova preparação revolucionária
							<br />
							para a prova do{' '}
							<strong
								className={`${classes.gradientText} gradient-text roboto-condensed`}
								style={matchesMobile ? { display: 'block' } : { display: 'inline' }}
							>
								Revalida INEP.
							</strong>
						</Typography>,
						<Grid
							item
							container
						>
							<Divider
								variant="middle"
								className={classes.divider2}
							/>
						</Grid>,
						<Typography
							align="left"
							color="secondary"
							className={`${classes.text2} poppins`}
						>
							{matchesMobile ? (
								<>
									Aqui a gente aprende de verdade e domina a prova!
									<br />
									<br />A junção do Extensivo Hardwork com o
									<p
										className="gradient-text"
										style={{ display: 'inline' }}
									>
										{' '}
										Sprint Final Revalida{' '}
									</p>
									num novo formato.
								</>
							) : (
								<>
									Aqui a gente aprende de verdade e domina a prova!
									<br />
									<br />A junção do Extensivo Hardwork com o
									<p
										className="gradient-text"
										style={{ display: 'inline' }}
									>
										{' '}
										Sprint Final Revalida{' '}
									</p>
									num novo formato.
								</>
							)}
						</Typography>,
					]}
					buttonProps={{
						color: 'secondary',
						buttonLogoUrl: require('../../Assets/Svg/Icons/bagIcon.svg').default,
					}}
				/>
			</Container>

			<Grid
				container
				className={classes.backgroundArrows}
			>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					<Grid
						item
						container
						alignContent="center"
						justifyContent="space-around"
						className="section"
						rowGap={matchesDown1400 ? 1 : 2}
						style={{
							margin: `${matchesMobile ? '0 auto 8px' : matchesTablet ? '20px auto' : '60px auto 0'}`,
						}}
					>
						<Grid
							item
							xs={12}
							container
							alignContent={'flex-end'}
							justifyContent={'space-between'}
						>
							<Typography
								className={`${classes.subtitle1} roboto-condensed`}
								color="secondary"
							>
								VAI FAZER QUAL PROVA DO INEP?
							</Typography>

							{!matchesTablet && (
								<DefaultButton
									variant="outlined"
									color="secondary"
									className={`${classes.btn} btn`}
									onClick={handleOpen}
									selectedAgrupamento="REVALIDA INEP FUTURO (> 2024.1)"
								>
									Outra edição futura?
								</DefaultButton>
							)}
							{open && (
								<>
									<div className={classes.backdropBlur}></div>
									<CustomModal
										open={open}
										handleClose={handleClose}
										classes={classes}
										matchesTablet={matchesTablet}
										matchesMobile={matchesMobile}
										agrupamento={selectedAgrupamento}
									/>
								</>
							)}
						</Grid>
					</Grid>
				</Container>
				<Grid
					item
					xs={12}
					container
				>
					<Divider
						variant="middle"
						className={classes.whiteDivider}
					/>
				</Grid>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					<Grid
						item
						container
						justifyContent={'center'}
					>
						<Grid
							item
							container
							alignContent="center"
							justifyContent="space-around"
							className="section"
							rowGap={matchesDown1400 ? 1 : 2}
							style={{
								margin: `${matchesTablet ? '20px auto 40px' : '70px auto 60px'}`,
							}}
							ref={subscribe_card}
						>
							<Grid
								item
								container
								justifyContent="center"
								className={classes.gridExtensivoCard}
							>
								<ExtensivoRevalidaCard
									idTurma={'1189'}
									description={description1}
									info={infoTitles241}
									image={
										process.env.PUBLIC_URL + '/Assets/TrilhaRevalida/icon-trilha-revalida-24-1.png'
									}
									//discount={'1.000'}
									benefits={benefits2024}
									ahead={ahead1}
									showDiscount={showDiscount}
									handleOpen={handleOpen}
									selectedAgrupamento={'TRILHA REVALIDA - LISTA DE ESPERA'}
									//enableText={true}
									//enableButton={true}
									//enablePrice={false}
								/>
								{open && (
									<>
										<div className={classes.backdropBlur}></div>
										<CustomModal
											open={open}
											handleClose={handleClose}
											classes={classes}
											matchesTablet={matchesTablet}
											matchesMobile={matchesMobile}
											selectedAgrupamento={selectedAgrupamento}
										/>
									</>
								)}
							</Grid>
							<Grid
								item
								container
								justifyContent="center"
								className={classes.gridExtensivoCard}
							>
								<ExtensivoRevalidaCard
									idTurma={'1190'}
									description={description2}
									info={infoTitles242}
									image={
										process.env.PUBLIC_URL + '/Assets/TrilhaRevalida/icon-trilha-revalida-24-2.png'
									}
									discount={'1.000'}
									benefits={benefits2024}
									ahead={ahead1}
									showDiscount={showDiscount}
									handleOpen={handleOpen}
									selectedAgrupamento={'TRILHA REVALIDA - LISTA DE ESPERA'}
									//enableText={true}
									//enableButton={true}
									//enablePrice={false}
								/>
								{open && (
									<>
										<div className={classes.backdropBlur}></div>
										<CustomModal
											open={open}
											handleClose={handleClose}
											classes={classes}
											matchesTablet={matchesTablet}
											matchesMobile={matchesMobile}
											agrupamento={selectedAgrupamento}
										/>
									</>
								)}
							</Grid>
							<Grid
								item
								container
								justifyContent="center"
								className={classes.gridExtensivoCard}
							>
								<ExtensivoRevalidaCard
									idTurma={'1191'}
									description={description3}
									info={infoTitles251}
									image={
										process.env.PUBLIC_URL + '/Assets/TrilhaRevalida/icon-trilha-revalida-25-1.png'
									}
									discount={'1.000'}
									benefits={benefits2025}
									ahead={ahead2}
									showDiscount={showDiscount}
									resums
									handleOpen={handleOpen}
									selectedAgrupamento={'TRILHA REVALIDA - LISTA DE ESPERA'}
									//enableText={false}
									//enableButton={true}
									//enablePrice={false}
								/>
								{open && (
									<>
										<div className={classes.backdropBlur}></div>
										<CustomModal
											open={open}
											handleClose={handleClose}
											classes={classes}
											matchesTablet={matchesTablet}
											matchesMobile={matchesMobile}
											agrupamento={selectedAgrupamento}
										/>
									</>
								)}
							</Grid>
							<Grid
								item
								container
								justifyContent="center"
								className={classes.gridExtensivoCard}
							>
								<ExtensivoRevalidaCard
									idTurma={'1192'}
									description={description4}
									info={infoTitles252}
									image={
										process.env.PUBLIC_URL + '/Assets/TrilhaRevalida/icon-trilha-revalida-25-2.png'
									}
									discount={'1.000'}
									benefits={benefits2025}
									ahead={ahead2}
									showDiscount={showDiscount}
									resums
									handleOpen={handleOpen}
									selectedAgrupamento={'TRILHA REVALIDA - LISTA DE ESPERA'}
									//enableText={false}
									//enableButton={true}
									//enablePrice={true}
								/>
								{open && (
									<>
										<div className={classes.backdropBlur}></div>
										<CustomModal
											open={open}
											handleClose={handleClose}
											classes={classes}
											matchesTablet={matchesTablet}
											matchesMobile={matchesMobile}
											agrupamento={selectedAgrupamento}
										/>
									</>
								)}
							</Grid>
							{matchesTablet && (
								<DefaultButton
									variant="outlined"
									color="secondary"
									className={`${classes.btn} btn`}
									onClick={handleOpen}
									selectedAgrupamento={'REVALIDA INEP FUTURO (> 2024.1)'}
								>
									Outra edição futura?
								</DefaultButton>
							)}
							<Grid
								container
								alignItems={'center'}
								direction={'column'}
								style={{ marginTop: '1rem' }}
							>
								<Grid
									item
									container
									justifyContent={'center'}
									className={classes.discountGrid}
								>
									<Typography
										color="secondary"
										className="poppins"
									>
										Desconto de 30% para ex-alunos Extensivo Revalida, Sprint Revalida e/ou Arena
										Revalida.*
									</Typography>
								</Grid>
								<Grid
									item
									container
									justifyContent={'center'}
									className={classes.discountGrid}
								>
									<Typography
										color="secondary"
										className="poppins"
									>
										*Consulte opções de parcelamento no checkout.
									</Typography>
								</Grid>
								<Grid
									item
									container
									justifyContent={'space-between'}
									className={classes.bonusGrid}
								>
									<Grid
										item
										justifyContent={'space-between'}
									>
										<img
											src={process.env.PUBLIC_URL + '/Assets/TrilhaRevalida/icon-gift.svg'}
											alt=""
											style={{
												paddingRight: '0.5 rem',
											}}
											className={classes.iconGift}
										/>
									</Grid>

									<Typography
										color="primary"
										className={classes.bonusText}
									>
										*BÔNUS EXCLUSIVO: Vamos te treinar para a prova DISCURSIVA!
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Countdown
							startDate={startDate}
							endDate={endDate}
							matchesMobile={matchesMobile}
							classes={classes}
						/>
					</Grid>
				</Container>
			</Grid>

			<LearningPath
				title={'O que é a Trilha do aprendizado definitivo?'}
				cardsInfo={learningPathTemplate}
				background={'white-background'}
			/>

			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid className={classes.containerBackgroundColor}>
					<Grid
						item
						xs={12}
						container
						style={matchesMobile ? { marginBottom: 16, paddingLeft: '1em' } : { left: '2em' }}
						className="revalida"
					>
						<Typography
							className={`${classes.subtitle1} roboto-condensed gradient-text`}
							color="primary"
						>
							Além da trilha do aprendizado você também ganha
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						container
						justifyContent={'center'}
						style={{ marginTop: matchesMobile ? 0 : '2rem', paddingBottom: '2rem' }}
					>
						{trilhaBonus.map((item, index) => {
							return (
								<Grid
									key={item.id}
									item
									xs={6}
									md={/*activeButtonId === 1 ? 4 : */ 3}
									container
									justifyContent={'center'}
									className={`${classes.gridFlipCard} revalida`}
								>
									<FlipCard
										noFlip
										key={item.id}
										image={item.img}
										title={item.title}
										description={item.description}
										color="secondary"
										mobile={matchesMobile}
										size={/*activeButtonId === 1 ? 4 : */ 3}
										classes={classes}
									/>
								</Grid>
							);
						})}
					</Grid>
					<Grid
						item
						xs={12}
						container
						justifyContent={'center'}
					>
						<Divider
							variant="middle"
							className={classes.divider}
						/>
					</Grid>
					<Grid
						container
						justifyContent={matchesTablet ? 'center' : 'space-around'}
						alignContent={'center'}
						style={{ margin: matchesMobile ? '1rem auto' : '2rem auto', padding: '0 2rem' }}
					>
						<MultimidiaCard
							imgUrl={require('../../Assets/Extensivo/Revalida/icon-proof.svg').default}
							description={'A maior revolução Hardwork: Vamos te treinar para a prova DISCURSIVA!'}
						/>
					</Grid>
					{/* <Typography
						style={{
							fontSize: '1.2rem',
						}}
					>
						*Divulgaremos mais detalhes em novembro.
					</Typography> */}
				</Grid>
				<Grid
					className={classes.containerBackgroundColor}
					style={matchesMobile ? { paddingBottom: '2rem' } : null}
				>
					<Grid
						item
						xs={12}
						container
						style={
							matchesMobile
								? { marginBottom: 16, paddingLeft: '1em' }
								: { left: '2em', marginBottom: '2rem' }
						}
						className="revalida"
					>
						<Typography
							className={`${classes.subtitle1} roboto-condensed gradient-text`}
							color="primary"
						>
							E na hora da revisão, receba antecipadamente
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						container
						justifyContent="center"
						style={matchesMobile ? { marginBottom: '1rem' } : null}
					>
						<Grid
							item
							xs={6}
							container
							justifyContent={'center'}
							alignContent={'center'}
							style={{ borderRight: '2px solid #6D6D6D' }}
						>
							<img
								src={require('./Sale/SprintFinal/assets/logo-new-sf-revalida.svg').default}
								alt=""
							/>
						</Grid>
						<Grid
							item
							xs={6}
							container
							justifyContent={'center'}
							alignContent={'center'}
							style={{ padding: matchesMobile ? '0 1rem' : '0 3rem' }}
						>
							<Typography
								color="secondary"
								className={`${classes.sprintText} poppins`}
							>
								Acesso garantido e antecipado ao <strong>Sprint Final Revalida</strong>, a única revisão
								realmente focada na prova do Inep.
							</Typography>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						container
						justifyContent={'center'}
						style={{ marginTop: matchesMobile ? 0 : '2rem', paddingBottom: '2rem' }}
					>
						{sprintFinal.map((item, index) => {
							return (
								<Grid
									key={item.id}
									item
									xs={6}
									md={/*activeButtonId === 1 ? 4 : */ 3}
									container
									justifyContent={'center'}
									className={`${classes.gridFlipCard} revalida`}
								>
									<FlipCard
										noFlip
										key={item.id}
										image={item.img}
										title={item.title}
										description={item.description}
										color="secondary"
										mobile={matchesMobile}
										size={/*activeButtonId === 1 ? 4 : */ 3}
										classes={classes}
									/>
								</Grid>
							);
						})}
					</Grid>
					<Grid
						item
						xs={12}
						container
						justifyContent={'center'}
					>
						<Divider
							variant="middle"
							className={classes.divider}
						/>
					</Grid>
					<Grid
						container
						justifyContent={matchesTablet ? 'center' : 'space-around'}
						alignContent={'center'}
						style={{ margin: matchesMobile ? '1rem auto' : '2rem auto', padding: '0 2rem' }}
					>
						<MultimidiaCard
							imgUrl={require('../LandingPages/Sale/SprintFinal/assets/logo-multimidia.svg').default}
							description={'Ganhe acesso ao treinamento multimídia 2023 do HWM'}
						/>
					</Grid>
				</Grid>
				{/* <Grid
					className={classes.containerBackgroundColor}
					style={{ paddingBottom: matchesMobile ? '1rem' : '4rem' }}
				>
					<Grid
						item
						xs={12}
						container
						className="revalida"
						style={{ padding: matchesMobile ? '0px 0px 20px 20px' : '20px 0' }}
					>
						<Typography
							className={`${classes.subtitle1} roboto-condensed`}
							color="secondary"
						>
							E ganhe ainda um presente especial
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						container
						style={{ padding: '0 1rem' }}
					>
						<Grid
							item
							xs={10}
							md={4}
						>
							<img
								src={require('../../Assets/Extensivo/Revalida/img-alexa-in-the-hands.png')}
								alt=""
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={8}
							container
							alignContent="flex-end"
							style={
								matchesMobile
									? { padding: '1em 0' }
									: matchesTablet
									? { padding: '1em 0' }
									: { padding: '0 4em 0 2em' }
							}
						>
							<Grid
								item
								xs={12}
								className="revalida"
							>
								<Typography
									className={`${classes.flashCardsTitle} residencia gradient-text`}
									color="secondary"
								>
									Flashcards Hardwork
								</Typography>
							</Grid>
							<Grid
								item
								xs={12}
							>
								<Typography
									color="secondary"
									className={`${classes.thirdSectionText} poppins`}
								>
									Hora de revisar "brincando" com a skill dos flashcards Revalida do Hardwork Medicina
									na Alexa!
									<br />
									<br />
									Você receberá em casa, gratuitamente, um dispositivo <strong>
										Echo Dot 4
									</strong>{' '}
									para usar como quiser! Basta pedir: "Alexa, abra Flashcards Revalida" e ver a mágica
									acontecer...
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid> */}
			</Container>

			<StudentsFeedbackSection type="revalida" />
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	gradientText: {
		fontSize: '2.75rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '3rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.75rem !important',
		},
		[theme.breakpoints.down(901)]: {
			fontSize: '2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.75rem !important',
		},
	},
	firstText: {
		fontSize: '2.65rem !important',
		fontWeight: '600 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down(361)]: {
			fontSize: '1.4rem !important',
		},
	},
	text: {
		fontSize: '1.85rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.35rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.05rem !important',
		},
		[theme.breakpoints.down(361)]: {
			fontSize: '1rem !important',
		},
	},
	text2: {
		fontSize: '1.45rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.35rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.05rem !important',
		},
	},
	subtitle1: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
			fontSize: '1.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.65rem !important',
			lineHeight: 'unset !important',
		},
	},
	textMultimidiaCard: {
		fontSize: '1.2rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	containerBackgroundColor: {
		backgroundColor: '#33333380',
		margin: '60px auto 30px',
		borderRadius: 15,
		maxWidth: '1410px !important',
		padding: '2em 2.5% 3vh',
		[theme.breakpoints.down('sm')]: {
			padding: '3vh 2.5% 0',
			borderRadius: 'unset',
		},
	},
	gridFlipCard: {
		margin: '15px 0 !important',
		height: 270,
		padding: '0 1rem',
		[theme.breakpoints.down('sm')]: {
			padding: 'unset',
			height: 160,
		},
	},
	divider: {
		minHeight: 1,
		backgroundColor: '#6D6D6D',
		width: '98%',
		margin: '2rem 0 !important',
	},
	divider2: {
		minHeight: 1,
		backgroundColor: '#6D6D6D',
		width: '98%',
		margin: '1rem 0 !important',
	},
	gridExtensivoCard: {
		width: '337px !important',
		marginBottom: '2rem !important',
		[theme.breakpoints.down('xl')]: {
			width: '285px !important',
		},
		[theme.breakpoints.down(1400)]: {
			width: '265px !important',
		},
		[theme.breakpoints.down('lg')]: {
			width: '205px !important',
		},
		[theme.breakpoints.down('md')]: {
			width: '287px !important',
			marginBottom: '32px !important',
		},
		[theme.breakpoints.down('sm')]: {
			width: 'unset !important',
			marginBottom: '3rem !important',
		},
	},
	btn: {
		height: 50,
		fontSize: '1rem !important',
		width: 'unset',
		fontWeight: 'bold !important',
		border: '4px solid !important',
		transition: 'ease-in',
		alignSelf: 'center',
		borderRadius: '10px !important',
		backgroundColor: '#000000 !important',
		'&:hover': {
			scale: 1.05,
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.75rem !important',
			minHeight: 'unset !important',
		},
		[theme.breakpoints.down('md')]: {
			marginTop: '3rem !important',
			height: 'unset',
			fontSize: '0.95rem !important',
			width: '90%',
		},
	},
	modalContent: {
		margin: 'auto',
		borderRadius: 12,
		width: '697px', // Define a largura do modal
		height: 'fit-content',
		border: '2px solid #000000',
		backgroundColor: '#DCDCDC !important',
		zIndex: '1000 !important',
		boxShadow: theme.shadows[5], // Adiciona uma sombra ao modal
		'& .MuiBackdrop-root': {
			backgroundColor: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			width: 'unset',
			margin: 'auto 1rem',
		},
	},
	form: {
		borderRadius: '8px !important',
		backgroundColor: '#E1E1E1 !important',
		margin: '5px 0 !important',
	},
	formHeight: {
		maxHeight: '3em',
	},
	cancelIcon: {
		color: '#000000',
		right: '-20px',
		top: '-10px',
		fontSize: '2rem !important',
		'&:hover': {
			cursor: 'pointer',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'absolute',
			top: '-20px',
			right: '-18px',
		},
	},
	optionBtn: {
		fontSize: '1.35rem !important',
		fontWeight: 'bold !important',
		width: '80%',
	},
	gridForm: {
		padding: '1.5rem 2rem',
		backgroundImage: `url(${process.env.PUBLIC_URL + '/Assets/Img/bgWave2.png'})`,
		height: '100%',
		[theme.breakpoints.down('sm')]: {
			padding: '1.5rem',
		},
	},
	titleForm: {
		fontSize: '2.25rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem !important',
		},
	},
	backdropBlur: {
		position: 'fixed',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		backgroundColor: '#00000080',
		zIndex: 999,
	},
	tableRowEven: {
		backgroundColor: '#CCCCCC !important',
	},
	whiteDivider: {
		minHeight: 1,
		backgroundColor: '#ffffff',
		width: '57%',
		margin: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			width: '95%',
		},
	},
	sprintText: {
		fontSize: '1.35rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem !important',
		},
	},
	timerGrid: {
		position: 'fixed',
		bottom: '2rem',
		zIndex: 3,
		border: '3px solid #ffffff',
		margin: '0 auto !important',
		backgroundColor: '#000000',
		padding: '0.75rem 9rem 0.75rem 4rem',
		borderRadius: '25px 50px 50px 25px !important',
		[theme.breakpoints.down('md')]: {
			padding: '0.75rem 2rem 0.75rem 3rem',
			borderRadius: '15px !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0.75rem 1rem',
		},
	},
	backgroundArrows: {
		backgroundImage: `url(${require('../../Assets/Extensivo/Revalida/bg-arrows-trilha-revalida.png')})`,
		backgroundSize: 'cover',
		padding: '0 0 3rem',
		[theme.breakpoints.down('md')]: {
			paddingTop: '3rem',
		},
		[theme.breakpoints.down('sm')]: {
			backgroundImage: `url(${require('../../Assets/Extensivo/Revalida/bg-arrows-trilha-revalida-mobile.png')})`,
			paddingTop: 'unset',
			marginTop: '3rem',
		},
	},
	flashCardsTitle: {
		fontSize: '1.8em !important',
		fontWeight: 'bold !important',
	},
	thirdSectionText: {
		fontSize: '1.25rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	timerIcon: {
		position: 'absolute',
		right: -14,
		top: -29,
		[theme.breakpoints.down('xl')]: {
			top: -20,
			right: -11,
			width: '10%',
		},
		[theme.breakpoints.down(1450)]: {
			width: '15%',
		},
		[theme.breakpoints.down('lg')]: {
			top: -32,
			right: -13,
			scale: 0.85,
		},
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	timeText: {
		fontSize: '3.85rem !important',
		lineHeight: 'unset !important',
		color: 'yellow !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2.5rem !important',
			alignSelf: 'center',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '3rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
		},
	},
	endOfDiscount: {
		fontSize: '1.25rem !important',
		alignSelf: 'center',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	phoneNumberForm: {
		height: '100%',
		borderRadius: 10,
		width: '100%',
		border: 'unset',
		'&:hover': {
			border: '1px solid #000000de',
		},
		[theme.breakpoints.down('md')]: {
			height: '3em',
		},
	},
	discountGrid: {
		backgroundColor: '#000000',
		borderRadius: 15,
		padding: '0.5rem 2rem',
		width: 'fit-content !important',
		margin: '0.5rem 0 !important',
	},
	bonusGrid: {
		backgroundColor: '#ffffffff',
		borderRadius: 15,
		padding: '0.5rem 1rem',
		width: 'fit-content !important',
		margin: '0.5rem 0 !important',
	},
	bonusText: {
		fontWeight: 'bolder !important',
		fontSize: '1.2rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.35rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.05rem !important',
		},
		iconGift: {
			padding: '2rem',
		},
	},
}));