import React from 'react';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import iconCap from '../assets/icon-graduation-cap-duotone.svg';

const BoxSlider = ({ text, title, subtitle, background }) => {
	const theme = useTheme();
	const classes = useStyles();
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
    const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
    

	return (
		<Grid
			container
			justifyContent={matchesMobile ? 'center' : 'flex-start'}
			alignItems={'flex-end'}
			className={classes.box}
			style={{
				backgroundImage: `url(${background})`,
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
			}}
		>
			<Grid
				container
				xs={12}
				lg={12}
				direction="column"
                justifyContent={matchesTablet ? 'flex-end' : 'center'}
				rowGap={matchesTablet ? 2 : 4}
				style={{
					height: matchesTablet ? '50%' : null,
					paddingRight: !matchesTablet && '3rem',
				}}
			>
				<Grid item>
					<Grid
                   item
                   display={'flex'}
						justifyContent={'flex-start'}
						alignItems={'center'}

					>
						<img
							src={iconCap}
							alt="r3CG"
                            style={{marginRight: 10}}
						/>
						<Typography
							fontFamily={'Manrope'}
							fontWeight={800}
							color={'#FFFFFF'}
							className={classes.title}
                            
						>
							{title}
						</Typography>
					</Grid>
					<Typography
						fontFamily={'Manrope'}
						fontWeight={500}
						color={'#FFFFFF'}
						className={classes.subtitle}
					>
						{subtitle}
					</Typography>
				</Grid>
				<Typography
					fontFamily={'Manrope'}
					fontSize={20}
					fontWeight={400}
					color={'#FFFFFF'}
					className={classes.text}
					style={{ whiteSpace: 'pre-line' }}
				>
					{text}
				</Typography>
			</Grid>
		</Grid>
	);
};

export default BoxSlider;

const useStyles = makeStyles((theme) => ({
	box: {
		height: '585px !important',
		borderRadius: '32px',
		padding: '0 2rem',
		[theme.breakpoints.down('xl')]: {
			height: '660px',
		},
		[theme.breakpoints.down('md')]: {
			height: '756px',
			padding: '1rem',
		},
	},
	title: {
		fontSize: '24px',
		[theme.breakpoints.down('sm')]: {
			lineHeight: '1 !important',
			fontSize: '1.35rem !important',
		},
	},
	subtitle: {
		fontSize: '20px !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.2rem !important',
		},
	},
	text: {
        fontSize: '16px !important',
        marginBottom: '10px !important',
		[theme.breakpoints.down('sm')]: {
			lineHeight: '20px !important',
			fontSize: '1rem !important',
		},
	},
}));
