import React, { useState } from 'react';
import { useTheme, useMediaQuery, Grid, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Padding } from '@mui/icons-material';

const StudentsComments = ({ background }) => {
	const classes = useStyles();
    const theme = useTheme();
	

	return (
		<Grid
			container
			// width={250}
			// height={480}
			className={classes.gridBox}
			// style={{
			// 	backgroundImage: `url(${background})`,
			// }}
		>
			<img
				src={background}
				alt=""
			/>
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	gridBox: {
		borderRadius: '24px',
		height: 696,
		width: 'fit-content !important',
        
        [theme.breakpoints.down('md')]: {
            paddingRight: '20px !important',
            height: 'fit-content'

            
		},
	},
    
}));

export default StudentsComments;
