import React, { useRef, useState } from 'react';
import { Container, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';

import Footer from '../../../Components/Footer';
import { Header } from '../../../Components/Header';

//Assets
import BgGreen from '../Assets/card-green-bg.png';
import CvGreen from '../Assets/card-green-cover.png';

export default function CorrectionEdicaoEspecial() {
	const classes = useStyles();
	const theme = useTheme();
	const embeddedContent = useRef(null);
	const [isSubscribed, setIsSubscribed] = useState(false);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const executeScroll = () => embeddedContent.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

	const handleSubscribed = () => {
		setIsSubscribed(true);
	};

	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const cidade = query.get('cidade');

	return (
		<Container
			fixed
			maxWidth="xl"
			disableGutters={true}
		>
			{matchesMobile || matchesTablet ? null : <Header />}
			<Grid
				container
				className="section"
				style={matchesMobile ? { marginTop: 80 } : { marginBottom: 0 }}
			>
				<Grid
					item
					xs={12}
					md={12}
					container
					justifyContent="center"
				>
					{matchesMobile ? (
						<img
							src={require('../Assets/live-react-banner-arena-vertical.png')}
							alt=""
						/>
					) : (
						<img
							src={require('../Assets/correcao-banner.png')}
							style={{ left: -20 }}
							alt=""
						/>
					)}
				</Grid>

				<Grid
					item
					xs={12}
					container
					alignItems={'center'}
					justifyContent={'center'}
					style={matchesMobile ? { marginBottom: 16 } : { marginTop: '2em' }}
				>
					<Typography
						className={`${classes.subtitle2} poppins`}
						color="secondary"
					>
						Faça login ou inscreva-se abaixo para acompanhar a correção e participar do sorteio!
					</Typography>
				</Grid>
				<Grid
					item
					xs={12}
				>
					<Divider
						variant="middle"
						className={classes.divider}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					ref={embeddedContent}
				>
					<iframe
						title="correcao"
						src={`//hardworkmedicina.com.br/correcao-prova/127?cidade=${cidade}`}
						style={{ border: 0, width: '100%', height: '630px', backgroundColor: 'transparent' }}
					></iframe>
				</Grid>
			</Grid>

			<section style={{ display: 'flex', gap: '4rem' }}>
				{/* card um */}
				<div
					style={{
						backgroundColor: 'white',
						borderRadius: '16px',
						width: '50%',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<div style={{ padding: '3rem' }}>
						<h2 style={{ color: 'black', fontSize: '34px', textAlign: 'center' }}>
							Responda a pesquisa para participar do sorteio
						</h2>
					</div>
					<div className={classes.cardBackground}>
						<h2 style={{ color: 'white', fontSize: '26px', textAlign: 'center' }}>
							Como foi teu desempenho na prova da primeira fase do Revalida INEP?
						</h2>
						<div
							className={classes.divhover}
							style={{
								display: 'flex',
								justifyContent: 'center',
								backgroundColor: 'white',
								padding: '15px 40px',
								borderRadius: '36px',
								minWidth: '77%',
								cursor: 'pointer',
							}}
						>
							<p style={{ color: 'black' }}>Tenho certeza que fui bem na prova</p>
						</div>
						<div
							className={classes.divhover}
							style={{
								display: 'flex',
								justifyContent: 'center',
								backgroundColor: 'white',
								padding: '15px 40px',
								borderRadius: '36px',
								minWidth: '77%',
								cursor: 'pointer',
							}}
						>
							<p style={{ color: 'black' }}>Preciso de ajuda com os recursos</p>
						</div>
						<div
							className={classes.divhover}
							style={{
								display: 'flex',
								justifyContent: 'center',
								backgroundColor: 'white',
								padding: '15px 40px',
								borderRadius: '36px',
								minWidth: '77%',
								cursor: 'pointer',
							}}
						>
							<p style={{ color: 'black' }}>Algo deu errado e não fui tão bem</p>
						</div>
					</div>
				</div>
				{/* card 2 */}
				<div
					style={{
						backgroundColor: 'white',
						borderRadius: '16px',
						width: '50%',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<div
						className={classes.cardCover}
						style={{ padding: '3rem' }}
					>
						<h2 style={{ color: 'white', fontSize: '34px', textAlign: 'center' }}>SOU HARDWORKER!</h2>
					</div>
					<div
						style={{
							padding: '20px',
							color: 'black',
							display: 'flex',
							flexDirection: 'column',
							gap: '2rem',
						}}
					>
						<p>Olá, Fulano de tal,</p>
						<p>
							Lamentamos saber que você não foi aprovada desta vez. Sabemos que isso pode ser desafiador,
							mas queremos ajudar você a se preparar para o próximo exame.
						</p>
						<p>
							Dê uma olhada em nossos cursos e materiais de apoio, que podem ser úteis para sua
							preparação. Estamos aqui para apoiar você em cada passo do caminho.
						</p>
						<p>Estamos ao seu lado para te ajudar a alcançar seus objetivos!</p>
					</div>
					<div style={{ display: 'flex', justifyContent: 'center', paddingTop: '8%' }}>
						<div style={{ backgroundColor: '#1C9766', padding: '12px 18px', borderRadius: '36px' }}>
							<p style={{ color: 'white', fontWeight: 600 }}>Veja como podemos ajudar</p>
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	paper: {
		width: 462,
		[theme.breakpoints.down('lg')]: {
			width: '200 !important',
		},
		[theme.breakpoints.down('md')]: {
			width: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '1.2em',
		},
	},
	cardBackground: {
		backgroundImage: `url(${BgGreen})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		padding: '4rem',
		height: '400px',
		display: 'flex',
		flexDirection: 'column',
		gap: '2rem',
		alignItems: 'center',
		width: '100%',
		borderEndEndRadius: '16px',
		borderEndStartRadius: '16px',
	},
	cardCover: {
		backgroundImage: `url(${CvGreen})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		padding: '2rem',
		height: '160px',
		display: 'flex',
		flexDirection: 'column',
		gap: '2rem',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		borderStartStartRadius: '16px',
		borderStartEndRadius: '16px',
	},
	divhover: {
		transition: 'ease 0.2s',
		'&:hover': {
			backgroundColor: '#ADFFD6 !important',
			transform: 'scaleY(1.1)',
			border: '2px solid #34A86E',
		},
	},
	firstSectionGridCard: {
		position: 'absolute',
		right: 0,
		top: 20,
		[theme.breakpoints.down('md')]: {
			position: 'unset',
			margin: '16px 0px !important',
		},
		// [theme.breakpoints.down('sm')]: {
		// 	position: 'unset',
		// 	margin: '16px 0px !important',
		// },
	},
	text: {
		fontSize: '1.5rem !important',
		lineHeight: '1.25 !important',
		marginTop: '8px !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.35rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	divider: {
		margin: '24px 0px !important',
		backgroundColor: 'grey',
		minHeight: 1,
	},
	subtitle1: {
		fontSize: '2.5rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('lg')]: {
			marginTop: '4rem !important',
		},
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
			marginTop: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.6rem !important',
			lineHeight: 'unset !important',
			marginBottom: '8px !important',
		},
	},
	subtitle2: {
		fontSize: '1rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	impactText: {
		marginTop: '16px !important',
		fontSize: '1.5rem !important',
		lineHeight: 'unset',
		background: 'linear-gradient(90deg, rgba(0,145,166,1) 10%, rgba(0,194,110,1) 100%)',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1rem !important',
		},
	},
	btn: {
		width: 400,
		backgroundColor: '#FFFFFF !important',
		fontSize: '1.5rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		'&:hover': {
			scale: 1.05,
		},
		[theme.breakpoints.down('sm')]: {
			width: 'unset',
			fontSize: '1rem !important',
		},
	},
	backgroundImg: {
		backgroundImage: `url(${BgGreen})`,
	},
}));
