import { Button, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Header } from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import Form from '../../../Components/Utils/Forms/PreRegistration';

//Assets
import firstSectionImg from '../Assets/img-first-section.png';
import imgButton from '../Assets/img-button.png';
import imgDownload from '../Assets/img-download.png';
import imgRedirect from '../Assets/icon-redirect.png';
import { useEffect, useState } from 'react';

export function LeftContainer({ imgSource, paragraph, textDate, matchesLaptop }) {
	return (
		<Grid
			item
			container
			height={'100%'}
			xs={matchesLaptop ? 12 : 5.8}
			className="section"
		>
			<Grid
				item
				container
				width={600}
				top={matchesLaptop ? 0 : 20}
				position={'sticky'}
				zIndex={999}
			>
				<img src={imgSource} />
				<Typography
					color={'secondary'}
					fontFamily={'Poppins'}
					fontSize={matchesLaptop ? 28 : 32}
					lineHeight={1}
					fontWeight={500}
					paddingTop={2}
					marginTop={4}
				>
					{paragraph}
				</Typography>
				<Grid
					item
					container
					alignItems={'center'}
					justifyContent={'center'}
					borderRadius={2}
					marginTop={8}
					height={60}
					className="section-revalida-background"
				>
					<Grid
						item
						xs
						paddingX={4}
						alignItems={'center'}
					>
						<Typography
							color={'secondary'}
							fontFamily={'Poppins'}
							fontSize={matchesLaptop ? 28 : 40}
							lineHeight={1}
							fontWeight={500}
							textAlign={'center'}
						>
							{textDate}
						</Typography>
					</Grid>
				</Grid>
				<Grid
					item
					container
					marginTop={6}
				>
					<Typography
						fontFamily={'Poppins'}
						color="secondary"
						fontWeight={500}
						fontSize={matchesLaptop ? 30 : 40}
						lineHeight={1}
					>
						Ainda não conferiu o gabarito da prova?
					</Typography>
					<Grid
						item
						container
						alignItems={'center'}
						justifyContent={'center'}
						gap={1}
						style={{
							height: 57,
							marginTop: 20,
							width: matchesLaptop ? '100%' : '70%',
							borderRadius: 30,
							border: '2px solid #FFFFFF',
							backgroundImage: `url(${imgButton})`,
							backgroundSize: 'cover',
							cursor: 'pointer',
						}}
						onClick={() =>
							window.open(
								'https://download.inep.gov.br/revalida/provas_e_gabaritos/2024_1_PEP_publicacao.pdf'
							)
						}
					>
						<img
							src={imgDownload}
							alt="btn"
						/>
						<Grid item>
							<Typography
								fontSize={24}
								fontWeight={700}
								color={'secondary'}
							>
								Baixar gabarito da prova
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					container
					marginTop={8}
				>
					<Typography
						fontFamily={'Poppins'}
						color="secondary"
						fontSize={matchesLaptop ? 16 : 20}
						lineHeight={1}
					>
						Ainda não respondeu nossa pesquisa da prova prática?
					</Typography>
					<Grid
						item
						container
						alignItems={'center'}
						justifyContent={'center'}
						gap={1}
						style={{
							height: 57,
							marginTop: 20,
							width: matchesLaptop ? '100%' : '80%',
							borderRadius: 30,
							border: '2px solid #FFFFFF',
							cursor: 'pointer',
						}}
						className="section-revalida-background"
						onClick={() =>
							window.open(
								'https://home.hardworkmedicina.com.br/materiais/pesquisa-prova-pratica-revalida-24-2'
							)
						}
					>
						<img
							src={imgRedirect}
							alt="btn"
							style={{ width: 20 }}
						/>
						<Grid item>
							<Typography
								fontSize={24}
								fontWeight={700}
								color={'secondary'}
							>
								Responder pesquisa
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default function Page() {
	const [scroll, setScroll] = useState();

	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLaptop = useMediaQuery(theme.breakpoints.down('lg'));

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY;
			if (scrollPosition > 90) {
				setScroll(true);
			} else {
				setScroll(false);
			}
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<Container disableGutters>
			<Header />
			<Grid
				container
				gap={2}
				justifyContent={'space-between'}
				marginTop={3}
			>
				<LeftContainer
					matchesLaptop={matchesLaptop}
					imgSource={firstSectionImg}
					paragraph={'Acompanhe a correção da prova ao vivo e com comentários de nossos professores.'}
					textDate={'25 de julho às 21H'}
				/>
				<Grid
					container
					item
					xs={matchesLaptop ? 12 : 5.8}
					minHeight={'60vh'}
					className="section"
				>
					<Grid
						maxWidth={557}
						position={!matchesLaptop && scroll ? 'fixed' : 'relative'}
						top={0}
					>
						<Form
							group={'LIVE DE CORREÇÃO REVALIDA 24.2 PRÁTICA'}
							title={'INSCREVA-SE GRATUITAMENTE PARA PARTICIPAR DA LIVE DE CORREÇÃO!'}
							titleButton={'Confirmar inscrição'}
							type={'revalida-ldl'}
							urlToRedirect={
								'https://home.hardworkmedicina.com.br/live/correcao-prova-pratica-revalida-24-2'
							}
							confirmationMessage={'Sua pré-inscrição está confirmada!'}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Footer />
		</Container>
	);
}
