import React, { useState, useEffect} from 'react';
import { useMediaQuery, useTheme, Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import IconBag from '../assets/shopping-bag.svg';
import iconPointer from '../assets/icon-bullseye-pointer-regular.svg';

const exams = [
	{ name: 'ABC', id: '1294' },
	{ name: 'AMP', id: '1295' },
	{ name: 'AMRIGS', id: '1296' },
	{ name: 'EINSTEIN', id: '1297' },
	{ name: 'ENARE', id: '1348' },
	{ name: 'FAMEMA', id: '1302' },
	{ name: 'FAMERP', id: '1351' },
	{ name: 'F.MED. JUNDIAÍ', id: '1305' },
	{ name: 'SÍRIO LIBANÊS', id: '1315' },
	{ name: 'IAMSPE', id: '1307' },
	{ name: 'PSU GO', id: '1309' },
	{ name: 'PSU MG', id: '1311' },
	{ name: 'SANTA CASA SP', id: '1317' },
	{ name: 'SES DF', id: '1318' },
	{ name: 'SES PE', id: '1316' },
	{ name: 'SURCE', id: '1314' },
	{ name: 'SUS BA', id: '1313' },
	{ name: 'SUS SP', id: '1312' },
	{ name: 'UERJ', id: '1310' },
	{ name: 'UFES', id: '1308' },
	{ name: 'UFRJ', id: '1306' },
	{ name: 'UNESP BOTUCATU', id: '1304' },
	{ name: 'UNICAMP', id: '1303' },
	{ name: 'UNIFESP', id: '1301' },
	{ name: 'USP', id: '1300' },
	{ name: 'USP RIBEIRÃO', id: '1298' },
];

const PaymentCard = () => {
	const theme = useTheme();
	const classes = useStyles();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
    const [newLot, setNewLot] = useState(false);

    useEffect(() => {
        const targetDate = new Date('2024-08-02T23:59:00-03:00');
        const currentDate = new Date();
        if (currentDate > targetDate) { 
          setNewLot(true);
        } 
      }, []);

	return (
		<Grid
			container
			direction="column"
			justifyContent="center"
			alignItems="center"
			className={classes.gridBox}
		>
			<Button
				disabled
				className={classes.optionButton}
			>
				Melhor opção
			</Button>
			<Grid
				item
				className={classes.content}
			>
				<Typography className={classes.gradientText}>Pacote todos os Sprints</Typography>
				<Typography
					display="flex"
					color="#000"
					fontFamily="Manrope"
					flexDirection="column"
					textAlign="start"
				>
					<span style={{ fontSize: '16px', fontWeight: 400, marginBottom: '1rem' }}>
						Inscreva-se em 26 Sprints por
					</span>

					<Grid
						container
						direction="column"
						justifyContent="space-between"
					>
						<span
							style={{
								fontSize: '16px',
								fontWeight: 500,
								color: '#959595',
								textDecoration: 'line-through',
								paddingRight: '1.5rem',
							}}
						>
							De: R$25.394,60
						</span>
						<span style={{ fontSize: '16px', fontWeight: 600, marginRight: '0.8rem' }}>
							Por R$ 5.590,00 à vista
						</span>
						<span style={{ fontSize: '32px', fontWeight: 800 }}>12x de R$ 465,83</span>
					</Grid>  
					<span style={{ fontSize: '16px', fontWeight: 400, marginBottom: '1rem' }}>
						Inscreva-se em todos os sprints por um valor especial
					</span>
				</Typography>
				<Button
					href="https://hardworkmedicina.com.br/inscricao/todosSprints"
					startIcon={
						<img
							src={IconBag}
							alt="shopping bag"
						/>
					}
					className={classes.subscribeButton}
					style={{ padding: '0.3rem 2rem', background: '#33C96F ', color: '#fff' }}
				>
					Inscrever-se
				</Button>
			</Grid>
			<Grid item>
				<Typography className={classes.includedSprintsTitle}>Sprints inclusos neste pacote:</Typography>
				<Grid
					container
					spacing={1}
					className={classes.sprintsGrid}
				>
					{[
						'ABC',
						'AMP',
						'AMRIGS',
						'EINSTEIN',
						'ENARE',
						'FAMEMA',
						'FAMERP',
						'F.MED. JUNDIAÍ',
						'SÍRIO LIBANÊS',
						'IAMSPE',
						'PSU GO',
						'PSU MG',
						'SANTA CASA SP',
						'SES DF',
						'SES PE',
						'SURCE',
						'SUS BA',
						'SUS SP',
						'UERJ',
						'UFES',
						'UFRJ',
						'UNESP BOTUCATU',
						'UNICAMP',
						'UNIFESP',
						'USP',
						'USP RIBEIRÃO',
					].map((sprint) => (
						<Grid
							item
							key={sprint}
						>
							<Button
								style={{ background: '#303030', color: '#fff' }}
								className={classes.sprintButton}
							>
								{sprint}
							</Button>
						</Grid>
					))}
				</Grid>
			</Grid>
		</Grid>
	);
};

const PaymentCardSP = () => {
	const theme = useTheme();
	const classes = useStyles();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
    const [newLot, setNewLot] = useState(false);

    useEffect(() => {
        const targetDate = new Date('2024-08-02T23:59:00-03:00');
        const currentDate = new Date();
        if (currentDate > targetDate) {
          setNewLot(true);
        } 
      }, []);

	const saoPauloSprints = [
		'ABC',
		'EINSTEIN',
		'FAMEMA',
		'FAMERP',
		'F.MED. JUNDIAÍ',
		'SÍRIO LIBANÊS',
		'IAMSPE',
		'SANTA CASA SP',
		'SÍRIO/FCC',
		'SUS SP',
		'UNESP BOTUCATU',
		'UNICAMP',
		'UNIFESP',
		'USP',
		'USP RIBEIRÃO',
	];

	return (
		<Grid
			container
			direction="column"
			justifyContent="center"
			alignItems="center"
			className={classes.gridBox}
		>
			<Grid
				item
				className={classes.content}
			>
				<Typography
					className={classes.gradientText}
					style={{ marginTop: '2rem' }}
				>
					Pacote Sprints São Paulo
				</Typography>
				<Typography
					display="flex"
					color="#000"
					fontFamily="Manrope"
					flexDirection="column"
					textAlign="start"
				>
					<span style={{ fontSize: '16px', fontWeight: 400, marginBottom: '1rem' }}>
						Inscreva-se em 14 Sprints* por
					</span>

					<Grid
						container
						direction="column"
						justifyContent="space-between"
					>
						<span
							style={{
								fontSize: '16px',
								fontWeight: 500,
								color: '#959595',
								textDecoration: 'line-through',
								paddingRight: '1.5rem',
							}}
						>
							 'De: R$ 13.521,00'
						</span>
						<span style={{ fontSize: '16px', fontWeight: 600 }}> Por R$ 3.990,00 à vista</span>
						<span style={{ fontSize: '32px', fontWeight: 800 }}> 12x de R$ 332,50</span>
					</Grid>
					<span style={{ fontSize: '16px', fontWeight: 400, marginBottom: '2rem' }}>
						Uma seleção das principais provas de SP
					</span>
				</Typography>
				<Button
					href="https://hardworkmedicina.com.br/inscricao/sprintsSP"
					startIcon={
						<img
							src={IconBag}
							alt="shopping bag"
						/>
					}
					className={classes.subscribeButton}
					style={{ padding: '0.3rem 2rem', background: '#33C96F ', color: '#fff' }}
				>
					Inscrever-se
				</Button>
			</Grid>
			<Grid item>
				<Typography className={classes.includedSprintsTitle}>Sprints inclusos neste pacote:</Typography>
				<Grid
					container
					spacing={1}
					className={classes.sprintsGrid}
				>
					{[
						'ABC',
						'AMP',
						'AMRIGS',
						'EINSTEIN',
						'ENARE',
						'FAMEMA',
						'FAMERP',
						'F.MED. JUNDIAÍ',
						'SÍRIO LIBANÊS',
						'IAMSPE',
						'PSU GO',
						'PSU MG',
						'SANTA CASA SP',
						'SES DF',
						'SES PE',
						'SURCE',
						'SUS BA',
						'SUS SP',
						'UERJ',
						'UFES',
						'UFRJ',
						'UNESP BOTUCATU',
						'UNICAMP',
						'UNIFESP',
						'USP',
						'USP RIBEIRÃO',
					].map((sprint) => (
						<Grid
							item
							key={sprint}
						>
							<Button
								style={
									saoPauloSprints.includes(sprint)
										? { background: '#303030', color: '#fff' }
										: { background: '#F5F5F5', color: '#656565' }
								}
								className={classes.sprintButton}
							>
								{sprint}
							</Button>
						</Grid>
					))}
				</Grid>
			</Grid>
		</Grid>
	);
};

const PaymentCardSelect = () => {
    const theme = useTheme();
    const classes = useStyles();
    const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));
  
    //Estado inicial para manter true depois que carrega 
    const [newLot, setNewLot] = useState(() => {
        const savedNewLot = localStorage.getItem('newLot');
        return savedNewLot === 'true';
      });
    const [numberSprint, setNumberSprint] = useState(1);
    const [discount, setDiscount] = useState(0);
    const [selectedSprints, setSelectedSprints] = useState([]);
    const [buttonText, setButtonText] = useState('Selecione abaixo');
    const [totalPrice, setTotalPrice] = useState(newLot ? 1749 : 1649);
    const [installments, setInstallments] = useState(150);
    const [totalValue, setTotalValue] = useState(1990);
  
    useEffect(() => {
        const targetDate = new Date('2024-08-02T23:59:00');
        const currentDate = new Date();
        const timeToTargetDate = targetDate - currentDate;
    
        if (timeToTargetDate > 0) {
          const timeoutId = setTimeout(() => {
            window.location.reload();
          }, timeToTargetDate);
    
          return () => clearTimeout(timeoutId);
        } else {
          setNewLot(true);
          localStorage.setItem('newLot', 'true');
        }
      }, []);


	const toggleSprintSelection = (sprint) => {
		if (selectedSprints.includes(sprint)) {
			setSelectedSprints(selectedSprints.filter((item) => item !== sprint));
		} else {
			setSelectedSprints([...selectedSprints, sprint]);
		}
	};

	const handlePaymentSelect = () => {
		if (selectedSprints.length > 0) {
			const selectedIds = selectedSprints
				.map((sprint) => {
					const found = exams.find((exam) => exam.name === sprint);
					return found ? found.id : null;
				})
				.filter((id) => id !== null)
				.join(',');

			const url = `https://hardworkmedicina.com.br/inscricao/${selectedIds}`;
			window.open(url, '_blank');
		}
	};

	// Verifica se algum sprint está selecionado para alterar o texto do botão
	useEffect(() => {
		if (selectedSprints.length > 0) {
			setButtonText('Inscrever-se');
		} else {
			setButtonText('Selecione abaixo');
		}
	}, [selectedSprints]);

	// Atualiza o preço total, parcelas e desconto
	useEffect(() => {
		const additionalSprints = Math.max(0, selectedSprints.length - 1);
		const newTotalPrice = (newLot ? 1749 : 1649) + additionalSprints * (newLot ? 1049.40 : 989.4);
		setTotalPrice(newTotalPrice);
		setInstallments(newTotalPrice / 12);
		setDiscount(additionalSprints * (newLot ? 699.4 : 659.6));
		setNumberSprint(selectedSprints.length > 0 ? selectedSprints.length : 1);
		setTotalValue(1990 + additionalSprints * 1194);
	}, [selectedSprints]);

	// Função para formatar os valores com vírgula em vez de ponto
	const formatCurrency = (value) => {
		return value.toLocaleString('pt-BR', {
			style: 'currency',
			currency: 'BRL',
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});
	};

	return (
		<Grid
			container
			direction="column"
			justifyContent="center"
			alignItems="center"
			className={classes.gridBox}
		>
			<Grid
				item
				className={classes.content}
			>
				<Typography
					className={classes.gradientText}
					style={{ marginTop: '2rem' }}
				>
					Sprints unitários
				</Typography>
				<Typography
					display="flex"
					color="#000"
					fontFamily="Manrope"
					flexDirection="column"
					textAlign="start"
				>
					<span style={{ fontSize: '16px', fontWeight: 400, marginBottom: '1rem' }}>
						Inscreva-se em {numberSprint} ou mais Sprints a partir de
					</span>

					<Grid
						container
						direction="column"
						justifyContent="space-between"
					>
						<span
							style={{
								fontSize: '16px',
								fontWeight: 500,
								color: '#959595',
								textDecoration: 'line-through',
								paddingRight: '1.5rem',
							}}
						>
							De: {formatCurrency(totalValue)}{' '}
						</span>
						<span style={{ fontSize: '16px', fontWeight: 600 }}>
							{`Por ${formatCurrency(totalPrice)} à vista`}
						</span>
						<span style={{ fontSize: '32px', fontWeight: 800 }}>
							{`12x de ${formatCurrency(installments)}`}
						</span>
					</Grid>
					<span style={{ fontSize: '16px', fontWeight: 400, marginBottom: '1rem' }}>
						Com desconto de <span style={{ fontWeight: 600 }}>40%</span>{' '}
						{discount === 0 && (
							<span style={{ fontSize: '16px', fontWeight: 400, marginBottom: '1rem' }}>em</span>
						)}
						{discount > 0 && (
							<span style={{ fontSize: '16px', fontWeight: 400, marginBottom: '1rem' }}>
								aplicado nos
							</span>
						)}{' '}
						sprints adicionais!
					</span>
					{discount > 0 && (
						<span style={{ fontSize: '16px', fontWeight: 400, marginBottom: '2rem' }}>
							<span style={{ fontWeight: 600 }}> Economia atual de {formatCurrency(discount)}!</span>
						</span>
					)}
				</Typography>
				<Button
					variant="outlined"
					onClick={handlePaymentSelect}
					startIcon={
						<img
							src={selectedSprints.length > 0 ? IconBag : iconPointer}
							alt="icone"
						/>
					}
					className={classes.subscribeButton}
					style={{
						padding: '0.3rem 2rem',
						backgroundColor: selectedSprints.length > 0 ? '#33C96F' : '#f1f1f1',
						color: selectedSprints.length > 0 ? '#f1f1f1' : '#087B36',
					}}
				>
					{buttonText}
				</Button>
			</Grid>
			<Grid item>
				<Typography className={classes.includedSprintsTitle}>Selecione seus sprints:</Typography>
				<Grid
					container
					spacing={1}
					className={classes.sprintsGrid}
				>
					{[
						'ABC',
						'AMP',
						'AMRIGS',
						'EINSTEIN',
						'ENARE',
						'FAMEMA',
						'FAMERP',
						'F.MED. JUNDIAÍ',
						'SÍRIO LIBANÊS',
						'IAMSPE',
						'PSU GO',
						'PSU MG',
						'SANTA CASA SP',
						'SES DF',
						'SES PE',
						'SURCE',
						'SUS BA',
						'SUS SP',
						'UERJ',
						'UFES',
						'UFRJ',
						'UNESP BOTUCATU',
						'UNICAMP',
						'UNIFESP',
						'USP',
						'USP RIBEIRÃO',
					].map((sprint) => (
						<Grid
							item
							key={sprint}
						>
							<Button
								onClick={() => toggleSprintSelection(sprint)}
								style={{
									background: selectedSprints.includes(sprint) ? '#303030' : '#CCCCCC',
									color: selectedSprints.includes(sprint) ? '#fff' : '#303030',
								}}
								className={classes.sprintButton}
							>
								{sprint}
							</Button>
						</Grid>
					))}
				</Grid>
			</Grid>
		</Grid>
	);
};

const PaymentCardContainer = () => {
	const classes = useStyles();
	const theme = useTheme();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Grid
			container
			spacing={3}
			justifyContent="center"
		>
			<Grid
				item
				xl={3.5}
				xs={12}
				sm={6}
				md={4}
			>
				<PaymentCardSelect />
			</Grid>

			<Grid
				item
				xl={3.5}
				xs={12}
				sm={6}
				md={4}
			>
				<PaymentCardSP />
			</Grid>
			<Grid
				item
				xl={3.5}
				xs={12}
				sm={6}
				md={4}
			>
				<PaymentCard />
			</Grid>
			<Grid flexDirection={'column'}>
				<Grid style={{ marginLeft: matchesMobile ? 30 : 0, marginRight: matchesMobile ? 30 : 0 }}>
					<Typography
						fontFamily={'Manrope'}
						color={'#656565'}
						textAlign={'center'}
						backgroundColor={'#EBEBEB'}
						className={classes.obsText}
					>
						*Os Sprints estão sujeitos a alteração ou incorporação em caso de mudança de banca.
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	gridBox: {
		border: '2px ',
		borderRadius: '24px',
		padding: '1rem 1.9rem ',
		backgroundColor: '#EBEBEB',
		[theme.breakpoints.down('sm')]: {
			padding: '1rem 2.rem !important',
			justifyContent: 'center',
		},
	},
	optionButton: {
		cursor: 'pointer',
		borderRadius: '24px !important',

		width: 'fit-content',
		fontFamily: 'Manrope',
		fontSize: '10px !important',
		color: '#fff !important',
		background: 'linear-gradient(304deg, #FD2C78 20.08%, #FF8251 79.92%)',
		alignSelf: 'flex-end',
		marginBottom: theme.spacing(1),
	},
	gradientText: {
		background: 'linear-gradient(304deg, #FD2C78 20.08%, #FF8251 79.92%)',
		WebkitBackgroundClip: 'text',
		WebkitTextFillColor: 'transparent',
		fontFamily: 'Manrope',
		fontSize: '24px !important',
		textAlign: 'start',
	},
	content: {
		width: '100%',
		textAlign: 'center',
	},
	subscribeButton: {
		color: '#fff !impotant',
		cursor: 'pointer',
		borderRadius: '24px !important',
		width: 'fit-content',
		fontFamily: 'Manrope',
		fontSize: '16px',
		marginTop: theme.spacing(2),
		marginBottom: '2rem !important',
	},
	includedSprintsTitle: {
		marginTop: theme.spacing(3),
		fontWeight: 600,
		fontSize: '16px',
		textAlign: 'center',
		color: '#000 !important',
		marginBottom: '2rem !important',
	},
	obsText: {
		marginTop: '30px !important',
	},
	obsTextPack: {
		marginTop: '30px !important',
		paddingLeft: '15px !important',
		paddingRight: '15px !important',
		background: 'linear-gradient(304deg, #FD2C78 20.08%, #FF8251 79.92%)',
	},
	sprintsGrid: {
		marginTop: theme.spacing(1),
	},
	sprintButton: {
		width: '76px',
		height: '64px',
		backgroundColor: '#3C3C3C',
		color: '#fff',
		borderRadius: '8px',

		fontSize: '13px !important',
		textTransform: 'none',
	},
}));

export default PaymentCardContainer;
