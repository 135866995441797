//assets
import imgCarrousel1 from '../assets/img-carrosel1.png';
import imgCarrousel2 from '../assets/img-carrosel2.png';
import imgCarrousel3 from '../assets/img-carrosel3.png';
import imgCarrousel4 from '../assets/img-carrosel4.png';
import imgCarrousel5 from '../assets/img-carrosel5.png';
import imgCarrousel6 from '../assets/imgCarrousel6.png'
import imgCarrousel1Mobile from '../assets/img-carrosel1-mobile.png';
import imgCarrousel2Mobile from '../assets/img-carrosel2-mobile.png';
import imgCarrousel3Mobile from '../assets/img-carrosel3-mobile.png';
import imgCarrousel4Mobile from '../assets/img-carrosel4-mobile.png';
import imgCarrousel5Mobile from '../assets/img-carrosel5-mobile.png';

import sfCarrossel01 from '../assets/sf-carrossel-01.png';
import sfCarrossel02 from '../assets/sf-carrossel-02.png';
import sfCarrossel03 from '../assets/sf-carrossel-03.png';
import sfCarrossel04 from '../assets/sf-carrossel-04.png';
import sfCarrossel05 from '../assets/sf-carrossel-05.png';
import sfCarrossel06 from '../assets/sf-carrossel-06.png';
import sfCarrossel07 from '../assets/sf-carrossel-07.png';
import sfCarrossel08 from '../assets/sf-carrossel-08.png';
import sfCarrossel09 from '../assets/sf-carrossel-09.png';
import sfCarrossel01Mobile from '../assets/sfCarrossel01Mobile.png'
import sfCarrossel02Mobile from '../assets/sfCarrossel02Mobile.png'
import sfCarrossel03Mobile from '../assets/sfCarrossel03Mobile.png'
import sfCarrossel04Mobile from '../assets/sfCarrossel04Mobile.png'
import sfCarrossel05Mobile from '../assets/sfCarrossel05Mobile.png'
import sfCarrossel06Mobile from '../assets/sfCarrossel06Mobile.png'
import sfCarrossel07Mobile from '../assets/sfCarrossel07Mobile.png'
import sfCarrossel08Mobile from '../assets/sfCarrossel08Mobile.png'
import sfCarrossel09Mobile from '../assets/sfCarrossel09Mobile.png'

import iconCircleInfo from '../assets/icon-memo-circle-info.svg';
import iconMonitor from '../assets/icon-monitor-waveform.svg';
//import iconMessage from '../assets/icon-monitor-waveform.svg';
import iconSwords from '../assets/icon-swords.svg';
import iconBallons from '../assets/icon-balloons.svg';
import iconCam from '../assets/icon-cam.svg';
import iconArrows from '../assets/icon-glass-arrows.svg';
import iconFlashcards from '../assets/icon-flashcards.svg'
import iconPerson from '../assets/icon-person.svg'
import iconExclamation from '../assets/icon-exclamation.svg'
import iconChart from '../assets/icon-chart-simple.svg'
import iconTablet from '../assets/icon-tablet-button-duotone-solid.svg'

export const cardsData = [
    {
		background: imgCarrousel2,
		backgroundMobile: imgCarrousel4Mobile,
		title: 'Pedro Mota',
		subtitle: ['Neurologia na USP, UNIFESP, USP-RP, ENARE e UNICAMP'],
		text: ['O Hardwork foi essencial para a minha preparação.', <b/>, 'As questões que eu fazia eram as que o curso sugeria e foquei no Sprint para os meus estudos.', <b/>,'Isso fez toda a diferença.']
	},
    {
		background: imgCarrousel1,
		backgroundMobile: imgCarrousel3Mobile,
		title: 'Mariana Garcia',
		subtitle: [ 'Pediatria na USP, UNIFESP e FMABC'],
		text: 'Estudei estatísticas de provas desde o 3º ano e acompanhei todas as lives gratuitas do Hardwork. Usei o  Sprint para questões específicas e, gostei de não ter um cronograma rígido e poder adaptar meus estudos. Precisava de uma revisão rápida e o Hardwork mudou tudo para mim. ',
	},
	{
		background: imgCarrousel3,
		backgroundMobile: imgCarrousel1Mobile,
		title: 'Gabriela Grasso',
		subtitle: ['Medicina de Emergência na USP, UNIFESP, UNICAMP e EINSTEIN'],
		text: 'Fiz Hardwork antes e, ao prestar prova novamente, voltei porque vi a diferença no meu estudo, conhecimento e domínio das provas.',
	},
    {
		background: imgCarrousel6,
		backgroundMobile: imgCarrousel6,
		title: 'Guilherme Dantas',
		subtitle: ['Clínica Médica na UNIFESP, USP-RP, ENARE e UNICAMP'],
		text: 'O Sprint Final do Hardwork foi a cereja do bolo na reta final da preparação para as provas de residência médica. Revisões direcionadas com mensagens rápidas me fizeram guardar as abordagens de cada banca, deixando na memória os pontos-chave antes das provas. A praticidade e um estudo orientado valem ouro nesses momentos de tensão!',
	},
	{
		background: imgCarrousel4,
		backgroundMobile: imgCarrousel2Mobile,
		title: 'Cristiano Sarks',
		subtitle: ['Clínica Médica no ENARE, PSU-GO, SES-GO e SES-DF'],
		text: 'O Hardwork mudou minha vida e meu jeito de pensar. A plataforma planeja revisões e foca no essencial para as provas. Os Sprints e as questões do banco foi um divisor de águas. A abordagem do Hardwork trouxe tranquilidade e organização, transformando meu método de estudo.',
	},
	{
		background: imgCarrousel5,
		backgroundMobile: imgCarrousel5Mobile,
		title: 'Jean Carlos Bueno',
		subtitle: ['Medicina de Emergência na USP, ENARE, UNICAMP e SUS-SP'],
		text: 'Me identifiquei com a filosofia do Hardwork. O estudo  direcionado de questões mudou o jogo para mim. As aulas são interativas e te obrigam a seguir uma linha de raciocínio. O direcionamento do Hardwork fez uma \n diferença absurda na minha preparação. ',
	},
];

export const sprintComments = [
	{
		textComments:
			'Sprintouuuu !!!! O sofrimento agora é escolher onde fazer a residência, nunca pensei que essa seria a decisão mais difícil. só gratidão a toda equipe por desenvolver esse método que permitiu conciliar estudos e trabalho e ainda lacrar nas provas.',
		textName: 'Dra. Manoela Farias Alvess',
		textSprint: 'Sprint Final AMRIGS e USP',
	},
	{
		textComments:
			'Bati 82 acertos na AMRIGS graças ao extensivo e ao sprint (isso que não cumpri 100% do planejado)!!! não me canso de falar que o Hardwork é o melhor curso! r1 psiquiatria no HPSP-POA!',
		textName: 'Dr. Carlos Silva',
		textSprint: 'Sprint Final UFRGS e PUC',
	},
	{
		textComments:
			'Oieee! com muita alegria que venho informar que eu passei na minha primeira opção! Tentei ano passado e não fui tão bem, estava em outro curso, esse ano fiz o hw extensivo e sprint para o PSU e passei! (inclusive no PSU)! Fui bem em todas as provas e minha  média de acerto subiu muito! gratidão!',
		textName: 'Dra. Manoela Farias Alvess',
		textSprint: 'Sprint Final AMRIGS e USP',
	},
	{
		textComments:
			'Mais um sprint concluído com sucesso! A metodologia é realmente eficaz, consegui melhorar muito meu desempenho nas provas.',
		textName: 'Dr. Carlos Silva',
		textSprint: 'Sprint Final UFRGS e PUC',
	},
];

export const studentsComments = [
	{
		background: sfCarrossel01,
        backgroundMobile: sfCarrossel01Mobile
		
	},
	{
		background: sfCarrossel02,
        backgroundMobile: sfCarrossel02Mobile
	
	},
	{
		background: sfCarrossel03,
        backgroundMobile: sfCarrossel03Mobile
		
	},
	{
		background: sfCarrossel04,
        backgroundMobile: sfCarrossel04Mobile
		
	},
	{
		background: sfCarrossel05,
        backgroundMobile: sfCarrossel05Mobile
	
	},
	{
		background: sfCarrossel06,
        backgroundMobile: sfCarrossel06Mobile
		
	},
	{
		background: sfCarrossel07,
        backgroundMobile: sfCarrossel07Mobile
		
	},
	{
		background: sfCarrossel08,
        backgroundMobile: sfCarrossel08Mobile
		
	},
	{
		background: sfCarrossel09,
        backgroundMobile: sfCarrossel09Mobile
    }	
];

export const resumeSprint = [
	{
		icon: iconArrows,
		title: 'Revisões cíclicas personalizadas',
		text: 'Mais de 100-130 blocos de revisões de temas para cada banca, baseados em análise minuciosa da banca feita pelo time Hardwork!' ,
	},
	{
		icon: iconCircleInfo,
		title: 'Provas dos últimos anos comentadas',
		text: 'Histórico robusto de questões comentadas de cada banca, entre 5-8 anos.',
	},
    {
		icon: iconExclamation,
		title: 'Mensagens importantes de cada banca',
		text: 'Revisão ativa com combinação de trívias criadas pelos professores Hardwork e mensagens importantes para cada banca. ',
	},
    {
		icon: iconSwords,
		title: 'Desafio diário',
		text: 'Desafio com questões e flashcards diariamente! Encare o desafio diário personalizado, com as tuas provas-alvo e provas-irmãs.​',
	},
    {
		icon: iconBallons, 
		title: 'Banco de questões Hardwork',
		text: 'Acesso completo ao banco de questões Hardwork, com mais de 130 mil questões, e possibilidade de criar simulados com provas irmãs de cada banca.',
	},
    {
		icon: iconCam,
		title: 'Vídeos shots',
		text: 'Vídeos direcionados com a didática dos professores Hardwork, enfatizando objetivamente as mensagens relevantes.​',
	},
    {
		icon: iconFlashcards,
		title: 'Flashcards',
		text: 'Acesso a milhares de flashcards com foco na banca!',

	},
	{
		icon: iconMonitor,
		title: 'Treinamento multimídia',
		text: 'Um bônus especial! Treinamento com padrões de imagem de provas com comentários dos especialistas Hardwork para revisar também mensagens importantes sobre as imagens nas provas!​',
	},
	

    {
		icon: iconTablet,
		title: 'Aplicativo',
		text: 'O acesso ao Sprint Final pode ser feito pelo navegador ou por aplicativo exclusivo.​',
	},
    {
		icon: iconChart,
		title: 'Estatísticas',
		text: 'Estatísticas detalhadas de cada banca, com comparação entre instituições. ​',
	},
    {
		icon: iconPerson,
		title: 'Contato direto com os professores',
		text: 'Aqui no Hardwork você têm contato direto com nosso time de professores para dúvidas e auxílio para possíveis recursos!',
	},
];
