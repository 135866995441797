import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Paper } from '@material-ui/core';

// Importando imagens
// import LogoTego from '../assets/logo-tego-solidario.svg';
// import LogoTep from '../assets/logo-tep-solidario.svg';
// import LogoEnare from '../assets/logo-enare.svg';
import LogoRevalida from '../assets/logo-ldl-revalida-inep.svg';

const imagesWithDates = [
	// { image: LogoEnare, date: 'De 06 de julho a 03 de agosto.', route: 'hardwork-pelo-sul/enare-dominado' },
	{ image: LogoRevalida, date: 'De 15 de setembro a 20 de outubro.', route: 'hardwork-pelo-sul/ldl-revalida' },
	// { image: LogoTep, date: 'De 10 de maio a 15 de junho.', route: 'hardwork-pelo-sul/tep' },
	// { image: LogoTego, date: 'De 22 a 26 de julho.', route: 'hardwork-pelo-sul/tego' },
];

const useStyles = makeStyles((theme) => ({
	imageContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		width: '340px',
		padding: '16px 8px',

		paddingBottom: 10,

		background: '#272727',
		borderRadius: '16px',
		margin: theme.spacing(2, 5),

		marginRight: 50,
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(2, 1),
		},
	},
	image: {
		width: '100%',
		height: '100%',
		marginBottom: theme.spacing(1),
	},
	text: {
		color: '#ffffff',
		fontFamily: 'Inter',
		fontSize: 16,
		fontWeight: 700,
		letterSpacing: 0,
		lineHeight: '26px',
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			fontSize: 10,
			lineHeight: '16px',
		},
	},
	link: {
		textDecoration: 'none',
		'&:hover': {
			transform: 'scale(1.2)',
		},
	},
	paper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'row',
		background: 'transparent',
	},
}));

const BannerProductStatic = () => {
	const classes = useStyles();
	const location = useLocation();
	const theme = useTheme();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const filteredImages = imagesWithDates.filter((item) => !location.pathname.endsWith(item.route));

	return (
		<Paper
			elevation={0}
			className={classes.paper}
		>
			{filteredImages.map((item, index) => (
				<div
					key={index}
					className={classes.imageContainer}
				>
					<a
						href={`https://home.hardworkmedicina.com.br/${item.route}`}
						target="_blank"
						rel="noopener noreferrer"
						className={classes.link}
					>
						<img
							src={item.image}
							alt={`Image ${index + 1}`}
							className={classes.image}
						/>
					</a>
					<p className={classes.text}>{item.date}</p>
				</div>
			))}
		</Paper>
	);
};

export default BannerProductStatic;
