
import iconArrow from '../assets/icon-arrow-sprint.svg'
import iconCam from '../assets/icon-cam-sprint.svg'
import iconBallon from '../assets/icon-ballon-sprint.svg'
import iconGlass from '../assets/icon-glass-sprint.svg'
import iconMetrics from '../assets/icon-metrics-sprint.svg'
import iconApp from '../assets/icon-tablet-sprint.svg'

import card01 from '../assets/card01-cm.png'
import card02 from '../assets/card02-cm.png'


export const resume = [
	{
		icon: iconArrow,
		title: 'Desafios Temáticos',
		text: 'Mapeamos todos os temas de cada banca e preparamos desafios temáticos com blocos de questões específicos; em cada desafio, aprendizados essenciais com comentários em texto, além de vídeos objetivos com detalhamento dos temas.',
	},
	{
		icon: iconCam,
		title: 'Vídeos objetivos',
		text: 'Vídeos objetivos direcionados para cada banca. Foco total nas mensagens relevantes para o R+ de cirurgia, com detalhamento de temas de cada subespecialidade.​',
	},
	{
		icon: iconBallon,
		title: 'Banco de questões',
		text: 'Centenas de questões comentadas de provas de R+ de cirurgia. Tenha acesso também a questões de de temas cirúrgicos de provas de acesso direto.​',
	},
	{
		icon: iconGlass,
		title: 'Revisões cíclicas diárias',
		text: 'Depois de desbravar cada tema nos desafios temáticos, hora de revisar até a data da prova! Todos os dias, um pequeno grupo de questões para ampliar, revisar e sedimentar todo o aprendizado.',
	},
	{
		icon: iconMetrics,
		title: 'Métricas personalizadas',
		text: 'Aqui o estudo é totalmente monitorado! Métricas detalhadas para acompanhar o aprendizado de cada tema relevante para a banca.',
	},

	{
		icon: iconApp,
		title: 'Aplicativo',
		text: 'Toda a flexibilidade de acesso através de aplicativo específico. Mas, se quiser, acesse também pelo navegador do computador.',
	},
];

export const cardsData = [
	{
		bg: card01,
		// bgMobile: card01Mobile,
		title: 'Foco total no R+ de Cirurgia!',
		text: [
			'O grande segredo das provas de R+ Cirurgia é conhecer a forma como ',
			<br />,
			'cada banca se aprofunda nos temas cirúrgicos! ',
			<br />,
			'Não é novidade para ninguém que as provas de especialidades ',
			<br />,
			'cirúrgicas são MUITO diferentes das de acesso direto.',
			<br />,
			<br />,
			'O que talvez você não saiba é que a maioria das pessoas se perde na',
			<br />,
			'preparação.',
			<br />,
			<br />,
			'No HardworQ R+ Cirurgia nós te mostramos todos os detalhes da USP',
			<br />,
			'e do ENARE, aprofundando com foco nas peculiaridades de cada tema',
		],
	},
	{
		bg: card02,
		// bgMobile: card02Mobile,
		title: 'Revisão direcionada e intensa',
		text: [
			'Dissecamos em detalhes cada uma das bancas de R+ de cirurgia,',
			<br />,
			'explorando todas as peculiaridades dos temas, propondo desafios',
			<br />,
			'temáticos direcionados.',
			<br />,
			<br />,
			'Realize o desafio temático e explore os temas com vídeos curtos e ',
			<br />,
			'comentários dos professores Hardwork.',
			<br />,
			<br />,
			'A cada desafio temático concluído, revisamos com questões diárias',
			<br />,
			'sobre cada um dos temas, ampliando o conhecimento, e revisando ',
			<br />,
			'até a data da prova.',
		],
	},
];