import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

function OtherProductsComponent({ objectOfContests, contest }) {
	const theme = useTheme();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const [selected, setSelected] = useState([]);
	const [arr, setArr] = useState([]);

	useEffect(() => {
		handleObject(objectOfContests, contest);
	}, [objectOfContests, contest]);

	const handleObject = (object, contest) => {
		const newArr = [];
		object.forEach((element) => {
			if (element.type === contest) {
				newArr.unshift({ ...element, stylization: 'firstCard' });
			} else {
				newArr.push({ ...element, stylization: 'otherCards' });
			}
		});
		setArr(newArr);
	};

	const handleHover = (e, i) => {
		if (selected.includes(e)) {
			const newArr = [];
			setSelected(newArr);
		} else {
			const newArr = [...selected, e];
			setSelected(newArr);
		}
	};

	const classes = useStyles();

	return (
		<Container
			fixed
			maxWidth="xl"
			disableGutters
		>
			<Grid
				container
				className="section"
			>
				<Grid
					container
					alignContent="space-around"
					justifyContent="start"
					className={`${classes.containerTitleAndParagraph} residencia`}
				>
                    {
                        window.location.pathname.includes('revalida') ?
                        <Typography className={`${classes.title} gradient-text-revalida title-card-home`}>
                            Vai assistir em outro estado?
                        </Typography>
                        : 
                        <Typography className={`${classes.title} gradient-text title-card-home`}>
                            Vai fazer outra prova?
                        </Typography>
                    }

					<Grid
						container
						spacing={0}
					>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.text} poppins`}
						>
							Veja todos os detalhes de todas as Live das Lives na íntegra clicando abaixo!
						</Typography>
					</Grid>
				</Grid>

				<Grid
					item
					container
					justifyContent={matchesMobile ? 'center' : 'flex-start'}
					direction="row"
					columnGap={6}
					className={`${classes.mainContainerCards} residencia`}
				>
					{arr?.map((e, i) => (
						<a
							key={i}
							href={e.link}
						>
							<Grid
								container
								direction="row"
								wrap="nowrap"
								key={i}
								onMouseEnter={() => handleHover(e)}
								onMouseLeave={() => handleHover(e)}
								className={classes.containerBorderAndImage}
							>
								<Grid
									container
									alignContent="center"
									className={
										e.stylization === 'firstCard'
											? classes.firstCardContainer
											: classes.othersCardContainer
									}
									key={i}
								>
									<Grid
										item
										container
										className={
											e.stylization === 'firstCard'
												? classes.mainBorderContainer
												: classes.othersBorderContainer
										}
										style={
											i !== 0
												? {
														background: selected.includes(e)
															? 'linear-gradient(to bottom, #FF5F45,#FF357D)'
															: 'linear-gradient(to bottom, #AF7373,#512F3A)',
												  }
												: null
										}
									></Grid>
									<Grid
										item
										container
										className={classes.imageContainer}
									>
										<img
											src={e.logo}
											alt={``}
										/>
									</Grid>
								</Grid>
							</Grid>
						</a>
					))}
				</Grid>
			</Grid>
		</Container>
	);
}

export default OtherProductsComponent;

const useStyles = makeStyles((theme) => ({
	mainContainerCards: {
		paddingLeft: '2rem !important',
		gap: '3rem !important',
		[theme.breakpoints.down('lg')]: {
			gap: '2rem !important',
		},
		[theme.breakpoints.down('md')]: {
			rowGap: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			rowGap: '1rem !important',
			paddingLeft: 'unset !important',
		},
	},
	firstCardContainer: {
		transition: 'all 300ms ease',
		width: '18.75rem !important',
		height: '4.4rem !important',
		borderRadius: '0.5rem !important',
		backgroundColor: '#585858',
		border: '1px solid #FF2A28',
		transform: 'scale(1.05)',
		[theme.breakpoints.down('md')]: {
			transform: 'scale(1)',
		},
		[theme.breakpoints.down('sm')]: {
			transform: 'scale(1)',
		},
	},
	othersCardContainer: {
		transition: 'all 300ms ease',
		width: '18.75rem !important',
		height: '4.4rem !important',
		borderRadius: '0.5rem !important',
		backgroundColor: '#373737',
		'&:hover': {
			cursor: 'pointer',
			backgroundColor: '#585858',
			border: '1px solid #FF2A28',
			transform: 'scale(1.05)',
		},
	},
	containerTitleAndParagraph: {
		marginBottom: '3rem !important',
	},
	containerBorderAndImage: {
		width: 'fit-content !important',
		columnGap: '2rem !important',
		height: '100%',
	},
	imageContainer: {
		width: '14rem !important',
		margin: '0 1.25rem !important',
		// padding: '0.5rem 0 !important',
	},
	mainBorderContainer: {
		width: '1.25rem !important',
		height: '100% !important',
		background: 'linear-gradient(to bottom, #FF5F45,#FF357D)',
		borderRadius: '0.5rem 0 0 0.5rem !important',
	},
	othersBorderContainer: {
		width: '1.25rem !important',
		height: '100% !important',
		borderRadius: '0.5rem 0 0 0.5rem !important',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.75rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.9rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '2.35rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.188rem !important',
		},
	},
	text: {
		fontSize: '1.3125rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
			lineHeight: '1.5 !important',
		},
	},
}));
