import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Container, Grid, Modal, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { styled, Box } from '@mui/system';

import FabricioValois from './Assets/fabricioRevalida.png';
import FabioColagrossi from './Assets/fabioColagrossiRevalida.png';
import FabioCabar from './Assets/fabioCabarRevalida.png';
import ClaudioLima from './Assets/claudioLimaRevalida.png';
import CynaraCarvalho from './Assets/cynaraCarvalhoRevalida.png';
import LaisKomatsu from './Assets/laisKomatsuRevalida.png';
import EricCosta from './Assets/ericCostaRevalida.png';
import DecarthonVitor from './Assets/decarthonVitorRevalida.png';
import FabioYuji from './Assets/fabioYujiRevalida.png';
import SophiaBatalha from './Assets/sophiaBatalhaRevalida.png';
import BenitoLourenco from './Assets/benitoLourencoRevalida.png';
import ArrowBack from '../../../../Assets/Svg/arrowBack.svg';
import ArrowForward from '../../../../Assets/Svg/arrowForward.svg';
import CaraDaProvaRevalida from './Assets/caraDaProvaRevalida.png';
import ShotsRevalida from './Assets/shotsRevalida.png';
import Imagens from './Assets/imagensRevalida.png';
import Apostas from './Assets/apostasRevalida.png';
import XoteDosMilagres from './Assets/xoteDosMilagresRevalida.png';
import GifApostas from '../LDL/Assets/gif-ldl-apostas.gif';
import GifCaraDaProva from '../LDL/Assets/gif-ldl-caradaprova.gif';
import GifImagens from '../LDL/Assets/gif-ldl-imagens.gif';
import GifShots from '../LDL/Assets/gif-ldl-shots.gif';

// COMPONENTS
import Footer from '../../../../Components/Footer';
import { Header } from '../../../../Components/Header';
import HostCard from '../../../../Components/Cards/HostCard';
import DefaultButton from '../../../../Components/Buttons/DefaultButton';
import { Helmet } from 'react-helmet';
import LiveInfoCard from './components/LiveInfoCard';
import WhereWhenComponent from './components/WhereWhenComponent';
import Api from '../../../../Services/Api';

import PriceCardRevalida from './components/PriceCardRevalida';
import DateInfoCardRevalida from './components/DateInfoCardRevalida';
import DateInfoCardRevalida2 from './components/DateInfoCardRevalida2';
import moment from 'moment';

const Hosts = [
	{
		ig_username: '@fabricio.valois',
		img: FabricioValois,
	},
	{
		img: FabioColagrossi,
	},
	{
		img: FabioCabar,
	},
	{
		img: ClaudioLima,
	},
	{
		img: CynaraCarvalho,
	},
	{
		img: LaisKomatsu,
	},
	{
		img: DecarthonVitor,
	},
	{
		img: EricCosta,
	},
	{
		img: FabioYuji,
	},
	{
		img: SophiaBatalha,
	},
	{
		img: BenitoLourenco,
	},
];

const ScriptCard = ({ img, gif, title, description, classes }) => {
	const [openGif, setOpenGif] = useState(true);

	const handleHover = () => {
		setOpenGif(!openGif);
	};

	return (
		<Paper
			className={`${classes.scriptPaper} rounded-card`}
			color="secondary"
			elevation={4}
			onMouseEnter={handleHover}
			onMouseLeave={handleHover}
		>
			<Grid style={{ backgroundColor: '#000000', borderRadius: 20 }}>
				{openGif ? (
					<img
						src={img}
						alt=""
						style={{ width: '100%' }}
					/>
				) : (
					<img
						src={gif}
						alt=""
						style={{ width: '100%', borderRadius: '20px 20px 0 0' }}
					/>
				)}
				<Typography
					align="left"
					color="secondary"
					className={`${classes.scriptTitle} title-card-home`}
				>
					{title}
				</Typography>
			</Grid>
			<Grid
				container
				alignContent="center"
				className={classes.gridDescriptionScriptCard}
			>
				<Typography
					align="left"
					color="primary"
					className={`${classes.scriptText} poppins`}
				>
					{description}
				</Typography>
			</Grid>
		</Paper>
	);
};

export default function RevalidaTemplate2024() {
	const classes = useStyles();
	const theme = useTheme();
	const carousel = useRef(null);
	const scriptCarousel = useRef(null);
	const purchase_card = useRef(null);
	const feedbackCarousel = useRef(null);
	const [currentContest, setCurrentContest] = useState('');
	const [lastLotDate, setLastLotDate] = useState();
	const [isFinished, setIsFinished] = useState();
	const [fullLots, setFullLots] = useState();
	const [currentId, setCurrentId] = useState();

	const currentDate = new Date();

	const currentDateUTC = moment(currentDate).utc();

	const feedbacksRevalida = [
		require('./Assets/img-revalida-feedback-final-01.png'),
		require('./Assets/img-revalida-feedback-final-02.png'),
		require('./Assets/img-revalida-feedback-final-03.png'),
		require('./Assets/img-revalida-feedback-final-04.png'),
		require('./Assets/img-revalida-feedback-final-05.png'),
		require('./Assets/img-revalida-feedback-final-06.png'),
		require('./Assets/img-revalida-feedback-final-07.png'),
		require('./Assets/img-revalida-feedback-final-08.png'),
		require('./Assets/img-revalida-feedback-final-09.png'),
		require('./Assets/img-revalida-feedback-final-10.png'),
		require('./Assets/img-revalida-feedback-final-11.png'),
		require('./Assets/img-revalida-feedback-final-12.png'),
	];

	const [contests] = useState([
		{
			id: 1360,
			name: 'sao-paulo',
			logo: require(`./Assets/ldl-sao-paulo-revalida.svg`).default,
			modality: 'presencial',
			shortName: '',
			testBench: '',
			address: 'Rua Josef Kryss, 318 - Parque Industrial Tomas Edson, São Paulo - SP',
			date: '24/08',
			locationCity: 'São Paulo',
			opening_date_sprint_final: '',
			close_date_sprint_final: '',
			opening_date_general_public: '',
			logoPriceCard: require('./Assets/logo-pricecard-revalida-saopaulo.svg').default,
			firstDescription: 'Teatro J Safra - Rua Josef Kryss, 318 - Parque Industrial Tomas Edson, São Paulo - SP',
			secondDescription: [
				'Dia ',
				<strong style={{ color: '#ffffff' }}>24/08/2024</strong>,
				', a partir das ',
				<strong style={{ color: '#ffffff' }}>09h</strong>,
				'. Previsão de término às 16h.',
			],
			feedbacks: feedbacksRevalida,
		},
		{
			id: 1362,
			name: 'salvador',
			logo: require(`./Assets/ldl-salvador-revalida.svg`).default,
			modality: 'presencial',
			shortName: 'salvador',
			testBench: 'salvador',
			address: 'Av. Tamburugy, 474 - Patamares, Salvador - BA, 41680-440',
			date: '24/08',
			locationCity: 'Salvador',
			opening_date_sprint_final: '',
			close_date_sprint_final: '',
			opening_date_general_public: '',
			logoPriceCard: require('./Assets/logo-pricecard-revalida-salvador.svg').default,
			firstDescription: 'Teatro Diplomata - Av. Tamburugy, 474 - Patamares, Salvador - BA, 41680-440',
			secondDescription: [
				'Dia ',
				<strong style={{ color: '#ffffff' }}>24/08/2024</strong>,
				', a partir das ',
				<strong style={{ color: '#ffffff' }}>09h</strong>,
				'. Previsão de término às 16h.',
			],
			feedbacks: feedbacksRevalida,
		},
		{
			id: 1361,
			name: 'recife',
			logo: require(`./Assets/ldl-recife-revalida.svg`).default,
			modality: 'presencial',
			shortName: 'recife',
			testBench: 'recife',
			address: 'Avenida Alfredo Lisboa, S/N - Bairro do Recife - PE',
			date: '24/08',
			locationCity: 'Recife',
			opening_date_sprint_final: '',
			close_date_sprint_final: '',
			opening_date_general_public: '',
			logoPriceCard: require('./Assets/logo-pricecard-revalida-recife.svg').default,
			firstDescription: 'Cais do Sertão - Avenida Alfredo Lisboa, S/N - Bairro do Recife - PE',
			secondDescription: [
				'Dia ',
				<strong style={{ color: '#ffffff' }}>24/08/2024</strong>,
				', a partir das ',
				<strong style={{ color: '#ffffff' }}>09h</strong>,
				'. Previsão de término às 16h.',
			],
			feedbacks: feedbacksRevalida,
		},
		{
			id: 1359,
			name: 'brasilia',
			logo: require(`./Assets/ldl-brasilia-revalida.svg`).default,
			modality: 'presencial',
			shortName: 'brasília',
			testBench: 'brasília',
			address: 'CICB - Centro Internacional de Convenções do Brasil',
			date: '24/08',
			locationCity: 'Brasília',
			opening_date_sprint_final: '',
			close_date_sprint_final: '',
			opening_date_general_public: '',
			logoPriceCard: require('./Assets/logo-pricecard-revalida-brasilia.svg').default,
			firstDescription:
				'CICB - Centro Internacional de Convenções do Brasil - St. de Clubes Esportivos Sul Trecho 2 Conjunto 63, Lote 50 - Asa Sul, Brasília - DF',
			secondDescription: [
				'Dia ',
				<strong style={{ color: '#ffffff' }}>24/08/2024</strong>,
				', a partir das ',
				<strong style={{ color: '#ffffff' }}>09h</strong>,
				'. Previsão de término às 16h.',
			],
			feedbacks: feedbacksRevalida,
		},
		{
			id: 1365,
			name: 'porto-velho',
			logo: require(`./Assets/ldl-porto-velho-revalida.svg`).default,
			modality: 'presencial',
			shortName: 'porto-velho',
			testBench: 'porto-velho',
			address: 'Av. Sete de Setembro, 934 - Centro, Porto Velho - RO',
			date: '24/08',
			locationCity: 'Porto Velho',
			opening_date_sprint_final: '',
			close_date_sprint_final: '',
			opening_date_general_public: '',
			logoPriceCard: require('./Assets/logo-pricecard-revalida-porto-velho.svg').default,
			firstDescription: 'Oscar Hotel Executive - Av. Sete de Setembro, 934 - Centro, Porto Velho - RO',
			secondDescription: [
				'Dia ',
				<strong style={{ color: '#ffffff' }}>24/08/2024</strong>,
				', a partir das ',
				<strong style={{ color: '#ffffff' }}>09h</strong>,
				'. Previsão de término às 16h (Horário Local).',
			],
			feedbacks: feedbacksRevalida,
		},
		{
			id: 1364,
			name: 'curitiba',
			logo: require(`./Assets/ldl-curitiba-revalida.svg`).default,
			modality: 'presencial',
			shortName: 'curitiba',
			testBench: 'curitiba',
			address: 'Teatro UP Experience - R. Prof. Pedro Viriato Parigot de Souza, 5.300',
			date: '24/08',
			locationCity: 'Curitiba',
			opening_date_sprint_final: '',
			close_date_sprint_final: '',
			opening_date_general_public: '',
			logoPriceCard: require('./Assets/logo-pricecard-revalida-curitiba.svg').default,
			firstDescription:
				'Teatro UP Experience - R. Prof. Pedro Viriato Parigot de Souza, 5.300 - Ecoville, Curitiba - PR',
			secondDescription: [
				'Dia ',
				<strong style={{ color: '#ffffff' }}>24/08/2024</strong>,
				', a partir das ',
				<strong style={{ color: '#ffffff' }}>09h</strong>,
				'. Previsão de término às 16h.',
			],
			feedbacks: feedbacksRevalida,
		},
		{
			id: 1357,
			name: 'belo-horizonte',
			logo: require(`./Assets/ldl-belo-horizonte-revalida.svg`).default,
			modality: 'presencial',
			shortName: 'belo-horizonte',
			testBench: 'belo-horizonte',
			address: 'Avenida Cristiano Machado, 4001 - Ipiranga - Belo Horizonte',
			date: '24/08',
			locationCity: 'Belo Horizonte',
			opening_date_sprint_final: '',
			close_date_sprint_final: '',
			opening_date_general_public: '',
			logoPriceCard: require('./Assets/logo-pricecard-revalida-belo-horizonte.svg').default,
			firstDescription:
				'Ouro Minas Hotel & Eventos - Avenida Cristiano Machado, 4001 - Ipiranga - Belo Horizonte',
			secondDescription: [
				'Dia ',
				<strong style={{ color: '#ffffff' }}>24/08/2024</strong>,
				', a partir das ',
				<strong style={{ color: '#ffffff' }}>09h</strong>,
				'. Previsão de término às 16h.',
			],
			feedbacks: feedbacksRevalida,
		},
		{
			id: 1358,
			name: 'campo-grande',
			logo: require(`./Assets/ldl-campo-grande-revalida.svg`).default,
			modality: 'presencial',
			shortName: 'campo-grande',
			testBench: 'campo-grande',
			address: 'Avenida Mato Grosso, 4250 - Carandá Bosque, Campo Grande – MS',
			date: '24/08',
			locationCity: 'Campo Grande',
			opening_date_sprint_final: '',
			close_date_sprint_final: '',
			opening_date_general_public: '',
			logoPriceCard: require('./Assets/logo-pricecard-revalida-campo-grande.svg').default,
			firstDescription:
				'Hotel Deville Prime Campo Grande - Avenida Mato Grosso, 4250 - Carandá Bosque, Campo Grande – MS',
			secondDescription: [
				'Dia ',
				<strong style={{ color: '#ffffff' }}>24/08/2024</strong>,
				', a partir das ',
				<strong style={{ color: '#ffffff' }}>09h</strong>,
				'. Previsão de término às 16h (Horário Local).',
			],
			feedbacks: feedbacksRevalida,
		},
		// {
		// 	id: 1363,
		// 	name: 'porto-alegre',
		// 	logo: require(`./Assets/ldl-porto-alegre-revalida.svg`).default,
		// 	modality: 'presencial',
		// 	shortName: 'porto-alegre',
		// 	testBench: 'porto-alegre',
		// 	address: 'Av. Ipiranga, 10 - Praia de Belas, Porto Alegre - RS',
		// 	date: '24/08',
		// 	locationCity: 'Porto Alegre',
		// 	opening_date_sprint_final: '',
		// 	close_date_sprint_final: '',
		// 	opening_date_general_public: '',
		// 	logoPriceCard: require('./Assets/logo-pricecard-revalida-porto-alegre.svg').default,
		// 	firstDescription:
		// 		'FGF - Federação Gaúcha de Futebol - Av. Ipiranga, 10 - Praia de Belas, Porto Alegre - RS (Entrada pela rua lateral: Rua Edvaldo Pereira Paiva, 831)',
		// 	secondDescription: [
		// 		'Dia ',
		// 		<strong style={{ color: '#ffffff' }}>24/08/2024</strong>,
		// 		', a partir das ',
		// 		<strong style={{ color: '#ffffff' }}>08h</strong>,
		// 		'. Previsão de término às 16h.',
		// 	],
		// 	feedbacks: feedbacksRevalida,
		// },
		{
			id: 1363,
			name: 'rio-branco',
			logo: require(`./Assets/ldl-rio-branco-revalida.svg`).default,
			modality: 'presencial',
			shortName: 'rio-branco',
			testBench: 'rio-branco',
			address: 'R. Rio Grande do Sul, 332 - Centro - Rio Branco - AC',
			date: '24/08',
			locationCity: 'Rio Branco',
			opening_date_sprint_final: '',
			close_date_sprint_final: '',
			opening_date_general_public: '',
			logoPriceCard: require('./Assets/logo-pricecard-revalida-rio-branco.svg').default,
			firstDescription: 'Hotel Diff - R. Rio Grande do Sul, 332 - Centro - Rio Branco - AC',
			secondDescription: [
				'Dia ',
				<strong style={{ color: '#ffffff' }}>24/08/2024</strong>,
				', a partir das ',
				<strong style={{ color: '#ffffff' }}>09h</strong>,
				'. Previsão de término às 16h (Horário Local).',
			],
			feedbacks: feedbacksRevalida,
		},
	]);
	const [lots, setLots] = useState([]);
	const [idLotVigente, setIdLotVigente] = useState();
	const [open, setOpen] = useState(false);
	const [numParticipants, setNumParticipants] = useState();
	const [selectedVideo, setSelectedVideo] = useState('');
	const [scriptMock, setScriptMock] = useState([]);
	const [sold, setSold] = useState();
	const [sprintPopUpVisibility, setSprintPopUpVisibility] = useState(false);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesDownXl = useMediaQuery(theme.breakpoints.down('xl'));

	useEffect(() => {
		const path = window.location.pathname;

		let lastPart;

		if (
			path.includes('sao-paulo') ||
			path.includes('salvador') ||
			path.includes('recife') ||
			path.includes('brasilia') ||
			path.includes('porto-velho') ||
			path.includes('curitiba') ||
			path.includes('belo-horizonte') ||
			path.includes('campo-grande') ||
			path.includes('porto-alegre') ||
			path.includes('rio-branco') ||
			path.includes('rio-de-janeiro')
		) {
			const parts = path.split('/');
			lastPart = parts[parts.length - 1];
		}

		const foundContest = contests.find((contest) => contest.name === lastPart);

		if (foundContest) {
			setCurrentContest(foundContest);
		}

		if (currentContest) {
			Api.get(`turmasabertas/${currentContest?.id}`).then((res) => {
				// res[0].qtd_inscritos = 500; // TESTE FORÇADO PARA VERIFICAR O COMPORTAMENTO QUANDO ACABAREM AS VAGAS
				// res[0].encerrada = true;

				setIdLotVigente(res[0]?.id_lote_vigente);
				let dateLastLot = new Date(res[0]?.lotes[5]?.data_limite_utc);
				setLastLotDate(dateLastLot);
				setIsFinished(res[0]?.encerrada);
				setFullLots(res[0]?.lotes);
				setCurrentId(res[0]?.id_lote_vigente);
				const currentDate = new Date();

				const activeLot = res[0]?.lotes?.find((lot, index) => {
					return lot?.id === res[0]?.id_lote_vigente;
				});

				if (activeLot) {
					setLots([activeLot]);
					setNumParticipants(res[0]?.qtd_inscritos);
				} else {
					if (res[0]?.encerrada !== true) {
						setNumParticipants(res[0]?.qtd_inscritos);
						const nextLot = res[0]?.lotes?.find((lot, index) => {
							const startDate = new Date(lot.data_inicio_utc);

							return currentDate < startDate;
						});

						const valueLimitLot4 = res[0]?.lotes?.find((lot) => lot.nome === '4')?.qtd_limite;
						if (valueLimitLot4 <= res[0]?.qtd_inscritos) {
							setSold(true);
						}
						setLots([nextLot]);
					}
				}
			});
		}

		const scriptMockArray = generateScriptMock(
			currentContest?.name?.toUpperCase(),
			currentContest?.testBench?.toUpperCase()
		);

		function addEvent(obj, evt, fn) {
			if (obj.addEventListener) {
				obj.addEventListener(evt, fn, false);
			} else if (obj.attachEvent) {
				obj.attachEvent('on' + evt, fn);
			}
		}

		let popupOpened = false;

		addEvent(window, 'load', function (e) {
			if (!matchesTablet) {
				addEvent(document, 'mouseout', function (e) {
					e = e ? e : window.event;
					var from = e.relatedTarget || e.toElement;
					if (!popupOpened && (!from || from.nodeName === 'HTML')) {
						setSprintPopUpVisibility(true);
						popupOpened = true;
					}
				});
			}
		});

		setTimeout(() => {
			if (matchesTablet && !popupOpened) {
				setSprintPopUpVisibility(true);
				popupOpened = true;
			}
		}, 15000);

		setScriptMock(scriptMockArray);
	}, [contests, currentContest]);

	function generateScriptMock(exam, testBench) {
		return [
			{
				id: 1,
				img: CaraDaProvaRevalida,
				gif: GifCaraDaProva,
				source: process.env.PUBLIC_URL + '/Assets/ResidenciaTemplate/caraDaProvaRevalida.mp4',
				title: 'A cara da prova',
				description: `Um choque de realidade! A gente já começa mostrando os temas mais comuns à prova do Revalida... os professores de todas as áreas participam, selecionando cerca de 15 temas que são A CARA DA PROVA!`,
			},
			{
				id: 2,
				img: ShotsRevalida,
				gif: GifShots,
				source: process.env.PUBLIC_URL + '/Assets/ResidenciaTemplate/shotsRevalida.mp4',
				title: 'Shots',
				description: `Hora do Stand Up Hardwork. O bloco queridinho da Live das Lives, com professores de diversas áreas no palco. Quando a luz se acende o professor relembra um tema de forma enfática, no padrão INEP; a luz se apaga e já trocamos de área, tema, professor... assim seguimos por cerca de 20 temas numa dinâmica avassaladora.`,
			},
			{
				id: 3,
				img: Imagens,
				gif: GifImagens,
				source: process.env.PUBLIC_URL + '/Assets/ResidenciaTemplate/imagensRevalida.mp4',
				title: 'Imagens',
				description: `Imagens, MUITAS imagens! Assim é a prova do Revalida INEP, assim é o bloco de imagens. Hora de desmistificar uma série de temas com imagens muito relevantes em diversas áreas.`,
			},
			{
				id: 4,
				img: XoteDosMilagres,
				gif: GifShots,
				source: process.env.PUBLIC_URL + '/Assets/ResidenciaTemplate/shotsRevalida2.mp4',
				title: 'Xote dos milagres',
				description:
					'Calma... não é música... mas até que poderia ser... É mais um bloco de shots, repleto de temas importantes, só que agora diferente. São temas um tanto milagrosos, inspirados por insights profundos dos professores Hardwork. ',
			},
			{
				id: 5,
				img: Apostas,
				gif: GifApostas,
				source: process.env.PUBLIC_URL + '/Assets/ResidenciaTemplate/apostasRevalida.mp4',
				title: 'Apostas',
				description: `Por fim, o bloco mais esperado da Live das Lives... os professores retornam ao palco para trazer, em cada área, as apostas Hardwork para a prova do Revalida INEP!`,
			},
		];
	}

	const executeScroll = () => purchase_card.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

	const handleLeftClick = (e) => {
		e.preventDefault();

		carousel.current.scrollLeft -= carousel.current.offsetWidth;
	};

	const handleRightClick = (e) => {
		e.preventDefault();

		carousel.current.scrollLeft += 380;
	};

	const handleScriptLeftClick = (e) => {
		e.preventDefault();

		scriptCarousel.current.scrollLeft -= scriptCarousel.current.offsetWidth;
	};

	const handleScriptRightClick = (e) => {
		e.preventDefault();

		scriptCarousel.current.scrollLeft += scriptCarousel.current.offsetWidth;
	};

	const handleFeedbackLeftClick = (e) => {
		e.preventDefault();

		feedbackCarousel.current.scrollLeft -= feedbackCarousel.current.offsetWidth;
	};

	const handleFeedbackRightClick = (e) => {
		e.preventDefault();

		feedbackCarousel.current.scrollLeft += feedbackCarousel.current.offsetWidth;
	};

	const handleOpen = (source) => {
		setSelectedVideo(source);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setSelectedVideo('');
	};

	const handleCloseSprintPopUp = () => {
		setSprintPopUpVisibility(false);
	};

	const redirectToSprintPage = () => {
		document.location.href = '/sprint-final-revalida';
	};

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{currentContest && (
				<Helmet>
					<title>{currentContest?.locationCity?.toUpperCase()} Live das Lives 2024</title>
					<meta
						name="description"
						content={''}
					/>
				</Helmet>
			)}
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="residencia"
			>
				{matchesMobile || matchesTablet ? null : <Header />}
			</Container>
			<Grid
				container
				alignContent={'center'}
				style={matchesMobile ? { marginTop: 80 } : null}
			>
				{/* Mouse over and timeout Popup */}
				{/* <Modal
					open={sprintPopUpVisibility}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
					style={{ zIndex: 1000, display: 'flex', justifyContent: 'center' }}
				>
					<Grid
						container
						justifyContent={'center'}
						rowGap={2}
						className={classes.gridModalSprint}
					>
						<Grid
							item
							xs={12}
							container
							justifyContent={'flex-end'}
							style={
								matchesMobile
									? { position: 'absolute', right: '25px', top: '25px' }
									: { position: 'absolute', right: '10px', top: '10px' }
							}
						>
							<CancelRoundedIcon
								onClick={() => handleCloseSprintPopUp()}
								className={classes.cancelIcon}
							/>
						</Grid>
						<Typography
							color="secondary"
							className={`${classes.titlePopUpSprint} poppins`}
						>
							Hora de #SPRINTAR
						</Typography>
						<Typography
							color="secondary"
							className={`${classes.descriptionPopUpSprint} poppins`}
						>
							Chegou a hora certa de entrar na alma da prova do Inep! A gente te treina para a objetiva e
							a discursiva! Ainda dá tempo!
						</Typography>
						<Grid
							item
							xs={12}
							container
							justifyContent="center"
							className="revalida"
							style={{ marginTop: '1.5rem' }}
						>
							<DefaultButton
								variant="outlined"
								color={'secondary'}
								type="revalida"
								className={`${classes.subscribeBtn2} background-central-concursos-invert-revalida poppins`}
								style={{ padding: '0.25rem 3rem !important' }}
								onClick={() => redirectToSprintPage()}
							>
								&nbsp;&nbsp;Vou sprintar
							</DefaultButton>
						</Grid>
					</Grid>
				</Modal> */}
				<video
					src={'/Assets/bg-video-revalida.mp4'}
					autoPlay
					loop
					muted
					playsInline
					className={classes.videoBackground}
				/>
				<Grid
					item
					xs={12}
					container
					direction={'column'}
					alignContent={'center'}
					justifyContent={'center'}
					className={`${classes.gridLogoContainer} residencia`}
				>
					<img
						src={require('./Assets/img-presencial-revalida-cinema.svg').default}
						alt=""
						className={classes.ldlLogo}
						style={{ marginBottom: matchesMobile ? '4rem' : matchesDownXl ? '7rem' : '6rem' }}
					/>
					<img
						src={currentContest.logo}
						alt=""
						className={classes.ldlLogo}
						style={matchesMobile ? { margin: '1rem 0', width: '72%' } : null}
					/>

					{currentDateUTC > lastLotDate || isFinished ? (
						<Grid
							item
							xs={8}
							sm={6}
							container
							justifyContent="center"
							className={'revalida'}
						>
							<DefaultButton
								variant="outlined"
								color={'secondary'}
								type="revalida"
								className={`${classes.subscribeBtn} white-btn-error residencia poppins`}
								style={{
									marginTop: '3rem',
									padding: matchesDownXl ? '0.25rem 1.25rem !important' : null,
								}}
								onClick={() => executeScroll()}
							>
								&nbsp;&nbsp;Inscrições encerradas
							</DefaultButton>
						</Grid>
					) : (
						<Grid
							item
							xs={8}
							sm={6}
							container
							justifyContent="center"
							className={'revalida'}
						>
							<DefaultButton
								variant="outlined"
								color={'primary'}
								type="revalida"
								className={`${classes.subscribeBtn} white-btn-secondary residencia poppins`}
								style={{
									marginTop: '3rem',
									padding: matchesDownXl ? '0.25rem 1.25rem !important' : null,
									border: '2px solid transparent !important',
								}}
								onClick={() => executeScroll()}
							>
								<img
									src={require('../../../../Assets/Svg/Icons/bagIcon.svg').default}
									alt=""
									className={classes.logoBtn}
								/>
								&nbsp;&nbsp;Garantir vaga
							</DefaultButton>
						</Grid>
					)}
				</Grid>
			</Grid>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="residencia"
			>
				<Grid
					container
					className="section"
				>
					<Grid
						item
						xs={12}
						container
						justifyContent={'space-between'}
					>
						<Grid
							item
							xs={12}
							md={6}
							container
							alignContent={'center'}
							style={{ top: matchesMobile ? null : matchesTablet ? 15 : 30 }}
						>
							<img
								src={require('./Assets/revisao-de-vespera-revalida.svg').default}
								alt="revisão véspera revalida"
							/>
							<Typography
								color="secondary"
								className={'Poppins'}
								style={{
									fontSize: matchesTablet ? '1.1rem' : '2.05rem',
									fontWeight: 'bold',
									marginTop: '0.5rem',
									lineHeight: 'unset',
								}}
							>
								A estratégia perfeita para entrar na alma do INEP.
							</Typography>
							<img
								src={
									matchesMobile
										? require('./Assets/img-revalida-experience-mobile.png')
										: require('./Assets/img-revalida-foco-blur.png')
								}
								alt=""
							/>
							<Typography
								color={'#C1C1C1'}
								className={`${classes.firstText} poppins`}
							>
								Foco total na <br />
								prova do INEP
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							md={5.5}
							container
						>
							<img
								src={
									matchesMobile
										? require('./Assets/img-revalida-experience-mobile-2.png')
										: require('./Assets/img-revalida-experience-blur.png')
								}
								alt=""
								className={classes.SecondImageLDL}
							/>
							<Typography
								color={'#C1C1C1'}
								className={`${classes.secondText} poppins`}
							>
								Imersão total do
								<br />
								começo ao fim!
							</Typography>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						container
					>
						<Grid
							item
							xs={12}
							md={7}
							container
							alignContent={'center'}
						>
							<img
								src={
									matchesMobile
										? require('./Assets/img-revalida-experience-mobile-3.png')
										: require('./Assets/img-revalida-experience-blur-2.png')
								}
								alt=""
							/>
							<Typography
								color={'#C1C1C1'}
								className={`${classes.thirdText} poppins`}
							>
								Um show de didática com
								<br />
								os melhores professores
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							md={5}
							container
							alignContent={'center'}
						>
							<img
								src={
									matchesMobile
										? require('./Assets/img-revalida-experience-mobile-4.png')
										: require('./Assets/img-revalida-experience-blur-3.png')
								}
								alt=""
							/>
							<Typography
								color={'#C1C1C1'}
								className={`${classes.fourthText} poppins`}
							>
								"Eu ouvi as vozes dos professores na hora da prova"
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Container>
			<Grid
				container
				rowGap={2}
				style={{ margin: matchesMobile ? '2rem 0' : '6rem 0' }}
			>
				<LiveInfoCard
					title={'Um show!'}
					description={
						matchesMobile
							? [
									`A Live das Lives é uma experiência! Não pode ser diferente! A véspera da prova não permite desperdício de energia. Tudo na Live das Lives é desenhado para que a revisão seja leve, prazerosa e intensa!`,
									<br />,
									<br />,
									'Temas explorados de formas variadas, numa dinâmica que manterá nossa conexão até o fim!',
							  ]
							: [
									`A Live das Lives é uma experiência! Não pode ser diferente! A véspera da prova não permite desperdício de energia. Tudo na Live das Lives é desenhado para que a revisão seja leve, prazerosa e intensa!`,
									<br />,
									<br />,
									'Temas explorados de formas variadas, numa dinâmica que manterá nossa conexão até o fim!',
							  ]
					}
				/>
				<LiveInfoCard
					title={'Véspera da prova!'}
					description={
						matchesMobile
							? [
									'A véspera da prova é sempre um momento complexo. Uma mistura de sentimentos que nos deixa sem saber o que fazer… Descansar? Assistir a aulas? Ler resumos?',
									<br />,
									<br />,
									`A Live das Lives surgiu para acabar com essa angústia! Tu só precisas ${
										currentContest?.modality === 'presencial' ? 'vir' : 'te conectar'
									}… deixe que a gente faz o resto!`,
							  ]
							: [
									'A véspera da prova é sempre um momento complexo. Uma mistura de sentimentos que nos deixa sem saber o que fazer… Descansar? Assistir a aulas? Ler resumos?',
									<br />,
									<br />,
									`A Live das Lives surgiu para acabar com essa angústia! Tu só precisas ${
										currentContest?.modality === 'presencial' ? 'vir' : 'te conectar'
									}… deixe que a gente faz o resto!`,
							  ]
					}
				/>
				<LiveInfoCard
					title={'Foco total!'}
					description={
						matchesMobile
							? [
									'A proposta da Live das Lives é simples: cerca de 7 horas de imersão total na prova! Nada passa em branco! Além das mensagens mais importantes dos temas de predileção do INEP, a live também traz todas as apostas',
									<br />,
									<br />,
									'do Hardwork para a prova, resultado do estudo profundo dos últimos anos do concurso. Nós dominamos tudo!',
							  ]
							: [
									'A proposta da Live das Lives é simples: cerca de 7 horas de imersão total na prova! Nada passa em branco!',
									<br />,
									<br />,
									'Além das mensagens mais importantes dos temas de predileção do INEP, a live também traz todas as apostas do Hardwork para a prova, resultado do estudo profundo dos últimos anos do concurso. Nós dominamos tudo!',
							  ]
					}
				/>
			</Grid>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					alignContent="space-around"
					justifyContent="center"
					className="section"
				>
					<Grid
						item
						xs={12}
						className="residencia"
					>
						<Typography
							align="left"
							className={`${classes.title} gradient-text-revalida title-card-home`}
						>
							Como funciona a live?
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						style={{ margin: matchesMobile ? '0.5rem 0 1.5rem' : '1.5em 0 2.5em' }}
					>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.text} poppins`}
						>
							Uma revisão de véspera explorando todos os temas importantes do INEP. <br />
							<br />A Live das Lives Revalida é um {''}
							<strong>
								evento EXCLUSIVAMENTE {''}
								{currentContest?.modality}, {''}
							</strong>
							que será transmitido em modo cinema, sendo projetado nas cidades com a possibilidade da
							presença de um professor Hardwork.
							<br />
							<br />A prova do <strong>REVALIDA INEP</strong> exige uma revisão muito especial. Para a
							conexão ser intensa do início ao fim, discutir e sedimentar muitos temas, a live é dividida
							em blocos diferentes, envolvendo vários professores simultaneamente:
						</Typography>
					</Grid>

					<Grid
						item
						xs={12}
						container
						justifyContent={matchesMobile ? 'space-between' : 'center'}
					>
						<Grid
							item
							className={classes.gridCarouselContainer}
							ref={scriptCarousel}
						>
							{scriptMock?.map((item, index) => {
								return (
									<Grid
										item
										xs={12}
										md={6}
										key={index}
										className={classes.gridScriptCard}
										style={index > 0 ? { marginLeft: '1.5em' } : null}
										onClick={() => handleOpen(item.source)}
									>
										<ScriptCard
											img={item.img}
											gif={item.gif}
											title={item.title}
											description={item.description}
											key={index}
											classes={classes}
										/>
									</Grid>
								);
							})}
						</Grid>

						<StyledModal
							aria-labelledby="unstyled-modal-title"
							aria-describedby="unstyled-modal-description"
							open={open}
							onClose={handleClose}
							slots={{ backdrop: StyledBackdrop }}
						>
							<Box
								display={'flex'}
								justifyContent={'center'}
								direction={''}
								sx={containerBox}
								style={matchesMobile ? { width: '330px', height: '190px' } : null}
							>
								<video
									sx={videoModal}
									src={selectedVideo}
									autoPlay
									loop
									style={matchesMobile ? { width: '25rem', borderRadius: 15 } : { borderRadius: 15 }}
								/>
							</Box>
						</StyledModal>

						<Button
							onClick={handleScriptLeftClick}
							className={`${classes.arrowBtn} ${classes.leftArrowBtn}`}
						>
							<img
								src={ArrowBack}
								alt=""
								className={classes.arrowIcon}
							/>
						</Button>
						<Button
							onClick={handleScriptRightClick}
							className={`${classes.arrowBtn} ${classes.arrowBtnRight}`}
						>
							<img
								src={ArrowForward}
								alt=""
								className={classes.arrowIcon}
							/>
						</Button>
					</Grid>
				</Grid>
				<Grid
					container
					alignContent="space-around"
					justifyContent="center"
					className="section"
					style={matchesMobile ? null : { margin: matchesTablet ? '4rem auto' : '8rem auto' }}
				>
					<Grid
						item
						xs={12}
						className="residencia"
					>
						<Typography
							align="left"
							className={`${classes.title} gradient-text-revalida title-card-home`}
						>
							Quando e onde a Live das Lives Revalida irá acontecer?
						</Typography>
					</Grid>

					<WhereWhenComponent contest={currentContest} />
					<DateInfoCardRevalida
						lot={lots[0]}
						allLotes={lots}
						contest={currentContest}
						openingDateSprintStudents={currentContest?.opening_date_sprint_final}
						closeDateSprintStudents={currentContest?.close_date_sprint_final}
						openingDateGeneralPublic={currentContest?.opening_date_general_public}
						targetDate={lots[0]?.data_limite}
						numParticipants={numParticipants}
						modality={currentContest.modality}
						executeScroll={executeScroll}
						fullLots={fullLots}
						currentId={currentId}
					/>
				</Grid>
			</Container>

			<Grid
				container
				className="background-ldl-revalida-horizontal"
			>
				<Grid
					container
					style={{ padding: '2vh 5%' }}
					className={classes.backgroundWaves}
				>
					<Container
						fixed
						maxWidth="xl"
						disableGutters={true}
					>
						<Grid
							container
							alignContent="center"
							justifyContent="center"
							className={`${matchesTablet ? null : 'section'}`}
							style={{
								borderRadius: 15,
								margin: matchesTablet ? '30px auto' : '30px auto 60px',
							}}
							ref={purchase_card}
						>
							<Grid
								item
								xs={12}
								style={{ marginBottom: '2rem' }}
							>
								<Typography
									align="left"
									color="primary"
									className={`${classes.title} title-card-home`}
								>
									Garanta sua inscrição!
								</Typography>
							</Grid>
							<PriceCardRevalida
								lotVigenteId={idLotVigente}
								lot={lots[0]}
								idTurma={currentContest?.id}
								contest={currentContest}
								numParticipants={numParticipants}
								lotModality={currentContest?.modality}
								limit={sold}
							/>
						</Grid>
					</Container>
				</Grid>
			</Grid>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					alignContent="space-around"
					justifyContent="center"
					className="section"
					style={matchesMobile ? null : { marginTop: 0 }}
				>
					<Grid
						item
						xs={12}
						className="residencia"
						style={{ marginBottom: '4rem', marginTop: '6rem' }}
					>
						<Typography
							align="left"
							className={`${classes.title} gradient-text-revalida title-card-home`}
						>
							Depoimentos de nossos alunos
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						container
						justifyContent={matchesMobile ? 'space-between' : 'center'}
						style={matchesMobile ? { marginBottom: '1rem' } : null}
					>
						<Grid
							item
							container
							className={classes.gridCarouselContainer}
							ref={feedbackCarousel}
							style={matchesMobile ? { marginBottom: '1rem' } : null}
						>
							{currentContest?.feedbacks &&
								currentContest?.feedbacks.map((img, index) => {
									return (
										<Grid
											item
											xs={12}
											md={3}
											container
											className={classes.gridFeedbackCards}
											style={index > 0 ? { marginLeft: '1.5em' } : null}
										>
											<Grid
												item
												xs={12}
											>
												<img
													src={img}
													alt=""
													style={{
														width: '100%',
														borderBottomLeftRadius: 15,
														borderBottomRightRadius: 15,
													}}
												/>
											</Grid>
										</Grid>
									);
								})}
						</Grid>
						<Button
							onClick={handleFeedbackLeftClick}
							className={`${classes.arrowBtn} ${classes.leftArrowBtnFeedbackSection}`}
						>
							<img
								src={ArrowBack}
								alt=""
								className={classes.arrowIcon}
							/>
						</Button>
						<Button
							onClick={handleFeedbackRightClick}
							className={`${classes.arrowBtn} ${classes.arrowBtnRightFeedbackSection}`}
						>
							<img
								src={ArrowForward}
								alt=""
								className={classes.arrowIcon}
							/>
						</Button>
					</Grid>
				</Grid>
			</Container>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					alignContent="space-around"
					justifyContent="center"
					className="section"
				>
					<Grid
						item
						xs={12}
						className="residencia"
					>
						<Typography
							align="left"
							className={`${classes.title} gradient-text-revalida title-card-home`}
						>
							{matchesMobile
								? ['Olha quem ', <br />, ' vai conduzir o show: ']
								: 'Olha quem vai conduzir o show: '}
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						container
						justifyContent={matchesMobile ? 'space-between' : 'center'}
					>
						<Grid
							item
							className={classes.gridCarouselContainer}
							ref={carousel}
							style={matchesMobile ? { left: 0 } : null}
						>
							{Hosts.map((item, index) => (
								<Grid
									item
									key={index}
									style={
										matchesMobile && index === 0
											? { marginRight: 35, right: 0 }
											: index === 8
											? { marginRight: 10 }
											: { marginRight: 35 }
									}
								>
									<HostCard
										ig_username={item.ig_username}
										img={item.img}
										specialty={item.specialty}
										key={index}
									/>
								</Grid>
							))}
						</Grid>
						<Grid
							item
							xs={12}
							style={matchesMobile ? { margin: '1em 0' } : { margin: '1em 0 1.5em' }}
						>
							<Typography
								align="left"
								color="secondary"
								className={`${classes.text} poppins`}
							>
								*Sujeito a alterações
							</Typography>
						</Grid>
						<Button
							onClick={handleLeftClick}
							className={`${classes.arrowBtn} ${classes.leftArrowBtn}`}
							style={{ top: 200 }}
						>
							<img
								src={ArrowBack}
								alt=""
								className={classes.arrowIcon}
							/>
						</Button>
						<Button
							onClick={handleRightClick}
							className={`${classes.arrowBtn} ${classes.arrowBtnRight}`}
							style={{ top: 200 }}
						>
							<img
								src={ArrowForward}
								alt=""
								className={classes.arrowIcon}
							/>
						</Button>
					</Grid>
				</Grid>
			</Container>
			<Footer />
		</Container>
	);
}

const Backdrop = React.forwardRef((props, ref) => {
	const { open, className, ...other } = props;
	return (
		<div
			className={clsx({ 'MuiBackdrop-open': open }, className)}
			ref={ref}
			{...other}
		/>
	);
});

Backdrop.propTypes = {
	className: PropTypes.string.isRequired,
	open: PropTypes.bool,
};

const useStyles = makeStyles((theme, linkActive) => ({
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.75rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.9rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '2.35rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.8rem !important',
		},
	},
	paper: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
	},
	scriptPaper: {
		height: 403,
		width: 684,
		cursor: 'pointer',
		[theme.breakpoints.down('xl')]: {
			width: 585,
			height: 349,
		},
		[theme.breakpoints.down('lg')]: {
			height: 'unset',
			width: 435,
		},
		[theme.breakpoints.down('md')]: {
			height: 393,
			width: 585,
		},
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
			width: 'unset',
		},
	},
	text: {
		fontSize: '1.3125rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
			lineHeight: '1.5 !important',
		},
	},
	arrowIcon: {
		width: '47%',
	},
	arrowBtn: {
		position: 'absolute !important',
		zIndex: 1000,
		borderRadius: '50% !important',
		width: 80,
		height: 80,
		backgroundColor: '#2D2D2D !important',
		opacity: 0.75,
		'&:hover': { opacity: 1, transition: '0.2s' },
		[theme.breakpoints.down('sm')]: {
			position: 'unset !important',
			zIndex: 'unset',
			width: 60,
			height: 60,
		},
	},
	btnFirstSection: {
		width: '65%',
		fontSize: '1.425rem !important',
		fontWeight: 'bold !important',
		'&:hover': {
			scale: 1.05,
			color: '#ffffff',
			'& $logoBtn': {
				filter: 'invert(0%) !important',
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
			minHeight: 'unset !important',
			width: '80%',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
			width: '100%',
		},
	},
	leftArrowBtn: {
		left: -90,
		top: 150,
		[theme.breakpoints.down('xl')]: {
			left: -90,
		},
		[theme.breakpoints.down(1389)]: {
			left: -50,
		},
		[theme.breakpoints.down('md')]: {
			left: -90,
		},
	},
	arrowBtnRight: {
		right: -80,
		top: 150,
		[theme.breakpoints.down('xl')]: {
			right: -35,
		},
		[theme.breakpoints.down('lg')]: {
			right: -55,
		},
	},
	gridCarouselContainer: {
		display: 'flex !important',
		overflowX: 'hidden',
		scrollBehavior: 'smooth',
		flexWrap: 'nowrap !important',
		overflowY: 'hidden',
		[theme.breakpoints.down('sm')]: {
			overflowX: 'auto',
		},
	},
	scriptTitle: {
		fontSize: '3.125rem !important',
		fontWeight: 'bold !important',
		position: 'absolute',
		bottom: 10,
		left: 35,
		textTransform: 'none',
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem !important',
			left: 17,
		},
	},
	scriptText: {
		fontSize: '1.125rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	gridScriptCard: {
		[theme.breakpoints.down('sm')]: {
			minWidth: '100%',
		},
	},
	gridDescriptionScriptCard: {
		backgroundColor: '#FFFFFF !important',
		padding: '1em 2em',
		borderRadius: 16,
		textTransform: 'none',
		[theme.breakpoints.down('sm')]: {
			padding: '1em',
		},
	},
	defaultBtn: {
		fontSize: '1.05rem !important',
		fontWeight: '700 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	logoBtn: {
		filter: 'invert(1)',
		transition: 'ease-in-out 0.2s',
		[theme.breakpoints.down('xl')]: {
			height: 30,
		},
		[theme.breakpoints.down('sm')]: {
			height: 17,
		},
	},
	subscribeBtn: {
		width: 'fit-content',
		padding: '0.5rem 5rem !important',
		alignSelf: 'center',
		fontSize: '1.35rem !important',
		fontWeight: 'bold !important',
		'&:hover': {
			scale: 1.05,
			color: '#ffffff !important',
			'& $logoBtn': {
				filter: 'invert(0)',
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '0.85rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0.5rem 2rem !important',
			fontSize: '0.75rem !important',
			marginTop: 'unset !important',
		},
	},
	subscribeBtn2: {
		width: 'fit-content',
		padding: '0.5rem 5rem !important',
		alignSelf: 'center',
		fontSize: '1.35rem !important',
		fontWeight: 'bold !important',
		'&:hover': {
			scale: 1.05,
			color: '#ffffff !important',
			'& $logoBtn': {
				filter: 'invert(0)',
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '0.85rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0.5rem 3rem !important',
			fontSize: '1rem !important',
			marginTop: 'unset !important',
		},
	},
	gridLogoContainer: {
		position: 'absolute',
	},
	ldlLogo: {
		alignSelf: 'center',
		[theme.breakpoints.down(1509)]: {
			width: '60%',
		},
		[theme.breakpoints.down('sm')]: {
			alignSelf: 'center',
			width: '80%',
		},
	},
	videoBackground: {
		objectFit: 'cover',
		height: 676,
		width: '100%',
		[theme.breakpoints.down('xl')]: {
			height: 480,
		},
		[theme.breakpoints.down('md')]: {
			height: 332,
		},
		[theme.breakpoints.down('sm')]: {
			height: 283,
		},
	},
	backgroundWaves: {
		backgroundImage: `url(${process.env.PUBLIC_URL + '/Assets/Img/bgWave2.png)'})`,
		width: '100vw !important',
		backgroundSize: 'cover',
	},
	gridFeedbackCards: {
		backgroundColor: '#313131',
		paddingTop: '1rem !important',
		borderRadius: '15px',
		minWidth: '25%',
		[theme.breakpoints.down('md')]: {
			minWidth: '50%',
		},
		[theme.breakpoints.down('sm')]: {
			minWidth: '70%',
		},
	},
	leftArrowBtnFeedbackSection: {
		left: -90,
		top: 245,
		[theme.breakpoints.down('xl')]: {
			left: -90,
		},
		[theme.breakpoints.down(1389)]: {
			left: -50,
		},
		[theme.breakpoints.down('md')]: {
			left: -90,
		},
	},
	arrowBtnRightFeedbackSection: {
		right: -80,
		top: 245,
		[theme.breakpoints.down('xl')]: {
			right: -35,
		},
		[theme.breakpoints.down('lg')]: {
			right: -55,
		},
	},
	firstText: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		letterSpacing: '-2px !important',
		position: 'absolute',
		bottom: '10px',
		left: '70px',
		textShadow: '2px 2px 4px #000000',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.5rem !important',
			bottom: '0px',
			left: '50px',
			color: 'secondary !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.5rem !important',
			left: '30px',
		},
		[theme.breakpoints.down('sm')]: {
			bottom: '5px',
			fontSize: '1.5rem !important',
		},
	},
	secondText: {
		letterSpacing: '-2px !important',
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		position: 'absolute',
		bottom: '5px',
		left: '70px',
		textShadow: '2px 2px 4px #000000',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.5rem !important',
			left: '50px',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.5rem !important',
			left: '30px',
		},
		[theme.breakpoints.down('sm')]: {
			bottom: '0px',
			fontSize: '1.5rem !important',
		},
	},
	thirdText: {
		fontSize: '3rem !important',
		letterSpacing: '-2px !important',
		fontWeight: 'bold !important',
		position: 'absolute',
		bottom: '155px',
		left: '70px',
		lineHeight: 'unset !important',
		textShadow: '2px 2px 4px #000000',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.5rem !important',
			bottom: '90px',
			left: '50px',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.5rem !important',
			bottom: '60px',
		},
		[theme.breakpoints.down('sm')]: {
			bottom: '-8px',
			fontSize: '1.5rem !important',
			left: '30px',
		},
	},
	gridButton: {
		position: 'absolute',
		bottom: '6.5em',
		[theme.breakpoints.down('md')]: {
			bottom: '0.5em',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			bottom: '1.5em',
		},
	},
	fourthText: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		letterSpacing: '-2px !important',
		position: 'absolute',
		bottom: '-8px',
		left: '70px',
		textShadow: '2px 2px 4px #000000',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.5rem !important',
			left: '50px',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.5rem !important',
			left: '30px',
		},
		[theme.breakpoints.down('sm')]: {
			bottom: '-2px',
			fontSize: '1.5rem !important',
		},
	},
	cancelIcon: {
		fontSize: '2rem !important',
		color: '#ffffff',
		'&:hover': {
			cursor: 'pointer',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
			position: 'absolute',
			top: '-15px',
			right: '-18px',
		},
	},
	gridModalSprint: {
		width: '40% !important',
		alignSelf: 'center',
		borderRadius: 8,
		border: '2px solid #A5A1B3',
		backgroundColor: '#2F2E33',
		padding: '4rem 6rem 3rem',
		[theme.breakpoints.down('lg')]: {
			width: '50% !important',
		},
		[theme.breakpoints.down('md')]: {
			width: '60% !important',
			padding: '4rem 4rem 3rem',
		},
		[theme.breakpoints.down('sm')]: {
			width: '90% !important',
			padding: '2.5rem 1.5rem',
		},
	},
	descriptionPopUpSprint: {
		fontSize: '1.25rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
		},
	},
	titlePopUpSprint: {
		fontSize: '2.5rem !important',
		fontWeight: '600 !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.75rem !important',
		},
	},
}));

const StyledModal = styled(Modal)`
	position: fixed;
	z-index: 1300;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
	z-index: -1;
	position: fixed;
	inset: 0;
	background-color: rgb(0 0 0 / 0.5);
	-webkit-tap-highlight-color: transparent;
`;

const containerBox = {
	width: 710,
	height: 400,
	borderRadius: '12px',
	boxShadow: '0px 2px 24px',
	padding: '0.5rem',
	backgroundColor: '#343a40',
};

const videoModal = {};
