import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const CardProductSolidario = ({
	isSelected,
	atleastOneWasSelected,
	description,
	launchDate,
	handleSelectedCard,
	logo,
}) => {
	const classes = useStyles();
	const theme = useTheme();

	return (
		<Grid
			item
			container
			alignContent={'space-between'}
			xs={12}
			md={5}
			lg={4}
			bgcolor={'#09090A'}
			className={classes.cardStyle}
			style={{
				opacity: atleastOneWasSelected && !isSelected ? '50%' : '100%',
			}}
			onClick={() => handleSelectedCard()}
		>
			<Grid
				item
				container
				xs={12}
			>
				<Grid
					item
					container
					justifyContent={'flex-end'}
					xs={12}
					style={{ height: 'fit-content' }}
				>
					<Grid
						item
						container
						justifyContent={'center'}
						alignItems={'center'}
						style={{
							width: '25px',
							height: '25px',
							borderRadius: '100%',
							background: '#000000',
							border: '2px solid #FFFFFF',
						}}
					>
						<Grid
							item
							style={{
								width: '15px',
								height: '15px',
								borderRadius: '50%',
								background: !isSelected ? 'transparent' : '#FFFFFF',
							}}
						></Grid>
					</Grid>
				</Grid>
				<Grid
					item
					container
					xs={12}
				>
					<Grid
						item
						container
						xs={12}
						justifyContent={'center'}
						alignContent={'center'}
						className={`${classes.cardImg}`}
					>
						<img
							src={logo}
							alt=""
						/>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
				>
					<Typography
						className="poppins"
						lineHeight={1}
						color={'#FFFFFF'}
					>
						{description}
					</Typography>
				</Grid>
			</Grid>
			<Grid
				item
				container
				alignItems={'center'}
				xs={12}
				style={{ marginTop: '2rem' }}
			>
				<Typography
					className={`poppins`}
					// variant="h5"
					fontWeight={'bold'}
					lineHeight={1}
					color={'#FFFFFF'}
				>
					{launchDate}
				</Typography>
			</Grid>
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	cardStyle: {
		minWidth: '88%',
		minHeight: '340px',
		borderRadius: '16px',
		transition: 'all 300ms ease',
		cursor: 'pointer',
		padding: '1rem',
		[theme.breakpoints.up('sm')]: {
			minWidth: '370px',
			'&:hover': {
				scale: 1.02,
			},
		},
	},
	cardImg: {
		marginBottom: '1rem !important',
	},
}));
export default CardProductSolidario;
