import React, { useEffect, useRef, useState } from 'react';
import { Container, Divider, Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

// COMPONENTS
import Footer from '../../Components/Footer';
import { Header } from '../../Components/Header';
import DiferencialBoxV2 from '../../Components/Cards/DiferencialBoxV2';

// TO IMPLEMENT
// import FaqSection from '../../Components/Sections/FaqSection';
// import Newsletter from '../../Components/Utils/Newsletter';

// ASSETS
import BgTrilhaRplus from '../../Assets/Extensivo/RPlus/bg-trilha-plus.png';
import LogoRplus from '../../Assets/Extensivo/RPlus/logo-rplus.svg';

// SF PROVAS
import AmrigsLogo from '../../Assets/SprintFinal/amrigsLogo.svg';
import EinsteinLogo from '../../Assets/SprintFinal/einsteinLogo.svg';
import EnareLogo from '../../Assets/SprintFinal/enareLogo.svg';
import IamspeLogo from '../../Assets/SprintFinal/iamspeLogo.svg';
import FacMedicinaJundiaiLogo from '../../Assets/SprintFinal/facMedicinaJundiaiLogo.svg';
import PsuMgLogo from '../../Assets/SprintFinal/psuMgLogo.svg';
import SantaCasaLogo from '../../Assets/SprintFinal/santaCasaSpLogo.svg';
import SesDfLogo from '../../Assets/SprintFinal/sesDfLogo.svg';
import SesPeLogo from '../../Assets/SprintFinal/sesPeLogo.svg';
import UfrjLogo from '../../Assets/SprintFinal/ufrjLogo.svg';
import AbcLogo from '../../Assets/SprintFinal/abcLogo.svg';
import SirioLibanesLogo from '../../Assets/SprintFinal/sirioLibanesLogo.svg';
import SurceLogo from '../../Assets/SprintFinal/surceLogo.svg';
import SusBaLogo from '../../Assets/SprintFinal/susBaLogo.svg';
import UnicampLogo from '../../Assets/SprintFinal/unicampLogo.svg';
import SusSpLogo from '../../Assets/SprintFinal/susSpLogo.svg';
import UnespBotucatuLogo from '../../Assets/SprintFinal/unespBotucatuLogo.svg';
import AmpLogo from '../../Assets/SprintFinal/ampLogo.svg';
import UnifespLogo from '../../Assets/SprintFinal/unifestpLogo.svg';
import UspLogo from '../../Assets/SprintFinal/uspLogo.svg';
import UspRibeiraoLogo from '../../Assets/SprintFinal/uspRibeiraoLogo.svg';
import FamemaLogo from '../../Assets/SprintFinal/famemaLogo.svg';
import BagIcon from '../../Assets/Svg/Icons/bagIcon.svg';
import UerjLogo from '../../Assets/SprintFinal/uerjLogo.svg';
import UfesLogo from '../../Assets/SprintFinal/ufesLogo.svg';
import PsugoLogo from '../../Assets/SprintFinal/psugoLogo.svg';

//Diferentials Assets
import lessonIcon from '../../Assets/Extensivo/RPlus/vault-icon.svg';
import lessonQuestion from '../../Assets/Extensivo/RPlus/vault-paper.svg';
import lessonLive from '../../Assets/Extensivo/RPlus/vault-people.svg';

import saleIcon from '../../Pages/LandingPages/Sale/LDL/Assets/saleIcon.svg';

// TO IMPLEMENT
// import DoctorCard from '../../Components/Cards/DoctorCard';

// import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import FirstSection from '../../Components/Sections/FirstSection';
import DefaultButton from '../../Components/Buttons/DefaultButton';
import PriceCardVersion6 from '../../Components/Cards/PriceCardVersion6';

const exams = [
	{
		id: '1210',
		imageUrl: AbcLogo,
	},
	{
		id: '1211',
		imageUrl: AmpLogo,
	},
	{
		id: '1212',
		imageUrl: AmrigsLogo,
	},
	{
		id: '1213',
		imageUrl: EinsteinLogo,
	},
	{
		id: '1214',
		imageUrl: EnareLogo,
	},
	{
		id: '1216',
		imageUrl: FamemaLogo,
	},
	{
		id: '1215',
		imageUrl: FacMedicinaJundiaiLogo,
	},
	{
		id: '1223',
		imageUrl: SirioLibanesLogo,
	},
	{
		id: '1217',
		imageUrl: IamspeLogo,
	},
	{
		id: '1221',
		imageUrl: PsugoLogo,
	},
	{
		id: '1218',
		imageUrl: PsuMgLogo,
	},
	{
		id: '1219',
		imageUrl: SantaCasaLogo,
	},
	{
		id: '1220',
		imageUrl: SesDfLogo,
	},
	{
		id: '1222',
		imageUrl: SesPeLogo,
	},
	{
		id: '1224',
		imageUrl: SurceLogo,
	},
	{
		id: '1226',
		imageUrl: SusBaLogo,
	},
	{
		id: '1225',
		imageUrl: SusSpLogo,
	},
	{
		id: '1236',
		imageUrl: UerjLogo,
	},
	{
		id: '1227',
		imageUrl: UfesLogo,
	},
	{
		id: '1235',
		imageUrl: UfrjLogo,
	},
	{
		id: '1231',
		imageUrl: UnespBotucatuLogo,
	},
	{
		id: '1228',
		imageUrl: UnicampLogo,
	},
	{
		id: '1230',
		imageUrl: UnifespLogo,
	},
	{
		id: '1232',
		imageUrl: UspLogo,
	},
	{
		id: '1234',
		imageUrl: UspRibeiraoLogo,
	},
];

const learningPathTemplate = [
	{
		title: 'Extensivo Hardwork',
		description: [
			'A trilha do aprendizado funciona por ligas de evolução. Começaremos por temas mais palpáveis, evoluindo para outros mais peculiares.',
			<>
				<br />
				<br />
			</>,
			'São 8 ligas no total, cada uma com temas diferentes. Para passar para a próxima, precisaremos dominar por completo a atual.',
			<>
				<br />
				<br />
			</>,
			'Veja como tudo vai funcionar em cada liga.',
		],
		bgColor: '#ffffff',
		titleColor: '#121212',
		descriptionColor: 'primary',
		//url: 'https://player.vimeo.com/video/769930065',
	},
	{
		title: 'Aula Hardwork',
		description: [
			'Tudo começa com a apresentação ao tema, que no Hardwork é feita com rigor em didática.',
			<>
				<br />
				<br />
			</>,
			'São as melhores aulas que você já viu sobre cada tema.',
		],
		bgColor: '#0D0D0D',
		titleColor: '#FF005D',
		descriptionColor: 'secondary',
		url: 'https://player.vimeo.com/video/571352809',
	},
	{
		title: 'Estudo por questões',
		description: [
			'Alguns dias após a apresentação ao tema, vamos te conduzir pelo estudo por questões.',
			<>
				<br />
				<br />
			</>,
			'Aqui você entenderá todas as formas em que cada parte do tema é explorado nas provas.',
			<>
				<br />
				<br />
			</>,
			'O professor Hardwork te mostrará tudo em detalhes, aprofundando em cada tema.',
		],
		bgColor: '#0D0D0D',
		titleColor: '#FF005D',
		descriptionColor: 'secondary',
		//url: 'https://player.vimeo.com/video/769929662',
	},
	{
		title: 'Revisões cíclicas e personalizadas',
		description: [
			'Questões, questões e questões! É assim que revisamos no Hardwork!',
			<>
				<br />
				<br />
			</>,
			'Toda a dinâmica de revisões é, na verdade, uma continuidade do estudo por questões, explorando com mais ênfase os pontos que você tiver mais dificuldade.',
			<>
				<br />
				<br />
			</>,
			'Todos os temas precisam ser dominados!',
		],
		bgColor: '#0D0D0D',
		titleColor: '#FF005D',
		descriptionColor: 'secondary',
		//url: 'https://player.vimeo.com/video/769929881',
	},
	{
		title: 'Avaliação de desempenho',
		description: [
			'A regra é dominar temas! A avaliação de desempenho e domínio dos temas será diária, usando métricas do estudo por questões.',
			<>
				<br />
				<br />
			</>,
			'Assim que o grau de suficiência em um tema for atingido, ele estará dominado! Domine todos e migre para a próxima liga de aprendizado.',
			<>
				<br />
				<br />
			</>,
			'"E se eu tiver dificuldade"? Calma! Siga a trilha...',
		],
		bgColor: '#0D0D0D',
		titleColor: '#FF005D',
		descriptionColor: 'secondary',
		//url: 'https://player.vimeo.com/video/769929824',
	},
	{
		title: ' ',
		description: [' '],
		bgColor: 'transparent',
		titleColor: '#FF005D',
		descriptionColor: 'secondary',
		//url: 'https://player.vimeo.com/video/769929780',
	},
];

const learningPathTemplateTwo = [
	{
		title: 'Sprint Final',
		description: [
			'O Sprint te faz entrar na alma do concurso, na mente do examinador! É o primeiro e único modelo de revisão totalmente focado em cada uma das provas! Tudo que tu precisas na reta final de preparação.',
		],
		bgColor: '#ffffff',
		titleColor: '#121212',
		descriptionColor: 'primary',
		//url: 'https://player.vimeo.com/video/769930065',
	},
	{
		title: 'Mais de 23 Sprints diferentes',
		description: [
			'A Revisão que utiliza modelo ativo de aprendizado e tem revisões cíclicas automáticas e personalizadas, que são preparadas pela plataforma de acordo com a necessidade de cada um de nós.',
			<>
				<br />
				<br />
			</>,
			'Cada um tem o seu próprio SPRINT!',
		],
		bgColor: '#0D0D0D',
		titleColor: '#FF005D',
		descriptionColor: 'secondary',
		//url: 'https://player.vimeo.com/video/769929780',
	},
];

const DIFERENTIALS_TRILHA = [
	{
		diferential_title: 'Acesso ao banco de aulas',
		diferential_description: `Continue com acesso a mais de 250 aulas Hardwork, elaboradas com rigor em didática.`,
		diferential_icon: lessonIcon,
	},
	{
		diferential_title: 'Acesso ao banco de questões',
		diferential_description: `Mais de 100 mil questões de provas dos principais concursos de residência do Brasil.`,
		diferential_icon: lessonQuestion,
	},
	{
		diferential_title: 'Acesso ao banco de lives',
		diferential_description: `Continue com acesso ao banco de lives do Hardwork Medicina, com discussões de mais de 200 dos principais temas voltados para provas de residência médica.`,
		diferential_icon: lessonLive,
	},
];

export default function TrilhaRPlus2024() {
	const classes = useStyles();
	const theme = useTheme();
	const subscribe_card_extensivo_r1 = useRef(null);
	const [promotionalDate, setPromotionalDate] = useState();
	const [scroll, setScroll] = useState();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLaptop = useMediaQuery(theme.breakpoints.down('lg'));
	const matchesLargeScreen = useMediaQuery(theme.breakpoints.down('xl'));

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY;
			if (scrollPosition > 50) {
				setScroll(true);
			} else {
				setScroll(false);
			}
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const executeScroll = () =>
		subscribe_card_extensivo_r1.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="residencia"
				style={{ marginBottom: '-4rem' }}
			>
				{scroll ? (
					<DefaultButton
						id="myBtn"
						variant="outlined"
						color={'secondary'}
						className={`${classes.btnFirstSection} ${'residencia white-btn-secondary'}  poppins`}
						onClick={() => executeScroll()}
					>
						<img
							src={saleIcon}
							alt=""
						/>
						&nbsp;Seja um R+!
					</DefaultButton>
				) : null}
				{matchesMobile || matchesTablet ? null : <Header />}
				<FirstSection
					backgroundImageUrl={BgTrilhaRplus}
					logoUrl={LogoRplus}
					imageProps={{
						width: matchesTablet ? '100%' : matchesMobile ? '100%' : '58%',
						left: matchesLaptop ? '-2em' : '2em',
						top: '14rem',
					}}
					type="red"
					buttonText="Garantir vaga!"
					executeScroll={executeScroll}
					firstTextPaper={[
						<Typography
							align="left"
							color="secondary"
							className={`${classes.firstText}`}
						>
							Continue conectado ao <br /> Hardwork durante a residência
						</Typography>,
						<Divider
							style={{ width: '100%', borderColor: '#a2a2a2', marginTop: '1rem', marginBottom: '1rem' }}
						/>,
						<Typography
							align="left"
							color="secondary"
							className={`${classes.text} poppins`}
						>
							leva o estudo por questões para outro nível, com desafio diário e métricas de desempenho e
							flashcards.
						</Typography>,
					]}
				/>
			</Container>

			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className="section"
				>
					<Grid
						item
						xs={12}
						container
					>
						<Grid
							item
							xs={12}
							container
						>
							<Typography
								className={`${classes.subtitle1} roboto-condensed`}
								color="secondary"
							>
								Trilha R+
							</Typography>
						</Grid>
						<Grid
							container
							item
							xs={12}
							rowGap={2}
							style={{ margin: '1rem 0rem 3rem 0rem' }}
						>
							{DIFERENTIALS_TRILHA.map((elm, index) => (
								<DiferencialBoxV2
									key={index}
									title={elm.diferential_title}
									description={elm.diferential_description}
									icon={elm.diferential_icon}
								/>
							))}
						</Grid>
						{/* <Typography
							color="secondary"
							className={`${classes.thirdSectionText} poppins`}
							style={{ marginTop: '-1rem', marginBottom: '2rem' }}
						>
							*Acesso liberado a partir de 15/01/2024.
						</Typography> */}
					</Grid>
				</Grid>
			</Container>

			<Grid
				container
				className="background-gradient-residencia"
			>
				<Grid
					container
					style={{ padding: '2vh 5%' }}
					className={classes.backgroundWaves}
				>
					<Container
						fixed
						maxWidth="xl"
						disableGutters={true}
					>
						<Grid
							container
							alignContent="center"
							justifyContent="center"
							wrap={matchesTablet ? 'wrap' : 'nowrap'}
							columnGap={2}
							className={`${matchesTablet ? null : 'section'} extensivo-live-card`}
							style={{
								// backgroundColor: 'black',
								borderRadius: 15,
								margin: matchesTablet ? '40px auto' : '80px auto',
							}}
							ref={subscribe_card_extensivo_r1}
						>
							<PriceCardVersion6
								idTurma={1324}
								// bgImageUrl={BgCardPrice}
								// logo={PriceCardLogo}
								titleArea="Clínica Médica"
								iconBtn={BagIcon}
								color
								setPromotionalDate={setPromotionalDate}
							/>
							<PriceCardVersion6
								idTurma={1325}
								// bgImageUrl={BgCardPrice}
								// logo={LogoCirurgia}
								titleArea="Cirurgia Geral"
								iconBtn={BagIcon}
								color
								setPromotionalDate={setPromotionalDate}
							/>
							<PriceCardVersion6
								idTurma={1327}
								// bgImageUrl={BgCardPrice}
								// logo={LogoGinecologia}
								titleArea="Ginecologia e Obstetrícia"
								iconBtn={BagIcon}
								color
								setPromotionalDate={setPromotionalDate}
							/>
							<PriceCardVersion6
								idTurma={1326}
								// bgImageUrl={BgCardPrice}
								// logo={LogoPediatria}
								titleArea="Pediatria"
								iconBtn={BagIcon}
								color
								setPromotionalDate={setPromotionalDate}
							/>
						</Grid>
						{/* <Typography
							color="primary"
							className={`${classes.disclaimerText2} poppins`}
							style={
								matchesMobile
									? { marginTop: '-1rem', marginBottom: '1.5rem' }
									: matchesTablet
									? { marginTop: '-1rem', marginBottom: '1.5rem' }
									: matchesLaptop
									? { marginTop: '-4rem', marginBottom: '3rem' }
									: matchesLargeScreen
									? { marginTop: '-3rem', marginBottom: '3rem' }
									: { marginTop: '-3rem', marginBottom: '3rem', paddingLeft: '4rem' }
							}
						>
							
							*Desconto de 30% para ex-alunos Hardwork.
						</Typography> */}
					</Container>
				</Grid>
			</Grid>

			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

export const ProductCard = ({ backgroundImage, logo, description, buttonText, urlRedirect }) => {
	const classes = useStyles();

	return (
		<Paper
			className={`${classes.scriptPaper} rounded-card`}
			color="secondary"
			elevation={4}
		>
			<Grid
				container
				justifyContent={'center'}
				alignContent={'center'}
				className={classes.gridImage}
				style={{ background: `url(${backgroundImage}) center/cover no-repeat`, height: '200px' }}
			>
				<img
					src={logo}
					alt=""
					style={{ visibility: 'visible' }}
				/>
			</Grid>
			<Grid
				container
				justifyContent={'center'}
				className={classes.gridDescriptionScriptCard}
			>
				<Typography
					align="left"
					color="secondary"
					className={`${classes.scriptText} poppins`}
				>
					{description}
				</Typography>
			</Grid>
		</Paper>
	);
};

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.5em !important',
		lineHeight: '1 !important',
	},
	backgroundWaves: {
		backgroundImage: `url(${process.env.PUBLIC_URL + '/Assets/Img/bgWave2.png'})`,
		width: '100vw !important',
	},
	gradientText: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		lineHeight: '1.5 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.8rem !important',
		},
	},
	firstText: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
	},
	text: {
		fontSize: '1.75rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.35rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.15rem !important',
		},
	},
	subtitle1: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('md')]: {
			lineHeight: 'unset !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '2.5rem !important',
			lineHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem !important',
			marginTop: '1.8rem !important',
			lineHeight: 'unset !important',
		},
	},
	flashCardsTitle: {
		fontSize: '1.8em !important',
		fontWeight: 'bold !important',
	},
	thirdSectionText: {
		fontSize: '1.12rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	disclaimerText2: {
		fontSize: '0.95rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.85rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.7rem !important',
		},
	},
	scriptPaper: {
		backgroundColor: 'transparent !important',
		height: '100%',
		[theme.breakpoints.down('xl')]: {
			minHeight: 'unset',
		},
		[theme.breakpoints.down('lg')]: {
			height: 'unset',
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
			width: 'unset',
			minHeight: 'unset',
			margin: '16px 0px',
		},
	},
	gridImage: {
		borderTopRightRadius: 15,
		borderTopLeftRadius: 15,
		padding: '2rem',
		height: '50%',
	},
	gridDescriptionScriptCard: {
		backgroundColor: '#63636366 !important',
		padding: '1.25rem',
		borderBottomLeftRadius: 16,
		borderBottomRightRadius: 16,
		height: '50%',
		[theme.breakpoints.down('lg')]: {
			padding: '1em 1.5em',
		},
		[theme.breakpoints.down('md')]: {
			height: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '1em',
			borderTopRightRadius: 0,
			borderTopLeftRadius: 0,
		},
	},
	scriptText: {
		fontSize: '0.95rem !important',
		overflowY: 'auto',
		paddingRight: 20,
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
			maxHeight: 175,
			minHeight: 'unset !important',
		},
	},
	gridButton: {
		bottom: 25,
		left: '-5%',
		[theme.breakpoints.down('lg')]: {
			bottom: -17,
		},
		[theme.breakpoints.down('sm')]: {
			left: '-7%',
			bottom: -15,
		},
	},
	btn: {
		fontSize: '1rem !important',
		width: '50%',
		fontWeight: 'bold !important',
		'&:hover': {
			scale: 1.05,
			color: '#ffffff !important',
			'& $logoBtn': {
				filter: 'invert(0)',
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.75rem !important',
			minHeight: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem !important',
			width: '50%',
		},
	},
	btnFirstSection: {
		// display: 'none',
		position: 'fixed !important',
		zIndex: '1500',
		border: '5px solid black !important',
		width: 'fit-content',
		padding: '0 3rem !important',
		bottom: '3rem',
		right: '3rem',
		fontSize: '1.425rem !important',
		fontWeight: 'bold !important',
		'&:hover': {
			scale: 1.05,
			color: '#ffffff',
			'& $logoBtn': {
				filter: 'invert(0%) !important',
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
			minHeight: 'unset !important',
			width: 'fit-content',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
			width: 'fit-content',
			bottom: '2rem',
			right: '1rem',
		},
	},
}));
