import React from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../Components/Footer';
import { Header } from '../../../Components/Header';

import Form from '../../../Components/Utils/Forms/PreRegistration';

export default function RevaldaSpecialEdition() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					justifyContent={'space-between'}
					alignContent="center"
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
				>
					<Grid
						item
						xs={12}
						md={6}
						container
						style={{ paddingBottom: !matchesMobile && '6rem' }}
					>
						<img
							src={require('./Assets/live-correcao-profs.png')}
							alt=""
						/>
						<Grid
							item
							xs={12}
							container
							wrap="nowrap"
							columnGap={2}
							alignItems={'center'}
						>
							<img
								src={require('./Assets/icon-live-revalida.png')}
								alt=""
								className={classes.logoLiveRevalida}
							/>
							<Typography
								color={'secondary'}
								className={`${classes.titleText} poppins`}
							>
								CORREÇÃO REVALIDA INEP
							</Typography>
						</Grid>
						<Grid
							container
							flexDirection={'column'}
							className={classes.gridText}
							style={{ gap: '3rem', marginTop: matchesTablet ? '1rem' : '2rem' }}
						>
							<Grid
								container
								flexDirection={'column'}
								gap={'1rem'}
							>
								<Grid
									style={{
										backgroundColor: '#09D68640',
										padding: '8px 10px',
										border: '1px solid #09D686',
										borderRadius: '8px',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<Typography
										color={'secondary'}
										className={`${classes.title} poppins`}
									>
										COM SORTEIOS ARENA HARDWORK
									</Typography>
								</Grid>
								<Typography
									color={'secondary'}
									className={`${classes.title} poppins`}
								>
									+ Recursos REVALIDA INEP
								</Typography>
							</Grid>
							{/* <Typography
								color="secondary"
								style={{ marginTop: '1.8rem', marginBottom: '1rem' }}
								className={`${classes.text} poppins`}
							>
								Lorem ipsum dolor sit amet consectetur. Quis lorem pharetra arcu netus eu tristique
								aliquet nisl leo. Nunc gravida id est nisl. Egestas et lacus magna tempor in turpis erat
								gravida. Velit laoreet quis elementum id turpis diam.
							</Typography> */}

							<Typography
								color="secondary"
								className={`${classes.impactText} poppins`}
							>
								29AGO • A partir das 15H00 • Evento online e gratuito
							</Typography>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						md={5}
						container
						justifyContent="center"
						className={classes.gridWithBackgroundImage}
					>
						<Grid
							item
							xs={12}
							className={classes.gridForm}
							gap={1}
						>
							<Form
								group="LIVE CORRECAO REVALIDA 2024.2"
								title={['Inscreva-se gratuitamente!']}
								titleButton="Confirmar inscrição"
								type="gradient-residencia-light-shadow-revalida"
								urlToRedirect="/pre-inscricao/live-correcao-revalida-inep"
								confirmationMessage={
									'Sua inscrição está confirmada! Em breve enviaremos mais informações em seu e-mail.'
								}
							/>
						</Grid>
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto 10%',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	gridForm: {
		width: 567,
		position: 'sticky',
		// bottom: 40,
		right: 0,
		top: 40,
		zIndex: 10,
		display: 'flex',
		flexDirection: 'column !important',
		alignItems: 'center !important',
		alignSelf: 'flex-start',
	},
	desafioSection: {
		[theme.breakpoints.down('lg')]: {
			width: '180px !important',
			flexDirection: 'row',
		},
		[theme.breakpoints.down('sm')]: {
			width: '160px !important',
			flexDirection: 'row',
		},
	},
	titleText: {
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem !important',
		},
	},
	title: {
		fontSize: '2rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		// color: 'rgba(54, 207, 70, 0.94) !important',

		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.15rem !important',
			lineHeight: 'unset !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1.1rem !important',
		},
	},
	titleSecond: {
		fontSize: '2.2rem !important',
		fontWeight: '700 !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.3rem !important',
			lineHeight: 'unset !important',
		},
	},
	titleDecoration: {
		fontSize: '6.5rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		position: 'absolute',
		left: '14rem',
		bottom: '-12px',
		// color: 'rgba(54, 207, 70, 0.94)',
		[theme.breakpoints.down('lg')]: {
			fontSize: '6.25rem !important',
			bottom: '-9px',
			left: '15rem',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '5.25rem !important',
			left: '12rem',
		},
	},
	text: {
		fontSize: '1.5rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.25rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	gridWithBackgroundImage: {
		paddingLeft: 20,
		[theme.breakpoints.down('md')]: {
			paddingLeft: 'unset',
		},
	},
	gridText: {
		// position: 'absolute',
		// top: 500,
		[theme.breakpoints.down('md')]: {
			top: 'unset',
			position: 'initial',
		},
	},
	impactText: {
		margin: '0 0 2rem !important',
		fontSize: '1rem !important',
		lineHeight: 'unset !important',
		fontWeight: '500 !important',
		width: 'fit-content !important',
		background: 'linear-gradient(90deg, var(#BB5CFF) 10%, var(#BB5CFFF0) 50%)',
		[theme.breakpoints.up('lg')]: {
			fontSize: '1.6rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.75rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1.3rem !important',
		},
	},
	divider: {
		position: 'absolute',
		border: '1.5px solid #fff !important',
		left: '4.85em',
		backgroundColor: '#fff',
		height: '230px !important',
		zIndex: -1,
		[theme.breakpoints.down('xl')]: {
			height: '200px !important',
		},
		[theme.breakpoints.down('lg')]: {
			height: '280px !important',
		},
		[theme.breakpoints.down('md')]: {
			height: '295px !important',
		},
		[theme.breakpoints.down('sm')]: {
			height: '240px !important',
		},
	},
	logoLiveRevalida: {
		height: '65%',
		[theme.breakpoints.down('sm')]: {
			height: '40%',
		},
	},
}));
