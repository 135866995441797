import React, { useRef } from 'react';
import { Grid, Typography, useTheme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';

//Assets
import imgTeachers from '../assets/bg-hwm-team-peb.png';
import caroselImg1 from '../assets/caroselImgLdl-sf-24-1.png';
import caroselImg2 from '../assets/caroselImgLdl-sf-24-2.png';
import caroselImg3 from '../assets/caroselImgLdl-sf-24-3.png';
import { Splide, SplideSlide } from '@splidejs/react-splide';

export const FourthSectionSprintR1 = () => {
	const theme = useTheme();

	const classes = useStyles();
	const splideRef = useRef();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const caroselImg = [
		{
			img: caroselImg1,
		},
		{
			img: caroselImg2,
		},
		{
			img: caroselImg3,
		},
	];

	return (
		<Grid
			container
			direction={'column'}
			rowGap={2}
		>
			<Grid
				item
				container
			>
				<Typography
					// variant={matchesMobile ? 'h4' : 'h3'}
					color={'primary'}
					fontFamily={'Manrope'}
					fontWeight={700}
					className={classes.title}
				>
					Vantagem para Alunos{' '}
				</Typography>
			</Grid>
			<Grid
				item
				container
				rowGap={3}
			>
				<Grid
					item
					container
					className={classes.gridContainer}
					style={{
						padding: matchesTablet ? '2rem' : '3rem',
					}}
				>
					<Grid
						item
						xs={12}
						lg={4}
					>
						<Typography
							color={'primary'}
							fontFamily={'Poppins'}
							fontWeight={400}
							className={classes.text}
						>
							Alunos do <strong>Sprint Final</strong> ganham prioridade nas inscrições da
							<br />
							Live das Lives de qualquer
							<br />
							instituição.
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						lg={8}
					>
						<Splide
							options={{ arrows: false, autoplay: true, type: 'loop', speed: 0.25, rewind: true }}
							ref={splideRef}
						>
							{caroselImg.map((item, index) => {
								return (
									<SplideSlide
										key={index}
										style={{ display: 'flex', justifyContent: 'flex-end' }}
									>
										<img
											src={item?.img}
											alt=""
											style={{
												width: matchesTablet ? '100%' : '90%',
											}}
										/>
									</SplideSlide>
								);
							})}
						</Splide>
					</Grid>
				</Grid>
				<Grid
					item
					container
					wrap={matchesTablet ? 'nowrap' : 'wrap'}
					direction={matchesTablet ? 'column-reverse' : 'row'}
					className={classes.gridContainer}
					style={
						matchesTablet
							? {
									padding: '2rem 1rem',
							  }
							: {
									padding: '1.5rem 2rem',
							  }
					}
				>
					<Grid
						item
						xs={12}
						lg={8}
					>
						<img
							src={imgTeachers}
							alt=""
							style={{
								width: matchesTablet ? '100%' : '90%',
							}}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						lg={4}
						style={{ paddingLeft: '1rem' }}
					>
						<Typography
							color={'primary'}
							align="right"
							fontFamily={'Poppins'}
							fontWeight={400}
							className={classes.text}
						>
							O time de professores Hardwork te ajuda a elaborar os <strong>recursos</strong>,
							<br />
							caso necessário..
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: '2rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '1.75rem !important',
		},
	},
	text: {
		fontSize: '1.5rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.85rem !important',
		},
	},
	gridContainer: {
		backgroundColor: '#EBEBEB',
		width: '100%',
		borderRadius: '25px',
		justifyContent: 'center',
		alignItems: 'center',
		[theme.breakpoints.down('md')]: {
			padding: '1rem',
			gap: '1rem',
		},
	},
}));
