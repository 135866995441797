import { useEffect } from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Divider, Grid, Paper, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

// Components
import { Header } from '../../Components/Header';
import { makeStyles } from '@mui/styles';
import DefaultButton from '../../Components/Buttons/DefaultButton';
import Footer from '../../Components/Footer';
import Api from '../../Services/Api';
import IntlTelInput from 'react-intl-tel-input';

const endPoint = 'turmasabertas/1358,1357,1359,1364,1365,1361,1363,1362,1360';

export function dateFormatWithTime(date) {
	const data = new Date(date);
	const dia = data.getDate();
	const hora = data.getHours();
	const minutos = data.getMinutes();
	const mesesEmPortugues = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];
	const mes = mesesEmPortugues[data.getMonth()];

	const diaFormatado = 10 > dia ? `0${dia}` : dia;

	const dataHoraFormatada = `${diaFormatado}${mes} às ${hora}:${10 > minutos ? `0${minutos}` : minutos}h`;

	return dataHoraFormatada;
}

const CardLiveDasLives = ({
	id,
	logo,
	mode,
	dateDay,
	finalDate,
	dateMonth,
	classes,
	url,
	contest,
	matchesMobile,
	opening,
	soldOut,
}) => {
	const [preInscricao, setPreInscricao] = useState({
		agrupamento: `REVALIDA - LIVE DAS LIVES ${contest} LISTA DE ESPERA`,
		nome: '',
		email: '',
		telefone: '',
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
	});
	const [waitListVisibility, setWaitListVisibility] = useState(false);
	const [lot, setLot] = useState([]);
	const currentDate = new Date();
	const [classSold, setClassSold] = useState(false);
	const [currentIndex, setCurrentIndex] = useState();

	const [info, setInfo] = useState();

	useEffect(() => {
		if (id) {
			Api.get(`turmasabertas/${id}`).then((res) => {
				const activeLot = res[0]?.lotes?.find((lot, index) => {
					return lot?.id === res[0]?.id_lote_vigente;
				});
				setCurrentIndex(currentIndex);

				if (activeLot) {
					setLot(activeLot);
				} else {
					const nextLot = res[0]?.lotes?.find((lot, index) => {
						const startDate = new Date(lot.data_inicio);

						return currentDate < startDate;
					});

					if (!nextLot && opening === 'Aberto') {
						setClassSold(true);
					} else {
						setLot(nextLot);
					}
				}
			});
		}
	}, [id, soldOut, currentIndex, opening]);

	const isActive = new Date(lot?.data_inicio) <= currentDate && currentDate <= new Date(lot?.data_limite);

	const handleCloseWaitList = () => {
		setWaitListVisibility(false);
	};

	const handleOpenWaitList = () => {
		setWaitListVisibility(true);
	};

	let sendPreInscricao = function (event) {
		event.preventDefault();
		const nomeCompleto = preInscricao.nome.trim();
		const nomeSobrenome = nomeCompleto.split(' ');
		if (!preInscricao.nome || nomeSobrenome.length < 2) {
			alert('Ops... Por favor informe seu nome e sobrenome');
			return;
		}
		if (!preInscricao.email || !preInscricao.email.includes('@') || !preInscricao.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (!preInscricao.telefone) {
			alert('Ops... Por favor preencha seu telefone');
			return;
		}

		trackPromise(
			Api.post('PreInscricao', preInscricao).then((res) => {
				if (res) {
					Swal.fire({
						title: 'Massa! ',
						text: `${'O cadastro está confirmado, avisaremos das novidades em breve.'}`,
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then((result) => {
						if (result.isConfirmed) {
							setTimeout(() => {
								document.location.reload();
							}, 500);
						}
					});
				}
			})
		);
	};

	const handlePhone = (valid, phoneNumber) => {
		if (valid) {
			setPreInscricao({
				...preInscricao,
				telefone: phoneNumber,
			});
		} else if (preInscricao.telefone.length) {
			setPreInscricao({
				...preInscricao,
				telefone: '',
			});
		}
	};

	return (
		<Paper
			className={`${classes.rootCard} rounded-card `}
			elevation={4}
			style={{
				backgroundColor: finalDate > currentDate ? null : url && opening !== 'Fechado' ? null : '#0000004d',
				border:
					finalDate > currentDate
						? '1px solid #09D785'
						: url && opening !== 'Fechado'
						? '1px solid #09D785'
						: '1px solid #09D78540',
			}}
		>
			<Grid
				style={{
					opacity: finalDate > currentDate ? null : url && opening !== 'Fechado' ? null : 0.3,
				}}
				className={`${classes.borderGrid} background-revalida-ldl-strong`}
			></Grid>
			<Grid
				container
				className={classes.gridBackground}
				style={{
					backgroundImage: url
						? `url(${require('../../Assets/LDL/bg-ldl-card.png')})`
						: `url(${require('../../Assets/LDL/bg-ldl-soon.png')})`,
				}}
			>
				<Grid
					item
					xs={12}
					container
					wrap="nowrap"
					alignItems={'center'}
				>
					<Grid
						item
						xs={url ? 9.5 : 12}
						container
						justifyContent={'center'}
						style={{
							borderRight:
								url && opening === 'Aberto'
									? '1px solid #FFFFFF'
									: opening === 'Fechado'
									? '1px solid #FFFFFF4d'
									: null,
							paddingRight: '2rem',
							height: 'fit-content',
						}}
					>
						<img
							src={logo}
							alt=""
							style={{
								opacity: finalDate > currentDate ? 1 : url && opening !== 'Fechado' ? 1 : 0.3,
								width: !matchesMobile ? '80%' : null,
							}}
						/>
					</Grid>
					{url && dateDay && (
						<Grid
							item
							xs={2.5}
							container
							direction={'column'}
							alignContent={'center'}
							justifyContent={'center'}
						>
							<Typography
								align="center"
								color="secondary"
								className={`${classes.dayDateText} poppins`}
								style={{ opacity: finalDate > currentDate ? 1 : opening === 'Fechado' ? 0.3 : 1 }}
							>
								{dateDay}
							</Typography>
							<Typography
								align="center"
								color="secondary"
								className={`${classes.monthDateText} poppins`}
								style={{ opacity: finalDate > currentDate ? 1 : opening === 'Fechado' ? 0.3 : 1 }}
							>
								{dateMonth}
							</Typography>
						</Grid>
					)}
				</Grid>
				<Grid
					item
					xs={12}
					container
					alignContent="center"
					justifyContent={'space-between'}
					wrap="nowrap"
					className={classes.gridInformation}
				>
					{opening !== 'Fechado' ? (
						<img
							src={require('../LandingPages/Sale/LDL/Assets/icon-white-location.svg').default}
							alt=""
							style={{ width: 12 }}
						/>
					) : null}

					<Typography
						align={matchesMobile ? 'center' : 'left'}
						color="secondary"
						className={
							opening !== 'Fechado'
								? `${classes.modeText} poppins`
								: `${classes.modeTextFinished} poppins`
						}
						style={{ opacity: finalDate > currentDate ? 1 : opening === 'Fechado' ? 0.3 : 1 }}
					>
						{mode}
					</Typography>
					<DefaultButton
						variant="outlined"
						color={'secondary'}
						className={`${classes.subscribeBtn} poppins ${
							opening === 'Fechado' ? 'background-gradient-gray' : 'background-revalida-ldl'
						}`}
						component={Link}
						to={soldOut ? null : url ? url : null}
						onClick={() => handleOpenWaitList()}
					>
						{(!url || soldOut) && (
							<img
								src={require('../../Assets/Svg/icon-white-bell.svg').default}
								alt=""
								style={{ width: 12 }}
							/>
						)}
						&nbsp;
						{url ? (soldOut ? 'Lista de espera' : isActive ? 'Garantir Vaga' : 'Ver detalhes') : 'Avise-me'}
					</DefaultButton>
				</Grid>
				{lot && lot?.nome === 'Lote 06' && opening === 'Fechado' ? (
					<Grid
						container
						className={'poppins'}
						style={{
							backgroundColor: '#2E3231',
							width: 'fit-content',
							padding: '1px 10px',
							borderRadius: '4px',
							marginTop: '1rem',
						}}
					>
						<Typography
							style={{ fontWeight: 600 }}
							color={'secondary'}
						>
							Lote Final - Vagas esgotadas
						</Typography>
					</Grid>
				) : (
					<Grid
						container
						className={'poppins'}
						style={{
							backgroundColor: isActive && '#43A27D',
							width: 'fit-content',
							padding: '1px 10px',
							borderRadius: '4px',
							marginTop: '1rem',
						}}
					>
						<Typography
							style={{ fontWeight: 600, fontSize: matchesMobile && '0.7rem' }}
							color={'secondary'}
						>
							{lot && isActive ? (
								`Lote Final - ABERTO`
							) : (
								<Grid
									container
									className={'poppins'}
									style={{
										backgroundColor: '#2E3231',
										width: 'fit-content',
										padding: '1px 10px',
										borderRadius: '4px',
										marginTop: '1rem',
									}}
								>
									<Typography
										style={{ fontWeight: 600 }}
										color={'secondary'}
									>
										Lote Final - Vagas esgotadas
									</Typography>
								</Grid>
							)}
						</Typography>
					</Grid>
				)}

				<Grid
					item
					xs={12}
					container
					alignContent="center"
					columnGap={1.5}
					style={{
						marginTop: '0.25rem',
						padding: '0.25rem 0.75rem',
					}}
				>
					{opening === 'Fechado' && (
						<Grid>
							{/* <Typography
								className={'poppins'}
								style={{
									fontSize: matchesMobile ? '0.8rem' : '1rem',
									fontWeight: 'bold',
									color: '#F53636',
								}}
							>
								Vagas Esgotadas
							</Typography> */}
						</Grid>
					)}
				</Grid>
				{contest && (
					<form
						className="form-control"
						onSubmit={sendPreInscricao}
						action="javascript:;"
						style={
							waitListVisibility
								? {
										overflow: 'hidden',
										transition: 'opacity 1s ease, height 1s ease',
										marginTop: '0.5rem',
								  }
								: { width: '100%', visibility: 'hidden', height: 0 }
						}
					>
						<Grid
							item
							container
							justifyContent={'space-between'}
							className={classes.containerWaitList}
							rowGap={matchesMobile ? 1 : 0}
						>
							<Grid
								item
								container
								justifyContent="space-between"
							>
								<Typography
									color="primary"
									className={`${classes.textWaitList} poppins`}
								>
									Cadastre-se para receber as novidades
								</Typography>
								<CancelRoundedIcon
									onClick={() => handleCloseWaitList()}
									className={classes.cancelIcon}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								md={5.75}
								container
							>
								<TextField
									fullWidth
									type="string"
									name="nome"
									placeholder="Seu nome completo"
									required={true}
									InputProps={{
										className: `${classes.form} ${classes.formHeight}`,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(e) =>
										setPreInscricao({
											...preInscricao,
											nome: e.target.value,
										})
									}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								md={5.75}
								container
								alignContent={'center'}
							>
								<IntlTelInput
									name="mobile"
									type="mobile"
									preferredCountries={['BR', 'PY', 'AR', 'CL', 'BO', 'UY', 'CU', 'PT']}
									style={{ width: '100%', border: 'unset' }}
									inputClassName={`${classes.phoneNumberForm} ${classes.form} ${classes.formHeight}`}
									nationalMode={true}
									onPhoneNumberChange={(valid, value, selectedCountryData, fullNumber) =>
										handlePhone(valid, fullNumber)
									}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								container
							>
								<TextField
									fullWidth
									type="email"
									name="email"
									placeholder="Seu melhor e-mail"
									required={true}
									InputProps={{
										className: `${classes.form} ${classes.formHeight}`,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									style={{ width: '100%' }}
									onChange={(e) =>
										setPreInscricao({
											...preInscricao,
											email: e.target.value,
										})
									}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								md={10}
								lg={5}
								container
								className="residencia"
								style={{ padding: '4px 0px', margin: '0 auto' }}
							>
								<DefaultButton
									type="submit"
									variant="outlined"
									color="secondary"
									className={`${classes.btnWaitList} btn`}
									onClick={(e) => sendPreInscricao(e)}
								>
									<img
										src={require('../../Assets/Arena/icon-waitList.svg').default}
										alt=""
										style={{ width: 20 }}
									/>{' '}
									&nbsp; Confirmar cadastro
								</DefaultButton>
							</Grid>
						</Grid>
					</form>
				)}
			</Grid>
		</Paper>
	);
};

export default function LiveDasLivesRevalida2024() {
	const theme = useTheme();
	const classes = useStyles();
	const [livesOptions, setLivesOptions] = useState([]);

	const [closedCards, setClosedCards] = useState([]);
	const [finalDate, setFinalDate] = useState();
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLargeScreen = useMediaQuery(theme.breakpoints.down('xl'));

	const currentDate = new Date();

	useEffect(() => {
		Api.get(endPoint).then((res) => {
			res.map((item, index) => {
				item.contest = item?.nome.split(' - ')[1].replace('2024.1', '').replace('2024', '').trim();
				item.mode = 'Exclusivamente presencial | Modo cinema';
				item.finished = 'Vagas Esgotadas';
				item.dateDay = new Date(item.data_inicio_turma).getDate().toString().padStart(2, '0');
				item.dateMonth = new Date(item.data_inicio_turma)
					.toLocaleString('pt-BR', { month: 'short' })
					.toUpperCase()
					.replace('.', '');
				item.expirationDate = item.data_fim_inscricoes;
				item.active = !item.encerrada;
				item.contest = item.contest.replace('REVALIDA', '').trim();

				if (item.nome.includes('BELO HORIZONTE')) {
					item.url = 'belo-horizonte';
				} else if (item.nome.includes('BRASÍLIA')) {
					item.url = 'brasilia';
				} else if (item.nome.includes('CAMPO GRANDE')) {
					item.url = 'campo-grande';
				} else if (item.nome.includes('CURITIBA')) {
					item.url = 'curitiba';
				} else if (item.nome.includes('PORTO ALEGRE')) {
					item.url = 'porto-alegre';
				} else if (item.nome.includes('PORTO VELHO')) {
					item.url = 'porto-velho';
				} else if (item.nome.includes('RECIFE')) {
					item.url = 'recife';
				} else if (item.nome.includes('RIO BRANCO')) {
					item.url = 'rio-branco';
				} else if (item.nome.includes('SALVADOR')) {
					item.url = 'salvador';
				} else if (item.nome.includes('SÃO PAULO')) {
					item.url = 'sao-paulo';
					// item.qtd_inscritos = 500; // TESTE FORÇADO PARA VERIFICAR O COMPORTAMENTO QUANDO ACABAREM AS VAGAS
					// item.max_alunos = 500;
				} else if (item.nome.includes('RIO DE JANEIRO')) {
					item.url = 'rio-de-janeiro';
				}
				item.opening = '';
				item.appear = '';
				item.soldOut = item.qtd_inscritos >= item.max_alunos ? true : false;
			});
			setFinalDate(new Date(res[0].data_fim_inscricoes));
			setLivesOptions(res);
			const currentDate = new Date();
			const arr = res.map((elm) => {
				return {
					...elm,
					opening:
						(new Date(elm.expirationDate) < currentDate && elm.expirationDate !== '') ||
						elm.encerrada === true
							? 'Fechado'
							: new Date(elm.expirationDate) > currentDate && elm.expirationDate !== ''
							? 'Aberto'
							: 'Em breve',
				};
			});
			const filteredArrayWithClosedCards = arr.filter((elm) => elm.opening === 'Fechado');
			const filteredArrayWithoutClosedCards = arr.filter((elm) => elm.opening !== 'Fechado');
			setClosedCards([...filteredArrayWithClosedCards]);
			setLivesOptions([...filteredArrayWithoutClosedCards]);
		});
	}, []);

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			<Helmet>
				<title>Live das Lives 2024</title>
				<meta
					name="description"
					content={''}
				/>
			</Helmet>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="residencia"
			>
				{matchesMobile || matchesTablet ? null : <Header />}
			</Container>
			<Grid
				container
				alignContent={'center'}
				style={matchesMobile ? { marginTop: 80 } : null}
			>
				<video
					src={process.env.PUBLIC_URL + '/Assets/bg-video-revalida.mp4'}
					autoPlay
					loop
					muted
					playsInline
					className={classes.videoBackground}
				/>
				<Grid
					item
					xs={12}
					container
					alignContent={'center'}
					justifyContent={'center'}
					className={`${classes.gridLogoContainer} residencia`}
					rowGap={1}
				>
					<img
						src={require('../../Assets/LDL/logo-revalida-first.svg').default}
						alt=""
						className={classes.ldlLogo}
						style={matchesLargeScreen ? { marginBottom: '3rem !important' } : null}
					/>
					<Grid
						item
						xs={12}
						container
						alignContent={'center'}
						justifyContent={'center'}
						style={{
							padding: matchesMobile ? '1rem' : '1rem 0',
							border: '1px solid #09D785',
							borderLeft: 'unset',
							borderRight: 'unset',
							margin: '1rem 0',
							background:
								'linear-gradient(90deg, rgba(19,19,19,0) 0%, rgba(15,15,15,1) 20%, rgba(15,15,15,1) 80%, rgba(19,19,19,0) 100%)',
						}}
					>
						<Typography
							color="secondary"
							className="poppins"
							style={{ fontSize: matchesMobile ? '1rem' : '2rem', fontWeight: 'bold' }}
						>
							REVISÃO DE VÉSPERA PARA A PROVA DO REVALIDA
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					className="section"
					style={{ margin: matchesMobile ? '60px auto 80px' : '20px auto 80px' }}
				>
					<Grid
						item
						xs={12}
						container
						justifyContent={'space-between'}
						rowGap={2}
					>
						{livesOptions.map((item, index) => {
							return item.active ? (
								<Grid
									item
									xs={12}
									md={5.8}
								>
									<Link
										to={item?.soldOut ? null : item.url}
										style={{ textDecoration: 'none' }}
									>
										<CardLiveDasLives
											id={item?.id}
											key={index}
											contest={item.contest}
											logo={item.logo}
											mode={item.mode}
											dateDay={item.dateDay}
											dateMonth={item.dateMonth}
											url={item.url}
											classes={classes}
											matchesMobile={matchesMobile}
											opening={item.opening}
											appear={true}
											soldOut={item?.soldOut}
											finalDate={item?.data_limite}
										/>
									</Link>
								</Grid>
							) : null;
						})}
					</Grid>
					{currentDate > finalDate ? (
						<Grid
							item
							xs={12}
							container
							justifyContent={'space-between'}
							alignItems={'center'}
							wrap="nowrap"
							style={{ padding: '2.5rem 0' }}
						>
							<Divider className={classes.divider} />
							<Typography
								color="secondary"
								className="poppins"
								style={{ fontSize: matchesMobile ? '1rem' : '2rem', fontWeight: '600' }}
							>
								ENCERRADAS
							</Typography>
							<Divider className={classes.divider} />
						</Grid>
					) : null}
					<Grid
						item
						xs={12}
						container
						justifyContent={'space-between'}
						rowGap={4}
						style={{ marginTop: currentDate < finalDate ? '1.3rem' : null }}
					>
						{closedCards.map((item, index) => {
							return (
								<Grid
									item
									xs={12}
									md={5.8}
								>
									<CardLiveDasLives
										id={item?.id}
										key={index}
										contest={item.contest}
										logo={item.logo}
										mode={item.finished}
										dateDay={item.dateDay}
										finalDate={finalDate}
										dateMonth={item.dateMonth}
										url={item.url}
										classes={classes}
										matchesMobile={matchesMobile}
										opening={item.opening}
										appear={true}
									/>
								</Grid>
							);
						})}
					</Grid>
				</Grid>
			</Container>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	videoBackground: {
		objectFit: 'cover',
		height: 676,
		width: '100%',
		[theme.breakpoints.down('xl')]: {
			height: 480,
		},
		[theme.breakpoints.down('md')]: {
			height: 332,
		},
		[theme.breakpoints.down('sm')]: {
			height: 283,
		},
	},
	ldlLogo: {
		alignSelf: 'center',
		[theme.breakpoints.down(1509)]: {
			width: '58%',
			marginBottom: '5rem !important',
		},
		[theme.breakpoints.down(1920)]: {
			width: '58%',
			marginBottom: '5rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			alignSelf: 'center',
			width: '90% !important',
		},
	},
	gridLogoContainer: {
		position: 'absolute',
		height: '100%',
		[theme.breakpoints.down('sm')]: {
			top: 50,
		},
	},
	rootCard: {
		display: 'flex !important',
		padding: 0,
	},
	dayDateText: {
		fontSize: '3.85rem !important',
		fontWeight: 'bold !important',
		lineHeight: '0.9 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.85rem !important',
		},
	},
	monthDateText: {
		fontSize: '2.25rem !important',
		fontWeight: 'bold !important',
		lineHeight: '0.9 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	gridBackground: {
		backgroundSize: 'cover',
		padding: '0.8rem 1rem 0.5rem',
		height: '100%',
		borderTopRightRadius: 20,
		borderBottomRightRadius: 20,
		[theme.breakpoints.down('sm')]: {
			padding: '1.5rem 0.5rem 1rem',
		},
	},
	gridInformation: {
		backgroundColor: '#000000bf',
		borderRadius: '10px',
		padding: '0.5rem 1rem',
		marginTop: '1rem !important',
		[theme.breakpoints.down('sm')]: {
			padding: '0.5rem',
		},
	},
	subscribeBtn: {
		width: '30% !important',
		padding: 'unset !important',
		transition: 'ease 0.2s !important',
		alignSelf: 'center',
		border: '1px transparent !important',
		fontWeight: 'bold !important',
		fontSize: '0.95rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem !important',
			width: '35% !important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '0.7rem !important',
		},
		[theme.breakpoints.down(361)]: {
			width: '37% !important',
		},
	},
	lotGrid: {
		borderRadius: '4px',
		padding: '0.25rem 0.59rem',
		width: 'fit-content !important',
		[theme.breakpoints.down('sm')]: {
			padding: '0.3rem',
			fontSize: '0.5rem !important',
		},
		[theme.breakpoints.down('md')]: {
			padding: '0.2rem',
			fontSize: '0.3rem !important',
		},
	},
	modeText: {
		alignSelf: 'center',
		fontSize: '1.05rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '0.75rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.55rem !important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '0.55rem !important',
		},
		[theme.breakpoints.down(361)]: {
			fontSize: '0.55rem !important',
		},
	},
	modeTextFinished: {
		alignSelf: 'center',
		fontSize: '1.05rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		color: '#F53636 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1.3rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.55rem !important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '0.55rem !important',
		},
		[theme.breakpoints.down(361)]: {
			fontSize: '0.55rem !important',
		},
	},
	lotText: {
		alignSelf: 'center',
		fontSize: '0.85rem !important',
		fontWeight: '500 !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.6rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem !important',
		},
	},
	divider: {
		alignSelf: 'center',
		backgroundColor: '#ffffff',
		minHeight: 1,
		width: '41%',
		[theme.breakpoints.down('sm')]: {
			margin: '1rem 0 !important',
			width: '32%',
		},
	},
	borderGrid: {
		width: '2.5%',
		borderTopLeftRadius: 15,
		borderBottomLeftRadius: 15,
		[theme.breakpoints.down('sm')]: {
			width: '5%',
		},
	},
	containerWaitList: {
		backgroundColor: '#d6cece',
		zIndex: 1,
		borderRadius: 15,
		padding: '0.5rem 1.25rem',
		boxShadow: '0px 0px 30px 0px #000000',
		border: '2px solid #000000',
		[theme.breakpoints.down('sm')]: {
			padding: '1rem 1.25rem',
		},
	},
	textWaitList: {
		fontSize: '1rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.95rem !important',
		},
	},
	form: {
		borderRadius: '8px !important',
		backgroundColor: '#E1E1E1 !important',
		margin: '5px 0 !important',
		'& .MuiInputBase-input': {
			padding: '0.5rem !important',
		},
	},
	formHeight: {
		maxHeight: '2.5em',
	},
	btnWaitList: {
		fontSize: '0.9rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		backgroundColor: '#00AB55 !important',
		border: '1px solid #707070 !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				backgroundColor: '#00543b !important',
				transition: '0.1s',
			},
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	cancelIcon: {
		color: '#000000',
		right: '-15px',
		top: '-5px',
		'&:hover': {
			cursor: 'pointer',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'absolute',
			top: '-15px',
			right: '-18px',
		},
	},
	phoneNumberForm: {
		height: '2.5em !important',
		borderRadius: 8,
		width: '100%',
		border: 'unset',
	},
}));
