import { useEffect, useState } from 'react';
import { Divider, Grid, Modal, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CountDownTimer from './CountDownTimer';
import RemainingVacancyBar from './RemainingVacancyBar';
import DefaultButton from '../../../../../Components/Buttons/DefaultButton';
import { Link } from 'react-router-dom';
import WaitListForm from '../../../../../Components/Utils/Forms/WaitListForm';

let className = 'background-central-concursos-vertical';

export default function DateInfoCard({
	lot,
	openingDateSprintStudents,
	closeDateSprintStudents,
	openingDateGeneralPublic,
	targetDate,
	numParticipants,
	contest,
	modality,
	executeScroll,
}) {
	const classes = useStyles();
	const theme = useTheme();
	const [openModal, setOpenModal] = useState(false);

	// useEffect(() => {
	//     if (window.location.pathname.includes('revalida')) {
	//         return className = 'background-ldl-vertical-revalida'
	//     }
	// }, [window.location.pathname])

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));

	const lotsDate = new Date(targetDate).getTime();
	// const startDate = useState(new Date(lot?.data_inicio).getTime());
	const startDate = new Date(lot?.data_inicio).getTime();
	const endDate = new Date(lot?.data_limite).getTime();
	const currentDate = new Date();

	function dateFormat(data) {
		if (data) {
			const dataObjeto = new Date(data);
			const dia = String(dataObjeto.getDate()).padStart(2, '0');
			const mes = String(dataObjeto.getMonth() + 1).padStart(2, '0'); // Adicionar +1 pois o mês começa em zero (janeiro é 0)
			const ano = dataObjeto.getFullYear();
			return `${dia}/${mes}/${ano}`;
		} else {
			return null;
		}
	}

	const handleOpen = () => {
		setOpenModal(true);
	};

	const handleClose = () => {
		setOpenModal(false);
	};

	const isActive = new Date(lot?.data_inicio) <= currentDate && currentDate <= new Date(lot?.data_limite);

	const timeDifference = startDate - currentDate;

	return (
		<>
			<Modal
				open={openModal}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				style={{ zIndex: 1000 }}
			>
				<WaitListForm
					group={`LDL ${contest?.name?.toUpperCase()}`}
					onHandleClose={handleClose}
					lot={`LOTE ${lot?.nome}`}
				/>
			</Modal>
			<Grid
				container
				style={{
					backgroundColor: '#ffffff',
					backgroundImage: `url(${require('../Assets/BG-gray-line.png')})`,
					borderRadius: 25,
					padding: matchesTablet ? '1rem' : '1.5rem',
				}}
			>
				<Grid
					item
					xs={12}
					md={6}
					container
					style={{ paddingRight: '2rem' }}
				>
					<Grid
						item
						xs={12}
						container
						rowGap={1}
						style={{
							paddingRight: matchesTablet ? '0.5rem' : null,
							height: 'fit-content',
							marginBottom: '0.5rem',
						}}
					>
						<Typography
							color="primary"
							className={`${classes.titleInfoCard} roboto-condensed`}
						>
							Lote SPRINT+: exclusivo para alunos Sprint
						</Typography>
						<Typography
							color="primary"
							className={`${classes.text} poppins`}
						>
							Alunos e alunas Sprint poderão se inscrever no lote EXCLUSIVO SPRINT+, que ficará disponível
							por <strong>apenas 24h</strong>.
							{/* O início das inscrições será às{' '}
							<strong>ZERO hora do dia 04/10</strong>, se encerrando às 23h59 do mesmo dia. */}
							{/* Alunos <strong>Sprint Final</strong> poderão se inscrever com prioridade, antes da abertura
							para o público geral. */}
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						container
						rowGap={1}
						style={{ height: 'fit-content', marginBottom: matchesTablet ? '0.5rem' : null }}
					>
						<Typography
							color="primary"
							className={`${classes.titleInfoCard} roboto-condensed`}
						>
							E tem mais...
						</Typography>

						<Typography
							color="primary"
							className={`${classes.text} poppins`}
						>
							Após confirmar inscrição no lote SPRINT+ tu poderás convidar um amigo ou uma amiga para ter
							o mesmo benefício que tu, e poder se inscrever no lote EXCLUSIVO SPRINT+. Mas atenção: a
							pessoa convidada tem um prazo de 24h para efetivar a inscrição.
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						container
						alignContent={'center'}
						style={{ paddingRight: '2.5rem' }}
					>
						<Divider
							style={{
								margin: 'unset !important',
								backgroundColor: 'grey',
								minHeight: 0.75,
								width: '100%',
							}}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						container
						rowGap={1}
						style={{
							paddingRight: matchesTablet ? '0.5rem' : null,
							height: 'fit-content',
							margin: matchesTablet ? '0.5rem 0 2rem' : null,
						}}
					>
						<Typography
							color="primary"
							className={`${classes.titleInfoCard} roboto-condensed`}
						>
							Como serão as inscrições para o PÚBLICO GERAL?
						</Typography>
						<Typography
							color="primary"
							className={`${classes.text} poppins`}
						>
							As inscrições para o público geral poderão ser feitas nos lotes adicionais, na dependência
							de ainda existirem vagas disponíveis.
							{/* O lote 2 será aberto no dia 09/10/23. */}
							{/* A abertura para o público geral será a partir do Lote 02, sujeito à disponibilidade de vagas
							remanescentes após o encerramento do Lote Sprint+. */}
						</Typography>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					className={classes.innerCard}
				>
					<Grid
						item
						xs={12}
						container
						className={classes.gridDivider}
					>
						<Divider
							className={classes.divider}
							orientation="vertical"
							style={matchesMobile && lot?.nome === 'SPRINT+' ? { top: 5, height: '65%' } : null}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						container
						className={'residencia'}
						rowGap={matchesTablet ? 1 : 3}
					>
						<Grid
							item
							xs={12}
							container
							direction={'row'}
							justifyContent={'flex-start'}
						>
							<Grid
								item
								xs={12}
								container
								rowGap={matchesTablet ? 3 : 4}
								alignContent={'space-between'}
							>
								<Grid
									item
									xs={12}
									container
									alignContent={'center'}
								>
									<Grid
										item
										xs={1.25}
										container
										justifyContent={matchesMobile ? 'flex-start' : 'center'}
									>
										<input
											type="radio"
											id="html"
											name="fav_language"
											value="HTML"
											color="red"
											checked={lot?.nome === 'SPRINT+'}
											style={{ width: matchesLg ? 20 : 25, accentColor: 'red' }}
										></input>
									</Grid>

									<Grid
										item
										xs={10.75}
										container
										className={`${classes.rootInnerCard} ${
											lot?.nome !== 'SPRINT+' ? classes.innerCardOpacity50 : null
										} background-central-concursos-vertical`}
										style={{ padding: matchesLg ? '0 0.4rem' : null }}
									>
										{matchesLg && lot?.nome === 'SPRINT+' && (
											<Grid
												item
												xs={12}
												lg={4.5}
												container
												direction={'column'}
												justifyContent={'center'}
												style={{ padding: matchesLg ? '0 0.5rem' : null }}
											>
												<Grid
													item
													xs={12}
													lg={6.5}
													container
													justifyContent={matchesLg ? 'space-evenly' : 'center'}
													rowGap={0.25}
													style={{
														backgroundColor: '#FFFFFF',
														borderRadius: '0 0 8px 8px',
														marginBottom: '0.5rem',
													}}
												>
													<Typography
														color="primary"
														className="poppins"
														style={{
															fontSize: matchesMobile ? '0.7rem' : '0.75rem',
															alignSelf: matchesLg ? 'center' : null,
															fontWeight: 'bold',
														}}
													>
														LOTE SPRINT+{' '}
														{currentDate < startDate ? 'ABRE EM' : 'SE ENCERRA EM'}
													</Typography>
													<CountDownTimer
														targetDate={currentDate < startDate ? startDate : lotsDate}
														textColor={'primary'}
													/>
												</Grid>
											</Grid>
										)}
										<Grid
											item
											xs={12}
											lg={timeDifference > 24 * 60 * 60 * 1000 ? 6 : 7}
											container
											style={
												matchesLg
													? {
															padding:
																lot?.nome === 'SPRINT+'
																	? '0 0.25rem 0.5rem'
																	: '0.5rem 0.25rem',
													  }
													: null
											}
										>
											<Typography
												color="secondary"
												className={`${classes.vipText} poppins`}
											>
												LOTE SPRINT+
											</Typography>
											<Grid
												item
												xs={12}
												container
												columnGap={1}
												style={{ marginBottom: '1rem' }}
											>
												<Typography
													color="secondary"
													className={`${classes.dateTextInnerCard} poppins`}
													style={{ lineHeight: matchesLg ? null : 0.75, alignSelf: 'end' }}
												>
													Exclusivo para alunos
												</Typography>
												<img
													src={require('../Assets/logo-sf-wide-white.svg').default}
													alt=""
													style={matchesMobile ? { width: '40%' } : { width: '30%' }}
												/>
											</Grid>
											{window.location.pathname === '/live-das-lives-sus' ? (
												<Typography
													color="secondary"
													className={`${classes.dateTextInnerCard} poppins`}
												>
													{lot?.nome !== 'SPRINT+' || lot === undefined
														? 'ENCERRADO'
														: `até 18:00 de ${dateFormat(lot?.data_limite)}`}
												</Typography>
											) : (
												<Typography
													color="secondary"
													className={`${classes.dateTextInnerCard} poppins`}
												>
													{lot?.nome !== 'SPRINT+' || lot === undefined
														? 'ENCERRADO'
														: `até 14:00 de ${dateFormat(lot?.data_limite)}`}
												</Typography>
											)}
										</Grid>
										{!matchesLg && lot?.nome === 'SPRINT+' && currentDate < startDate && (
											<Grid
												item
												xs={12}
												lg={timeDifference > 24 * 60 * 60 * 1000 ? 6 : 5}
												container
												direction={'column'}
												justifyContent={'center'}
												style={{ top: -8 }}
											>
												<Grid
													item
													xs={12}
													lg={6.5}
													container
													justifyContent={'center'}
													rowGap={0.5}
													style={{
														backgroundColor: '#FFFFFF',
														borderRadius: '0 0 8px 8px',
														paddingBottom: '0.5rem',
													}}
												>
													<Grid
														item
														xs={12}
														container
														justifyContent={matchesLg ? 'space-evenly' : 'center'}
														rowGap={0.5}
														style={{
															borderRadius: '0 0 8px 8px',
														}}
													>
														<Typography
															color="primary"
															className="poppins"
															style={{
																fontSize: matchesMobile ? '0.7rem' : '0.75rem',
																alignSelf: matchesLg ? 'center' : null,
																fontWeight: 'bold',
															}}
														>
															LOTE SPRINT+ abre em
														</Typography>
														<CountDownTimer
															targetDate={startDate}
															textColor={'primary'}
														/>
														<DefaultButton
															variant="outlined"
															color={'secondary'}
															className={`${classes.subscribeBtn} poppins background-central-concursos-horizontal`}
															component={Link}
															onClick={() => handleOpen()}
														>
															<img
																src={
																	require('../../../../../Assets/Svg/icon-white-bell.svg')
																		.default
																}
																alt=""
																style={{ width: 12 }}
															/>
															&nbsp;{'Avise-me'}
														</DefaultButton>
													</Grid>
												</Grid>
											</Grid>
										)}
										{!matchesLg && lot?.nome === 'SPRINT+' && currentDate > startDate && (
											<Grid
												item
												xs={12}
												lg={4.5}
												container
												direction={'column'}
												justifyContent={'center'}
												style={{ top: -14 }}
											>
												<Grid
													item
													xs={12}
													lg={6.5}
													container
													justifyContent={'center'}
													rowGap={0.5}
													style={{
														// padding: matchesMobile ? '0.5rem 0.5rem 0.25rem' : '0.5rem 0.75rem 0',
														backgroundColor: '#FFFFFF',
														borderRadius: '0 0 8px 8px',
														marginBottom: '1rem',
													}}
												>
													<Typography
														color="primary"
														className="poppins"
														style={{
															fontSize: matchesMobile ? '0.7rem' : '0.75rem',
															alignSelf: matchesMobile ? 'center' : null,
															fontWeight: 'bold',
														}}
													>
														LOTE SPRINT+ SE ENCERRA EM
													</Typography>
													<CountDownTimer
														targetDate={lotsDate}
														textColor={'primary'}
													/>
												</Grid>
											</Grid>
										)}
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									container
									alignContent={'center'}
								>
									<Grid
										item
										xs={1.25}
										container
										justifyContent={matchesMobile ? 'flex-start' : 'center'}
									>
										<input
											type="radio"
											id="html"
											name="fav_language"
											value="HTML"
											color="red"
											checked={lot?.nome === '2'}
											style={{ width: matchesLg ? 20 : 25, accentColor: 'red' }}
										></input>
									</Grid>
									<Grid
										item
										xs={10.75}
										container
										className={`${classes.rootInnerCardLote} ${
											(lot?.nome !== '2' && lot?.nome) || lot === undefined
												? classes.innerCardOpacity50
												: null
										}`}
										style={{
											backgroundColor: '#ffffff',
										}}
									>
										{matchesLg &&
											!isActive &&
											lot?.nome !== 'SPRINT+' &&
											lot?.nome !== '3' &&
											lot?.nome !== '4' && (
												<Grid
													item
													xs={12}
													lg={4.5}
													container
													direction={'column'}
													justifyContent={'center'}
												>
													<Grid
														item
														xs={12}
														lg={6.5}
														container
														justifyContent={matchesLg ? 'space-evenly' : 'center'}
														rowGap={0.5}
														style={{
															borderRadius: '0 0 8px 8px',
														}}
													>
														<Typography
															color="primary"
															className="poppins"
															style={{
																fontSize: matchesMobile ? '0.7rem' : '0.75rem',
																alignSelf: matchesLg ? 'center' : null,
																fontWeight: 'bold',
															}}
														>
															LOTE 02 abre em
															{/* {startDate} */}
														</Typography>
														<CountDownTimer
															targetDate={startDate}
															textColor={'primary'}
														/>
														<DefaultButton
															variant="outlined"
															color={'secondary'}
															className={`${classes.subscribeBtn} poppins background-central-concursos-horizontal`}
															component={Link}
															onClick={() => handleOpen()}
														>
															<img
																src={
																	require('../../../../../Assets/Svg/icon-white-bell.svg')
																		.default
																}
																alt=""
																style={{ width: 12 }}
															/>
															&nbsp;{'Avise-me'}
														</DefaultButton>
													</Grid>
												</Grid>
											)}
										<Grid
											item
											xs={12}
											lg={6}
											container
											direction={'column'}
											justifyContent={'center'}
											style={
												matchesMobile && lot?.nome === '2' && !isActive
													? { display: 'none' }
													: null
											}
										>
											<Typography
												color="primary"
												className={`${classes.vipText} ${
													(lot?.nome !== '2' && lot?.nome) || lot === undefined
														? classes.textOpacity
														: null
												} poppins`}
											>
												Lote 2
											</Typography>
											<Typography
												color="primary"
												className={`${classes.dateTextInnerCard} poppins`}
											>
												{lot?.nome === '2' && isActive
													? contest?.modality === 'online'
														? 'Aberto'
														: `Até esgotarem as vagas`
													: lot?.nome === '3' || lot?.nome === '4' || lot === undefined
													? 'Encerrado'
													: lot?.nome !== '2' || (lot?.nome === '2' && !isActive)
													? 'Em breve'
													: null}
											</Typography>
											{lot?.nome === '2' && isActive && (
												<DefaultButton
													variant="outlined"
													color={'primary'}
													className={`${classes.subscribeBtn2} white-btn-secondary poppins`}
													onClick={() => executeScroll()}
												>
													<img
														src={
															require('../../../../../Assets/Svg/Icons/bagIcon.svg')
																.default
														}
														alt=""
														className={classes.logoBtn}
													/>
													&nbsp;&nbsp;Garantir vaga
												</DefaultButton>
											)}
										</Grid>
										{isActive &&
											lot?.nome !== 'SPRINT+' &&
											lot?.nome !== '3' &&
											lot?.nome !== '4' &&
											(contest?.modality !== 'online' ? (
												<Grid
													item
													xs={12}
													lg={5}
													container
													justifyContent={'center'}
													alignContent={'center'}
													rowGap={0.5}
													style={{
														marginTop: matchesMobile
															? lot?.nome === '2' && isActive
																? null
																: '0.75rem'
															: null,
													}}
												>
													{50 > lot?.qtd_limite - numParticipants && (
														<RemainingVacancyBar
															numParticipants={numParticipants}
															qtd_limite={lot?.qtd_limite}
														/>
													)}
													{50 > lot?.qtd_limite - numParticipants &&
													lot?.qtd_limite - numParticipants > 30 ? (
														<Typography
															color="primary"
															className={`poppins`}
															style={{
																fontSize: matchesMobile ? '0.6rem' : '0.7rem',
																fontWeight: '500',
															}}
														>
															Últimas vagas
														</Typography>
													) : null}
													{30 > lot?.qtd_limite - numParticipants && (
														<Typography
															color="primary"
															className={`poppins`}
															style={{
																fontSize: matchesMobile ? '0.6rem' : '0.7rem',
																fontWeight: '500',
															}}
														>
															APENAS <strong>{lot?.qtd_limite - numParticipants}</strong>{' '}
															VAGAS RESTANTES
														</Typography>
													)}
												</Grid>
											) : (
												<Grid
													item
													xs={12}
													lg={6}
													container
													direction={'column'}
													justifyContent={'center'}
												>
													<Grid
														item
														xs={12}
														lg={6.5}
														container
														justifyContent={'center'}
														rowGap={0.5}
														style={{
															borderRadius: '0 0 8px 8px',
														}}
													>
														<Typography
															color="primary"
															className="poppins"
															style={{
																fontSize: matchesMobile ? '0.7rem' : '0.75rem',
																alignSelf: matchesMobile ? 'center' : null,
																fontWeight: 'bold',
															}}
														>
															LOTE 02 SE ENCERRA EM
														</Typography>
														<CountDownTimer
															targetDate={endDate}
															textColor={'primary'}
														/>
													</Grid>
												</Grid>
											))}
										{!matchesLg &&
											!isActive &&
											lot?.nome !== 'SPRINT+' &&
											lot?.nome !== '3' &&
											lot?.nome !== '4' &&
											lot !== undefined && (
												<Grid
													item
													xs={12}
													lg={isActive ? 5 : 6}
													container
													direction={'column'}
													justifyContent={'center'}
												>
													<Grid
														item
														xs={12}
														lg={6.5}
														container
														justifyContent={'center'}
														rowGap={0.5}
														style={{
															borderRadius: '0 0 8px 8px',
														}}
													>
														<Typography
															color="primary"
															className="poppins"
															style={{
																fontSize: matchesMobile ? '0.7rem' : '0.75rem',
																alignSelf: matchesMobile ? 'center' : null,
																fontWeight: 'bold',
															}}
														>
															LOTE 02 abre em
														</Typography>
														<CountDownTimer
															targetDate={startDate}
															textColor={'primary'}
														/>
														<DefaultButton
															variant="outlined"
															color={'secondary'}
															className={`${classes.subscribeBtn} poppins background-central-concursos-horizontal`}
															component={Link}
															onClick={() => handleOpen()}
														>
															<img
																src={
																	require('../../../../../Assets/Svg/icon-white-bell.svg')
																		.default
																}
																alt=""
																style={{ width: 12 }}
															/>
															&nbsp;{'Avise-me'}
														</DefaultButton>
													</Grid>
												</Grid>
											)}
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									container
									alignContent={'center'}
								>
									<Grid
										item
										xs={1.25}
										container
										justifyContent={matchesMobile ? 'flex-start' : 'center'}
									>
										<input
											type="radio"
											id="html"
											name="fav_language"
											value="HTML"
											color="red"
											checked={lot?.nome === '3'}
											style={{ width: matchesLg ? 20 : 25, accentColor: 'red' }}
										></input>
									</Grid>
									<Grid
										item
										xs={10.75}
										container
										className={`${classes.rootInnerCardLote} ${
											lot?.nome !== '3' || lot === undefined ? classes.innerCardOpacity50 : null
										}`}
										style={{
											backgroundColor: '#ffffff',
										}}
									>
										{matchesLg &&
											!isActive &&
											lot?.nome !== 'SPRINT+' &&
											lot?.nome !== '2' &&
											lot?.nome !== '4' && (
												<Grid
													item
													xs={12}
													lg={4.5}
													container
													direction={'column'}
													justifyContent={'center'}
												>
													<Grid
														item
														xs={12}
														lg={6.5}
														container
														justifyContent={matchesLg ? 'space-evenly' : 'center'}
														rowGap={0.5}
														style={{
															borderRadius: '0 0 8px 8px',
														}}
													>
														<Typography
															color="primary"
															className="poppins"
															style={{
																fontSize: matchesMobile ? '0.7rem' : '0.75rem',
																alignSelf: matchesLg ? 'center' : null,
																fontWeight: 'bold',
															}}
														>
															LOTE 03 abre em
														</Typography>
														<CountDownTimer
															targetDate={startDate}
															textColor={'primary'}
														/>
														<DefaultButton
															variant="outlined"
															color={'secondary'}
															className={`${classes.subscribeBtn} poppins background-central-concursos-horizontal`}
															component={Link}
															onClick={() => handleOpen()}
														>
															<img
																src={
																	require('../../../../../Assets/Svg/icon-white-bell.svg')
																		.default
																}
																alt=""
																style={{ width: 12 }}
															/>
															&nbsp;{'Avise-me'}
														</DefaultButton>
													</Grid>
												</Grid>
											)}
										<Grid
											item
											xs={12}
											lg={isActive ? 6.5 : 6}
											container
											direction={'column'}
											justifyContent={'center'}
											style={
												matchesMobile && lot?.nome === '3' && !isActive
													? { display: 'none' }
													: null
											}
										>
											<Typography
												color="primary"
												className={`${classes.vipText} ${
													lot?.nome === 'SPRINT+' ||
													lot?.nome === '2' ||
													lot?.nome === '4' ||
													lot === undefined
														? classes.textOpacity
														: null
												} poppins`}
											>
												Lote 3
											</Typography>
											<Typography
												color="primary"
												className={`${classes.dateTextInnerCard} poppins`}
											>
												{lot?.nome === '3' && isActive
													? modality === 'online'
														? 'Aberto'
														: `Até esgotarem as vagas`
													: lot?.nome === '4' || lot === undefined
													? 'Encerrado'
													: lot?.nome !== '3' || (lot?.nome === '3' && !isActive)
													? 'Em breve'
													: null}
											</Typography>
											{lot?.nome === '3' && isActive && (
												<DefaultButton
													variant="outlined"
													color={'primary'}
													className={`${classes.subscribeBtn2} white-btn-secondary poppins`}
													onClick={() => executeScroll()}
												>
													<img
														src={
															require('../../../../../Assets/Svg/Icons/bagIcon.svg')
																.default
														}
														alt=""
														className={classes.logoBtn}
													/>
													&nbsp;&nbsp;Garantir vaga
												</DefaultButton>
											)}
										</Grid>
										{isActive &&
											lot?.nome !== 'SPRINT+' &&
											lot?.nome !== '2' &&
											lot?.nome !== '4' &&
											(contest?.modality !== 'online' ? (
												<Grid
													item
													xs={12}
													lg={5.5}
													container
													justifyContent={'center'}
													alignContent={'center'}
													rowGap={0.5}
													style={{
														marginTop: matchesMobile
															? lot?.nome === '3' && isActive
																? null
																: '0.75rem'
															: null,
													}}
												>
													{50 > lot?.qtd_limite - numParticipants && (
														<RemainingVacancyBar
															numParticipants={numParticipants}
															qtd_limite={lot?.qtd_limite}
														/>
													)}
													{50 > lot?.qtd_limite - numParticipants &&
													lot?.qtd_limite - numParticipants > 30 ? (
														<Typography
															color="primary"
															className={`poppins`}
															style={{
																fontSize: matchesMobile ? '0.6rem' : '0.7rem',
																fontWeight: '500',
															}}
														>
															Últimas vagas
														</Typography>
													) : null}
													{30 > lot?.qtd_limite - numParticipants && (
														<Typography
															color="primary"
															className={`poppins`}
															style={{
																fontSize: matchesMobile ? '0.6rem' : '0.7rem',
																fontWeight: '500',
															}}
														>
															APENAS <strong>{lot?.qtd_limite - numParticipants}</strong>{' '}
															VAGAS RESTANTES
														</Typography>
													)}
												</Grid>
											) : (
												<Grid
													item
													xs={12}
													lg={5.5}
													container
													direction={'column'}
													justifyContent={'center'}
												>
													<Grid
														item
														xs={12}
														lg={6.5}
														container
														justifyContent={'center'}
														rowGap={0.5}
														style={{
															borderRadius: '0 0 8px 8px',
														}}
													>
														<Typography
															color="primary"
															className="poppins"
															style={{
																fontSize: matchesMobile ? '0.7rem' : '0.75rem',
																alignSelf: matchesMobile ? 'center' : null,
																fontWeight: 'bold',
															}}
														>
															LOTE 03 SE ENCERRA EM
														</Typography>
														<CountDownTimer
															targetDate={endDate}
															textColor={'primary'}
														/>
													</Grid>
												</Grid>
											))}
										{!matchesLg &&
											!isActive &&
											lot?.nome !== 'SPRINT+' &&
											lot?.nome !== '2' &&
											lot?.nome !== '4' &&
											lot !== undefined && (
												<Grid
													item
													xs={12}
													lg={isActive ? 5 : 6}
													container
													direction={'column'}
													justifyContent={'center'}
												>
													<Grid
														item
														xs={12}
														lg={6.5}
														container
														justifyContent={'center'}
														rowGap={0.5}
														style={{
															borderRadius: '0 0 8px 8px',
														}}
													>
														<Typography
															color="primary"
															className="poppins"
															style={{
																fontSize: matchesMobile ? '0.7rem' : '0.75rem',
																alignSelf: matchesMobile ? 'center' : null,
																fontWeight: 'bold',
															}}
														>
															LOTE 03 abre em
														</Typography>
														<CountDownTimer
															targetDate={startDate}
															textColor={'primary'}
														/>
														<DefaultButton
															variant="outlined"
															color={'secondary'}
															className={`${classes.subscribeBtn} poppins background-central-concursos-horizontal`}
															component={Link}
															onClick={() => handleOpen()}
														>
															<img
																src={
																	require('../../../../../Assets/Svg/icon-white-bell.svg')
																		.default
																}
																alt=""
																style={{ width: 12 }}
															/>
															&nbsp;{'Avise-me'}
														</DefaultButton>
													</Grid>
												</Grid>
											)}
									</Grid>
								</Grid>
								{window.location.pathname !== '/live-das-lives-famerp' &&
									window.location.pathname !== '/live-das-lives-usp' &&
									window.location.pathname !== '/live-das-lives-santa-casa-sp' && (
										<Grid
											item
											xs={12}
											container
											alignContent={'center'}
										>
											<Grid
												item
												xs={1.25}
												container
												justifyContent={matchesMobile ? 'flex-start' : 'center'}
											>
												<input
													type="radio"
													id="html"
													name="fav_language"
													value="HTML"
													color="red"
													checked={lot?.nome === '4'}
													style={{ width: matchesLg ? 20 : 25, accentColor: 'red' }}
												></input>
											</Grid>
											<Grid
												item
												xs={10.75}
												container
												className={`${classes.rootInnerCardLote} ${
													lot?.nome !== '4' || lot === undefined
														? classes.innerCardOpacity50
														: null
												}`}
												style={{
													backgroundColor: '#ffffff',
												}}
											>
												{matchesLg &&
													!isActive &&
													lot?.nome !== 'SPRINT+' &&
													lot?.nome !== '2' &&
													lot?.nome !== '3' && (
														<Grid
															item
															xs={12}
															lg={4.5}
															container
															direction={'column'}
															justifyContent={'center'}
														>
															<Grid
																item
																xs={12}
																lg={6.5}
																container
																justifyContent={matchesLg ? 'space-evenly' : 'center'}
																rowGap={0.5}
																style={{
																	borderRadius: '0 0 8px 8px',
																}}
															>
																<Typography
																	color="primary"
																	className="poppins"
																	style={{
																		fontSize: matchesMobile ? '0.7rem' : '0.75rem',
																		alignSelf: matchesLg ? 'center' : null,
																		fontWeight: 'bold',
																	}}
																>
																	LOTE 04 abre em
																</Typography>
																<CountDownTimer
																	targetDate={startDate}
																	textColor={'primary'}
																/>
																<DefaultButton
																	variant="outlined"
																	color={'secondary'}
																	className={`${classes.subscribeBtn} poppins background-central-concursos-horizontal`}
																	component={Link}
																	onClick={() => handleOpen()}
																>
																	<img
																		src={
																			require('../../../../../Assets/Svg/icon-white-bell.svg')
																				.default
																		}
																		alt=""
																		style={{ width: 12 }}
																	/>
																	&nbsp;{'Avise-me'}
																</DefaultButton>
															</Grid>
														</Grid>
													)}
												<Grid
													item
													xs={12}
													lg={isActive ? 6.5 : 6}
													container
													direction={'column'}
													justifyContent={'center'}
													style={
														matchesMobile && lot?.nome === '4' && !isActive
															? { display: 'none' }
															: null
													}
												>
													<Typography
														color="primary"
														className={`${classes.vipText} ${
															lot?.nome !== '4' ? classes.textOpacity : null
														} poppins`}
													>
														Lote 4
													</Typography>
													<Typography
														color="primary"
														className={`${classes.dateTextInnerCard} poppins`}
													>
														{lot === undefined
															? 'Encerrado'
															: lot?.nome !== '4' || (lot?.nome === '4' && !isActive)
															? 'Em breve'
															: modality === 'online'
															? 'Aberto'
															: `Até esgotarem as vagas`}
													</Typography>
													{lot?.nome === '4' && isActive && (
														<DefaultButton
															variant="outlined"
															color={'primary'}
															className={`${classes.subscribeBtn2} white-btn-secondary poppins`}
															onClick={() => executeScroll()}
														>
															<img
																src={
																	require('../../../../../Assets/Svg/Icons/bagIcon.svg')
																		.default
																}
																alt=""
																className={classes.logoBtn}
															/>
															&nbsp;&nbsp;Garantir vaga
														</DefaultButton>
													)}
												</Grid>
												{isActive &&
													lot?.nome !== 'SPRINT+' &&
													lot?.nome !== '2' &&
													lot?.nome !== '3' &&
													(contest?.modality !== 'online' ? (
														<Grid
															item
															xs={12}
															lg={5.5}
															container
															justifyContent={'center'}
															alignContent={'center'}
															rowGap={0.5}
															style={{ marginTop: matchesMobile ? '0.75rem' : null }}
														>
															{50 > lot?.qtd_limite - numParticipants && (
																<RemainingVacancyBar
																	numParticipants={numParticipants}
																	qtd_limite={lot?.qtd_limite}
																/>
															)}
															{50 > lot?.qtd_limite - numParticipants &&
															lot?.qtd_limite - numParticipants > 30 ? (
																<Typography
																	color="primary"
																	className={`poppins`}
																	style={{
																		fontSize: matchesMobile ? '0.6rem' : '0.7rem',
																		fontWeight: '500',
																	}}
																>
																	Últimas vagas
																</Typography>
															) : null}
															{30 > lot?.qtd_limite - numParticipants && (
																<Typography
																	color="primary"
																	className={`poppins`}
																	style={{
																		fontSize: matchesMobile ? '0.6rem' : '0.7rem',
																		fontWeight: '500',
																	}}
																>
																	APENAS{' '}
																	<strong>{lot?.qtd_limite - numParticipants}</strong>{' '}
																	VAGAS RESTANTES
																</Typography>
															)}
														</Grid>
													) : (
														<Grid
															item
															xs={12}
															lg={5.5}
															container
															direction={'column'}
															justifyContent={'center'}
														>
															<Grid
																item
																xs={12}
																lg={6.5}
																container
																justifyContent={'center'}
																rowGap={0.5}
																style={{
																	borderRadius: '0 0 8px 8px',
																}}
															>
																<Typography
																	color="primary"
																	className="poppins"
																	style={{
																		fontSize: matchesMobile ? '0.7rem' : '0.75rem',
																		alignSelf: matchesMobile ? 'center' : null,
																		fontWeight: 'bold',
																	}}
																>
																	LOTE 04 SE ENCERRA EM
																</Typography>
																<CountDownTimer
																	targetDate={endDate}
																	textColor={'primary'}
																/>
															</Grid>
														</Grid>
													))}
												{!matchesLg &&
													!isActive &&
													lot?.nome !== 'SPRINT+' &&
													lot?.nome !== '2' &&
													lot?.nome !== '3' &&
													lot !== undefined && (
														<Grid
															item
															xs={12}
															lg={isActive ? 5 : 6}
															container
															direction={'column'}
															justifyContent={'center'}
														>
															<Grid
																item
																xs={12}
																lg={6.5}
																container
																justifyContent={'center'}
																rowGap={0.5}
																style={{
																	borderRadius: '0 0 8px 8px',
																}}
															>
																<Typography
																	color="primary"
																	className="poppins"
																	style={{
																		fontSize: matchesMobile ? '0.7rem' : '0.75rem',
																		alignSelf: matchesMobile ? 'center' : null,
																		fontWeight: 'bold',
																	}}
																>
																	LOTE 04 abre em
																</Typography>
																<CountDownTimer
																	targetDate={startDate}
																	textColor={'primary'}
																/>
																<DefaultButton
																	variant="outlined"
																	color={'secondary'}
																	className={`${classes.subscribeBtn} poppins background-central-concursos-horizontal`}
																	component={Link}
																	onClick={() => handleOpen()}
																>
																	<img
																		src={
																			require('../../../../../Assets/Svg/icon-white-bell.svg')
																				.default
																		}
																		alt=""
																		style={{ width: 12 }}
																	/>
																	&nbsp;{'Avise-me'}
																</DefaultButton>
															</Grid>
														</Grid>
													)}
											</Grid>
										</Grid>
									)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}

const useStyles = makeStyles((theme) => ({
	titleInfoCard: {
		fontSize: '1.8rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.5rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.25rem !important',
		},
	},
	text: {
		fontSize: '1.2rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '0.8rem !important',
		},
	},
	innerCard: {
		backgroundColor: '#0d0d0de6',
		borderRadius: 22,
		padding: '2rem 1rem',
		[theme.breakpoints.down('lg')]: {
			padding: '1rem 1rem 3rem',
		},
		[theme.breakpoints.down('md')]: {
			padding: '1rem 1rem 1.5rem',
		},
	},
	titleInner: {
		fontSize: '2.25rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.5rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.25rem !important',
		},
	},
	vipText: {
		fontWeight: 'bold !important',
		fontSize: '1.5rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.9rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			lineHeight: 'unset !important',
		},
	},
	dateTextInnerCard: {
		fontSize: '1.2rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.7rem !important',
		},
	},
	textOpacity: {
		color: '#464646 !important',
	},
	rootInnerCard: {
		padding: '0.5rem 1rem',
		borderRadius: '10px',
		[theme.breakpoints.down('md')]: {
			padding: '0.2rem 0.4rem',
		},
	},
	titleOpening: {
		[theme.breakpoints.down('md')]: {
			fontSize: '0.8rem !important',
		},
	},

	rootInnerCardLote: {
		padding: '0.5rem 1rem',
		borderRadius: '10px',
		[theme.breakpoints.down('md')]: {
			padding: '0.5rem 0.75rem',
		},
	},
	innerCardOpacity50: {
		opacity: 0.5,
	},
	elipseGroup: {
		top: '2.7rem',
		height: '24rem',
		[theme.breakpoints.down('xl')]: {
			height: '25rem',
		},
		[theme.breakpoints.down('md')]: {
			height: '17rem',
			top: '1.5rem',
		},
	},
	divider: {
		alignSelf: 'center',
		backgroundColor: '#ffffff',
		minHeight: 1,
		left: 33,
		maxHeight: window.location.pathname === '/live-das-lives-santa-casa-sp' ? '60%' : '70%',
		top: window.location.pathname === '/live-das-lives-santa-casa-sp' ? -29 : -19,
		[theme.breakpoints.down('xl')]: {
			left: 28,
		},
		[theme.breakpoints.down('lg')]: {
			left: 21,
			top: -10,
		},
		[theme.breakpoints.down('md')]: {
			top: 2,
			left: 28,
		},
		[theme.breakpoints.down('sm')]: {
			margin: '1rem 0 !important',
			top: -14,
			left: 10,
		},
		[theme.breakpoints.down(361)]: {
			left: 10,
		},
	},
	subscribeBtn: {
		width: '80% !important',
		padding: 'unset !important',
		transition: 'ease 0.2s !important',
		alignSelf: 'center',
		border: '1px transparent !important',
		fontWeight: 'bold !important',
		fontSize: '0.95rem !important',
		'&:hover': {
			width: '85% !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
			width: '80% !important',
		},
	},
	subscribeBtn2: {
		width: 'fit-content',
		fontSize: '0.9rem !important',
		fontWeight: 'bold !important',
		'&:hover': {
			scale: 1.05,
			color: '#ffffff !important',
			'& $logoBtn': {
				filter: 'invert(0)',
			},
		},
		[theme.breakpoints.down('xl')]: {
			padding: '0.25rem 2.5rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '0.85rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem !important',
			padding: '0.25rem 1.25rem !important',
			border: '2px solid transparent !important',
			marginTop: 'unset !important',
		},
	},
	logoBtn: {
		filter: 'invert(1)',
		transition: 'ease-in-out 0.2s',
		[theme.breakpoints.down('xl')]: {
			height: 30,
		},
		[theme.breakpoints.down('sm')]: {
			height: 17,
		},
	},
	gridDivider: {
		height: window.location.pathname === '/live-das-lives-famerp' ? '92%' : '100%',
		position: 'absolute',
		[theme.breakpoints.down('sm')]: {
			height: window.location.pathname === '/live-das-lives-famerp' ? '90%' : '100%',
			top: window.location.pathname === '/live-das-lives-famerp' ? 23 : 'unset',
		},
	},
}));
