import React from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import { Header } from '../../../../Components/Header';
import Form from '../../../../Components/Utils/Forms/PreRegistration';

export default function LivePreSubSprintRevalida2024() {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
			</Container>
			<Grid container>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					<Grid
						container
						justifyContent={'space-between'}
						alignContent="center"
						className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
						style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
					>
						<Grid
							item
							xs={12}
							md={7}
							container
							justifyContent={'flex-start'}
							className={`${classes.gridTextContainer} revalida`}
							gap={3}
							style={{
								height: 'fit-content',
								paddingRight: matchesTablet ? null : '3rem',
								marginBottom: matchesMobile && '2rem',
							}}
						>
							<Grid
								item
								container
								direction={'column'}
								alignItems={matchesMobile && 'center'}
								rowGap={2}
							>
								<Grid
									item
									container
									columnGap={2}
									justifyContent={matchesMobile && 'center'}
								>
									<img
										src={require('../../../../Assets/Arena/logo-live-revalida.svg').default}
										alt=""
										style={{ width: matchesMobile && '25%' }}
									/>
									<Typography
										className={`${classes.title} roboto-condensed`}
										color="secondary"
									>
										Abertura de
										<br />
										inscrições
									</Typography>
								</Grid>
								<Grid
									item
									container
									direction={'column'}
									alignItems={matchesMobile && 'center'}
									className="revalida"
									rowGap={2}
								>
									<img
										src={require('./assets/logo-sprint-final-revalida.svg').default}
										alt=""
										style={matchesMobile ? { width: '80%' } : { width: '60%' }}
									/>
									<Typography
										className={`${classes.text2} poppins`}
										color="secondary"
									>
										A poderosa revisão Hardwork
									</Typography>
								</Grid>
								<Grid
									item
									container
									style={{ width: 'fit-content' }}
								>
									<Typography
										align="left"
										color="secondary"
										className={`${classes.impactText} poppins`}
										style={{ padding: '0 10px' }}
									>
										QUINTA
										<span className="bullet"> &#8226; </span>
										6JUN
										<span className="bullet"> &#8226; </span>
										20:24H
									</Typography>{' '}
								</Grid>
								<Grid
									item
									container
									style={{
										border: '1px solid rgba(0,194,110,1)',
										width: 'fit-content',
										padding: '0.5rem 1rem',
										borderRadius: 16,
										marginTop: '1rem',
									}}
								>
									<Typography
										className={`${classes.text} poppins`}
										color="secondary"
										align={matchesMobile ? 'center' : 'left'}
									>
										<strong>CADASTRE-SE GRATUITAMENTE</strong>
										<br />
										PARTICIPE E CONCORRA A UM SPRINT
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							md={5}
							container
							justifyContent="center"
							className={classes.gridWithBackgroundImage}
							gap={matchesTablet ? 4 : 0}
						>
							<Grid
								item
								xs={12}
								className={classes.gridForm}
								gap={1}
							>
								<Form
									group="LIVE ABERTURA SPRINT FINAL REVALIDA 2024.2"
									title={['Inscreva-se já para receber', <br />, 'mais informações']}
									titleButton="Confirmar Inscrição"
									type="revalida"
									urlToRedirect="/live/abertura-sprint-revalida"
									titleConfirmationMessage={'Inscrição confirmada!'}
									confirmationMessage={
										'Tu serás avisado de todas as novidades pelo email cadastrado.'
									}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</Grid>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesTablet ? null : <Footer noMarginBottom />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto 0',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	title: {
		fontWeight: 'bold !important',
		fontSize: '3.2rem !important',
		lineHeight: '1 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
			marginBottom: '8px !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.45rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem !important',
			marginBottom: 'unset !important',
		},
	},
	text: {
		fontSize: '1.325rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	text2: {
		fontSize: '1.325rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	gridForm: {
		width: 567,
		flexDirection: 'column !important',
		alignItems: 'center !important',
		[theme.breakpoints.down('xl')]: {
			bottom: 20,
		},
		[theme.breakpoints.down(1300)]: {
			width: 467,
		},
		[theme.breakpoints.down('md')]: {
			position: 'unset',
			right: 'unset',
			top: 'unset',
			zIndex: 0,
			marginBottom: '1.5rem !important',
		},
	},
	gridTextContainer: {
		[theme.breakpoints.down('lg')]: {
			paddingRight: '2em',
		},
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
	},
	gridWithBackgroundImage: {
		padding: '1rem 2rem 2.5rem',
		marginBottom: '2rem !important',
		[theme.breakpoints.down('lg')]: {
			padding: 'unset',
		},
		[theme.breakpoints.down('md')]: {
			backgroundImage: 'unset',
			marginBottom: 'unset !important',
		},
	},
	logo: {
		marginBottom: '1.5rem',
		[theme.breakpoints.down('xl')]: {
			marginBottom: '2.5rem',
		},
		[theme.breakpoints.down('md')]: {
			marginBottom: '1rem',
		},
	},
	impactText: {
		fontSize: '1.5rem !important',
		lineHeight: 'unset',
		fontWeight: '500 !important',
		background: 'linear-gradient(90deg, rgba(0,145,166,1) 10%, rgba(0,194,110,1) 100%)',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
		[theme.breakpoints.down(395)]: {
			fontSize: '1.2rem !important',
		},
		[theme.breakpoints.down(380)]: {
			fontSize: '1rem !important',
		},
	},
}));
