import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

// Components
import DefaultButton from '../../../../../Components/Buttons/DefaultButton';
import CountDownTimer from '../components/CountDownTimer';
import RemainingVacancyBar from './RemainingVacancyBar';

export default function PriceCardSantaCasa({ lot, idTurma, contest, numParticipants, lotModality, limit }) {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const startDate = new Date(lot?.data_inicio).getTime();
	const endDate = new Date(lot?.data_limite).getTime();
	const currentDate = new Date();

	const isActive = startDate <= currentDate && currentDate <= endDate;

	return (
		<Grid
			container
			alignContent={matchesTablet ? 'space-around' : 'center'}
			justifyContent="space-between"
			className={classes.rootCard}
		>
			<Grid
				item
				container
				xs={12}
			>
				<Grid
					item
					xs={12}
					md={6}
					container
					direction={'column'}
					justifyContent="space-evenly"
					alignContent="center"
					className={classes.gridImg}
					rowGap={matchesMobile ? 2 : null}
				>
					<img
						src={contest?.logoPriceCard}
						alt="Logo Live das Lives"
						className={classes.logoImg}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					container
					justifyContent="center"
					alignContent="center"
					className={classes.gridPriceInfo}
					style={
						matchesMobile
							? {
									padding: lotModality === 'online' ? '0 1.25rem 1.5rem' : '0 1.25rem 1.5rem',
							  }
							: {
									borderTopRightRadius: 10,
									padding:
										lotModality === 'online'
											? lot === undefined
												? '2.5rem 1rem 2.5rem 3rem'
												: '0 1rem 2.5rem 3rem'
											: isActive
											? `2.5rem 1rem 2.5rem 3rem`
											: '2.5rem 1rem 2.5rem 3rem',
							  }
					}
				>
					<Grid
						item
						container
						justifyContent={matchesMobile ? 'flex-start' : 'flex-end'}
						alignContent="center"
						rowGap={matchesMobile ? 0 : 1}
						// style={{ minHeight: matchesMobile && lot === undefined ? '10.75rem' : '14.75rem' }}
					>
						<>
							{lot?.nome === 'SPRINT+' && (
								<Grid
									item
									xs={12}
									lg={6.5}
									container
									justifyContent={'space-between'}
									wrap={'nowrap'}
									className={lot?.nome !== 'SPRINT+' ? null : 'background-central-concursos-vertical'}
									columnGap={1}
									style={{
										backgroundColor: lot?.nome !== 'SPRINT+' ? '#ffffff' : null,
										padding: matchesMobile ? '0.5rem 0.5rem 0.25rem' : '0.5rem 0.75rem 0',
										borderRadius: '0 0 8px 8px',
										marginBottom: '1rem',
									}}
								>
									<Typography
										color={lot?.nome === 'SPRINT+' ? 'secondary' : 'primary'}
										className="poppins"
										style={{
											fontSize: matchesMobile ? '0.7rem' : '0.8rem',
											alignSelf: matchesMobile ? 'center' : null,
										}}
									>
										<strong>
											{lot?.nome === 'SPRINT+' ? 'Lote Sprint+' : `Lote ${lot?.nome}`}
										</strong>
										<br />
										{lot?.nome === 'SPRINT+' && currentDate > startDate
											? 'se encerra em'
											: 'começa em'}
									</Typography>
									<CountDownTimer
										targetDate={currentDate < startDate ? startDate : endDate}
										textColor={lot?.nome === 'SPRINT+' ? 'secondary' : 'primary'}
									/>
								</Grid>
							)}
							{isActive &&
								lot?.nome !== 'SPRINT+' &&
								(lotModality !== 'online' ? (
									<Grid
										item
										xs={12}
										lg={4}
										container
										justifyContent={'space-between'}
										wrap={'nowrap'}
										className={
											lot?.nome !== 'SPRINT+' ? null : 'background-central-concursos-vertical'
										}
										columnGap={matchesMobile ? 0 : 1}
										style={{
											backgroundColor: lot?.nome !== 'SPRINT+' ? '#585151' : null,
											padding: matchesMobile ? '0.5rem 0.5rem 0.25rem' : '0.5rem 0.75rem 0.5rem',
											borderRadius: '0 0 8px 8px',
											marginBottom: '1rem',
											display: 50 > lot?.qtd_limite - numParticipants ? null : 'none',
										}}
									>
										<Grid
											item
											xs={12}
											container
											justifyContent={'center'}
											alignContent={'center'}
											rowGap={0.5}
											style={{ marginTop: '0.25rem' }}
										>
											{50 > lot?.qtd_limite - numParticipants && (
												<RemainingVacancyBar
													numParticipants={numParticipants}
													qtd_limite={lot?.qtd_limite}
												/>
											)}
											{50 > lot?.qtd_limite - numParticipants &&
											lot?.qtd_limite - numParticipants > 30 ? (
												<Typography
													color="secondary"
													className={`poppins`}
													style={{
														fontSize: matchesMobile ? '0.6rem' : '0.7rem',
														fontWeight: '500',
													}}
												>
													Últimas vagas
												</Typography>
											) : null}
											{30 > lot?.qtd_limite - numParticipants && (
												<Typography
													color="secondary"
													className={`poppins`}
													style={{
														fontSize: matchesMobile ? '0.6rem' : '0.7rem',
														fontWeight: '500',
													}}
												>
													APENAS <strong>{lot?.qtd_limite - numParticipants}</strong> VAGAS
													RESTANTES
												</Typography>
											)}
										</Grid>
									</Grid>
								) : (
									<Grid
										item
										xs={12}
										lg={7.5}
										container
										justifyContent={'space-between'}
										wrap={'nowrap'}
										className={
											lot?.nome !== 'SPRINT+' ? null : 'background-central-concursos-vertical'
										}
										columnGap={1}
										style={{
											backgroundColor: lot?.nome !== 'SPRINT+' ? '#ffffff' : null,
											padding: matchesMobile ? '0.5rem 0.5rem 0.25rem' : '0.5rem 0.75rem 0.5rem',
											borderRadius: '0 0 8px 8px',
											marginBottom: '1rem',
										}}
									>
										<Typography
											color={lot?.nome === 'SPRINT+' ? 'secondary' : 'primary'}
											className="poppins"
											style={{
												fontSize: matchesMobile ? '0.7rem' : '0.8rem',
												alignSelf: matchesMobile ? 'center' : null,
											}}
										>
											<strong>
												{lot?.nome === 'SPRINT+' ? 'Lote Sprint+' : `Lote ${lot?.nome}`}
											</strong>
											<br />
											{lot?.nome === 'SPRINT+'
												? 'se encerra em'
												: lotModality === 'online'
												? 'se encerra em'
												: 'começa em'}
										</Typography>
										<CountDownTimer
											targetDate={endDate}
											textColor={lot?.nome === 'SPRINT+' ? 'secondary' : 'primary'}
										/>
									</Grid>
								))}
							<Grid
								item
								container
							>
								{contest?.modality === 'presencial' && (
									<Typography
										color="primary"
										className={`${classes.text} poppins`}
									>
										{lot?.nome === 'SPRINT+' ? 'Apenas para alunos Sprint Final' : null}
										{lot?.nome === 'SPRINT+' ? <br /> : null}
										Evento Presencial em <strong>{contest?.locationCity}</strong>
									</Typography>
								)}
							</Grid>
							<Grid
								item
								container
								alignItems={'center'}
								style={{ margin: '0.5rem 0' }}
							>
								<img
									src={require('../../../../../Assets/Svg/icon-date-watch.svg').default}
									alt="live das lives"
									style={{ filter: 'invert(1)' }}
								/>
								<Typography
									color="primary"
									className={`${classes.dateText} poppins`}
								>
									&nbsp; Dia {''}
									<strong>
										{contest?.date} {''}
									</strong>
									a partir das {''}
									<strong>
										{window.location.pathname === '/live-das-lives-psu-mg' ||
										window.location.pathname === '/live-das-lives-iamspe'
											? '14:00h'
											: window.location.pathname === '/live-das-lives-usp' ||
											  window.location.pathname === '/live-das-lives-unifesp' ||
											  window.location.pathname === '/live-das-lives-sus'
											? '15:00h'
											: window.location.pathname === '/live-das-lives-famerp'
											? '14:30h'
											: window.location.pathname === '/live-das-lives-amrigs'
											? '10:00h'
											: '09:00h'}
									</strong>
									<br />
								</Typography>
							</Grid>
							<Grid
								item
								container
								style={{ marginTop: matchesMobile ? '1rem' : null }}
							>
								<DefaultButton
									variant="outlined"
									color={'secondary'}
									className={`${classes.subscribeBtn} ${
										isActive ? 'background-new-ldl-residencia-horizontal' : null
									} poppins`}
									component={Link}
									to={`//hardworkmedicina.com.br/inscricao/${idTurma}`}
									style={!isActive ? { backgroundColor: '#8D8D8D', color: '#FFFFFF' } : null}
									disabled={!isActive ? true : false}
								>
									{lot !== undefined && (
										<img
											src={
												isActive
													? require('../../../../../Assets/Svg/Icons/bagIcon.svg').default
													: require('../Assets/icon-wait-watch.svg').default
											}
											alt=""
											style={{
												width: 28,
												filter: isActive ? null : 'invert(0.8)',
												marginRight: matchesMobile ? 2 : null,
											}}
										/>
									)}
									&nbsp;
									{isActive
										? 'Inscrever-se'
										: limit === true
										? 'Vagas esgotadas'
										: lot === undefined
										? 'Inscrições encerradas'
										: 'Em breve...'}
								</DefaultButton>
							</Grid>
						</>
					</Grid>
				</Grid>
			</Grid>
			<Grid
				item
				container
				xs={12}
				className={classes.gridImageValues}
			></Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	rootCard: {
		borderRadius: 13,
		backgroundColor: '#000000 !important',
		border: '2px solid white !important',
		// height: 330,
		[theme.breakpoints.down('lg')]: {
			height: 'unset',
		},
		// [theme.breakpoints.down('md')]: {
		// 	height: 'unset',
		// },
	},
	gridImg: {
		height: '100%',
		borderBottomRightRadius: 0,
		borderTopRightRadius: 0,
		padding: '1rem 2rem !important',
		backgroundColor: '#ffffff !important',
		borderTopLeftRadius: 10,
		[theme.breakpoints.down('md')]: {
			height: 'unset',
		},
		[theme.breakpoints.down('sm')]: {
			borderTopRightRadius: 10,
			borderTopLeftRadius: 10,
			padding: '1rem !important',
		},
	},
	logoImg: {
		// width: 468.51,
		// width: '80%',
		minHeight: '75%',
		[theme.breakpoints.down('md')]: {
			padding: '0px',
			maxHeight: 'unset',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0px 10px',
			width: '90%',
		},
	},
	gridPriceInfo: {
		borderTopRightRadius: 10,
		// borderBottomRightRadius: 10,
		backgroundColor: '#ffffff',
		[theme.breakpoints.down('sm')]: {
			padding: '8px 0 ',
			borderTopRightRadius: 0,
		},
	},
	priceTextStrikeThrough: {
		fontSize: '2.5rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		textDecoration: 'line-through',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.15rem !important',
		},
	},
	priceTextWhite: {
		fontSize: '2.565rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.35rem !important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '1.35rem !important',
		},
	},
	text: {
		fontSize: '1.4rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1.15rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.25rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '0.8rem !important',
		},
	},
	dateText: {
		fontSize: '1.3rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	discount: {
		fontSize: '1.1rem',
		color: 'red',
		alignSelf: 'center',
		marginTop: '0.5em',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
			margin: '0.5em 0',
		},
	},
	subscribeBtn: {
		padding: '0.25rem 0 !important',
		alignSelf: 'center',
		fontSize: '1.35rem !important',
		fontWeight: 'bold !important',
		transition: 'ease-in-out 0.2s !important',
		width: '80%',
		'&:hover': {
			scale: 1.05,
			color: '#ffffff !important',
			// '& $logoBtn': {
			// 	filter: 'invert(0)',
			// },
		},
		[theme.breakpoints.down('xl')]: {
			padding: '0.25rem 2.5rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '0.85rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			fontSize: '0.75rem !important',
			padding: '0.25rem 1.25rem !important',
			border: '2px solid transparent !important',
			marginTop: 'unset !important',
		},
	},
	textSubscribeLdlPriceCard: {
		color: '#000000',
		fontSize: '3rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem !important',
		},
	},
	countDownText: {
		fontSize: '1rem !important',
		[theme.breakpoints.down(1290)]: {
			fontSize: '0.945rem !important',
		},
		[theme.breakpoints.down(1261)]: {
			fontSize: '0.9rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
		[theme.breakpoints.down(400)]: {
			fontSize: '0.75rem !important',
		},
		[theme.breakpoints.down(361)]: {
			fontSize: '0.65rem !important',
		},
	},
	gridImageValues: {
		height: 286,
		backgroundImage: `url(${require('../Assets/bg-santa-casa-values.png')})`,
		backgroundSize: 'cover',
		borderBottomLeftRadius: 10,
		borderBottomRightRadius: 10,
		[theme.breakpoints.down('xl')]: {
			height: 228,
		},
		[theme.breakpoints.down('sm')]: {
			backgroundImage: `url(${require('../Assets/bg-santa-casa-values-mobile.png')})`,
			height: 301,
		},
	},
}));
