import React, { useEffect, useState } from 'react';
import { useMediaQuery, useTheme, Grid, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

const colorsGradient = [
    { path: '/sprint-final', colorStartButton: '#FD2C78', colorEndButton: '#FF8251' },
    { path: '/hardworq/cirurgia', colorStartButton: '#36BADC', colorEndButton: '#5B69E5'  },
    { path: '/sprint-final/r3-clinica-medica', colorStartButton: '#FD2C78', colorEndButton: '#FF8251' },

];

const CounterDown = ({ scrollToPayment, date, text, textMobile, colorStartButton, colorEndButton}) => {
	const theme = useTheme();
    const classes = useStyles();

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));


    // const currentPath = window.location.pathname.replace(/\/$/, ''); 

    // const currentGradient = colorsGradient.find(gradient => 
    //     gradient.path.replace(/\/$/, '') === currentPath
    // ) || {};
    
   

	useEffect(() => {
		const timer = setInterval(() => {
			setTimeLeft(calculateTimeLeft());
		}, 1000);

		return () => clearInterval(timer);
	}, []);

	function calculateTimeLeft() {
		const targetDate = new Date(date);
		const now = new Date();
		const difference = targetDate - now;

		let timeLeft = {};

		if (difference > 0) {
			timeLeft = {
				days: Math.floor(difference / (1000 * 60 * 60 * 24)),
				hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
				minutes: Math.floor((difference / 1000 / 60) % 60),
				seconds: Math.floor((difference / 1000) % 60),
			};
		} else {
			timeLeft = {
				days: 0,
				hours: 0,
				minutes: 0,
				seconds: 0,
			};
		}

		return timeLeft;
	}

	const isCountdownZero =
		timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0;

	if (isCountdownZero) {
		return null;
	}

	return (
		<Grid
			container
			direction={matchesMobile ? 'column' : 'row'}
			justifyContent="center"
			alignItems="center"
			className={classes.container}
		>
			<Grid
				item
				xs={12}
                
				className={classes.countdown}
			>
				<Grid
					display="flex"
                    justifyContent={'center'}
					alignItems="center"
                    textAlign={'center'}
                    direction={matchesMobile ? 'column' : 'row'}
				>
					<Typography
						fontSize={matchesMobile ? 17 : 18}
						fontFamily="Manrope"
						fontWeight={500}
                        textAlign={'center'}
						className={classes.text}
						style={{ marginRight: matchesMobile ? '0.5rem' : '2rem',
                            whiteSpace: 'normal'
                            }}
					>
						{matchesMobile ? textMobile : text}
					</Typography>
					<Grid
						item
						fontSize={matchesMobile ? 6 : 12}
						fontFamily="Outfit"
						display="flex"
						justifyContent="center"
						className={classes.countdownItem}
					>
						<Grid
							item
							className={classes.countdownNumber}
                            fontSize={matchesMobile ? 25 : 45}
						>
							{timeLeft.days}
						</Grid>
						<Grid
							display="flex"
							marginBottom={matchesMobile ? 0 : 1.6}
							marginRight={0.8}
							marginLeft={0.3}
							alignItems={'flex-end'}
							item
							className={classes.countdownText}
                            
						>
							{timeLeft.days <= 1 ? 'Dia' : 'Dias'}
						</Grid>
						<Grid
							item
							className={classes.countdownNumber}
                            fontSize={matchesMobile ? 25 : 45}
						>
							{timeLeft.hours}
						</Grid>
						<Grid
							item
							className={classes.countdownText}
							display="flex"
							margin={0.4}
							marginBottom={1.5}
							alignItems="center"
							fontWeight={800}
							fontSize={32}
						>
							:
						</Grid>
						<Grid
							item
							className={classes.countdownNumber}
                            fontSize={matchesMobile ? 25 : 45}
						>
							{timeLeft.minutes}
						</Grid>
						<Grid
							item
							className={classes.countdownText}
							display="flex"
							margin={0.4}
							marginBottom={1.5}
							alignItems="center"
							fontWeight={800}
							fontSize={32}
						>
							:
						</Grid>
						<Grid
							item
							className={classes.countdownNumber}
                            fontSize={matchesMobile ? 25 : 45}
                           marginRight={2}
						>
							{timeLeft.seconds}
						</Grid>
					</Grid>
				</Grid>
				<Grid
					marginRight={2}
					marginLeft={2}
					marginTop={matchesMobile ? 2 : 0}
				>
					<Button
						onClick={() => scrollToPayment()}
						className={classes.optionButton}
                        color='secondary'
                        style={{background: `linear-gradient(45deg, ${colorStartButton} 30%, ${colorEndButton} 90%)`,}}
					>
						Garanta sua vaga!
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		position: 'fixed',
		bottom: 0,
		width: '100%',
		background: 'linear-gradient(204deg, #565656CC 17.06%, #1F1F1FCC 85.86%)',
		color: '#fff',
		padding: '0.5rem',
		zIndex: 1000,
		fontFamily: 'Manrope',
		backdropFilter: 'blur(20px)',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column !important',
			alignItems: 'center !important',
            padding: '1rem !important',
            
		},
	},
	countdown: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column !important',
			alignItems: 'center !important',
		},
	},
	text: {
		marginBottom: theme.spacing(1),
		textAlign: 'start',
		marginLeft: '50px !important',
		flex: 1,
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
			marginBottom: theme.spacing(2),
			marginLeft: '0px !important',
		},
	},
	countdownItem: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: theme.spacing(1),
		marginRight: '50px !important',
		[theme.breakpoints.down('sm')]: {
			marginRight: '0px !important',
			alignItems: 'center !important',
		},
	},
	countdownNumber: {
		fontSize: '37px',
		margin: theme.spacing(0.5),
		fontWeight: 800,
	},
	countdownText: {
		fontSize: '14px',
		margin: theme.spacing(0.5),
		textAlign: 'center',
	},
	optionButton: {
		cursor: 'pointer',
		borderRadius: '24px !important',
		marginRight: '50px !important',
		width: 'fit-content',
		fontFamily: 'Manrope',
		fontSize: '15px !important',	
		alignSelf: 'center',
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(2),
			padding: '0 40px 0 40px !important',
			marginRight: '0px !important',
		},
	},
}));

export default CounterDown;
