import React from 'react';
import { Container, Divider, Grid, IconButton, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

// ASSETS
import InstagramIcon from '../Assets/Svg/Icons/instagramIcon.svg';
import FacebookIcon from '../Assets/Svg/Icons/facebookIcon.svg';
import YoutubeIcon from '../Assets/Svg/Icons/youtubeIcon.svg';
import MasterCardIcon from '../Assets/Svg/Icons/masterCard.svg';
import BoletoIcon from '../Assets/Svg/Icons/boleto.svg';
import PixIcon from '../Assets/LDL/pixIcon.svg';
import VisaIcon from '../Assets/Svg/Icons/visaIcon.svg';
// import HwmAlterLogo from '../Assets/Img/hwmAlterLogo.png';

export default function Footer({ noMarginBottom }) {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const whiteArray = [
		'/sprint-final',
		'/sprint-final/r3-clinica-medica',
		'/hardworq/cirurgia',
		'/pre-inscricao/hardwork-hands-on',
		'/pre-inscricao/hardwork-hands-on',
		'/pre-inscricao/sprint-r3-clinica-medica',
	];
	const isWhiteHeader = whiteArray.includes(window.location.pathname);
	return (
		<Container
			maxWidth={false}
			disableGutters={true}
			className={`${matchesMobile ? classes.sectionMobile : classes.section} ${
				window.location.pathname === '/live/inscricao-sprint-final-revalida-2023' || noMarginBottom
					? classes.sectionLive
					: null
			}`}
		>
			<Grid
				item
				xs={12}
			>
				<Divider
					variant="middle"
					className={classes.divider}
				/>
			</Grid>
			<Container
				maxWidth="xl"
				disableGutters={true}
				className={classes.containerPadding}
			>
				<Grid
					container
					justifyContent="center"
					alignContent="center"
					style={
						matchesMobile
							? { padding: '0 10px' }
							: matchesTablet
							? { padding: '1em' }
							: { padding: '1em 0' }
					}
				>
					{/* <Grid
						item
						xs={12}
						sm={3}
						container
						className={classes.containerInfos}
					>
						<Grid
							item
							container
							alignContent="center"
							xs={12}
							style={matchesMobile ? null : { padding: '0.75em 0' }}
							className={classes.gridTitle}
						>
							<Typography
								sx={{
									// fontSize: {md: '.7em', lg: ".7em"}
									fontWeight: 'bold',
								}}
								color="secondary"
								className={`${classes.text} footer-info-texts poppins`}
							>
								Institucional
							</Typography>
						</Grid>
						<Grid
							item
							container
							alignContent="center"
							xs={12}
						>
							<Link
								color="secondary"
								href="/treinamentos"
								underline="hover"
								variant="caption"
								className={`${classes.subTexts} caption-footer poppins `}
							>
								{'Nossos cursos'}
							</Link>
						</Grid>
						<Grid
							item
							container
							alignContent="center"
							xs={12}
							style={matchesMobile ? { margin: '5px 0' } : null}
						>
							<Link
								color="secondary"
								href="/fale-conosco"
								underline="hover"
								variant="caption"
								className={`${classes.subTexts} caption-footer poppins `}
							>
								{'Fale conosco'}
							</Link>
						</Grid>
						<Grid
							item
							container
							alignContent="center"
							xs={12}
						>
							<Link
								color="secondary"
								href="/faq"
								underline="hover"
								variant="caption"
								className={`${classes.subTexts} caption-footer poppins `}
							>
								{'FAQ.'}
							</Link>
						</Grid>
					</Grid> */}
					<Grid
						item
						xs={12}
						sm={4}
						container
						className={classes.containerInfos}
						style={matchesMobile ? { minWidth: '53%' } : null}
					>
						<Grid
							item
							container
							alignContent="center"
							xs={12}
							className={classes.gridTitle}
						>
							<Typography
								sx={{
									// fontSize: {md: '.7em', lg: ".7em"}
									fontWeight: 'bold',
								}}
								color={isWhiteHeader ? 'primary' : 'secondary'}
								className={`${classes.text} footer-info-texts poppins`}
							>
								Fale conosco
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							container
							alignContent="center"
							className={classes.padding8px}
						>
							<IconButton
								className={`${classes.iconBtn} default-icon-button`}
								href="https://www.instagram.com/hardworkmedicina/"
								target="_blank"
							>
								<img
									src={InstagramIcon}
									alt="Instagram"
									className={classes.linkIcon}
								/>
							</IconButton>
							<IconButton
								className={`${classes.iconBtn} default-icon-button`}
								href="https://pt-br.facebook.com/hardworkmedicina/"
								target="_blank"
							>
								<img
									src={FacebookIcon}
									alt="Facebook"
									className={classes.linkIcon}
								/>
							</IconButton>
							<IconButton
								className={`${classes.iconBtn} default-icon-button`}
								href="https://www.youtube.com/c/HardworkMedicina"
								target="_blank"
							>
								<img
									src={YoutubeIcon}
									alt="Youtube"
									className={`${classes.linkIcon} yt-icon`}
								/>
							</IconButton>
						</Grid>
						<Grid
							item
							xs={12}
						>
							<Link
								color="secondary"
								href="mailto:contato@hardworkmedicina.com.br"
								underline="hover"
								variant="caption"
								className={`${classes.subTexts} caption-footer poppins `}
							>
								{'contato@hardworkmedicina.com.br'}
							</Link>
						</Grid>
						<Grid
							item
							xs={12}
						>
							<Link
								color="secondary"
								href="https://api.whatsapp.com/send?phone=5511932443698"
								underline="hover"
								align="right"
								className={`${classes.subTexts} caption-footer poppins`}
							>
								WhatsApp: (11) 93244-3698
							</Link>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						sm={4}
						container
						className={classes.containerInfos}
					>
						<Grid
							item
							container
							alignContent="center"
							xs={12}
							className={classes.gridTitle}
						>
							<Typography
								sx={{
									fontWeight: 'bold',
								}}
								color={isWhiteHeader ? 'primary' : 'secondary'}
								className={`${classes.text} footer-info-texts poppins`}
							>
								Pagamento
							</Typography>
						</Grid>
						<Grid
							item
							xs={7}
							sm={9}
							md={8}
							container
							direction="row"
							className={`${classes.padding8px} ${classes.paymentGrid}`}
						>
							<Grid
								item
								xs={12}
								sm={4}
								md={3}
								container
								alignContent="center"
							>
								<img
									src={VisaIcon}
									alt="Visa"
									className={classes.paymentIcon}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								sm={4}
								md={3}
								container
								alignContent="center"
								style={{ margin: '0px 8px' }}
							>
								<img
									src={MasterCardIcon}
									alt="MasterCard"
									className={classes.paymentIcon}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								sm={4}
								md={3}
								container
								alignContent="center"
							>
								<img
									src={window.location.pathname === '/live-das-lives-revalida' ? PixIcon : BoletoIcon}
									alt="Boleto"
									className={classes.paymentIcon}
									style={
										window.location.pathname === '/live-das-lives-revalida'
											? { width: 'unset' }
											: null
									}
								/>
							</Grid>
						</Grid>
						<Grid
							item
							container
							alignContent="center"
							xs={12}
						>
							<Typography
								variant="caption"
								color="secondary"
								className={`${classes.subTexts} caption-footer poppins `}
							>
								Pagamentos com cartão de crédito
								<br />
								ou {window.location.pathname === '/live-das-lives-revalida' ? 'PIX' : 'boleto bancário'}
								.
							</Typography>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						sm={4}
						container
						className={classes.containerInfos}
						style={matchesMobile ? { minWidth: '53%' } : null}
					>
						<Grid
							item
							xs={12}
							container
							alignContent="center"
							className={classes.gridTitle}
						>
							<Typography
								sx={{
									fontWeight: 'bold',
								}}
								color={isWhiteHeader ? 'primary' : 'secondary'}
								className={`${classes.text} footer-info-texts poppins`}
							>
								Segurança
							</Typography>
						</Grid>
						<Grid
							item
							xs={12}
							className={classes.padding8px}
						>
							<IconButton
								className={`${classes.safeBrowsingButton} default-icon-button`}
								href="https://transparencyreport.google.com/safe-browsing/search?url=https:%2F%2Fhardworkmedicina.com.br%2F&hl=pt_BR"
								target="_blank"
								style={matchesMobile ? { padding: 0 } : null}
							>
								<img
									src={process.env.PUBLIC_URL + '/Assets/Img/safeBrowsing.png'}
									alt="Google Safe Browsing Logo"
									className={classes.googleSafeImg}
								/>
							</IconButton>
						</Grid>
						<Grid
							item
							xs={12}
						>
							<Typography
								variant="caption"
								color="secondary"
								className={`${classes.subTexts} caption-footer poppins `}
							>
								Compra 100% segura. Seus dados estão protegidos.
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Container>
			<Grid
				item
				xs={12}
			>
				<Divider
					variant="middle"
					className={classes.divider}
				/>
			</Grid>
			<Container
				maxWidth="xl"
				disableGutters={true}
				className={classes.containerPadding}
			>
				<Grid
					item
					xs={12}
					container
					justifyContent="space-between"
					style={
						matchesMobile
							? { padding: '.5em 0' }
							: matchesTablet
							? { padding: '.5em 8px' }
							: { padding: '.5em 0' }
					}
				>
					<IconButton
						href="/"
						className="default-icon-button"
						style={
							matchesMobile
								? {
										paddingLeft: 'unset !important',
										display: 'flex',
										justifyContent: 'flex-start',
										width: '90px',
								  }
								: { paddingLeft: '0', display: 'flex', justifyContent: 'flex-start' }
						}
					>
						<img
							src={process.env.PUBLIC_URL + '/Assets/Img/hwmAlterLogo.png'}
							alt="Hardwork Medicina"
							className={`logo-footer`}
						/>
					</IconButton>
					<Grid
						item
						xs={8}
						container
						direction="column"
						justifyContent="center"
						alignContent="flex-end"
					>
						<Typography
							variant="caption"
							color="#6D6D6D"
							align="right"
							className={`${classes.copyrightText} poppins`}
						>
							© Copyright 2024 WEMED
						</Typography>
						<Grid>
							<Link
								color="#6D6D6D"
								href="https://hardworkmedicina.com.br/politica-de-privacidade"
								target="_blank"
								underline="hover"
								variant="caption"
								align="right"
								className={`${classes.copyrightText} poppins`}
							>
								Politica de privacidade
							</Link>
							<Typography
								variant="caption"
								color="#6D6D6D"
								align="right"
								className={`${classes.copyrightText} poppins`}
							>
								&nbsp;•&nbsp;
							</Typography>
							<Link
								color="#6D6D6D"
								href="https://hardworkmedicina.com.br/termos-de-uso"
								target="_blank"
								underline="hover"
								variant="caption"
								align="right"
								className={`${classes.copyrightText} poppins`}
							>
								Termos de uso
							</Link>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px !important',
		margin:
			window.location.pathname === '/sprint-final' ||
			window.location.pathname === '/pre-inscricao/hardwork-hands-on' ||
			window.location.pathname === '/hardworq/cirurgia' ||
			window.location.pathname === '/sprint-final/r3-clinica-medica' || 
            window.location.pathname === '/pre-inscricao/hardwork-hands-on' ||
            window.location.pathname === '/pre-inscricao/sprint-r3-clinica-medica'

				? '0'
				: '2.5% auto',
	},
	linkIcon: {
		width: 35,
		filter:
			(window.location.pathname === '/sprint-final' ||
				window.location.pathname === '/pre-inscricao/hardwork-hands-on' ||
				window.location.pathname === '/hardworq/cirurgia' ||
				window.location.pathname === '/sprint-final/r3-clinica-medica' || 
                window.location.pathname === '/pre-inscricao/hardwork-hands-on' ||
                window.location.pathname === '/pre-inscricao/sprint-r3-clinica-medica') &&
			'invert(1)',
		[theme.breakpoints.down('md')]: {
			width: 30,
		},
	},
	text: {
		fontSize: '1.2rem !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
			lineHeight: 'unset !important',
		},
	},
	divider: {
		margin: 'unset !important',
		backgroundColor: '#6D6D6D',
		minHeight: 1,
	},
	subTexts: {
		fontSize: '1rem !important',
		color:
			window.location.pathname === '/sprint-final' ||
			window.location.pathname === '/pre-inscricao/hardwork-hands-on' ||
			window.location.pathname === '/hardworq/cirurgia' ||
			window.location.pathname === '/sprint-final/r3-clinica-medica' || 
            window.location.pathname === '/pre-inscricao/hardwork-hands-on' ||
            window.location.pathname === '/pre-inscricao/sprint-r3-clinica-medica'
				? '#000000 !important'
				: '#DFDFDF !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.8rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '0.6rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.82rem !important',
			letterSpacing: 'unset !important',
		},
	},
	copyrightText: {
		fontSize: '0.8rem !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.6rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.65rem !important',
		},
	},
	sectionMobile: {
		padding: '7.5% 0 2vh',
	},
	safeBrowsingButton: {
		display: 'flex !important',
		justifyContent: 'flex-start !important',
	},
	containerInfos: {
		[theme.breakpoints.down('sm')]: {
			margin: '10px 0 !important',
		},
	},
	iconBtn: {
		[theme.breakpoints.down('md')]: {
			padding: '0px 8px !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '0 5px !important',
		},
	},
	containerPadding: {
		padding: '0 0.5rem',
	},
	padding8px: {
		padding: '8px 0',
		flexWrap: 'nowrap !important',
	},
	gridTitle: {
		[theme.breakpoints.down('sm')]: {
			marginBottom: '8px !important',
		},
	},
	googleSafeImg: {
		width: '30%',
		filter:
			(window.location.pathname === '/sprint-final' ||
				window.location.pathname === '/pre-inscricao/hardwork-hands-on' ||
				window.location.pathname === '/hardworq/cirurgia' ||
				window.location.pathname === '/sprint-final/r3-clinica-medica' ||
                window.location.pathname === '/pre-inscricao/hardwork-hands-on' ||
                window.location.pathname === '/pre-inscricao/sprint-r3-clinica-medica') &&
			'invert(1) grayscale(1)',
		[theme.breakpoints.down('lg')]: {
			width: '40%',
		},
		[theme.breakpoints.down('md')]: {
			width: '45%',
		},
		[theme.breakpoints.down('sm')]: {
			width: '30%',
		},
	},
	paymentGrid: {
		margin: '8px 0',
		[theme.breakpoints.down('md')]: {
			margin: '0',
		},
	},
	paymentIcon: {
		width: 60,
		filter:
			(window.location.pathname === '/sprint-final' ||
				window.location.pathname === '/pre-inscricao/hardwork-hands-on' ||
				window.location.pathname === '/hardworq/cirurgia' ||
				window.location.pathname === '/sprint-final/r3-clinica-medica' ||
                window.location.pathname === '/pre-inscricao/hardwork-hands-on' ||
                window.location.pathname === '/pre-inscricao/sprint-r3-clinica-medica') &&
			'invert(1)',
		[theme.breakpoints.down('sm')]: {
			width: 52,
		},
	},
	sectionLive: {
		marginTop: 0,
		[theme.breakpoints.down('md')]: {
			marginTop: '2.5%',
		},
	},
}));
