import React from 'react';
import { Grid, Paper, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DefaultButton from '../Buttons/DefaultButton';

export default function FirstSection({
	backgroundImageUrl,
	logoUrl,
	buttonText,
	executeScroll,
	firstTextPaper,
	type,
	buttonProps,
	buttonDisabled,
	imageProps,
}) {
	const classes = useStyles();
	const theme = useTheme();

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));
	const matchesXl = useMediaQuery(theme.breakpoints.down('xl'));
	const isExtensivo24 = window.location.pathname === '/extensivo-r1-2024';

	return (
		<Grid
			container
			alignContent="center"
			justifyContent="center"
			className={classes.firstSection}
			style={matchesMobile ? { marginTop: 60 } : matchesTablet ? { marginTop: 80 } : null}
		>
			<Grid
				item
				xs={12}
				container
				alignContent="center"
			>
				<Grid
					item
					xs={12}
					md={10}
					onClick={buttonText ? null : isExtensivo24 ? () => executeScroll() : null}
					style={{ cursor: isExtensivo24 ? 'pointer' : null }}
				>
					<img
						src={logoUrl}
						alt=""
						className={classes.logo}
						style={
							imageProps
								? {
										width: matchesMobile
											? window.location.pathname === '/migracao-trilha-revalida'
												? '60%'
												: window.location.pathname === '/pre-inscricao/trilha-revalida'
												? '70%'
												: window.location.pathname === '/trilha-rplus-2024'
												? '95%'
												: window.location.pathname === '/trilha-r1-2024'
												? '80%'
												: window.location.pathname === '/trilha-r3-clinica-medica'
												? '80%'
												: window.location.pathname === '/trilha-r1-2024-2025'
												? '80%'
												: '50%'
											: matchesTablet && window.location.pathname === '/trilha-r1-2024'
											? '80%'
											: imageProps.width,
										left:
											matchesMobile &&
											window.location.pathname === '/pre-inscricao/trilha-revalida'
												? '3em'
												: matchesMobile
												? '5em'
													? window.location.pathname === '/trilha-r1-2024'
														? '2rem'
														: '0rem'
													: null
												: matchesTablet
												? '7em'
													? window.location.pathname === '/pre-inscricao/trilha-r1-2024'
														? '5em'
														: null
													: '4em'
												: imageProps.left,
										top:
											matchesMobile &&
											window.location.pathname === '/pre-inscricao/trilha-revalida'
												? '3.5em'
												: matchesMobile && window.location.pathname === '/trilha-rplus-2024'
												? '5em'
												: matchesMobile
												? '1.5em'
													? window.location.pathname === '/trilha-r1-2024'
														? '4.5em'
														: null
													: null
												: matchesLg
												? window.location.pathname === '/migracao-trilha-revalida'
													? '0.75em'
													: '2.75em'
													? window.location.pathname === '/pre-inscricao/trilha-r1-2024'
														? '10em'
														: matchesTablet
													: '2.75em'
												: imageProps.top,
								  }
								: null
						}
					/>
					<img
						src={backgroundImageUrl}
						alt=""
						style={
							isExtensivo24
								? { opacity: 0.7, marginBottom: '2rem' }
								: {
										opacity:
											window.location.pathname ===
											'/pre-inscricao/live-treinamento-discursiva-revalida'
												? 0.45
												: 0.7,
								  }
						}
					/>
					{buttonText ? (
						<Grid
							item
							xs={8}
							sm={6}
							container
							justifyContent="center"
							className={`${classes.gridButton} ${
								type === 'red' ? 'residencia' : type === 'green' ? 'revalida' : null
							}`}
							style={
								buttonProps
									? {
											bottom: matchesMobile
												? 'unset'
												: matchesTablet
												? '2.5em'
												: matchesXl
												? '5.5em'
												: buttonProps.bottom,
											left: matchesLg ? '3em' : buttonProps.left,
									  }
									: null
							}
						>
							<DefaultButton
								disabled={buttonDisabled ? buttonDisabled : false}
								variant="outlined"
								color={buttonProps ? 'primary' : 'secondary'}
								className={`${classes.btnFirstSection} ${
									buttonProps ? buttonProps.type : 'grid-btn-subscribe btn'
								} ${buttonProps ? 'white-btn-secondary' : 'btn-secondary'} poppins`}
								onClick={() => executeScroll()}
							>
								{buttonProps?.buttonLogoUrl ? (
									<img
										src={buttonProps.buttonLogoUrl}
										alt=""
										className={classes.logoBtn}
									/>
								) : null}
								&nbsp;{buttonText}
							</DefaultButton>
						</Grid>
					) : null}
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					container
					justifyContent={window.location.pathname === '/migracao-trilha-revalida' ? 'center' : 'flex-start'}
					alignContent="center"
					className={classes.firstSectionCard}
				>
					{window.location.pathname === '/migracao-trilha-revalida' ? (
						<iframe
							// width="560"
							width="940"
							// height="315"
							height={matchesMobile ? '500' : matchesLg ? '400' : '529'}
							src="https://player.vimeo.com/video/857680008"
							title="Vimeo video player"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowfullscreen
							style={{ borderRadius: '20px', width: 'fit-content', border: '2px solid #5f5858' }}
						></iframe>
					) : (
						<Grid
							item
							xs={12}
							container
							justifyContent={matchesTablet ? 'center' : 'flex-end'}
							style={
								window.location.pathname === '/sprint-final-revalida' ||
								window.location.pathname === '/trilha-revalida' ||
								window.location.pathname === '/sprint-final'
									? {
											marginTop: '1rem',
											padding: matchesLg ? 0 : '0 4rem',
									  }
									: matchesMobile && window.location.pathname === '/trilha-r1-2024'
									? { marginBottom: '2rem' }
									: null
							}
						>
							<Paper
								color="primary"
								className={`${classes.firstSectionPaper} box box-primary`}
								elevation={4}
							>
								<Grid
									item
									xs={12}
									className={classes.gridItem}
									container
									justifyContent="flex-start"
									alignContent="center"
								>
									{firstTextPaper}
								</Grid>
							</Paper>
						</Grid>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme) => ({
	firstSection: {
		maxWidth: '1410px',
		margin: '20px auto',
		[theme.breakpoints.down('sm')]: {
			padding: '2vh 5% 0 !important',
			margin: '20px auto 0',
		},
	},
	gridButton: {
		position: 'absolute',
		bottom: '6.5em',
		[theme.breakpoints.down('md')]: {
			bottom: '0.5em',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			bottom: '1.5em',
		},
	},
	btnFirstSection: {
		width: '65%',
		fontSize: '1.425rem !important',
		fontWeight: 'bold !important',
		'&:hover': {
			scale: 1.05,
			color: '#ffffff',
			'& $logoBtn': {
				filter: 'invert(0%) !important' /* Linha para passar estilo do pai para o filho */,
			},
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
			minHeight: 'unset !important',
			width: '80%',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.9rem !important',
			width: '100%',
		},
	},
	firstSectionCard: {
		height: '100%',
		position: 'absolute',
		right: '0',
		[theme.breakpoints.down('xl')]: {
			right: '1.5rem',
		},
		[theme.breakpoints.down('md')]: {
			position: 'unset !important',
			right: 'unset !important',
			height: 'unset !important',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'unset !important',
			top: 'unset !important',
			margin: window.location.pathname === '/migracao-trilha-revalida' ? '1.5rem 0 !important' : 'unset',
		},
	},
	firstSectionPaper: {
		color: 'unset !important',
		height: '100%',
		borderRadius: '30px !important',
		padding: '1.5rem 1.5em 2.8em',
		paddingTop: 20,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			padding: '1rem !important',
		},
	},
	gridItem: {
		padding: '.5em 0',
	},
	firstText: {
		fontSize: '3rem !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '2rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem !important',
		},
	},
	logo: {
		position: 'absolute',
		zIndex: 1,
		top: window.location.pathname === '/migracao-trilha-revalida' ? '4.75em' : '12.75em',
		left: window.location.pathname === '/sprint-final' ? '2em' : '6em',
		maxWidth:
			window.location.pathname === '/sprint-final' ||
			window.location.pathname === '/trilha-r1-2024' ||
			window.location.pathname === '/trilha-r3-clinica-medica'
				? 'unset'
				: 498,
		// cursor: 'pointer',
		[theme.breakpoints.down('xl')]: {
			top: '10.75em',
			width: 400,
		},
		[theme.breakpoints.down('lg')]: {
			top: '5.75em',
			width: 300,
		},
		[theme.breakpoints.down('sm')]: {
			width: 250,
			top: '2.75em',
			left: '.5em',
		},
	},
	logoBtn: {
		filter: 'invert(100%)',
		height: window.location.pathname === '/migracao-trilha-revalida' ? '1.75rem' : null,
		marginRight: window.location.pathname === '/migracao-trilha-revalida' ? '0.5rem' : null,
		[theme.breakpoints.down('sm')]: {
			width: 25,
		},
		// '&:hover': {
		// 	filter: 'invert(0%) !important',
		// },
	},
}));
