import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Divider, Grid, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../Assets/Svg/HardworkLogo.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import HomeIcon from '@mui/icons-material/Home';

// import SearchIcon from '@mui/icons-material/Search';

const useStyles = makeStyles((theme) => ({
	toolbar: {
		display: 'flex',
		justifyContent: 'space-evenly',
		minHeight: 73,
		position: 'relative',
		maxWidth: '1410px',
		marginRight: 'auto',
		marginLeft: 'auto',
	},
	logo: {
		width: '6em',
		paddingLeft: '0',
	},
	divider: {
		margin: 'unset !important',
		backgroundColor: 'grey',
		minHeight: 1,
	},
	btn: {
		border: '1px solid !important',
		height: '80%',
		fontSize: '.8rem',
		fontWeight: 'bold !important',
	},
	headerButtons: {
		textTransform: 'unset !important',
		fontSize: '1rem !important',
		whiteSpace: 'nowrap',
		borderStyle: 'none !important',
	},
	btnCadastro: {
		height: '80%',
		fontSize: '.8rem',
		fontWeight: 'bold !important',
	},
	// inputRoot: {
	// 	color: 'inherit !important',
	// },
	// inputInput: {
	// 	padding: '8px 12px !important',
	// 	paddingRight: `calc(1em + 12px) !important`,
	// 	transition: theme.transitions.create('width'),
	// 	width: '20ch !important',
	// 	'&:focus': {
	// 		width: '35ch !important',
	// 	},
	// 	[theme.breakpoints.down('lg')]: {
	// 		width: '20ch !important',
	// 		'&:focus': {
	// 			width: '30ch !important',
	// 		},
	// 	},
	// },
	// gridSearchBar: {
	// 	height: 40,
	// 	backgroundColor: '#3A3A3A',
	// 	borderRadius: 10,
	// 	display: 'flex',
	// 	alignSelf: 'center',
	// 	[theme.breakpoints.down('lg')]: {
	// 		height: 30,
	// 	},
	// },
	menuBtnHover: {
		fontSize: '1rem !important',
		padding: '6px 16px !important',
		fontWeight: '500 !important',
		color: '#ffffff !important',
		minHeight: 'auto',
		zIndex: 1100,
		borderRadius: '2.5px !important',
		width: '100%',
		display: 'flex !important',
		justifyContent: 'flex-start !important',
		textTransform: 'unset !important',
		'&:hover': {
			backgroundColor: '#3A3A3A !important',
		},
	},
	navMenuDropDown: {
		position: 'absolute',
		top: 39,
		padding: '10px 0',
		borderRadius: 12,
		zIndex: 1000,
		backgroundColor: '#302d2d !important',
		display: 'flex',
		alignItems: 'center',
		left: '33%',
		width: 220,
		justifyContent: 'center',
		[theme.breakpoints.down('xl')]: {
			width: 180,
		},
		[theme.breakpoints.down('lg')]: {
			left: '26%',
		},
		[theme.breakpoints.down('md')]: {
			left: '21%',
			width: 220,
		},
	},
	gridLoginBtn: {
		width: '10% !important',
		[theme.breakpoints.down('lg')]: {
			width: '12% !important',
		},
		[theme.breakpoints.down('md')]: {
			width: '15% !important',
		},
	},
	hoverBtn: {
		transition: 'ease 0.2s',
		'&:hover': {
			opacity: '0.90 !important',
		},
	},
}));

const whiteArray = [
    '/sprint-final',
    '/sprint-final/r3-clinica-medica',
    '/hardworq/cirurgia',
    '/pre-inscricao/hardwork-hands-on',
    '/pre-inscricao/hardwork-hands-on',
    '/pre-inscricao/sprint-r3-clinica-medica',

  ];

export function Header() {
	const classes = useStyles();

	const theme = useTheme();

	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	const [openMenuDropDown, setOpenMenuDropDown] = useState(false);
	// const [anchorEl, setAnchorEl] = useState(null);
	// const [anchorEl2, setAnchorEl2] = useState(null);
	let location = useLocation();
	// const open = Boolean(anchorEl);
	// const open2 = Boolean(anchorEl2);

	// const handleSearch = () => {
	// 	// TODO
	// };
	// function enviarDadosParaAPI(caminho, utmSource, utmMedium, utmCampaign, utmTerm, utmContent) {
	// 	console.log(caminho, utmSource, utmMedium, utmCampaign, utmTerm, utmContent);
	// }

	// useEffect(() => {
	// 	const pathname = window.location.pathname;
	// 	const urlParams = new URLSearchParams(window.location.search);
	// 	const utmSource = urlParams.get('utm_source');
	// 	const utmMedium = urlParams.get('utm_medium');
	// 	const utmCampaign = urlParams.get('utm_campaign');
	// 	const utmTerm = urlParams.get('utm_term');
	// 	const utmContent = urlParams.get('utm_content');
	// 	enviarDadosParaAPI(pathname, utmSource, utmMedium, utmCampaign, utmTerm, utmContent);
	// }, []);

	const handleHover = (event) => {
		setOpenMenuDropDown(true);
	};
	const handleClose = () => {
		setOpenMenuDropDown(false);
	};
    
	return (
		<Grid
			container
			style={{
                backgroundColor: whiteArray.includes(window.location.pathname) ? '#f5f5f5' : null,
                padding: '0 20px',
              }}
		>
			<Grid
				container
				className={classes.toolbar}
			>
				<Grid
					container
					style={matchesTablet ? { padding: '8px 1em' } : { padding: '8px 0' }}
				>
					<Grid
						item
						container
						xs={2}
						style={{ padding: '5px 0' }}
					>
						<IconButton
							component={Link}
							to="/"
							className="default-icon-button"
							style={{
								paddingLeft: '0',
							}}
						>
							<img
								src={Logo}
								alt="Hardwork Medicina Logo"
								className={`${classes.logo} logo`}
								style={{
									filter:
                                    whiteArray.includes(window.location.pathname)
											? 'invert(1)'
											: null,
								}}
							/>
						</IconButton>
					</Grid>
					<Grid
						item
						xs={10}
						container
						alignContent="center"
						justifyContent="flex-end"
					>
						{/* TO DO DEPOIS */}
						{/* <Grid
                            item
                            style={{ display: 'flex', alignItems: 'center', marginRight: '24px' }}
                        >
                            <Grid className={classes.gridSearchBar}>
                                <InputBase
                                    placeholder="Pesquise seu curso..."
                                    classes={{ root: classes.inputRoot, input: classes.inputInput }}
                                    color="#000000"
                                ></InputBase>
                                <IconButton
                                    size="large"
                                    aria-label="search"
                                    color="inherit"
                                    onClick={handleSearch}
                                    style={{ borderRadius: 0 }}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </Grid>
                        </Grid> */}
						<Grid
							item
							style={{ display: 'flex', alignItems: 'center' }}
						>
							<Button
								color={
									whiteArray.includes(window.location.pathname)
										? 'primary'
										: 'secondary'
								}
								size="medium"
								classes={{
									root: classes.btnCadastro,
								}}
								className="register-btn top-buttons-header poppins"
								href="//hardworkmedicina.com.br/cadastro"
								style={{
									border:
                                    whiteArray.includes(window.location.pathname)
											? '1px solid transparent !important'
											: null,
								}}
							>
								CADASTRE-SE
							</Button>
						</Grid>
						<Grid
							item
							container
							justifyContent={'center'}
							alignContent="center"
							className={classes.gridLoginBtn}
							// style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
						>
							<Button
								variant="outlined"
								color={
									whiteArray.includes(window.location.pathname)
										? 'primary'
										: 'secondary'
								}
								size="medium"
								classes={{
									root: classes.btn,
								}}
								className="login-btn poppins top-buttons-header"
								href="//hardworkmedicina.com.br/login"
							>
								Login
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
				>
					<Divider
						variant="middle"
						className={classes.divider}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					style={{ padding: '.25em' }}
					container
					justifyContent="center"
				>
					<Button
						variant="outlined"
						color={
							whiteArray.includes(window.location.pathname)
								? 'primary'
								: 'secondary'
						}
						size="medium"
						classes={{
							root: classes.headerButtons,
						}}
						className="header-btn poppins"
						component={Link}
						to="/"
						style={
							location.pathname === '/'
								? {
										margin: '0 12px',
										borderBottom: '2px solid #fff !important',
								  }
								: { margin: '0 12px' }
						}
					>
						<HomeIcon />
						&nbsp; Home
					</Button>
					<Button
						variant="outlined"
						color={
							whiteArray.includes(window.location.pathname)
								? 'primary'
								: 'secondary'
						}
						size="medium"
						classes={{
							root: classes.headerButtons,
						}}
						className="header-btn poppins"
						onMouseOver={handleHover}
						onMouseOut={handleClose}
					>
						Nossos cursos
						<KeyboardArrowDownIcon style={{ fontSize: '2rem' }} />
					</Button>
					{openMenuDropDown ? (
						<nav
							className={classes.navMenuDropDown}
							onMouseOver={handleHover}
							onMouseLeave={handleClose}
						>
							<ul
								style={{
									display: 'flex',
									flexDirection: 'column',
									height: '100%',
									justifyContent: 'space-around',
									width: '99%',
								}}
							>
								<Button
									className={`${classes.menuBtnHover} poppins`}
									component={Link}
									to="/residencia-medica"
								>
									Residência Médica
								</Button>
								<Button
									className={`${classes.menuBtnHover} poppins`}
									component={Link}
									to="/revalida-inep"
								>
									Revalida INEP
								</Button>
								{/* <Button
                                    className={`${classes.menuBtnHover} poppins`}
                                    component={Link}
                                    to="/treinamentos"
                                >
                                    Todos os cursos
                                </Button> */}
							</ul>
						</nav>
					) : null}
					{/* <Button
                        variant="outlined"
                        color="secondary"
                        size="medium"
                        classes={{
                            root: classes.headerButtons,
                        }}
                        className="header-btn poppins"
                        component={Link}
                        to="//hardworkmedicina.com.br/turmas"
                    >
                        Inscrições
                    </Button> */}
					{/* <Button
                        variant="outlined"
                        color="secondary"
                        size="medium"
                        classes={{
                            root: classes.headerButtons,
                        }}
                        className="header-btn poppins"
                        component={Link}
                        to="/faq"
                        style={{ margin: '0 16px' }}
                    >
                        FAQ
                    </Button> */}
					<Button
						variant="outlined"
						color={
							whiteArray.includes(window.location.pathname)
								? 'primary'
								: 'secondary'
						}
						size="medium"
						classes={{
							root: classes.headerButtons,
						}}
						className="header-btn poppins"
						component={Link}
						to="/fale-conosco"
					>
						Contato
					</Button>
					<Button
						variant="outlined"
						color={
							whiteArray.includes(window.location.pathname)
								? 'primary'
								: 'secondary'
						}
						size="medium"
						classes={{
							root: classes.headerButtons,
						}}
						className="header-btn poppins"
						component={Link}
						to="//blog.hardworkmedicina.com.br/"
					>
						Blog
					</Button>
					<Button
						variant="outlined"
						color="secondary"
						size="medium"
						classes={{
							root: classes.headerButtons,
						}}
						className={`${classes.hoverBtn} header-btn poppins background-central-concursos-horizontal`}
						component={Link}
						to="/materiais/central-de-concursos"
						style={{ marginLeft: '0.5rem' }}
					>
						<img
							src={require('../Assets/Svg/icon-book.svg').default}
							alt=""
						/>
						&nbsp; Central de concursos
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
}
