import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import 'chartjs-plugin-datalabels';
ChartJS.register(ArcElement, Tooltip, Legend);

export default function DoughnutChart() {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Ler o parâmetro "progress" da URL
    const urlParams = new URLSearchParams(window.location.search);
    const progressParam = urlParams.get('progress');
    if (progressParam) {
      setProgress(parseInt(progressParam, 10));
    }
  }, []);

  const getColor = () => {
    if (progress <= 20) return '#DC1717';
    if (progress <= 59) return '#DC7317';
    if (progress <= 79) return '#DCBB17';
    if (progress <= 99) return '#B6DC17';
    return '#59DC17';
  };

  const data = {
    labels: ['Progress', 'Remaining'],
    datasets: [
      {
        label: 'Progress',
        data: [progress, 100 - progress],
        backgroundColor: [getColor(), 'rgba(158, 158, 158, 1)'],
        borderColor: ['rgba(220, 115, 23, 1)'],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '70%', // Tamanho do corte do círculo interno
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: true,
        align: 'center',
        formatter: function () {
          return `${progress}%`;
        },
      },
    },
    animation: {
      animateRotate: true,
      animateScale: true,
    },
  };

  return (
    <div style={{ position: 'relative', width: '300px', height: '300px', background: '#fff'  }}>
      <Doughnut data={data} options={options} />
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '4rem',
          fontWeight: 'bold',
          color: '#000',
        }}
      >
        {progress}%
      </div>
    </div>
  );
}
